import React, { useEffect, useState } from 'react';
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import { ToastContainer, toast } from "react-toastify";
//radio button imports start
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
//radio button imports end
import edit from "./../../../assests/images/edit.png";
import print from "./../../../assests/images/printing.png";
import DataTable from "../../sharedComponent/dataTable/DataTable";
import axios from "axios";

function MeasurementUnitSetup() {
  

const apiAddress = process.env.REACT_APP_SECRET;
const[Search, setSearch]=useState()
const[UnitDes, setUnitDes]=useState('')
const[Empty, setEmpty]=useState()
const[MeasurementUnit, setMeasurementUnit]=useState({

    Id: 0,
    UnitDescription: "",
    IsActive: true,
    CreatorId: 0,
    CreationDate: "2023-10-25T05:33:21.663Z",
    UpdatorId: 0,
    UpdateDate: "2023-10-25T05:33:21.663Z"
  
});

  //Measurement table declaration start
  const [measurementisLoading, SetmeasurementIsLoading] = React.useState(false);
  
  const [measuremntUnitRows, setmeasuremntUnitRows] = React.useState([]);

  const [measurementpageState, setmeasurementpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const MeasurmentUnitcolumns = [
    { field: "sn", headerName: "IS/N", width: 170 },
    { field: "unitdescription", headerName: "Unit Description", width: 170 },
    {
      field: "status",
      headerName: "Status",
      width: 170,
    },

    {
      field: "action",
      headerName: "Action",
      width: 170,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div

          // onClick={() => {
          //   onEditClick(params.row.id);
          // }}
          >
            <img src={edit} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
// measurement declearation ends

//measurement funtions start
  const GetMeasurementNoAPi = async (props) => {
      await MeasurementListPaged(props, "Main");
  };

  const MeasurementListPaged = async(props,from) =>{
    if (from != "Search") {
      setmeasurementpageState((old) => ({ ...old, isLoading: true }));

      SetmeasurementIsLoading(true);

      await axios
        .get(
          "" +
          process.env.REACT_APP_SECRET +
          "/api/MeasurementUnit/GetMeasurementUnitPaged?startRecordNo=" +
          props.page +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          "1=1" +
          "&sortColumn=" +
          "id" +
          "&sortOrder=" +
          "desc" +
          ""
        )
        .then((response) => {
          setmeasurementpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: measurementpageState.page,
          }));
          var list = [];
          response.data._measurementUnitViews.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              unitdescription: d.unitDescription,
              status: d.isActive,

              // action: d.number,
            };
            list.push(obj);
          });
          setmeasuremntUnitRows(list);
          SetmeasurementIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetmeasurementIsLoading(false);
        });
      }
      else{

      // const newString = "'" + Search + "'";
      // const condition = "unitdescription=" + newString;
      var where=""
      if(!!props.whereClause== false || props.whereClause==undefined){
        where="1=1"
        setSearch("");
      }
      else{
        where=props.whereClause
      }
      console.log(where)

      setmeasurementpageState((old) => ({ ...old, isLoading: true }));

      SetmeasurementIsLoading(true);

      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/MeasurementUnit/GetMeasurementUnitPaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            where +
            "&sortColumn=" +
            "id" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setmeasurementpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: measurementpageState.page,
          }));
          var list = [];
          response.data._measurementUnitViews.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              unitdescription: d.unitDescription,
              status: d.isActive,

              // action: d.number,
            };
            list.push(obj);
          });
          setmeasuremntUnitRows(list);
          SetmeasurementIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetmeasurementIsLoading(false);
        });     
      } 
    };

    const onSearch = async () => {
      var prop= Search
      if(Search == "" || Search == undefined) {
        toast.error("Search is Empty", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
        else
        {
          setSearch(prop);
          var upperText = prop.toUpperCase();
          const newString = "'%" + upperText + "%'";
          const condition = "upper(UnitDescription) like " + newString;
          prop = condition;
        }
    
        var obj = {
          page: 0,
          pageSize: 10,
          whereClause: prop,
        };

      await MeasurementListPaged(obj, "Search");
    };
    const onClear = async () => {
      var props = {
        page: 0,
        pageSize: 10,
      };
      setSearch("");
      await MeasurementListPaged(props, "Main");
    };
  //Acknowledgement table end

  const onSave = async () => {
    if (!!UnitDes == false || UnitDes == undefined) {
      toast.error("Unit Description is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else{
      
      MeasurementUnit.UnitDescription= UnitDes;

      var url = apiAddress + "/api/MeasurementUnit";
      await axios.post(url,MeasurementUnit)

      .then((response) => {    
        toast.success("Insert Successfull", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
     });
    onReset();
    console.log("Inserted")
    var props = {
      page: 0,
      pageSize: 10,
    };
    await MeasurementListPaged(props, "Main");
    console.log("Api hit")

      console.log("not hit")
    }
  };
  const onReset= async() =>{
    if(!!UnitDes== false || UnitDes == undefined ){
      console.log("empty")
    }
    else{
      setUnitDes("");
      console.log("emptied")
    }

  };
  const handleChange = (event) => {
    setUnitDes("");
  };

  return (
    <>
      <div className="card card-shadow p-3">
        <div className="row ">
          <div className="col-12 col-md-5 col-lg-5">
          <ToastContainer />
            <div>
              <h5>Measuremnt Unit</h5>
            </div>
            <TextAreaComponents
              id={5}
              value={(e) => setUnitDes(e)}
              label={"Unit Description"}
              placeholder={"Unit Description"}
              onChange={onReset}
              val={UnitDes}
              //   val={PurchaseOrderNumber}
            />
            <div className="mt-2">
              <FormControl className="d-flex flex-row gap-3">
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  className="mt-2"
                >
                  Status
                </FormLabel>
                <RadioGroup
                  row
                  defaultValue={"active"}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  
                >
                  <FormControlLabel
                    value="active"
                    control={<Radio />}
                    label="Active"
                  />
                  <FormControlLabel
                    value="inactive"
                    control={<Radio />}
                    label="Inactive"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="row mt-3">
              <div className="col-6"></div>
              <div className="col-6">
                <div className="d-flex gap-4 ">
                  <button onClick={()=>{onSave()}} className="btn-yes btn-common">Save</button>
                  <button onClick={()=>{onReset()}} className="btn-no btn-common">Reset</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7 col-lg-7">
            <div>
              <h5>Measurement Unit List</h5>
            </div>
            <div className='row'>
            <div className='mb-3 col-md-6'>
                <TextAreaComponents id={6} value={(e)=>setSearch(e)} label={"Search"} val={Search} />
            </div>
            <div className="col-md-6 d-flex gap-3 ">
                  <button className="btn-yes btn-common" onClick={onSearch}>Search</button>
                  <button className="btn-no btn-common" onClick={onClear}>Reset</button>
                </div>
            </div>

            <div>
              <DataTable
                pageState={measurementpageState}
                GetPurchaseOrderNoAPi={GetMeasurementNoAPi}
                isLoading={measurementisLoading}
                rows={measuremntUnitRows}
                columns={MeasurmentUnitcolumns}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MeasurementUnitSetup;
