import React, { useEffect } from "react";
import banner from "../../../assests/videos/pexels-mr-borys-7959328 (1080p).mp4";
import AOS from "aos";
import "aos/dist/aos.css";

const Banner = () => {
  useEffect(() => {
    AOS.init({
      easing: "ease-in-sine",
    });
  }, []);

  return (
    <div className="">
      <video
        src={banner}
        autoPlay
        loop
        muted
        className="w-full h-screen object-cover relative top-0 left-0 z-0"
      ></video>
      <div className="text-right text-white flex justify-end">
        <div className="relative bottom-96 pr-5 z-10">
          <h1 className="mb-4 text-6xl font-bold" data-aos="fade-down">
            A & H Solutions
          </h1>
          <p className="mb-4" data-aos="fade-down">
            The future of efficient, transparent, and streamlined procurement.
          </p>
          <button
            className="btn bg-purple-700 border-none text-white hover:bg-purple-400"
            data-aos="fade-down"
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default Banner;
