import React, { useEffect, useState } from "react";
import { format, toDate } from 'date-fns';
//import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import dayjs from "dayjs";
import Switch from "@mui/material/Switch";
import { GetAllItemApi } from "../../../../api/adminApi/itemSetpAPi/ItemSetupApi.js";
import TextAreaComponents from "../../../sharedComponent/TextAreaComponents/TextAreaComponents.jsx";
import AutoCompleteMultiSelect from "../../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import { GetAllModelApi } from "../../../../api/adminApi/ModelApi.js";
import { ToastContainer, toast } from "react-toastify";
import { GetAllDesignationApi } from "../../../../api/adminApi/designationApi/DesingnationAPI.js";
import { GetAllCustomerApi } from "../../../../api/adminApi/customer/CustomerApi.js";
import { GetItemUnitPrice,GetItemPricingDetailsById } from "../../../../api/adminApi/itemSetpAPi/ItemSetupApi.js";
import closeicon from "./../../../../assests/images/close.png";
import checkMark from "./../../../../assests/images/check-mark.png";
import print from "./../../../../assests/images/printing.png";

import {
    GetEmployeeAPi,
    GetItem,
    GetSupplierAPi,
    PostPurchaseOrderAPi,
  } from "../../../../api/screenApi/purchaseOrder/purchaseOrderApi.js";
  import "./ItemPricingSetup.css"
  import ItemPricingDetailsReport from "./ItemPricingDetailsReport/ItemPricingDetailsReport.jsx";
//import { GetAllVatApi } from "../../../api/adminApi/VatApi";

// dialog 
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// dialog
//radio button imports start
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CustomDateTimePicker from "../../../sharedComponent/datetimePicker/CustomDateTimePicker.js";
import DataTable from "../../../sharedComponent/dataTable/DataTable.jsx";
//radio button imports end
import axios from "axios";
import edit from "./../../../../assests/images/edit.png";
import { GetAllVatApi } from "../../../../api/adminApi/VatApi.js";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
//import { GetAllCustomerApi } from "../../../api/adminApi/customer/CustomerApi";
//import "./EmployeeSetup.css"


function ItemPricingSetup() {

  const apiAddress = process.env.REACT_APP_SECRET;
  //AutoComplete  states start
  const [CustomerList, setCustomerList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [vatList, setVatList] = useState([]);
  const[Error, setError]=useState({})
  const[Search, setSearch]=useState()
  const [clearAutoComplete, setclearAutoComplete] = useState(false);
  const [clearItemAutoComplete, setclearItemAutoComplete] = useState(false);
  const [flag, setFlag] = useState(true);
  //AutoComplete sates end

  const [UserName, setUserName] = useState();
  const [BaseUnitPrice, setBaseUnitPrice] = useState();
  const [UserRole, setUserRole] = useState();
  const [UserPassword, setUserPassword] = useState();
  const [UserConPassword, setUserConPassword] = useState();
  const [SelectedCustomer, setSelectedCustomer] = useState(null);
  const [SelectedItem, setSelectedItem] = useState(null);
  const [TotalPrice, setTotalPrice] = useState();
  const [ProfitMarginAmount, setProfitMarginAmount] = useState(0);
  const [ProfitMarginType, setProfitMarginType] = useState();
  const [Price, setPrice] = useState(0);
  const[DateTime, setDateTime]= useState(dayjs());
  const [TotalPriceBeforeProfit,setTotalPriceBeforeProfit]= useState(0);
  const [TotalAddtionalPrice,setTotalAddtionalPrice]= useState(0);



  //const [value, setValue] = React.useState('one');
  const [ItemList, setItemList] = useState([]);
  const [isAitApplicable, setAitApplicable] = useState(false);
  const [isCustomerApplicable, setCustomerApplicable] = useState(false);
  const [isDateApplicable, setDateApplicable] = useState(false);
  const [AdditionalCostName, setAdditionalCostName] = useState();
  const [AdditionalCostAmount, setAdditionalCostAmount] = useState();
  const [AdditionalDetails, setAdditionalDetails] = useState([]);
  const [CustomerDetails, setCustomerDetails] = useState([]);
  const [AdditionalType, setAdditionalType] = useState(true);
  const [AdditionalCalcType, setAdditionalCalcType] = useState(true);
  const [FromDate, setFromDate] = useState();
  const [ToDate, setToDate] = useState();

  const[ItemPricingSetupData, setItemPricingSetupData]=useState()




console.log(AdditionalType);

  const handleCustomerSwitch = () => {
    setCustomerApplicable(!isCustomerApplicable);
  };
  const handleDateSwitch = () => {
    setDateApplicable(!isDateApplicable);
  };

  const GetItemList = async () => {
    var list = [];
    var res = await GetItem();
        res.map((d, i) => {
            var obj = { id: d.id , title: d.combination };
            list.push(obj);
          });
           setItemList(list);
  };

  const GetAllCustomer = async (e) => {
    var list = [];
    var res = await GetAllCustomerApi();
    res.map((d, i) => {
        var obj = { id: d.id , title: d.customerName };
        list.push(obj);
      });
    setCustomerList(list);
    console.log(res);
    console.log(list);
  };

  const HandleItemUnitPrice = async (e) => {

    console.log(setSelectedItem)
    var res = await GetItemUnitPrice(e);
    if(res){
      setBaseUnitPrice(res);
      setTotalPrice(res);
    }
    else{
      setBaseUnitPrice(0);
    }
    console.log(res);
    console.log(BaseUnitPrice)
  };

  const handleProfitMarginValue =(e)=>{
    console.log(e);
  }

  const removeCustomerList = (index) => {
    var list = [...CustomerDetails];
    list.splice(index, 1);
    setCustomerDetails(list);
};
  const removeAdditionalList = (index,d) => {
    var list = [...AdditionalDetails];
    list.splice(index, 1);
    setAdditionalDetails(list);

    const sum = list.reduce((accumulator, object) => {
      if(object.isSum){

        return Number(accumulator) +(object.isSpecific?Number(object.value):(Number(BaseUnitPrice)*(Number(object.value)*0.01)))
      }
      else{
        return Number(accumulator) - (object.isSpecific?Number(object.value):(Number(BaseUnitPrice)*(Number(object.value)*0.01)))
      }
      
    }, 0);
setTotalAddtionalPrice(sum)

setTotalPrice((prev)=>Number(BaseUnitPrice)+Number(sum)+(ProfitMarginType?Number(ProfitMarginAmount):(Number(BaseUnitPrice)*(Number(ProfitMarginAmount)*0.01))))
  };

  const GetVatList = async () => {
    var res = await GetAllVatApi();

    setVatList(res);
  };

  // dialog functions start
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  // dialog functions end
  
  //Supplier Field Declaration End
  const handleAdditionalTypeChange = (e) => {
    setAdditionalType(e.target.value === 'true'); // Convert the value to a boolean
    console.log(e.target.value);
  };
  const handleAdditionalCalcTypeChange = (e) => {
    setAdditionalCalcType(e.target.value === 'true'); // Convert the value to a boolean
    console.log(e.target.value);
  };
  const handleProfitMarginTypeChange = (e) => {
    setProfitMarginType(e.target.value === 'true'); // Convert the value to a boolean
    setProfitMarginAmount("");

    if(TotalPrice !=0){
      var prevTotalPrice=TotalPrice;

      if(ProfitMarginType==true){
        var price = 0-Number(ProfitMarginAmount)
        setTotalPrice(price+prevTotalPrice);
      }
      else{
        var price = 0-(Number(BaseUnitPrice)*(Number(ProfitMarginAmount)*0.01))
        setTotalPrice(price+prevTotalPrice);
      }

    }
    console.log(e.target.value);
  };
  const handleCustomerSelect = (e) => {
    setSelectedCustomer(e);
    //setclearAutoComplete(true);
    console.log(e);
    };

  const handleItemSelect = (e) => {
    console.log(e)
    setSelectedItem(e);
    if(e?.id!=undefined){
      HandleItemUnitPrice(e?.id);
      ItemPricingSetup.Id=0;
    }
    else{
      setSelectedItem(e?.id);
    }
    console.log(setSelectedItem);
    }
    console.log(SelectedItem)


      const handleAdditionalAdd = () => {
        if(!!AdditionalCostName==false){
          toast.error("Name Is Required", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else if(!!AdditionalCostAmount==false){
          toast.error("Price Is Required", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else if(!!TotalPrice==false){
          toast.error("Total Price Is 0 , Pick An Item First", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else{
        var list = [...AdditionalDetails];
        var AdditionalDetailsObj = {
          id: 0,
          pricingSetupId: 0,
          name:AdditionalCostName,
          value: AdditionalCostAmount,
          isSpecific: AdditionalType,
          isSum: AdditionalCalcType,
          creatorId: 1,
          creationDate:DateTime,
          modifierId: 1,
          modificationDate:DateTime
        };
        list.push(AdditionalDetailsObj);
        setAdditionalDetails(list);

        const sum = list.reduce((accumulator, object) => {
          if(object.isSum){

            return Number(accumulator) +(object.isSpecific?Number(object.value):(Number(BaseUnitPrice)*(Number(object.value)*0.01)))
          }
          else{
            return Number(accumulator) - (object.isSpecific?Number(object.value):(Number(BaseUnitPrice)*(Number(object.value)*0.01)))
          }
          
        }, 0);
    setTotalAddtionalPrice(sum)

    setTotalPrice((prev)=>Number(BaseUnitPrice)+Number(sum)+(ProfitMarginType?Number(ProfitMarginAmount):(Number(BaseUnitPrice)*(Number(ProfitMarginAmount)*0.01))))

    setAdditionalCostName('');
    setAdditionalCostAmount('');

      //finalamount();
        setOpen(false);
      }
      };

      const handleCustomerAdd = () => {
        
        if(!!SelectedCustomer==false){
          toast.error("Select Customer", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
        else{
          if(isDateApplicable==false){
              var list=[...CustomerDetails]
              var CustomerDetailsObj = {
                id: 0,
                pricingSetupId: 0,
                customerId: SelectedCustomer.id,
                customerName: SelectedCustomer.title,
                isSpecificDate: isDateApplicable,
                fromDate:null,
                toDate:null,
                creatorId: 1,
                creationDate: "2023-11-06T05:29:01.316Z",
                modifierId: 1,
                modificationDate: "2023-11-06T05:29:01.316Z",
              };
              list.push(CustomerDetailsObj);
              setCustomerDetails(list);
                

                          
              setclearAutoComplete(true);
              setSelectedCustomer(null); 
     
              

              console.log(list)
              //setOpen(false);
            }
            else{
              if(!!FromDate==false){
                toast.error("Select From Date", {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                });
              }
                else if(!!ToDate==false){
                  toast.error("Select To Date", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                  });
                }
                else{
                  var list = [...CustomerDetails];
                  var CustomerDetailsObj = {
                    id: 0,
                    pricingSetupId: 0,
                    customerId: SelectedCustomer.id,
                    customerTitle: SelectedCustomer.title,
                    isSpecificDate: isDateApplicable,
                    fromDate:FromDate,
                    toDate:ToDate,
                    creatorId: 1,
                    creationDate: "2023-11-06T05:29:01.316Z",
                    modifierId: 1,
                    modificationDate: "2023-11-06T05:29:01.316Z",
                  };
                  list.push(CustomerDetailsObj);
                  setCustomerDetails(list);
                  setSelectedCustomer("");
                  //setclearAutoComplete(true);
                  setFromDate(null);
                  setToDate(null);
                  console.log(list)
                  //setOpen(false);
                }
              }
          }
        };

const[ItemPricingSetup, setItemPricingSetup]=useState({
      Id: 0,
      ItemId: 0,
      BaseUnitPrice: 0,
      ProfitMarginIsSpecific: true,
      ProfitMargin: 0,
      IsSpecificCustomer:"",
      CreatorId: 1,
      CreationDate: "2023-11-06T05:29:01.316Z",
      ModifierId: 1,
      ModificationDate: "2023-11-06T05:29:01.316Z",
      Combination:"",
      ProductName:"",
      EmployeeName:""

  });

  const[AdditionalPricingSetup, setAdditionalPricingSetup]=useState({
    
    "itemPricingAdditionalPricesView": [
      {
        id: 0,
        pricingSetupId: 0,
        name: "string",
        value: 0,
        isSpecific: true,
        isSum: true,
        creatorId: 0,
        creationDate: "2023-11-06T05:29:01.316Z",
        modifierId: 0,
        modificationDate: "2023-11-06T05:29:01.316Z"
      }
    ]});

const[ItemPricingCustomerSetup, setItemPricingCustomerSetup]=useState({

    "itemPricingCustomerView": [
      {
        id: 0,
        pricingSetupId: 0,
        customerId: 0,
        isSpecificDate: true,
        fromDate: "2023-11-06T05:29:01.316Z",
        toDate: "2023-11-06T05:29:01.316Z",
        creatorId: 0,
        creationDate: "2023-11-06T05:29:01.316Z",
        modifierId: 0,
        modificationDate: "2023-11-06T05:29:01.316Z"
      }
    ]
  
});

  //Api calling section start
  //Api calling section end
  useEffect(() => {
    //GetAllDesignation();
    GetAllCustomer();
    onReset();
    GetItemList();
    GetVatList();
    //setToggleState(1);
  }, []);

  //Acknowledgement table start
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [measuremntUnitRows, setmeasuremntUnitRows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const MeasurmentUnitcolumns = [
    // { field: "sn", headerName: "IS/N", width: 60 },
    {
      field: "id",
      headerName: "Id",
      width: 150,
    },
    {
      field: "itemId",
      headerName: "Item Id",
      width: 150,
    },
    {
        field: "productName",
        headerName: "Product Name",
        width: 200,
      },
      {
        field: "baseUnitPrice",
        headerName: "Price",
        width: 200,
      },
      {
        field: "creationDate",
        headerName: "Create Date",
        width: 200,
      },
      {
        field: "action",
        headerName: "Action",
        width: 170,
        renderCell: (params) => (
          
          <div className="d-flex gap-3" style={{ gap: "10px" }}>
            <div
              onClick={() => {
                onCheckClick(params.row);
              }}
            >
              <img src={checkMark} alt="" style={{ width: "1rem" }} />
            </div>
            <div
              onClick={() => {
                window.open(
                  "/item-pricing-details-report/" + params.row.id,
                  "popup",
                  "width=850,height=550,left=280,top=80"
                );
              }}
            >
              <img src={print} alt="" style={{ width: "1rem" }} />
            </div>
          </div>
        ),
        align: "center",
        headerAlign: "center",
        flex: 1,
      },
  ];

  const GetPurchaseOrderNoAPi = async (props) => {
    setakgpageState((old) => ({ ...old, isLoading: true }));
    console.log(props);
    SetakgIsLoading(true);
    var where=""
    if(!!props.whereClause== false || props.whereClause==undefined){
      where="1=1"
      setSearch("");
    }
    else{
      where=props.whereClause
    }
    console.log(where)
    await axios
      .get(
        "" +
          process.env.REACT_APP_SECRET +
          "/api/Item/GetItemPricingSetupPaged?startRecordNo=" +
          props.page +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          where +
          "&sortColumn=" +
          "id" +
          "&sortOrder=" +
          "desc" +
          ""
      )
      .then((response) => {
        console.log(response.data);
        setakgpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: akgpageState.page,
        }));
        var list = [];
        response.data._itemPricingSetupPagedViews.map((d, i) => {
            var obj = {
                sn: i + 1,
                id: d.id,          
                itemId: d.itemId,
                productName: d.productName,
                baseUnitPrice: d.baseUnitPrice,
                creationDate: d.creationDate
              };
          list.push(obj);
        });
        setmeasuremntUnitRows(list);
        SetakgIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        SetakgIsLoading(false);
      });
  };
  //Acknowledgement table end

  //search starts

  const handleSearch = (prop) => {
    var search = "";
    setSearch(prop);
  };

  const onsearchClick = () => {
    var prop = Search;
    if (prop == null || prop == undefined || prop == "") {
      toast.error("Search is Empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      setSearch(prop);
    } else {
      setSearch(prop);
      var upperText = prop.toUpperCase();
      const newString = "'%" + upperText + "%'";
      const queryString= "upper(itemid) like ";
      const data = encodeURIComponent(newString);
      const condition =queryString + data;
      prop = condition;

      
    }
    
    var obj = {
      page: 0,
      pageSize: 10,
      whereClause: prop,
    };
    GetPurchaseOrderNoAPi(obj);
  };
  //search function start

  const onClear = async () => {
  if(!!Search == false){
  console.log("already empty")
  }
  else{
    setSearch("");
    console.log("emptied")
  }
    //var prop="";

    var obj = {
      page: 0,
      pageSize: 10,
      whereClause: "1=1",
    };
    GetPurchaseOrderNoAPi(obj);

    console.log("api called")

  }
  //search end

  const onCheckClick = async (data) => {

    var res = await GetItemPricingDetailsById(data.id);
    console.log(res.data);

    ItemPricingSetup.Id= res.data.itemPricingSetupView.id;

    

    var item=ItemList.find(x=>x.id==res.data.itemPricingSetupView.itemId)

    //ItemPricingSetup.ItemId= res.data.itemPricingSetupView.itemId;

    setItemPricingSetupData(res.data.itemPricingSetupView)

    setSelectedItem(item);
    setBaseUnitPrice(res.data.itemPricingSetupView.baseUnitPrice);
    setTotalPrice(res.data.itemPricingSetupView.baseUnitPrice);

    setProfitMarginAmount(res.data.itemPricingSetupView.profitMargin);
    setProfitMarginType(res.data.itemPricingSetupView.profitMarginIsSpecific);

    setAdditionalDetails(res.data.itemPricingAdditionalPricesViews);
    setCustomerDetails(res.data.itemPricingCustomerViews);

    console.log(ItemPricingSetup.Id)
    

    // setOrderDetailsList(res.purchaseOrderdetailsList);
    // setPurchaseOrderData(res.purchaseOrder);
    // setPurchaseOrderDetailsList(res.purchaseOrderdetailsList);

    // setitemPricingDetails(res.data.itemPricingSetupView);
    // setItemPricingAddtionalDetails(res.data.itemPricingAdditionalPricesViews);
    // setitemPricingCustomerDetails(res.data.itemPricingCustomerViews);
  };


  const onReset = async(e) =>{
    var isfalse= false;
    setclearAutoComplete(true);
    setSelectedCustomer(null);
    setclearItemAutoComplete(true);
    setSelectedItem(null);
    setAdditionalDetails([]);
    setCustomerDetails([]);
    setCustomerApplicable(false);
    setProfitMarginAmount("");
    setBaseUnitPrice("");
    setTotalPrice(0);
    setTotalPriceBeforeProfit(0);
    setCustomerApplicable(false);
    setDateApplicable(false);
    isfalse=isCustomerApplicable;

    
    console.log(ItemPricingSetup.BaseUnitPrice);

    
    console.log("emptied");

  }

  const addProfitMarginAmount = (e) => {
    if (!!e == false) {
      setProfitMarginAmount(0);
    } else {
      setProfitMarginAmount(parseInt(e));
    }
    setFlag(!flag);
  };


  const handleInputChange = (e, inputType) => {
    const value = parseInt(e)
    if(ProfitMarginType==true)
    {
      setTotalPrice((prev)=>Number(BaseUnitPrice)+Number(TotalAddtionalPrice)+Number(value))
    }
    else{
      setTotalPrice((prev)=>Number(BaseUnitPrice)+Number(TotalAddtionalPrice)+((Number(BaseUnitPrice))*(Number(value)*0.01)))
    }
    if (inputType === 'input1') {
      setProfitMarginAmount(value);
    } 
  };
const finalamount=()=>{ 

  var finalAmount= Number(BaseUnitPrice)+Number(TotalAddtionalPrice);
  console.log(TotalAddtionalPrice);
  setTotalPrice(finalAmount);

}

  useEffect(()=>{
    //netTotal();
    //finalamount();

  },[ProfitMarginAmount])




  const onSave = async () => {
        console.log("save");
      if (!!SelectedItem == false ) {
        toast.error("Select Item", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("hit")
      }
    else{

      if(ItemPricingSetup.Id==0){

      ItemPricingSetup.ItemId=SelectedItem.id;
      ItemPricingSetup.BaseUnitPrice=TotalPrice;
      ItemPricingSetup.ProfitMarginIsSpecific=ProfitMarginType;
      ItemPricingSetup.ProfitMargin=ProfitMarginAmount;
      ItemPricingSetup.IsSpecificCustomer=isCustomerApplicable;

      var itemPricingCreateView = {

        itemPricingSetupView:ItemPricingSetup,
        itemPricingAdditionalPricesView:AdditionalDetails,
        itemPricingCustomerView: CustomerDetails
      };


    //   var url = apiAddress + "/api/Item/ItemPricing";
    //   await axios.post(url,itemPricingCreateView)
    //   .then((response) => {
    //     toast.success("New Item Pricing Created", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });})
    //   .catch((response)=>{
    //     toast.error("Something Went Wrong", {
    //     position: "top-right",
    //     autoClose: 5000,
    //     hideProgressBar: false,
    //     closeOnClick: true,
    //     pauseOnHover: true,
    //     draggable: true,
    //     progress: undefined,
    //     theme: "colored",
    //   });
    // })

      }
      else{
        
      ItemPricingSetup.ItemId=ItemPricingSetupData.itemId;
      ItemPricingSetup.BaseUnitPrice=TotalPrice;
      ItemPricingSetup.ProfitMarginIsSpecific=ProfitMarginType;
      ItemPricingSetup.ProfitMargin=ProfitMarginAmount;
      ItemPricingSetup.IsSpecificCustomer=isCustomerApplicable;

      var itemPricingCreateView = {

        itemPricingSetupView:ItemPricingSetup,
        itemPricingAdditionalPricesView:AdditionalDetails,
        itemPricingCustomerView: CustomerDetails
      };


      var url = apiAddress + "/api/Item/ItemPricingUpdate";
      await axios.put(url,itemPricingCreateView)
      .then((response) => {
        toast.success("Item Pricing Updated", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });})
      .catch((response)=>{
        toast.error("Something Went Wrong", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    })

      }



      

    setclearItemAutoComplete(false);
    setSelectedItem(null);
    setBaseUnitPrice("");
    setAdditionalDetails([]);
    setCustomerDetails([]);
    setFromDate(null);
    setToDate(null);
    setProfitMarginAmount("");
      console.log("not hit")
      console.log(itemPricingCreateView)
    }
  };

  return (
    <div>  
    <div>
    <ToastContainer />

    <div className="card card-shadow p-3">
        <div className="row">
          <div className="col-12">
          <h6>Item Price Entry</h6>
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
          <div className="mt-2">
          {clearItemAutoComplete}
            <AutoCompleteMultiSelect
            datas={ItemList}
            value={(e) => {handleItemSelect(e)}}
            id={1}
            label={"Select Item"}
            getOptionLabel={(option) => option.title}
            clear={clearItemAutoComplete}
            val={SelectedItem}    
            />
        </div>
        <div>
          <TextAreaComponents
            id={5}
            value={(e) => setBaseUnitPrice(e)}
            label={"Base Unit Price"}
            val={BaseUnitPrice}
            isactive={true}
                    
          />
          </div>
          <div>
          <TextAreaComponents
            id={5}
            value={(e) => finalamount(e)}
            label={"Final Unit Price"}
            val={TotalPrice}
            isactive={true}
              
          />
          </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 gap-2 d-flex flex-column">
          <FormControl className="d-flex flex-row gap-3 mt-2">
          <FormLabel id="demo-row-radio-buttons-group-label" className="mt-2">
            Profit Margin Type
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={ProfitMarginType? 'true':'false'}
            onChange={handleProfitMarginTypeChange}
            
          >
            <FormControlLabel
              value="true"
              control={<Radio />}
              label="Specific"
              
            />
            <FormControlLabel
              value="false"
              control={<Radio />}
              label="Pecentage"
          
            />
          </RadioGroup>
        </FormControl>
        <TextAreaComponents
            id={2}
            
            value={(e) => handleInputChange(e,'input1')}
            label={"Profit Margin Amount"}
            val={ProfitMarginAmount}          
            />
          </div>
        </div>
      </div>
      <div className="d-flex  gap-2 justify-content-center" style={{flexWrap:"wrap",flexDirection:"column"}}>
      <div className="card card-shadow w-100  mt-2 p-3">
        <div className="row">
          <div className="col-12">
          <h6>Additional Price Entry</h6>
          </div>
        </div>
        <div className="row">
          <div>
            <React.Fragment>
                <Button variant="outlined" onClick={handleClickOpen}>
                    Add Additional Price?
                </Button>
                <div className="">
                <Dialog fullWidth={true} open={open} onClose={handleClose}>
                <DialogTitle className="d-flex justify-content-center">Add Additional Price</DialogTitle>
                <DialogContent>
                <DialogContentText>
                <div className="row ">
                <div className="col-12 col-md-12 col-lg-2 d-flex flex-column gap-2 mt-3 ">
                        <p>Name:</p>
                        <p>Type:</p>
                        <p>Calculation:</p>
                        <p>Value:</p>                    
                </div>
                <div className="col-12 col-md-12 col-lg-10 d-flex flex-column mt-2 gap-2">
                <TextAreaComponents
                        id={6}
                        value={(e) => setAdditionalCostName(e)}
                        //label={}
                        //val={BillNo}
                        //isactive={true}
                    />
                 <FormControl className="d-flex flex-row justify-content gap-3">
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={AdditionalType? 'true':'false'}
                        onChange={handleAdditionalTypeChange}
                    >
                        <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Specefic"
                        //onChange={(e)=>{setAdditionalType(e.target.value)}}
                        />
                        <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Percentage"
                        //onChange={(e)=>{setAdditionalType(e.target.value)}}
                        />
                    </RadioGroup>
                </FormControl>
                <FormControl className="d-flex flex-row justify-content gap-3">
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={AdditionalCalcType? 'true':'false'}
                        onChange={handleAdditionalCalcTypeChange}
                    >
                        <FormControlLabel
                        value="true"
                        control={<Radio />}
                        label="Summation"
                        //onChange={(e)=>{setAdditionalCalcType(e.target.value)}}
                        />
                        <FormControlLabel
                        value="false"
                        control={<Radio />}
                        label="Subtraction"
                        //onChange={(e)=>{setAdditionalCalcType(e.target.value)}}
                        />
                    </RadioGroup>
                </FormControl>
                                 
                                 
                    <TextAreaComponents
                        id={2}
                        value={(e) => setAdditionalCostAmount(e)}
                        //label={"Purchase Bill No"}
                        //val={BillNo}
                        //isactive={true}
                    />       
                </div>
                </div>
                </DialogContentText>

                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleAdditionalAdd}>Add</Button>
                </DialogActions>
                </Dialog></div>
            </React.Fragment>
            </div>

          <div className="mt-3">
          {AdditionalDetails && AdditionalDetails.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Name</th>
                  <th>Amount</th>
                  <th>IsSpecific</th>
                  <th>IsSum</th>
                </tr>
              </thead>
              <tbody>
                {AdditionalDetails.map((d, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{d.name}</td>
                    <td>{d.value}</td>
                    <td>{d.isSpecific ?'Specific':'Percentage'}</td>
                    <td>{d.isSum ?'Specific':'Percentage'}</td>
                    <td>
                      <div
                        className="d-flex icon gap-2 mt-2"
                        onClick={() => removeAdditionalList(i,d)}
                      >
                        <img src={closeicon} alt="img" />
                        {/* <img src={info} alt="img" /> */}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            null
            // <p>No additional details available.</p>
          )}
        </div>
            
        </div>
      </div>

      <div className="card card-shadow w-100 mt-2 p-3">
        <div className="row d-flex">
          <div className="row">
          <div className="col-12">
          <h6>Specific Customer Entry</h6>
          </div>
          </div>
            <div className="col-3 text-primary mt-2">
            <span>Is Specific Customer? </span>
            </div>
            <div className="col-7">
            <Switch onChange={handleCustomerSwitch}  />
            </div>
            </div>
            {isCustomerApplicable && (
              
            <div>           
            <div className="">
            {clearAutoComplete}
            <AutoCompleteMultiSelect
            datas={CustomerList}
            value={(e)=>{handleCustomerSelect(e)}}
            id={1}
            label={"Select Customer"}
            getOptionLabel={(option) => option.title}
            clear={clearAutoComplete}
            val={SelectedCustomer?SelectedCustomer:null}
            />
            </div>

            <div className="row d-flex mt-3">
            <div className="col-3 text-primary mt-2">
            <span>Is Specific Date? </span>
            </div>
            <div className="col-7">
            <Switch onChange={handleDateSwitch}  />
            </div>
            </div>
            {isDateApplicable &&(
            <div className="col-12 d-flex gap-1 mt-2">
            <div className="col-6">
              <CustomDateTimePicker
            value={(props) => setFromDate(props)}
            //isDisabled={true}
            //val={CustomerOpeningBalanceDate}
            label={"From Date"}
            val={FromDate}
            //disable={handleDateSwitch}
            /></div>
            <div className="col-6">
              <CustomDateTimePicker
            value={(props) => setToDate(props)}
            //isDisabled={true}
            //val={CustomerOpeningBalanceDate}
            label={"To Date"}
            val={ToDate}
            //disable={handleDateSwitch}
            /></div>
            </div>)}
            <div className="d-flex justify-contect-end">
            <Button onClick={handleCustomerAdd}>Add</Button>
            </div>
            </div>)}
            
            
            <div className="">
            {CustomerDetails && CustomerDetails.length > 0 ? (
            <table className="table justify-content-center">
              <thead>
                <tr>
                  <th>SN</th>
                  <th>Customer Name</th>
                  <th>From Date</th>
                  <th>To Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {CustomerDetails.map((d, i) => (            
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{d.customerName}</td>
                      <td>
                        {d.fromDate ? (
                        format(new Date(d.fromDate), 'yyyy-MM-dd HH:mm:ss')):null
                        }
                      </td>
                      <td>
                        {d.fromDate ? (
                        format(new Date(d.toDate), 'yyyy-MM-dd HH:mm:ss')):null
                        }
                      </td>
                      <td>
                      <div
                      className="d-flex icon gap-2 mt-2"
                      onClick={() => removeCustomerList(i)}
                    >
                      <img src={closeicon} alt="img" />
                      {/* <img src={info} alt="img" /> */}
                    </div>
                      </td>
                    </tr>
                  ))}

              </tbody>
            </table>
              ) : (
              <p>No Customer details available.</p>
            )}
            </div>
            </div>
            </div>



      <div className="row mt-3 justify-content-start">
        <div className="col-12"></div>
        <div className="col-6">
          <div className="d-flex gap-4 ">
            <button onClick={(e)=>{onSave(e)}} className="btn-yes btn-common">Save</button>
            <button className="btn-no btn-common" onClick={(e)=> {onReset(e) }}>Reset</button>
          </div>
        </div>
      </div>
      
      
        </div>
      <div className="card card-shadow p-3 mt-3">
      <div className="col-12  ">
        <div className="col-md-6">
          <h5>Item Pricing List</h5>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => handleSearch(props)}
              label={"Search"}
              val={Search}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
          <button className="btn-search btn-common" onClick={ ()=> {onsearchClick()}} >
              Search
            </button>
            <button className="btn-search btn-common" onClick={ ()=> {onClear()}}>
              Clear
            </button>
          </div>
        </div>
        <div className="col-12 mt-2">
          <DataTable
            pageState={akgpageState}
            GetPurchaseOrderNoAPi={GetPurchaseOrderNoAPi}
            isLoading={akgisLoading}
            rows={measuremntUnitRows}
            columns={MeasurmentUnitcolumns}
          />
        </div>
      </div>
      </div>
    </div>
  );
}

export default ItemPricingSetup;
