import React, { useEffect } from "react";
import Login from "../login/Login";
import PurchaseOrder from "./purchaseOrder/PurchaseOrder";
import PurchaseBillCancelation from "./purchaseBillcancelation/PurchaseBillCancelation";
import PurchaseBillReturn from "./purchaseBillReturn/PurchaseBillReturn";

import PurchaseBill from "./purchaseBill/PurchaseBill";
import billImg from "./../../assests/images/bill.png";
import orderImg from "./../../assests/images/checklist.png";
import ackImg from "./../../assests/images/acknowledgement.png";
import adjustment from "./../../assests/images/adjustment.png";
import cancelation from "./../../assests/images/cancelation.png";
import bireturn from "./../../assests/images/BillReturn.png";
import stimg from "./../../assests/images/stock.png";
import { getAPI } from "../../api/screenApi/ScreenApi";

import stockissueimg from "./../../assests/images/stockissue.png";
import backimg from "./../../assests/images/back.png";
import StockAdjustment from "./stockAdjustment/StockAdjustment";
import stockadjimg from "./../../assests/images/stockAdjustment.png";
import PurchaseAcknowledegement from "./purchaseAcknowledgement/PurchaseAcknowledegement";
import "./../../css/Responsive.css";

function Purchase(props) {
  const [menuSelection, setMenuSelection] = React.useState(0);
  const [screenDetails, setScreenDetails] = React.useState(0);
  const [toggleSideBar, setToggleSideBar] = React.useState(false);
  const changeMenu = (props) => {
    setMenuSelection(props);
  };
  useEffect(() => {
    var dt = getAPI(1);
    console.log(dt);
    setScreenDetails(dt);
  }, []);

  return (
    <div>
      <div className=" purchaseContainer"></div>
      <div className="d-flex mt-2" style={{ color: "black" }}>
        <div style={{ position: "relative" }}>
          <div
            className={` backImgDiv ${
              toggleSideBar ? "backImgDivOpen" : "backImgDivClose"
            } `}
            style={{ transition: "all .5s" }}
            onClick={() => {
              setToggleSideBar(!toggleSideBar);
            }}
          >
            <img src={backimg} alt="" />
          </div>
          <div
            className={`scrollable-div ${
              toggleSideBar ? "sideBarOff" : "sideBarOn"
            } `}
            style={{ transition: "width .5s" }}
          >
            <h6 className=" ms-3 mt-2" style={{ fontSize: "13px" }}>
              <b>Purchase</b>
            </h6>
            <hr className="" style={{ margin: ".5rem" }} />

            <div
              className={`menuItem ${menuSelection == 0 ? "active" : ""}`}
              style={{
                width: "100%",
                height: "2.5rem",
                transition: "background-color 0.3s",
              }}
              onClick={() => changeMenu(0)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={orderImg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Order{" "}
                </p>
              </div>
            </div>

            <div
              className={`menuItem ${menuSelection == 1 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(1)}
            >
              <div
                className="  d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={billImg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Bill
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 2 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(2)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={ackImg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Acknowledgement
                </p>
              </div>
            </div>

            <div
              className={`menuItem ${menuSelection == 5 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(5)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={adjustment}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Stock Adjustment{" "}
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 6 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(6)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={cancelation}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Purchase Bill cancelation{" "}
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 7 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(7)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={bireturn}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Purchase Bill Return{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        {menuSelection === 0 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <PurchaseOrder />
          </div>
        ) : (
          ""
        )}
        {menuSelection === 2 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <PurchaseAcknowledegement />
          </div>
        ) : (
          ""
        )}

        {menuSelection === 1 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <PurchaseBill />
          </div>
        ) : (
          ""
        )}

        {menuSelection === 5 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <StockAdjustment />
          </div>
        ) : (
          ""
        )}
        {menuSelection === 6 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <PurchaseBillCancelation />
          </div>
        ) : (
          ""
        )}
        {menuSelection === 7 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <PurchaseBillReturn />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
export default Purchase;
