import React, { useState, useEffect } from "react";
import "./PurchaseBillReturn.css";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import edit from "./../../../assests/images/edit.png";
import checkMark from "./../../../assests/images/check-mark.png";
import remove from "./../../../assests/images/remove.png";
import print from "./../../../assests/images/printing.png";
import DataTable from "../../sharedComponent/dataTable/DataTable";
import axios from "axios";
import { GetPurchaseBillByNumber } from "../../../api/screenApi/purchaseBill/purchaseBillApi";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlertModal from "../../sharedComponent/SweetAlertModal/SweetAlertModal";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";
import ClientSiteDataTable from "../../sharedComponent/dataTable/ClientSiteDataTable.jsx";
import { EntryNumberGenarator } from "../../sharedComponent/CommonMethods.js";

function PurchaseBillReturn() {
  const apiAddress = process.env.REACT_APP_SECRET;
  const [AlertOpen, setAlertOpen] = useState(false);
  const [ReturnNo, setReturnNo] = useState();
  const [SelectedBill, setSelectedBill] = useState();
  const [SelectedBillNo, setSelectedBillNo] = useState();
  const [ItemsUnderBill, setItemsUnderBill] = useState([]);
  const [SelectedItem, setSelectedItem] = useState([]);
  const [ReturnDate, setReturnDate] = useState();
  const [ManualBillNo, setManulaBillNo] = useState();
  const [Reason, setReason] = useState();
  const [Remarks, setRemarks] = useState();
  const [ReturnDetailsList, setReturnDetailsList] = useState([]);
  const [PurchaseBillReturnView, setPurcchaseBillReturnView] = useState({});
  const [Search, setSearch] = useState();
  const [BillSearch, setBillSearch] = useState();

  const [purchaseBillReturn, setpurchaseBillReturn] = useState({
    number: "string",
    billReturnDate: ReturnDate,
    purchaseBillNumber: "string",
    manualBillNo: "string",
    reason: "string",
    remarks: "string",
    counterId: 0,
    creatorId: 0,
    creationDate: "2023-10-21T09:25:16.836Z",
    updatorId: 0,
  });

  //validation toaster start
  const errorToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  //validation toaster end
  //Bill return table start
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [akgrows, setAkgrows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const Acknowledgementcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "billReturnNo", headerName: "PurchaseBill Return No", flex: 1 },
    {
      field: "date",
      headerName: "Return Date",
      renderCell: (params) => <span>{DateFormater(params.row.date)}</span>,
      flex: 1,
    },
    { field: "reason", headerName: "Reason", flex: 1 },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              window.open(
                "/purchase-billReturn-report/" + params.row.billReturnNo,
                "popup",
                "width=850,height=550,left=280,top=80"
              );
            }}
          >
            <img src={print} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const BillReturnListPaged = async (props, from) => {
    if (from != "Search") {
      setakgpageState((old) => ({ ...old, isLoading: true }));

      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseBillReturn/GetPurchaseBillReturnPaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          var res = EntryNumberGenarator(
            response.data.purchaseBillReturn[0].number,
            "INV1-PR"
          );
          setReturnNo(res);
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data.purchaseBillReturn.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billReturnNo: d.number,
              date: d.billReturnDate,
              reason: d.reason,
              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    } else {
      const newString = "'" + Search + "'";
      const condition = "Number=" + newString;
      setakgpageState((old) => ({ ...old, isLoading: true }));

      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseBillReturn/GetPurchaseBillReturnPaged?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data.purchaseBillReturn.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billReturnNo: d.number,
              date: d.billReturnDate,
              reason: d.reason,
              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    }
  };
  const onSearch = async () => {
    await BillReturnListPaged("props", "Search");
  };
  const onClear = async () => {
    var props = {
      page: 0,
      pageSize: 10,
    };
    setSearch("");
    await BillReturnListPaged(props, "Main");
  };

  const GetPurchaseOrderNoAPi = async (props) => {
    await BillReturnListPaged(props, "Main");
  };
  //Bill return table end

  //bill table start
  const Billcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "billNo", headerName: "Bill No", flex: 1 },
    {
      field: "billDate",
      headerName: "Bill Date",
      renderCell: (params) => <span>{DateFormater(params.row.billDate)}</span>,
      flex: 1,
    },
    { field: "supplier", headerName: "Supplier Name", flex: 1 },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onCheckClick(params.row);
            }}
          >
            <img src={checkMark} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
  const [billrows, setBillrows] = React.useState([]);

  const [isLoading, SetisLoading] = React.useState(false);

  const [pageState, setpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const BillListPaged = async (props, from) => {
    if (from != "Search") {
      setpageState((old) => ({ ...old, isLoading: true }));

      SetisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseBill/GetPurchaseBillPagedForReturn?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: pageState.page,
          }));
          var list = [];
          response.data._purchaseBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billNo: d.number,
              billDate: d.billDate,
              supplier: d.supplierName,
              remarks: d.remarks,
              fulllRow: d,
            };
            list.push(obj);
          });
          setBillrows(list);
          SetisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetisLoading(false);
        });
    } else {
      const newString = "'" + BillSearch + "'";
      const condition = "Number=" + newString;
      setpageState((old) => ({ ...old, isLoading: true }));

      SetisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseBill/GetPurchaseBillPagedForReturn?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: pageState.page,
          }));
          var list = [];
          response.data._purchaseBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billNo: d.number,
              billDate: d.billDate,
              supplier: d.supplierName,
              remarks: d.remarks,
              fulllRow: d,
            };
            list.push(obj);
          });
          setBillrows(list);
          SetisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetisLoading(false);
        });
    }
  };
  const onBillSearch = async () => {
    await BillListPaged("props", "Search");
  };
  const onBillClear = async () => {
    setBillSearch("");
    var props = {
      page: 0,
      pageSize: 10,
    };
    await BillListPaged(props, "Main");
  };
  const GetPurchaseBillPage = async (props) => {
    await BillListPaged(props, "Main");
  };
  //bill table end

  //items under bill table start
  const ItemsUnderBillColumns = [
    // { field: "id", headerName: "Serial", flex: 1 },
    {
      field: "itemId",
      headerName: "ItemId",

      flex: 1,
    },

    {
      field: "itemName",
      headerName: "ProductName",

      flex: 1,
    },

    {
      field: "qty",
      headerName: "PO.Quantity",
      flex: 1,
    },
    {
      field: "unitPrice",
      headerName: "UnitPrice",
      flex: 1,
    },
  ];
  //items under bill table end

  //selected item table start
  const SelectedItemColumns = [
    // { field: "id", headerName: "Serial", flex: 1 },
    {
      field: "itemId",
      headerName: "ItemId",

      flex: 1,
    },

    {
      field: "itemName",
      headerName: "ProductName",

      flex: 1,
    },

    {
      field: "qty",
      headerName: "PO.Quantity",
      flex: 1,
    },
    {
      field: "unitPrice",
      headerName: "Unitprice",
      flex: 1,
    },
  ];
  //selected item table end

  const onCheckClick = async (data) => {
    setSelectedBill(data);
    setSelectedBillNo(data.billNo);
    var res = await GetPurchaseBillByNumber(data.billNo);
    var list = [];
    res.purchaseBillDetailsList.map((d, i) => {
      var obj = {
        id: i + 1,
        puchaseBillNumber: d.puchaseBillNumber,
        itemId: d.itemId,
        itemName: d.itemName,
        qty: d.qty,
        unitPrice: d.unitPrice,
        amount: d.amount,
        discount: d.discount,
        afterDiscount: d.afterDiscount,
        sd: d.sd,
        vat: d.vat,
      };
      list.push(obj);
    });
    setItemsUnderBill(list);
  };
  const handleSelectRow = (data) => {
    var list = [...SelectedItem];
    list.push(data);
    setSelectedItem(list);
  };

  const removeRow = (i) => {
    var list = [...SelectedItem];
    list.splice(i, 1);
    setSelectedItem(list);
  };

  const onSave = () => {
    setAlertOpen(false);
    var valid = true;
    if (!!ReturnDate == false) {
      valid = false;
      errorToast("Return date required");
    }
    if (!!ManualBillNo == false) {
      valid = false;
      errorToast("Manual bill no is required");
    }
    if (!!Reason == false) {
      valid = false;
      errorToast("Reason is required");
    }
    if (!!Remarks == false) {
      valid = false;
      errorToast("Remarks is required");
    }
    if (!!SelectedBill == false) {
      valid = false;
      errorToast("Bill not selected");
    }
    if (SelectedItem.length == 0) {
      valid = false;
      errorToast("No item selected");
    }
    purchaseBillReturn.billReturnDate = ReturnDate;
    purchaseBillReturn.purchaseBillNumber = SelectedBill.billNo;
    purchaseBillReturn.manualBillNo = ManualBillNo;
    purchaseBillReturn.reason = Reason;
    purchaseBillReturn.remarks = Remarks;
    purchaseBillReturn.counterId = SelectedBill.fulllRow.counterId;
    purchaseBillReturn.creatorId = SelectedBill.fulllRow.creatorId;
    purchaseBillReturn.creationDate = "2023-10-21T09:25:16.836Z";
    purchaseBillReturn.updatorId = SelectedBill.fulllRow.creatorId;

    var list = [];

    SelectedItem.map((d, i) => {
      var detailsObj = {
        id: 0,
        billReturnNumber: "string",
        itemCode: d.itemId,
        qty: d.qty,
        unitPrice: d.unitPrice,
        rakId: 0,
        sd: d.sd,
        vat: d.vat,
      };
      list.push(detailsObj);
    });

    PurchaseBillReturnView.purchaseBillReturn = purchaseBillReturn;
    PurchaseBillReturnView.purchaseBillReturnDetailsList = list;

    if (valid) {
      var url = apiAddress + "/api/PurchaseBillReturn";
      axios.post(url, PurchaseBillReturnView).then((response) => {
        successToast("Saved successfully");
        setRemarks("");
        setReason("");
        setReturnDate(null);
        setManulaBillNo("");
        setItemsUnderBill([]);
        setSelectedItem([]);
        setSelectedBillNo("");

        var props = {
          page: 0,
          pageSize: 10,
        };
        setSearch("");
        BillReturnListPaged(props, "Main");
        BillListPaged(props, "Main");
      });
    }
  };
  const getSelectedItems = (data) => {
    var list = [];
    data.map((d, i) => {
      var res = ItemsUnderBill.find((x) => x.id == d);

      if (!!res == true) {
        list.push(res);
      }
    });

    setSelectedItem(list);
  };

  const handleClear = () => {
    setRemarks("");
    setReason("");
    setReturnDate(null);
    setManulaBillNo("");
    setItemsUnderBill([]);
    setSelectedItem([]);
    setSelectedBillNo("");
  };
  return (
    <div>
      <ToastContainer />
      <SweetAlertModal
        open={AlertOpen}
        headerText={"Are you sure?"}
        bodyText={"You want to save?"}
        close={() => setAlertOpen(false)}
        onyes={onSave}
      />
      <div className="card p-3 card-shadow acknowledgementCard">
        <div>
          <h6>Purchase Bill List</h6>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setBillSearch(props)}
              label={"Search"}
              val={BillSearch}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onBillSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onBillClear}>
              Clear
            </button>
          </div>
        </div>
        <DataTable
          pageState={pageState}
          GetPurchaseOrderNoAPi={GetPurchaseBillPage}
          isLoading={isLoading}
          rows={billrows}
          columns={Billcolumns}
        />
      </div>

      <h5>Purchase Bill Return Entry</h5>
      <div className="card d-flex  gap-2 card-shadow PurchaseBillCancellationCard p-3">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2">
            <TextAreaComponents
              id={5}
              //   value={(e) => setPurchaseOrderNumber(e)}
              label={"PurchaseBill Return No"}
              val={ReturnNo}
              isactive={true}
            />
            <TextAreaComponents
              id={5}
              value={(e) => setManulaBillNo(e)}
              label={"Manual Bill No"}
              val={ManualBillNo}
            />
            <TextAreaComponents
              id={5}
              //   value={(e) => setPurchaseOrderNumber(e)}
              label={"Purchase Bill No"}
              val={SelectedBillNo}
              isactive={true}
            />
          </div>

          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2">
            <CustomDateTimePicker
              value={(e) => setReturnDate(e)}
              val={ReturnDate}
            />
            <TextAreaComponents
              id={5}
              value={(e) => setReason(e)}
              label={"Reason"}
              val={Reason}
            />
            <CustomDateTimePicker
              //  value={(e) => setDateTime(e)}
              label={"Purchase Bill Date"}
              val={dayjs(SelectedBill?.billDate)}
              isDisabled={true}
            />
          </div>
          <div className="mt-2">
            <TextAreaComponents
              id={5}
              value={(e) => setRemarks(e)}
              label={"Remarks"}
              val={Remarks}
            />
          </div>
        </div>
      </div>
      {/* Items under purchase bill div start */}
      {/* <div className="card card-shadow p-3 mt-3"> */}

      {/* <table class="table table-striped mt-2 ">
          <thead style={{ backgroundColor: "#2d358f", color: "white" }}>
            <tr>
              <th>S/N</th>
              <th>Item Id</th>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>UnitPrice</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {ItemsUnderBill.map((d, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{d.itemId}</td>
                  <td>{d.itemName}</td>
                  <td className="col-1">
                    <p>{d.qty}</p>
                  </td>

                  <td className="col-1">
                    <p>{d.unitPrice}</p>
                  </td>

                  <td>
                    <div onClick={() => handleSelectRow(d)}>
                      <img src={checkMark} alt="" style={{ width: "1rem" }} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table> */}
      {ItemsUnderBill.length > 0 ? (
        <>
          <div className="card card-shadow p-3 mt-3">
            <div>
              <h6>Items Under Purchase Bill</h6>
            </div>
            <ClientSiteDataTable
              dataRows={ItemsUnderBill}
              columns={ItemsUnderBillColumns}
              isActive={true}
              getselectedData={getSelectedItems}
            />
          </div>
        </>
      ) : (
        ""
      )}

      {/* </div> */}
      {/* Items under purchase bill div end*/}

      {/* Selected item div start */}
      {/* <div className="card card-shadow p-3 mt-3"> */}

      {/* <table class="table table-striped mt-2 ">
          <thead style={{ backgroundColor: "#2d358f", color: "white" }}>
            <tr>
              <th>S/N</th>
              <th>Item Id</th>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>UnitPrice</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {SelectedItem.map((d, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{d.itemId}</td>
                  <td>{d.itemName}</td>
                  <td className="col-1">
                    <p>{d.qty}</p>
                  </td>

                  <td className="col-1">
                    <p>{d.unitPrice}</p>
                  </td>

                  <td>
                    <div onClick={() => removeRow(i)}>
                      <img src={remove} alt="" style={{ width: "1.5rem" }} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table> */}

      {SelectedItem.length > 0 ? (
        <>
          <div className="card card-shadow p-3 mt-3">
            <div>
              <h6>Selected Item</h6>
            </div>
            <ClientSiteDataTable
              dataRows={SelectedItem}
              columns={SelectedItemColumns}
              // isActive={true}
              // getselectedData={getSelectedItems}
            />
          </div>
        </>
      ) : (
        ""
      )}
      {/* </div> */}
      {/* selected item div end */}

      <div className="row mt-3">
        <div className="col-4">
          <div className="d-flex gap-4 ">
            <button
              className="btn-yes btn-common"
              onClick={() => setAlertOpen(true)}
            >
              Save
            </button>
            <button className="btn-no btn-common" onClick={handleClear}>
              Clear
            </button>
            {/* <button className="btn-Review btn-common">Review</button> */}
          </div>
        </div>
      </div>

      <div className="card p-3 card-shadow acknowledgementCard mt-3">
        <div>
          <h6>Return Item List</h6>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setSearch(props)}
              label={"Search"}
              val={Search}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onClear}>
              Clear
            </button>
          </div>
        </div>
        <DataTable
          pageState={akgpageState}
          GetPurchaseOrderNoAPi={GetPurchaseOrderNoAPi}
          isLoading={akgisLoading}
          rows={akgrows}
          columns={Acknowledgementcolumns}
        />
      </div>
    </div>
  );
}

export default PurchaseBillReturn;
