import React, { useEffect, useState } from "react";
import { GetAllItemApi } from "../../../api/adminApi/itemSetpAPi/ItemSetupApi";
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import { GetAllModelApi } from "../../../api/adminApi/ModelApi";
import { ToastContainer, toast } from "react-toastify";
//radio button imports start
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import DataTable from "../../sharedComponent/dataTable/DataTable";
//radio button imports end
import axios from "axios";
import edit from "./../../../assests/images/edit.png";
import { GetAllVatApi } from "../../../api/adminApi/VatApi";

function SupplierSetup() {

  const apiAddress = process.env.REACT_APP_SECRET;
  //AutoComplete  states start
  const [modelList, setModelList] = useState([]);
  const [vatList, setVatList] = useState([]);
  const[Error, setError]=useState({})
  const[Search, setSearch]=useState()
  //AutoComplete sates end
  //Supplier Field Declaration Start
  const [SupplierName, setSupplierName] = useState();
  const [SupplierAddress, setSupplierAddress] = useState();
  const [SupplierMobile, setSupplierMobile] = useState();
  const [SupplierEmail, setSupplierEmail] = useState();
  const [SupplierBIN, setBIN] = useState();
  const [SupplierTIN, setTIN] = useState();
  const [SupplierVatRegNo, setVatRegNo] = useState();
  const [SupplierContactPerson, setContactPerson] = useState();
  //Supplier Field Declaration End

  const[SupplierSetup, setSupplierSetup]=useState({

      Id: 0,
      SupplierName: "",
      Address: "",
      Mobile: "",
      Email: "",
      BIN: "",
      TIN: "",
      VATRegNo: "",
      ContactPerson: "",
      IsActive: true,
      CreatorId: 0,
      CreationDate: "2023-10-25T07:10:20.620Z",
      UpdatorId: 0,
      UpdateDate: "2023-10-25T07:10:20.620Z",
      // CreatorName: "admin",
      // UpdatorName: "admin"
    
  
});

  //Api calling section start
  //Api calling section end

  const handleModelSelect = (e) => {
    console.log(e);
  };
  const handleVatSelect = (e) => {
    console.log(e);
  };
  useEffect(() => {
  }, []);

  //Supplier table declaration start
  const [SupplierisLoading, SetSupplierIsLoading] = React.useState(false);
  const [SupplierRows, SetSupplierRows] = React.useState([]);

  const [SupplierpageState, SetSupplierpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const Suppliercolumns = [
    { field: "sn", headerName: "IS/N", width: 60 },
    {
      field: "name",
      headerName: "Supplier Name",
      width: 150,
    },
    {
        field: "address",
        headerName: "Address",
        width: 150,
      },
      {
        field: "mobile",
        headerName: "Mobile",
        width: 150,
      },
      {
        field: "email",
        headerName: "Email",
        width: 150,
      },
      {
        field: "bin",
        headerName: "BIN",
        width: 150,
      },
      {
        field: "tin",
        headerName: "TIN",
        width: 150,
      },
      {
        field: "vatregno",
        headerName: "VATRegNo",
        width: 150,
      },
    {
      field: "contactperson",
      headerName: "Contact Person",
      width: 150,
    },
    {
        field: "isactive",
        headerName: "IsActive",
        width: 150,
      },

    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div

          // onClick={() => {
          //   onEditClick(params.row.id);
          // }}
          >
            <img src={edit} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
  // supplier table declaration end

  // supplier table function start

  const GetSupllierNoAPi = async (props) => {
      await SupplierListPaged(props, "Main");
  };

  const SupplierListPaged = async(props,from) =>{
    
    if (from != "Search") {
      SetSupplierpageState((old) => ({ ...old, isLoading: true }));

     SetSupplierIsLoading(true);

      await axios
        .get(
          "" +
          process.env.REACT_APP_SECRET +
          "/api/Supplier/GetSupplierPaged?startRecordNo=" +
          props.page +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          "1=1" +
          "&sortColumn=" +
          "id" +
          "&sortOrder=" +
          "desc" +
          ""
      )
      .then((response) => {
        console.log(response.data);
        SetSupplierpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: SupplierpageState.page,
        }));
        var list = [];
        response.data._supplierViews.map((d, i) => {
          var obj = {
            id: i,
            sn: i + 1,
            name: d.supplierName,
            address: d.address,
            mobile: d.mobile,
            email: d.email,
            bin: d.bin,
            tin: d.tin,
            vatregno: d.vatRegNo,
            contactperson: d.contactPerson,
            isactive: d.isActive,
          };
          list.push(obj);
        });
        SetSupplierRows(list);
        SetSupplierIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        SetSupplierIsLoading(false);
      });
      }
      else{

        var where=""
        if(!!props.whereClause== false || props.whereClause==undefined){
        where="1=1"
        setSearch("");
        }
        else{
          where=props.whereClause
        }
        console.log(where)

      // const newString = "'" + Search + "'";
      // const condition = "suppliername=" + newString;
      SetSupplierpageState((old) => ({ ...old, isLoading: true }));

      SetSupplierIsLoading(true);

      await axios
        .get(
          "" +
          process.env.REACT_APP_SECRET +
          "/api/Supplier/GetSupplierPaged?startRecordNo=" +
          props.page +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          where +
          "&sortColumn=" +
          "id" +
          "&sortOrder=" +
          "desc" +
          ""
      )
      .then((response) => {
        console.log(response.data);
        SetSupplierpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: SupplierpageState.page,
        }));
        var list = [];
        response.data._supplierViews.map((d, i) => {
          var obj = {
            id: i,
            sn: i + 1,
            name: d.supplierName,
            address: d.address,
            mobile: d.mobile,
            email: d.email,
            bin: d.bin,
            tin: d.tin,
            vatregno: d.vatRegNo,
            contactperson: d.contactPerson,
            isactive: d.isActive,
          };
          list.push(obj);
        });
        SetSupplierRows(list);
        SetSupplierIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        SetSupplierIsLoading(false);
      });
      } 
    };

    const onSearch = async () => {
      var prop= Search
      if(Search == "" || Search == undefined) {
        toast.error("Search is Empty", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
        else
        {
          setSearch(prop);
          var upperText = prop.toUpperCase();
          const newString = "'%" + upperText + "%'";
          const condition = "upper(suppliername) like " + newString;
          prop = condition;
        }
    
        var obj = {
          page: 0,
          pageSize: 10,
          whereClause: prop,
        };

      await SupplierListPaged(obj, "Search");
    };
    const onClear = async () => {
      var props = {
        page: 0,
        pageSize: 10,
      };
      setSearch("");
      await SupplierListPaged(props, "Main");
    };
  //Supplier table function end


  const onReset= async() =>{

    setSupplierName("");
    setSupplierAddress("");
    setBIN("");
    setContactPerson("");
    setSupplierEmail("");
    setSupplierMobile("");
    setTIN("");
    setVatRegNo("");


  };
  const onSave = async () => {
    //let isValid =validateField()
    
    if (!!SupplierName == false ) {
      toast.error("Supplier Name is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!SupplierAddress == false ) {
      toast.error("Supplier Address is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!SupplierMobile == false ) {
      toast.error("Supplier Mobile Number is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!SupplierEmail == false ) {
      toast.error("Supplier Email is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!SupplierBIN == false ) {
      toast.error("BIN is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!SupplierTIN == false ) {
      toast.error("TIN is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!SupplierVatRegNo == false ) {
      toast.error("VAT Reg No. required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!SupplierContactPerson == false) {
      toast.error("Contact Person is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else{
      SupplierSetup.SupplierName= SupplierName;
      SupplierSetup.Address= SupplierAddress;
      SupplierSetup.Mobile= SupplierMobile;
      SupplierSetup.Email= SupplierEmail;
      SupplierSetup.BIN= SupplierBIN;
      SupplierSetup.TIN= SupplierTIN;
      SupplierSetup.VATRegNo= SupplierVatRegNo;
      SupplierSetup.ContactPerson= SupplierContactPerson;

      var url = apiAddress + "/api/Supplier";
      await axios.post(url,SupplierSetup)

      .then((response) => {
        toast.success("Insert Successfull", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        var props = {
          page: 0,
          pageSize: 10,
        };
        SupplierListPaged(props, "Main");
      })
      .catch((response)=>{
        console.log(response)
      });
      console.log("not hit")
    }
  };


  return (
    <div className="card card-shadow p-3">
      <ToastContainer />
      <div>
        <h5>Supplier Setup</h5>
      </div>
      <div className="row mt-2 ">
        <div className="col-12 col-md-3 col-lg-3 d-flex flex-column gap-3">
          <TextAreaComponents
            id={6}
            value={(e) => setSupplierName(e)}
            label={"Supplier Name"}
            val={SupplierName}
            //   val={PurchaseOrderNumber}
          />
          <TextAreaComponents
            id={6}
            value={(e) => setBIN(e)}
            label={"BIN"}
            val={SupplierBIN}
            //   val={PurchaseOrderNumber}
          />
        </div>
        <div className="col-12 col-md-3 col-lg-3 d-flex flex-column gap-3 ">
        <TextAreaComponents
            id={6}
            value={(e) => setSupplierAddress(e)}
            label={"Supplier Address"}
            val={SupplierAddress}
            //   val={PurchaseOrderNumber}
          />
          <TextAreaComponents
            id={6}
            value={(e) => setTIN(e)}
            label={"TIN"}
            val={SupplierTIN}
            //   val={PurchaseOrderNumber}
          />
          
        </div>
        <div className="col-12 col-md-3 col-lg-3 d-flex flex-column gap-3 ">
        <TextAreaComponents
            id={6}
            value={(e) => setSupplierMobile(e)}
            label={"Supplier Mobile No."}
            val={SupplierMobile}
            //   val={PurchaseOrderNumber}
          />
          <TextAreaComponents
            id={6}
            value={(e) => setVatRegNo(e)}
            label={"VAT Registration No"}
            val={SupplierVatRegNo}
            //   val={PurchaseOrderNumber}
          />
        </div>
        <div className="col-12 col-md-3 col-lg-3 d-flex flex-column gap-3 ">
        <TextAreaComponents
            id={6}
            value={(e) => setSupplierEmail(e)}
            label={"Supplier Email Address"}
            val={SupplierEmail}
            //   val={PurchaseOrderNumber}
          />
          <TextAreaComponents
            id={6}
            value={(e) => setContactPerson(e)}
            label={"Contact Person"}
            val={SupplierContactPerson}
            //   val={PurchaseOrderNumber}
          />
        </div>
      </div>
      <div className="mt-2">
        <FormControl className="d-flex flex-row gap-3">
          <FormLabel id="demo-row-radio-buttons-group-label" className="mt-2">
            Status
          </FormLabel>
          <RadioGroup
            row
            defaultValue={"active"}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            // onChange={purchaseOrderTypeRadio}
          >
            <FormControlLabel
              value="active"
              control={<Radio />}
              label="Active"
            />
            <FormControlLabel
              value="inactive"
              control={<Radio />}
              label="Inactive"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div className="row mt-3 justify-content-start">
        <div className="col-12"></div>
        <div className="col-6">
          <div className="d-flex gap-4 ">
            <button onClick={()=>{onSave()}} className="btn-yes btn-common">Save</button>
            <button onClick={()=>{onReset()}} className="btn-no btn-common">Reset</button>
          </div>
        </div>
      </div>
      <div className="col-12 mt-3 ">
        <div>
          <h5>Supplier List</h5>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setSearch(props)}
              label={"Search"}
              val={Search}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onClear}>
              Clear
            </button>
          </div>
        </div>
        <div className="mt-2">
          <DataTable
            pageState={SupplierpageState}
            GetPurchaseOrderNoAPi={GetSupllierNoAPi}
            isLoading={SupplierisLoading}
            rows={SupplierRows}
            columns={Suppliercolumns}
          />
        </div>
      </div>
    </div>
  );
}
export default SupplierSetup;
