import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import axios from "axios";
import checkMark from "./../../../assests/images/check-mark.png";
import print from "./../../../assests/images/printing.png";
import remove from "./../../../assests/images/remove.png";
import { GetPurchaseOrderByNumber } from "../../../api/screenApi/purchaseBill/purchaseBillApi";
import "./purchaseBill.css";
import DataTable from "../../sharedComponent/dataTable/DataTable";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlertModal from "../../sharedComponent/SweetAlertModal/SweetAlertModal";
import { EntryNumberGenarator } from "../../sharedComponent/CommonMethods";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";
import ClientSiteDataTable from "../../sharedComponent/dataTable/ClientSiteDataTable.jsx";

function PurchaseBill(props) {
  const apiAddress = process.env.REACT_APP_SECRET;
  const [AlertOpen, setAlertOpen] = useState(false);

  //Stock Receive entry states start
  const [StockReceiveNumber, setStockReceiveNumber] = useState();
  const [SelectedReceivedBy, setSelectedReceivedBy] = useState();
  const [DateTime, setDateTime] = useState(dayjs());
  const [Remarks, setRemarks] = useState();
  const [TotalPrice, setTotalPrice] = useState();
  const [TotalVat, setTotalVat] = useState();
  const [TotalAmount, setTotalAmount] = useState();
  const [Search, setSearch] = useState();
  const [OrderSearch, setOrderSearch] = useState();
  const [Quantity, setQuantity] = useState();
  const [RequestedQty, setRequestedQty] = useState();
  const [Discount, setDiscount] = useState(0);
  const [SD, setSD] = useState();
  const [Vat, setVat] = useState();
  const [TotalBaseAmount, setTotalBaseAmount] = useState();
  const [DiscountTotal, setDiscountTotal] = useState();
  const [AfterDiscount, setAfterDiscount] = useState();
  const [TotalSd, setTotalSd] = useState();
  const [TotalAmountWithVat, setTotalAmountWithVat] = useState();

  const [Ait, setAit] = useState(0);
  const [AdditionalDiscount, setAdditionalDiscount] = useState(0);

  const [SeeMoreButton, setSeeMoreButton] = useState();
  const [BasePrice, setBasePrice] = useState();
  const [PurcchaseBillDetailsList, setPurcchaseBillDetailsList] = useState([]);

  const [PurchaseOrderData, setPurchaseOrderData] = useState({});
  const [selectedPurchaseOrder, setSelectedPurchaseorder] = useState();
  const [selectedPurchaseOrderDate, setSelectedPurchaseorderDate] = useState();
  const [finalAmount, setFinalAmount] = useState(0);
  const [OrderDetailsList, setOrderDetailsList] = useState([]);
  const [flag, setFlag] = useState(true);
  const [isAitApplicable, setAitApplicable] = useState(false);
  const [BillNo, setBillNo] = useState();
  const [PurchaseBillView, setPurchaseBillView] = useState({
    purchaseBill: {
      number: "123",
      billDate: DateTime,
      purchaseOrderNumber: "string",
      manualBillNo: "string",
      manualBillDate: DateTime,
      supplierId: 0,
      supplierName: "string",
      remarks: "string",
      addtionalDiscount: 0,
      ait: 0,
      finalAmount: 0,
      isVoid: true,
      counterId: 0,
      creatorId: 0,
      creationDate: dayjs(),
      updatorId: 0,
      updateDate: dayjs(),
    },
  });

  //validation toaster start
  const errorToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  //validation toaster end

  // Purchase Order Table start
  const columns = [
    { field: "id", headerName: "S/N", flex: 1 },
    { field: "number", headerName: "Order No", flex: 1 },
    {
      field: "orderDate",
      headerName: "Order Date",
      renderCell: (params) => <span>{DateFormater(params.row.orderDate)}</span>,
      flex: 1,
    },
    { field: "supplierName", headerName: "Supplier Name", flex: 1 },
    { field: "remarks", headerName: "Remarks ", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      width: 170,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onCheckClick(params.row);
            }}
          >
            <img src={checkMark} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
  const [orderrows, setorderrows] = React.useState([]);

  const [isorderLoading, SetorderisLoading] = React.useState(false);

  const [orderpageState, setorderpageState] = React.useState({
    isLoading: false,
    // data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const onOrderListPaged = async (props, from) => {
    if (from != "Search") {
      setorderpageState((old) => ({ ...old, isLoading: true }));

      SetorderisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseOrder/GetPurchaseOrderPagedForBill?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setorderpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: orderpageState.page,
          }));
          var list = [];
          response.data._purchaseOrders.map((d, i) => {
            var obj = {
              id: i + 1,
              number: d.number,
              orderDate: d.orderDate,
              supplierName: d.supplierName,
              remarks: d.remarks,
            };
            list.push(obj);
          });
          setorderrows(list);
          SetorderisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetorderisLoading(false);
        });
    } else {
      const newString = "'" + OrderSearch + "'";
      const condition = "Number=" + newString;
      setorderpageState((old) => ({ ...old, isLoading: true }));

      SetorderisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseOrder/GetPurchaseOrderPagedForBill?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setorderpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: orderpageState.page,
          }));
          var list = [];
          response.data._purchaseOrders.map((d, i) => {
            var obj = {
              id: i + 1,
              number: d.number,
              orderDate: d.orderDate,
              supplierName: d.supplierName,
              remarks: d.remarks,
            };
            list.push(obj);
          });
          setorderrows(list);
          SetorderisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetorderisLoading(false);
        });
    }
  };

  const onOrderSearch = async () => {
    await onOrderListPaged("props", "Search");
  };
  const onOrderSearchClear = async () => {
    setOrderSearch("");
    var props = {
      page: 0,
      pageSize: 10,
    };
    await onOrderListPaged(props, "Main");
  };

  const GetPurchaseOrderNoAPi = async (props) => {
    await onOrderListPaged(props, "Main");
  };

  const [PurchaseOrderDetailsList, setPurchaseOrderDetailsList] = useState([]);
  const onCheckClick = async (data) => {
    setSelectedPurchaseorder(data);
    setSelectedPurchaseorderDate(dayjs(data.orderDate));
    var res = await GetPurchaseOrderByNumber(data.number);

    setOrderDetailsList(res.purchaseOrderdetailsList);
    setPurchaseOrderData(res.purchaseOrder);
    setPurchaseOrderDetailsList(res.purchaseOrderdetailsList);
  };

  // Purchase Order Table End

  // Purchase Bill Table starts

  const billcolumns = [
    { field: "id", headerName: "S/N", flex: 1 },
    { field: "billnumber", headerName: "Bill No", flex: 1 },
    {
      field: "billDate",
      headerName: "Bill Date",
      renderCell: (params) => <span>{DateFormater(params.row.billDate)}</span>,
      flex: 1,
    },
    { field: "supplierName", headerName: "Supplier Name", flex: 1 },
    { field: "remarks", headerName: "Remarks ", flex: 1 },
    {
      field: "action",
      headerName: "Action ",
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              window.open(
                "/purchase-bill-report/" + params.row.billnumber,
                "popup",
                "width=850,height=550,left=280,top=80"
              );
            }}
          >
            <img src={print} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      flex: 1,
    },
  ];

  const [billrows, setBillrows] = React.useState([]);

  const [isBillLoading, SetBillisLoading] = React.useState(false);

  const [billpageState, setBillpageState] = React.useState({
    isLoading: false,
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const BillListPaged = async (props, from) => {
    if (from != "Search") {
      setBillpageState((old) => ({ ...old, isLoading: true }));

      SetBillisLoading(true);

      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseBill/GetPurchaseBillPaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          var entryNo = EntryNumberGenarator(
            response.data._purchaseBill[0].number,
            "INV1-PB-"
          );
          setBillNo(entryNo);
          setBillpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: billpageState.page,
          }));
          var list = [];
          response.data._purchaseBill.map((d, i) => {
            var obj = {
              id: i + 1,
              billnumber: d.number,
              billDate: d.billDate,
              supplierName: d.supplierName,
              remarks: d.remarks,
            };
            list.push(obj);
          });
          setBillrows(list);
          SetBillisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetBillisLoading(false);
        });
    } else {
      const newString = "'" + Search + "'";
      const condition = "Number=" + newString;
      setBillpageState((old) => ({ ...old, isLoading: true }));

      SetBillisLoading(true);

      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseBill/GetPurchaseBillPaged?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setBillpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: billpageState.page,
          }));
          var list = [];
          response.data._purchaseBill.map((d, i) => {
            var obj = {
              id: i + 1,
              billnumber: d.number,
              billDate: d.billDate,
              supplierName: d.supplierName,
              remarks: d.remarks,
            };
            list.push(obj);
          });
          setBillrows(list);
          SetBillisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetBillisLoading(false);
        });
    }
  };
  const onSearch = async () => {
    await BillListPaged("props", "Search");
  };
  const onClear = async () => {
    var props = {
      page: 0,
      pageSize: 10,
    };
    setSearch("");
    await BillListPaged(props, "Main");
  };

  const GetPurchaseBillNoAPi = async (props) => {
    await BillListPaged(props, "Main");
  };

  // Purchase Bill Table End

  const OrderItemRowSelect = (d) => {
    var res = PurcchaseBillDetailsList.find((x) => x.itemId == d.itemId);
    if (res == undefined) {
      const list = [...PurcchaseBillDetailsList];
      var obj = {
        id: 0,
        puchaseBillNumber: "string",
        itemId: d.itemId,
        itemName: d.productName,
        qty: d.requestedQty,
        unitPrice: 0,
        amount: 0,
        discount: 0,
        afterDiscount: 0,
        addtionalDiscount: 0,
        ait: 0,
        sd: d.sdPercent,
        vat: d.vatPercent,
      };
      list.push(obj);
      setPurcchaseBillDetailsList(list);
    }
  };
  const removeItem = (index) => {
    var temp = [...PurcchaseBillDetailsList];
    temp.splice(index, 1);
    setPurcchaseBillDetailsList(temp);
  };

  const SeeMoreButtonValue = (event) => {
    setSeeMoreButton(event.target.value);
  };

  const inputChange = (e, index, field) => {
    if (!!e == false) {
      e = 0;
    }
    var updatedList = [...PurcchaseBillDetailsList];
    updatedList[index][field] = parseFloat(e);
    const { qty, unitPrice, discount, sd, vat } = updatedList[index];
    var afterDiscount = qty * unitPrice - discount;
    updatedList[index].afterDiscount = afterDiscount;
    var individualRowTotal = qty * unitPrice - discount + sd + vat;
    updatedList[index].amount = individualRowTotal;
    setFinalAmount(individualRowTotal);
    setPurcchaseBillDetailsList(updatedList);
  };

  const individualTotal = (itemList, field) => {
    var sum = 0;
    itemList.map((d, i) => {
      sum += d[field];
    });
    if (field == "amount") {
      PurchaseBillView.purchaseBill.finalAmount =
        sum + Ait - AdditionalDiscount;
      // setFinalAmount(sum);
    }
    return sum;
  };

  useEffect(() => {
    netTotal();
  }, [flag]);

  const handleAitSwitch = () => {
    setAitApplicable(!isAitApplicable);
  };
  const addAIT = (e) => {
    if (!!e == false) {
      setAit(0);
    } else {
      setAit(parseInt(e));
    }
    setFlag(!flag);
  };
  const addAdditioanlDiscount = (e) => {
    if (!!e == false) {
      setAdditionalDiscount(0);
    } else {
      setAdditionalDiscount(parseInt(e));
    }
    setFlag(!flag);
  };

  const netTotal = () => {
    const total =
      PurchaseBillView.purchaseBill.finalAmount - AdditionalDiscount + Ait;
    // setFinalAmount(total);
    PurchaseBillView.purchaseBill.finalAmount = total;
  };

  //selected order column start
  const SelectedOrderColumns = [
    // { field: "id", headerName: "Serial", flex: 1 },
    {
      field: "combination",
      headerName: "ProductName",

      flex: 2.5,
    },

    {
      field: "requestedQty",
      headerName: "Requested Quantity",
      flex: 1,
    },
    {
      field: "sdPercent",
      headerName: "SD",
      flex: 1,
    },
    {
      field: "vatPercent",
      headerName: "Vat",
      flex: 1,
    },
  ];
  //selected order column end
  const getSelectedItems = (data) => {
    var list = [];
    data.map((d, i) => {
      var res = OrderDetailsList.find((x) => x.id == d);
      if (res != undefined) {
        var obj = {
          id: res.id,
          puchaseBillNumber: "string",
          itemId: res.itemId,
          itemName: res.productName,
          combination: res.combination,
          qty: res.requestedQty,
          unitPrice: 0,
          amount: 0,
          discount: 0,
          afterDiscount: 0,
          addtionalDiscount: 0,
          ait: 0,
          sd: res.sdPercent,
          vat: res.vatPercent,
        };
        list.push(obj);
      }
    });
    setPurcchaseBillDetailsList(list);
  };

  //selected item column start
  const SelectedItemColumns = [
    // { field: "id", headerName: "Serial", flex: 1 },

    {
      field: "combination",
      headerName: "ProductName",

      flex: 2.5,
    },

    {
      field: "qty",
      headerName: "Quantity",
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <TextAreaComponents
            id={2}
            value={(e) => NewinputChange(e, params.id, params.field)}
            val={params.row.qty}
          />
        </div>
      ),
      flex: 1,
    },
    {
      field: "unitPrice",
      headerName: "BasePrice",
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <TextAreaComponents
            id={2}
            value={(e) => NewinputChange(e, params.id, params.field)}
            val={params.row.unitPrice}
          />
        </div>
      ),
      flex: 1,
    },
    {
      field: "discount",
      headerName: "Discount",
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <TextAreaComponents
            id={2}
            value={(e) => NewinputChange(e, params.id, params.field)}
            val={params.row.discount}
          />
        </div>
      ),
      flex: 1,
    },
    {
      field: "afterDiscount",
      headerName: "AfterDiscount",
      flex: 1,
    },
    {
      field: "sd",
      headerName: "SD",
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <TextAreaComponents
            id={2}
            value={(e) => NewinputChange(e, params.id, params.field)}
            val={params.row.sd}
          />
        </div>
      ),
      flex: 1,
    },
    {
      field: "vat",
      headerName: "Vat",
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <TextAreaComponents
            id={2}
            value={(e) => NewinputChange(e, params.id, params.field)}
            val={params.row.vat}
          />
        </div>
      ),
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Total",
      flex: 1,
    },
  ];
  //selected item column end

  //New calculation start
  const NewinputChange = (e, i, field) => {
    var obj = PurcchaseBillDetailsList.find((x) => x.id == i);
    var index = PurcchaseBillDetailsList.indexOf(obj);
    if (!!e == false) {
      e = 0;
    }
    var updatedList = [...PurcchaseBillDetailsList];
    updatedList[index][field] = parseFloat(e);
    const { qty, unitPrice, discount, sd, vat } = updatedList[index];
    var afterDiscount = qty * unitPrice - discount;
    updatedList[index].afterDiscount = afterDiscount;
    var individualRowTotal = qty * unitPrice - discount + sd + vat;
    updatedList[index].amount = individualRowTotal;
    // setFinalAmount(individualRowTotal);
    setPurcchaseBillDetailsList(updatedList);
  };

  //New calculation end

  const onSave = async () => {
    setAlertOpen(false);
    var valid = true;

    if (!!PurchaseOrderData.number == false) {
      valid = false;
      errorToast("Please select a Order");
    }

    if (!!Remarks == false) {
      valid = false;
      errorToast("Remarks is required");
    }
    if (valid) {
      PurchaseBillView.purchaseBill.purchaseOrderNumber =
        PurchaseOrderData.number;
      PurchaseBillView.purchaseBill.supplierId = PurchaseOrderData.supplierId;
      PurchaseBillView.purchaseBill.supplierName =
        PurchaseOrderData.supplierName;
      PurchaseBillView.purchaseBill.remarks = Remarks;
      PurchaseBillView.purchaseBill.addtionalDiscount = AdditionalDiscount;
      PurchaseBillView.purchaseBill.ait = Ait;
      PurchaseBillView.purchaseBill.counterId = PurchaseOrderData.counterId;
      PurchaseBillView.purchaseBill.creatorId = PurchaseOrderData.supplierId;
      PurchaseBillView.purchaseBill.updatorId = PurchaseOrderData.supplierId;
      PurchaseBillView.PurchaseBillDetailsList = PurcchaseBillDetailsList;
      // PurchaseBillView.purchaseBill.finalAmount = finalAmount;

      var url = apiAddress + "/api/PurchaseBill";
      axios.post(url, PurchaseBillView).then((response) => {
        successToast("Successfully saved");
        setSelectedPurchaseorder({});
        setRemarks("");
        setPurcchaseBillDetailsList([]);
        setOrderDetailsList([]);
        setAit(0);
        setAdditionalDiscount(0);
        setDateTime(null);
        var props = {
          page: 0,
          pageSize: 10,
        };

        BillListPaged(props, "Main");
        onOrderListPaged(props, "Main");
      });
    }
  };

  return (
    <div>
      <ToastContainer />
      <SweetAlertModal
        open={AlertOpen}
        headerText={"Are you sure?"}
        bodyText={"You want to save?"}
        close={() => setAlertOpen(false)}
        onyes={onSave}
      />
      <h5 className="">Purchase Bill</h5>

      <div className="card card-shadow p-3 mt-3">
        <div>
          <h6>Purchase Order List</h6>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={5}
              value={(props) => setOrderSearch(props)}
              label={"Search"}
              val={OrderSearch}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6  gap-2 d-flex">
            <button className="btn-search btn-common" onClick={onOrderSearch}>
              Search
            </button>
            <button
              className="btn-search btn-common"
              onClick={onOrderSearchClear}
            >
              Clear
            </button>
          </div>
        </div>

        <div className="mt-2">
          <DataTable
            pageState={orderpageState}
            GetPurchaseOrderNoAPi={GetPurchaseOrderNoAPi}
            isLoading={isorderLoading}
            rows={orderrows}
            columns={columns}
          />
        </div>
      </div>

      <div className="card card-shadow mt-3 p-3">
        <div className="row">
          <div className="col-12">
            <h6>Purchase Bill Entry</h6>
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <div className="mt-2">
              <TextAreaComponents
                id={5}
                // value={(e) => setStockReceiveNumber(e)}
                label={"Purchase Bill No"}
                val={BillNo}
                isactive={true}
              />
            </div>
            <div>
              <TextAreaComponents
                id={5}
                // value={(e) => setStockReceiveNumber(e)}
                label={"Purchase Order No"}
                val={selectedPurchaseOrder?.number}
                isactive={true}
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column mt-2 gap-2 PurchaseOrderCard">
            <CustomDateTimePicker
              value={(props) => setDateTime(props)}
              isDisabled={true}
              val={selectedPurchaseOrderDate}
              label={"Purchase Order Date"}
            />
            <CustomDateTimePicker
              value={(props) => setDateTime(props)}
              val={DateTime}
            />
          </div>
          <div className="mt-2">
            <TextAreaComponents
              id={6}
              value={(props) => setRemarks(props)}
              label={"Remarks"}
              val={Remarks}
            />
          </div>
        </div>
      </div>

      {/* <div className="card card-shadow p-3 mt-3">
        <div>
          <h6>Items Under Purchase Order</h6>
        </div>
        <table class="table table-striped mt-2 ">
          <thead style={{ backgroundColor: "#2d358f", color: "white" }}>
            <tr>
              <th>S/N</th>
            
              <th>Item Name</th>
              <th>PO Quantity</th>

              <th>SD</th>
              <th>VAT</th>

              <th>Reamrks</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {OrderDetailsList.map((d, i) => {
              return (
                <tr key={i} onClick={() => OrderItemRowSelect(d)}>
                  <td>{i + 1}</td>
                  
                  <td>{d.combination}</td>
                  <td className="col-1">
                    <TextAreaComponents
                      id={2}
                    
                      value={(e) => inputChange(e, i, "qty")}
                     
                      placeholder={"Requested Qty"}
                      val={d.requestedQty}
                      isactive={true}
                    />
                  </td>

                  <td className="col-1">
                    <TextAreaComponents
                      id={2}
                      
                      value={(e) => inputChange(e, i, "sd")}
                     
                      placeholder={"S.D."}
                      val={d.sdPercent}
                      isactive={true}
                    />
                  </td>
                  <td className="col-1">
                    <TextAreaComponents
                      id={2}
                     
                      value={(e) => inputChange(e, i, "vat")}
                    
                      placeholder={"VAT"}
                      val={d.vatPercent}
                      isactive={true}
                    />
                  </td>

                  <td>
                    <p>{d.remarks}</p>
                  </td>
                  <td>
                    <div onClick={() => OrderItemRowSelect(d)}>
                      <img src={checkMark} alt="" style={{ width: "1rem" }} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="row d-flex justify-content-end"></div>
      </div> */}

      {OrderDetailsList.length > 0 ? (
        <>
          <div className="card card-shadow p-3 mt-3">
            <div>
              <h6>Items Under Purchase Order</h6>
            </div>
            <ClientSiteDataTable
              dataRows={OrderDetailsList}
              columns={SelectedOrderColumns}
              isActive={true}
              getselectedData={getSelectedItems}
            />
          </div>
        </>
      ) : (
        ""
      )}

      {/* <div className="card card-shadow p-3 mt-3">
        <div>
          <h6>Selected Items</h6>
        </div>
        <table class="table table-striped mt-2 ">
          <thead style={{ backgroundColor: "#2d358f", color: "white" }}>
            <tr>
              <th>S/N</th>
              <th>Item Id</th>
              <th>Item Name</th>
              <th>PO Quantity</th>
              <th>Base Price</th>
              <th>Discount</th>
              <th>After Discount</th>
              <th>SD</th>
              <th>VAT</th>
        

              <th>Total</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {PurcchaseBillDetailsList.map((d, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{d.itemId}</td>
                  <td>{d.itemName}</td>
                  <td className="col-1">
                    <TextAreaComponents
                      id={2}
                     
                      value={(e) => inputChange(e, i, "qty")}
                      label={d.requestedQty}
                      placeholder={"Requested Qty"}
                      val={d.qty}
                    />
                  </td>
                  <td className="col-1">
                    <TextAreaComponents
                      id={2}
                     
                      value={(e) => inputChange(e, i, "unitPrice")}
                      label={"0"}
                      placeholder={"Base Price"}
                    />
                  </td>
                  <td className="col-1">
                    <TextAreaComponents
                      id={2}
                     
                      value={(e) => inputChange(e, i, "discount")}
                      label={"0"}
                      placeholder={"Discount"}
                    />
                  </td>
                
                  <td>{d.afterDiscount}</td>
                  <td className="col-1">
                    <TextAreaComponents
                      id={2}
                    
                      value={(e) => inputChange(e, i, "sd")}
                      label={d.sdPercent}
                      placeholder={"S.D."}
                      val={d.sd}
                    />
                  </td>
                  <td className="col-1">
                    <TextAreaComponents
                      id={2}
                  
                      value={(e) => inputChange(e, i, "vat")}
                      label={d.vatPercent}
                      placeholder={"VAT"}
                      val={d.vat}
                    />
                  </td>

                  <td>{d.amount}</td>
                  <td>
                    <div onClick={() => removeItem(i)}>
                      <img src={remove} alt="" style={{ width: "1.5rem" }} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="row d-flex justify-content-end"></div>
      </div> */}

      {PurcchaseBillDetailsList.length > 0 ? (
        <>
          <div className="card card-shadow p-3 mt-3">
            <div>
              <h6>Selected Items</h6>
            </div>
            <ClientSiteDataTable
              dataRows={PurcchaseBillDetailsList}
              columns={SelectedItemColumns}
              // isActive={true}
              // getselectedData={getSelectedItems}
            />
          </div>
        </>
      ) : (
        ""
      )}

      <div className="card card-shadow p-3 mt-3">
        <div className="row p-3 justify-content-end">
          <div className="col-12 col-md-12 col-lg-5 d-flex flex-column  gap-2 ">
            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Total Base Amount</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    value={(e) => setTotalBaseAmount(e)}
                    label={"Total Base Amount"}
                    val={individualTotal(PurcchaseBillDetailsList, "unitPrice")}
                    isactive={true}
                    placeholder={"Total Base Amount"}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Discount Total</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    value={(e) => setDiscountTotal(e)}
                    label={"Discount Total "}
                    val={individualTotal(PurcchaseBillDetailsList, "discount")}
                    isactive={true}
                  />
                </div>
              </div>
            </div>
            <hr className="hr" />

            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Total After Discount</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    value={(e) => setAfterDiscount(e)}
                    label={"Total After Discount"}
                    val={individualTotal(
                      PurcchaseBillDetailsList,
                      "afterDiscount"
                    )}
                    isactive={true}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Total SD</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    value={(e) => setTotalSd(e)}
                    label={"Total SD "}
                    val={individualTotal(PurcchaseBillDetailsList, "sd")}
                    isactive={true}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Total Vat</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    value={(e) => setTotalVat(e)}
                    label={"Total VAT"}
                    val={individualTotal(PurcchaseBillDetailsList, "vat")}
                    isactive={true}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Total Amount with Vat</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    value={(e) => setTotalAmountWithVat(e)}
                    label={"Total Amount With VAT"}
                    val={individualTotal(PurcchaseBillDetailsList, "amount")}
                    isactive={true}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Is AIT applicable? </span>
                </div>
                <div className="col-7">
                  <Switch onChange={handleAitSwitch} />
                </div>
              </div>
            </div>
            {isAitApplicable && (
              <div>
                <div className="row  d-flex">
                  <div className="col-5">
                    <span>AIT</span>
                  </div>
                  <div className="col-7">
                    <TextAreaComponents
                      id={2}
                      value={(e) => addAIT(e)}
                      label={"AIT"}
                      val={Ait}
                    />
                  </div>
                </div>
              </div>
            )}
            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Additional Discount</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    value={(e) => addAdditioanlDiscount(e)}
                    label={"Additional Discount"}
                    val={AdditionalDiscount}
                  />
                </div>
              </div>
            </div>
            <hr className="hr" />

            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Final Total Amount</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    // value={(e) => setTotalAmountWithVatandAit(e)}
                    label={"Total Amount With VAT and AIT"}
                    val={PurchaseBillView.purchaseBill.finalAmount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-4">
          <div className="d-flex gap-4 ">
            <button
              className="btn-yes btn-common"
              onClick={() => setAlertOpen(true)}
            >
              Save
            </button>
            <button className="btn-no btn-common">Clear</button>
            <button className="btn-Review btn-common">Review</button>
          </div>
        </div>
      </div>

      <div className="card card-shadow p-3 mt-3">
        <div>
          <h6>Purchase Bill List</h6>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setSearch(props)}
              label={"Search"}
              val={Search}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onClear}>
              Clear
            </button>
          </div>
        </div>

        <div className="mt-2">
          <DataTable
            pageState={billpageState}
            GetPurchaseOrderNoAPi={GetPurchaseBillNoAPi}
            isLoading={isBillLoading}
            rows={billrows}
            columns={billcolumns}
          />
        </div>
      </div>
    </div>
  );
}

export default PurchaseBill;
