import React, { useState } from "react";
import bin from "./../../../../../assests/images/recycle-bin.png";
import "./CardDetails.css";

const CardDetails = (props) => {
  const [showMinus, setShowMinus] = useState(true);
  const [quantity, setQuantity] = useState(props.data.quantity);

  const changeBinDisplay = (type, data) => {
    if (type === 1) {
      var q = quantity + 1;
      data.quantity = q;
      props.onquantitychange(data);
      setQuantity(q);
      if (q > 1) {
        setShowMinus(false);
      } else if (q === 1) {
        console.log("Else");
        setShowMinus(true);
      }
    } else {
      var q = quantity - 1;
      if (quantity < 1) {
        props.onDeleteClick(data);
      } else {
        setQuantity(q);
        data.quantity = q;
        props.onquantitychange(data);
      }
    }
  };
  const onDeleteClick = (data) => {
    props.onDeleteClick(data);
  };

  return (
    <div>
      <div className="d-flex mt-3" style={{ gap: "10px" }}>
        <div>
          <img
            src={require("./../../../../../assests/images/close-up-tyres.jpg")}
            alt=""
            style={{ height: "6rem" }}
          />
        </div>
        <div className="d-flex  flex-column w-100">
          <div className="d-flex itemDetailsAndImage justify-content-between">
            <h5>{props.data.name}</h5>
            <p className="price d-flex justify-content-end">
              ৳ {props.data.suggestedPrice * props.data.quantity}
            </p>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex  price-Div">
              <div className="d-flex align-items-center quantity">
                <span
                  onClick={() => {
                    changeBinDisplay(0, props.data);
                  }}
                  className="plus-icon"
                >
                  -
                </span>
                {quantity}
                <span
                  onClick={() => {
                    changeBinDisplay(1, props.data);
                  }}
                  className="plus-icon"
                >
                  +
                </span>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <img
                src={bin}
                alt=""
                style={{ width: "1.5rem", height: "1.5rem", cursor: "pointer" }}
                onClick={() => {
                  onDeleteClick(props.data);
                }}
              />
            </div>
          </div>
          <p style={{ color: "#e2296d", fontSize: ".8rem" }}>
            * Price may vary from suggested price
          </p>
        </div>
      </div>
    </div>
  );
};

export default CardDetails;
