import React, { useEffect, useRef, useState } from "react";
import "./Cart.css";
import bag from "./../../../assests/images/shopping-bag.png";
import CardDetails from "./Components/CardDetails/CardDetails";
import axios from "axios";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";

const Cart = () => {
  const [showDetails, setShowDetails] = useState(false);
  const [success, setsuccess] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const cardDetails = useRef(null);
  const cart = useRef(null);
  const navbarTopRef = useRef(null);
  const [cartItems, setCartItems] = useState([]);
  const [total, settotal] = useState(0.0);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showDetails &&
        cardDetails.current &&
        !cardDetails.current.contains(event.target) &&
        !cart.current.contains(event.target)
      ) {
        setShowDetails(false);
      }
    };

    const broadcastChannel = new BroadcastChannel("storage_change_channel");
    broadcastChannel.addEventListener("message", (event) => {
      if (event.data === "storage_changed") {
        var res = sessionStorage.getItem("cartItems");
        setCartItems(JSON.parse(res));
        const sum = JSON.parse(res).reduce((accumulator, object) => {
          return accumulator + object.quantity * object.suggestedPrice;
        }, 0);
        settotal(sum);
      }
    });
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  });

  useEffect(() => {
    var res = sessionStorage.getItem("cartItems");
    console.log(res);
    if (res != undefined) {
      var list = JSON.parse(res);
      setCartItems(list);
      const sum = list.reduce((accumulator, object) => {
        return accumulator + object.quantity * object.suggestedPrice;
      }, 0);
      settotal(sum);
    }
  }, []);

  const [navbarOnTop, setNavbarOnTop] = useState(true);
  const [scrollValue, setscrollValue] = useState(0);
  const [slideUp, setSlideUp] = useState(true);
  useEffect(() => {
    window.addEventListener("scroll", (event) => {
      if (navbarTopRef?.current?.getBoundingClientRect() != null) {
        const filterBarRect = navbarTopRef?.current?.getBoundingClientRect();
        const isAboveViewport = filterBarRect.top === 0 ? true : false;
        setNavbarOnTop(isAboveViewport);
        var top = filterBarRect.top * -1;
        var scrollval = scrollValue * -1;
        if (top > scrollval) {
          setSlideUp(true);
          setShowDetails(false);
        } else if (top <= 0) {
          setSlideUp(true);
        } else {
          setSlideUp(false);
          setShowDetails(false);
        }

        var s = filterBarRect.top;
        setscrollValue(s);
      }
    });
  });
  const showCartDetails = () => {
    console.log("showDetails: " + showDetails);
    if (!showDetails) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  };
  const onquantitychange = (props) => {
    console.log(props);
    var obj = cartItems.find((x) => x.id == props.id);
    console.log(obj);
    obj.quantity = props.quantity;
    console.log(cartItems);
    setCartItems(cartItems);
    sessionStorage.setItem("cartItems", JSON.stringify(cartItems));
    const broadcastChannel = new BroadcastChannel("storage_change_channel");
    broadcastChannel.postMessage("storage_changed");
    window.dispatchEvent(new Event("storage"));
  };
  const onDeleteClick = (props) => {
    console.log(props);
    var obj = cartItems.find((x) => x.id == props.id);
    if (obj !== undefined) {
      var index = cartItems.indexOf(obj);
      if (index !== -1) {
        cartItems.splice(index, 1);
      }
    }
    setCartItems(cartItems);
    sessionStorage.setItem("cartItems", JSON.stringify(cartItems));
    const broadcastChannel = new BroadcastChannel("storage_change_channel");
    broadcastChannel.postMessage("storage_changed");
    window.dispatchEvent(new Event("storage"));
  };
  const apiAddress = process.env.REACT_APP_SECRET;
  const handleSubmit = () => {
    var data = sessionStorage.getItem("imsToken");
    var decodedData = jwtDecode(data);
    var SalesOrder = {};
    var salesobj = {
      number: "string",
      orderDate: dayjs(),
      counterId: 1,
      orderFromCustomerId: decodedData.EmployeeId,
      customerReferenceNo: "159368",
      remarks: "N/A",
      isVoid: true,
      creatorId: decodedData.UserId,
      creationDate: dayjs(),
      updatorId: decodedData.UserId,
      updateDate: dayjs(),
      salesType: 2,
    };
    // setSalesOrder(salesobj);
    SalesOrder.salesOrder = salesobj;
    var res = sessionStorage.getItem("cartItems");
    var list = JSON.parse(res);
    var salesOrderdetailsList = [];
    list.map((dt, i) => {
      var itemdetails = {
        id: 0,
        salesOrderNumber: "string",
        itemId: dt.id,
        isVoid: true,
        requestedQty: dt.quantity,
        remarks: "string",
      };
      salesOrderdetailsList.push(itemdetails);
    });
    SalesOrder.salesOrderdetailsList = salesOrderdetailsList;
    var url = apiAddress + "/api/SalesOrder";
    axios.post(url, SalesOrder).then((response) => {
      setsuccess(true);

      sessionStorage.removeItem("cartItems");
    });
  };

  return (
    <div className="cart d-flex align-items-end ">
      <div
        className="modal fade static"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Check Out
              </h5>
            </div>
            <div className="modal-body">
              {success ? (
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ flexDirection: "column" }}
                >
                  <div>
                    <img
                      src={require("./../../../assests/images/check.png")}
                      alt=""
                      style={{ width: "6rem" }}
                    />
                  </div>
                  <h4> Order Successfully Placed</h4>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    data-bs-dismiss="modal"
                    onClick={() => {
                      setsuccess(false);
                      window.location.href = "/home";
                    }}
                  >
                    Close
                  </button>
                </div>
              ) : (
                <>
                  <div className="modal-cards">
                    {cartItems.map((dt, i) => {
                      return (
                        <CardDetails
                          data={dt}
                          key={i}
                          onquantitychange={onquantitychange}
                          onDeleteClick={onDeleteClick}
                        />
                      );
                    })}
                  </div>

                  <div className="d-flex justify-content-between mt-3">
                    <h6>
                      <b style={{ fontSize: "1.3rem" }}>Total</b>
                      <p style={{ color: "#e2296d", fontSize: ".8rem" }}>
                        * Price may vary from suggested price
                      </p>
                    </h6>
                    <h6 style={{ fontSize: "1.3rem" }}>৳ {total}</h6>
                  </div>
                  <div
                    className="d-flex justify-content-end"
                    style={{ gap: "10px" }}
                  >
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn btn-success"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Confirm Order
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div ref={navbarTopRef}></div>
      <div
        className={`${showDetails ? "cart-details" : "d-none"} ${
          slideUp ? "" : " d-none"
        } `}
        ref={cardDetails}
      >
        <div className="d-flex justify-content-center mt-1">
          <h6 style={{ fontWeight: "bold", color: "#0b0028" }}>
            Your Order from A & H Solutions
          </h6>
        </div>
        <div className="card-item-details">
          {cartItems.length > 0 ? (
            cartItems.map((dt, i) => {
              return (
                <CardDetails
                  data={dt}
                  key={i}
                  onquantitychange={onquantitychange}
                  onDeleteClick={onDeleteClick}
                />
              );
            })
          ) : (
            <p className="text-center">Cart is empty</p>
          )}
        </div>
        <div className="p-1">
          <hr />
          <div className="d-flex justify-content-between mb-2">
            <h6>Subtotal</h6>
            <h6>৳ {total}</h6>
          </div>

          <div className="d-flex justify-content-between">
            <h6>
              <b>Total</b>
            </h6>
            <h6>৳ {total}</h6>
          </div>
          <div className="d-flex justify-content-between">
            {cartItems.length > 0 ? (
              <button
                className="btn btn-checkout"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                onClick={() => {
                  setShowDetails(false);
                }}
              >
                Checkout
              </button>
            ) : (
              <button className="btn btn-grayout" href="/checkout">
                Checkout
              </button>
            )}
          </div>
        </div>
      </div>
      <div ref={cart}>
        <div className="desktopCart">
          <div
            className="popup"
            onClick={() => {
              showCartDetails();
            }}
          >
            {cartItems.length > 0 ? (
              <div className="cart-counter">{cartItems.length}</div>
            ) : (
              ""
            )}

            <img src={bag} alt="" />
            {/* <img src={cancelIcon} alt="" className={`${showDetails ? "" : "d-none"}`} /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
