import React, { useEffect, useState } from "react";
//import * as React from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { GetAllItemApi } from "../../../api/adminApi/itemSetpAPi/ItemSetupApi";
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import { GetAllModelApi } from "../../../api/adminApi/ModelApi";
import { ToastContainer, toast } from "react-toastify";
import {
  GetAllDesignationApi,
  GetAllRolesApi,
} from "../../../api/adminApi/designationApi/DesingnationAPI";

//radio button imports start
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import DataTable from "../../sharedComponent/dataTable/DataTable";
//radio button imports end
import axios from "axios";
import edit from "./../../../assests/images/edit.png";
import { GetAllVatApi } from "../../../api/adminApi/VatApi";
import "./EmployeeSetup.css";

function EmployeeSetup() {
  const apiAddress = process.env.REACT_APP_SECRET;
  //AutoComplete  states start
  const [DesignationList, setDesignationList] = useState([]);
  const [RolesList, setRolesList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [vatList, setVatList] = useState([]);
  const [Error, setError] = useState({});
  const [Search, setSearch] = useState();
  //AutoComplete sates end
  //Supplier Field Declaration Start
  const [EmployeeTitle, setEmptitle] = useState();
  const [EmployeeFirstName, setEmpFirstName] = useState();
  const [EmployeeMiddleName, setEmpMiddleName] = useState();
  const [EmployeeLastName, setEmpLastName] = useState();
  const [EmployeeContactNo, setEmpContactNo] = useState();
  const [EmployeeEmail, setEmpEmail] = useState();
  const [EmployeeCode, setEmpCode] = useState();
  const [EmployeeAddress, setEmpAddress] = useState();
  const [EmployeeDOB, setDOB] = useState();
  const [UserName, setUserName] = useState();
  const [UserRole, setUserRole] = useState();
  const [UserPassword, setUserPassword] = useState();
  const [UserConPassword, setUserConPassword] = useState();
  const [SelectedDesignation, setSelectedDesignation] = useState();
  const [SelectedUserRole, setSelectedUserRole] = useState();
  const [value, setValue] = React.useState("one");

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
  };
  //Supplier Field Declaration End

  const [toggleState, setToggleState] = useState();
  const toggleTab = (index) => {
    setToggleState(index);
    console.log(index);
  };
  const handleDesignationSelect = (e) => {
    setSelectedDesignation(e);
    console.log(e);
  };

  const handleUserRoleSelect = (e) => {
    setSelectedUserRole(e);
    console.log(e);
  };

  const GetAllDesignation = async (e) => {
    var list = [];
    var res = await GetAllDesignationApi();
    res.map((d, i) => {
      var obj = { id: d.designationId, title: d.designationName };
      list.push(obj);
    });
    setDesignationList(list);
    console.log(res);
    console.log(list);
  };

  const GetAllRoles = async (e) => {
    var list = [];
    var res = await GetAllRolesApi();
    res.map((d, i) => {
      var obj = { id: d.roleId, title: d.roleName };
      list.push(obj);
    });
    console.log(list);
    setRolesList(list);
  };
  const [EmployeeSetup, setEmployeeSetup] = useState({
    EmployeeId: 0,
    DesignationId: 0,
    Title: "",
    FirstName: "",
    MiddleName: "",
    LastName: "",
    EmployeeCode: "",
    ContactNo: "",
    PresentAddress: "",
    Email: "",
    Gender: "male",
    DateOfBirth: "2023-10-29T13:22:59.060Z",
    IsActive: true,
    CreatorId: 0,
    CreateDate: "2023-10-29T13:22:59.060Z",
    UpdatorId: 0,
    UpdateDate: "2023-10-29T13:22:59.060Z",
  });
  const [UserSetup, setUserSetup] = useState({
    UserId: 0,
    EmployeeId: 0,
    RoleId: 0,
    Username: "",
    Password: "",
    IsActive: true,
    CreatorId: 0,
    CreateDate: "2023-10-30T07:09:42.998Z",
    UpdatorId: 0,
    UpdateDate: "2023-10-30T07:09:42.998Z",
  });

  //Api calling section start
  //Api calling section end
  useEffect(() => {
    GetAllDesignation();
    GetAllRoles();
    setToggleState(1);
  }, []);

  //Acknowledgement table start
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [measuremntUnitRows, setmeasuremntUnitRows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const Employeecolumns = [
    { field: "sn", headerName: "IS/N", width: 60 },
    {
      field: "title",
      headerName: "Title",
      flex: 1,
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 1,
    },
    {
      field: "middleName",
      headerName: "Middle Name",
      flex: 1,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 1,
    },
    {
      field: "designationName",
      headerName: "Designation",
      flex: 1,
    },
    {
      field: "employeeCode",
      headerName: "Emp.Code",
      flex: 1,
    },
    {
      field: "contactNo",
      headerName: "Mobile",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "presentAddress",
      headerName: "Address",
      flex: 1,
    },
    {
      field: "gender",
      headerName: "Gender",
      flex: 1,
    },
    {
      field: "dateOfBirth",
      headerName: "DOB",
      flex: 1,
    },
    {
      field: "isactive",
      headerName: "IsActive",
      flex: 1,
    },

    {
      field: "action",
      headerName: "Action",

      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div

          // onClick={() => {
          //   onEditClick(params.row.id);
          // }}
          >
            <img src={edit} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const GetPurchaseOrderNoAPi = async (props) => {
    setakgpageState((old) => ({ ...old, isLoading: true }));
    console.log(props);
    SetakgIsLoading(true);

    var where = "";
    if (!!props.whereClause == false || props.whereClause == undefined) {
      where = "1=1";
      setSearch("");
    } else {
      where = props.whereClause;
    }
    console.log(where);

    await axios
      .get(
        "" +
          process.env.REACT_APP_SECRET +
          "/api/Employee/GetEmployeePaged?startRecordNo=" +
          props.page +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          where +
          "&sortColumn=" +
          "employeeid" +
          "&sortOrder=" +
          "desc" +
          ""
      )
      .then((response) => {
        console.log(response.data);
        setakgpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: akgpageState.page,
        }));
        var list = [];
        response.data._employeePagedViews.map((d, i) => {
          var obj = {
            id: i,
            sn: i + 1,
            title: d.title,
            firstName: d.firstName,
            middleName: d.middleName,
            lastName: d.lastName,
            designationName: d.designationName,
            employeeCode: d.employeeCode,
            contactNo: d.contactNo,
            email: d.email,
            presentAddress: d.presentAddress,
            gender: d.gender,
            dateOfBirth: d.dateOfBirth,
            isactive: d.isActive,
          };
          list.push(obj);
        });
        setmeasuremntUnitRows(list);
        SetakgIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        SetakgIsLoading(false);
      });
  };
  //Acknowledgement table end

  // Search start

  const handleSearch = (prop) => {
    var search = "";
    setSearch(prop);
  };

  const onsearchClick = () => {
    var prop = Search;
    if (!!prop == false) {
      toast.error("Search is Empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      //prop = "1=1";
      setSearch(prop);
    } else {
      setSearch(prop);
      var plus = "+";
      var upperText = prop.toUpperCase();
      const newString = "'%" + upperText + "%'";
      const queryString = "upper(FirstName+MiddleName+LastName) like ";
      const data = encodeURIComponent(queryString);
      const condition = data + newString;
      prop = condition;
    }

    var obj = {
      page: 0,
      pageSize: 10,
      whereClause: prop,
    };
    GetPurchaseOrderNoAPi(obj);
  };

  const onClear = async () => {
    if (!!Search == false) {
      console.log("already empty");
    } else {
      setSearch("");
      console.log("emptied");
    }
    //var prop="";

    var obj = {
      page: 0,
      pageSize: 10,
      whereClause: "1=1",
    };
    GetPurchaseOrderNoAPi(obj);

    console.log("api called");
  };
  // Search end

  const onReset = async () => {
    setEmptitle("");
    setEmpFirstName("");
    setEmpMiddleName("");
    setEmpLastName("");
    setEmpContactNo("");
    setEmpEmail("");
    setEmpCode("");
    setSelectedDesignation("");
    setEmpAddress("");
    setDOB("");
    setUserName("");
    setUserRole("");
    setUserPassword("");
    setUserConPassword("");
  };
  const onSave = async () => {
    if (!!EmployeeTitle == false) {
      toast.error("Title Is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit");
    } else if (!!EmployeeFirstName == false) {
      toast.error("First Name is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit");
    } else if (!!EmployeeMiddleName == false) {
      toast.error("Middle Name Not Given", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit");
    } else if (!!EmployeeLastName == false) {
      toast.error("Last Name Not Given", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit");
    } else if (!!EmployeeContactNo == false) {
      toast.error("Contact Number is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit");
    } else if (!!EmployeeEmail == false) {
      toast.error("Email is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit");
    } else if (!!EmployeeCode == false) {
      toast.error("Employee Code is Missing", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit");
    } else if (!!EmployeeAddress == false) {
      toast.error("Address is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit");
    } else if (!!EmployeeDOB == false) {
      toast.error("Date of Birth Not Given", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit");
    } else if (!!SelectedDesignation == false) {
      toast.error("Select Designation", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit");
    } else if (!!UserName == false) {
      toast.error("User Name Is Required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit");
    } else if (!!SelectedUserRole == false) {
      toast.error("User Role Not Given", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit");
    } else if (!!UserPassword == false) {
      toast.error("User Password Is Required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit");
    } else if (!!UserConPassword == false) {
      toast.error("User Confirm Password Is Required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit");
    } else if (UserConPassword !== UserPassword) {
      toast.error("Passwords Do Not Match", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit");
    } else {
      EmployeeSetup.Title = EmployeeTitle;
      EmployeeSetup.FirstName = EmployeeFirstName;
      EmployeeSetup.MiddleName = EmployeeMiddleName;
      EmployeeSetup.LastName = EmployeeLastName;
      EmployeeSetup.ContactNo = EmployeeContactNo;
      EmployeeSetup.Email = EmployeeEmail;
      EmployeeSetup.EmployeeCode = EmployeeCode;
      EmployeeSetup.DesignationId = SelectedDesignation.id;
      EmployeeSetup.PresentAddress = EmployeeAddress;
      EmployeeSetup.DateOfBirth = EmployeeDOB;
      UserSetup.RoleId = SelectedUserRole.id;
      UserSetup.Username = UserName;
      UserSetup.Password = UserPassword;

      var employeeCreateView = {
        employeeView: EmployeeSetup,
        userViews: UserSetup,
      };

      var url = apiAddress + "/api/Employee";
      await axios.post(url, employeeCreateView).then((response) => {
        toast.success("New Employee Created", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        toast.success("New User Created", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
      onReset();
      console.log("Inserted");
      var obj = {
        page: 0,
        pageSize: 10,
        whereClause: "1=1",
      };
      GetPurchaseOrderNoAPi(obj);

      console.log("not hit");
      console.log(employeeCreateView);
    }
  };

  return (
    <div>
      <div>
        <ToastContainer />
        <div className="card card-shadow p-3">
          <div className="d-flex">
            <h5 className="">Employee Entry</h5>
            <Box sx={{ width: "100%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="secondary tabs example"
              >
                <Tab value="one" label="Employee" />
                <Tab value="two" label="User" />
              </Tabs>
            </Box>
          </div>

          <div>
            {value === "one" && (
              <div className="row mt-2 ">
                <div className="col-12 col-md-3 col-lg-3 d-flex flex-column gap-3">
                  <TextAreaComponents
                    id={6}
                    value={(e) => setEmptitle(e)}
                    label={"Employee Title"}
                    val={EmployeeTitle}
                  />
                  <TextAreaComponents
                    id={6}
                    value={(e) => setEmpContactNo(e)}
                    label={"Contact Number"}
                    val={EmployeeContactNo}
                  />
                  <TextAreaComponents
                    id={1}
                    value={(e) => setEmpAddress(e)}
                    label={"Address"}
                    val={EmployeeAddress}
                  />
                </div>
                <div className="col-12 col-md-3 col-lg-3 d-flex flex-column gap-3 ">
                  <TextAreaComponents
                    id={6}
                    value={(e) => setEmpFirstName(e)}
                    label={"First Name"}
                    val={EmployeeFirstName}
                  />
                  <TextAreaComponents
                    id={6}
                    value={(e) => setEmpEmail(e)}
                    label={"Email"}
                    val={EmployeeEmail}
                  />
                  <div className="datetime">
                    <CustomDateTimePicker
                      value={(props) => setDOB(props)}
                      //isDisabled={true}
                      val={EmployeeDOB}
                      label={"Date Of birth"}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3 d-flex flex-column gap-3 ">
                  <TextAreaComponents
                    id={6}
                    value={(e) => setEmpMiddleName(e)}
                    label={"Middle Name"}
                    val={EmployeeMiddleName}
                  />
                  <TextAreaComponents
                    id={6}
                    value={(e) => setEmpCode(e)}
                    label={"Employee Code"}
                    val={EmployeeCode}
                  />
                  <div className="">
                    <FormControl className="d-flex flex-row justify-content-between gap-3">
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        className="mt-2"
                      >
                        Gender
                      </FormLabel>
                      <RadioGroup
                        row
                        defaultValue={"Male"}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        // onChange={purchaseOrderTypeRadio}
                      >
                        <FormControlLabel
                          value="Male"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          value="Female"
                          control={<Radio />}
                          label="Female"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3 d-flex flex-column gap-3 ">
                  <TextAreaComponents
                    id={6}
                    value={(e) => setEmpLastName(e)}
                    label={"Last Name"}
                    val={EmployeeLastName}
                  />
                  <div>
                    <AutoCompleteMultiSelect
                      datas={DesignationList}
                      value={handleDesignationSelect}
                      id={1}
                      label={"Designation"}
                      getOptionLabel={(option) => option.title}
                      val={SelectedDesignation}
                    />
                  </div>
                  <div className="">
                    <FormControl className="d-flex flex-row justify-content-between gap-3">
                      <FormLabel
                        id="demo-row-radio-buttons-group-label"
                        className="mt-2"
                      >
                        Status
                      </FormLabel>
                      <RadioGroup
                        row
                        defaultValue={"active"}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        // onChange={purchaseOrderTypeRadio}
                      >
                        <FormControlLabel
                          value="active"
                          control={<Radio />}
                          label="Active"
                        />
                        <FormControlLabel
                          value="inactive"
                          control={<Radio />}
                          label="Inactive"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            )}
            {value === "two" && (
              <div>
                <div className="row mt-2 ">
                  <div className="col-12 col-md-6 col-lg-4 d-flex flex-column gap-3">
                    <div>
                      <AutoCompleteMultiSelect
                        datas={RolesList}
                        value={handleUserRoleSelect}
                        id={1}
                        label={"User Role"}
                        getOptionLabel={(option) => option.title}
                        val={UserRole}
                      />
                    </div>
                    <TextAreaComponents
                      id={6}
                      value={(e) => setUserName(e)}
                      label={"User Name"}
                      val={UserName}
                    />
                    <TextAreaComponents
                      id={6}
                      value={(e) => setUserPassword(e)}
                      label={"Password"}
                      val={UserPassword}
                    />
                    <TextAreaComponents
                      id={6}
                      value={(e) => setUserConPassword(e)}
                      label={"Confirm Password"}
                      val={UserConPassword}
                    />
                    <div className="">
                      <FormControl className="d-flex flex-row justify-content gap-3">
                        <FormLabel
                          id="demo-row-radio-buttons-group-label"
                          className="mt-2"
                        >
                          Status
                        </FormLabel>
                        <RadioGroup
                          row
                          defaultValue={"active"}
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          // onChange={purchaseOrderTypeRadio}
                        >
                          <FormControlLabel
                            value="active"
                            control={<Radio />}
                            label="Active"
                          />
                          <FormControlLabel
                            value="inactive"
                            control={<Radio />}
                            label="Inactive"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="row mt-3 justify-content-start">
              <div className="col-12"></div>
              <div className="col-6">
                <div className="d-flex gap-4 ">
                  <button
                    onClick={() => {
                      onSave();
                    }}
                    className="btn-yes btn-common"
                  >
                    Save
                  </button>
                  <button
                    onClick={() => {
                      onReset();
                    }}
                    className="btn-no btn-common"
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div></div>
      </div>
      <div className="card card-shadow p-3 mt-3">
        <div className="col-12  ">
          <div className="col-md-6">
            <h5>Employee List</h5>
          </div>
          <div className="row ">
            <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
              <TextAreaComponents
                id={6}
                value={(props) => setSearch(props)}
                label={"Search"}
                val={Search}
              />
            </div>
            <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
              <button
                className="btn-search btn-common"
                onClick={() => {
                  onsearchClick();
                }}
              >
                Search
              </button>
              <button
                className="btn-search btn-common"
                onClick={() => {
                  onClear();
                }}
              >
                Clear
              </button>
            </div>
          </div>
          <div className="col-md-12 col-xs-6 mt-2">
            <DataTable
              pageState={akgpageState}
              GetPurchaseOrderNoAPi={GetPurchaseOrderNoAPi}
              isLoading={akgisLoading}
              rows={measuremntUnitRows}
              columns={Employeecolumns}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeSetup;
