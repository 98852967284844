import React, { useEffect } from 'react';
import service from '../../../assests/images/pro-services.png'
import AOS from "aos";

const Services = () => {

    useEffect(() => {
        AOS.init({
          easing: "ease-in-sine",
        });
      }, []);

    return (
        <div id='service' className='my-16 py-10 lg:py-20' style={{ backgroundImage: 'url(https://blog.procureport.com/wp-content/uploads/2023/10/Advantages-of-Opting-for-Procurement-Services-1280x569.jpg)'}}>
            <div className='flex flex-col lg:flex-row gap-3 lg:gap-0 items-center justify-center text-white'>
                
                <div className='flex-1 pl-10 lg:pl-20' 
                data-aos="fade-down"
                >
                    <h2 className='font-bold text-4xl mb-4 z-10'>Procurement Services</h2>
                    <hr className='mb-10 w-1/2 text-white border-[4px]' />
                    <p className='max-w-xl z-10'>
                        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptatum quibusdam sapiente rem maxime, suscipit similique delectus velit est autem libero quas soluta accusamus molestias rerum voluptatem earum repellendus. Natus nemo debitis amet veniam est, sapiente officia cupiditate quod aut veritatis?
                    </p>
                </div>

                <div className='flex-1 flex justify-center' 
                data-aos="fade-up"
                >
                    <div className=' border-[4px] rounded-lg'>
                        <img src={service} className="max-w-xs lg:max-w-xl p-2 rounded-xl " alt="" />

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;