import axios from "axios";
import jwtDecode from "jwt-decode";
import React from "react";

const apiAddress = process.env.REACT_APP_SECRET;

export const getAPI = async (props) => {
  var token = sessionStorage.getItem("imsToken");
  var tokenData = jwtDecode(token);
  console.log(tokenData);
  var res = await axios
    .get(
      apiAddress +
        "/api/Screen/getAllScreenDetailsByRoleId?roleId=" +
        tokenData.RoleId
    )
    .then((response) => {
      return response.data;
    });
  console.log(res);
  return res;
};
