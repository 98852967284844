import React, { useEffect, useState } from "react";
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";

import checkMark from "./../../../assests/images/check-mark.png";
import print from "./../../../assests/images/printing.png";

//radio button imports end

import "./stockIssue.css";
import { GetEmployeeAPi } from "../../../api/screenApi/purchaseOrder/purchaseOrderApi";
import axios from "axios";
import DataTable from "../../sharedComponent/dataTable/DataTable.jsx";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater.jsx";
import {
  getAPI,
  getAPIForIssue,
} from "../../../api/stockIssueAPI/StockIssueAPI.js";
import ClientSiteDataTable from "../../sharedComponent/dataTable/ClientSiteDataTable.jsx";
import dayjs from "dayjs";
import jwtDecode from "jwt-decode";
import { EntryNumberGenarator } from "../../sharedComponent/CommonMethods.js";
import { ToastContainer, toast } from "react-toastify";
import SweetAlertModal from "../../sharedComponent/SweetAlertModal/SweetAlertModal.js";

function StockIssue(props) {
  const errorToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  
  const [billNo, setBillNo] = useState();
  const [DateTime, setDateTime] = useState();
  const [Remarks, setRemarks] = useState("");
  const [IssuetypeSelect, setIssuetypeSelect] = React.useState({});
  const [Search, setSearch] = useState();
  const [billItemDetailsrows, setBillItemDetailsrows] = React.useState([]);
  const [selectedBillItemDetailsrows, setSelectedBillItemDetailsrows] =
    React.useState([]);
  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });
  const [pageState, setpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const initialValue = [
    { id: 1, title: "Sales Bill" },
    { id: 2, title: "Purchase Bill Return" },
  ];
  const [clearAutoComplete, setclearAutoComplete] = useState(false);
  const [billrows, setBillrows] = React.useState([]);
  const Billcolumns = [
    {
      field: "orderDate",
      headerName: "Order Date",
      flex: 1,
      renderCell: (params) => {
        return <span>{DateFormater(params.row.orderDate)}</span>;
      },
    },
    // { field: "number", headerName: "Order Number", flex: 1 },
    { field: "id", headerName: "Order Number", flex: 1 },
    { field: "customerName", headerName: "Customer Name", flex: 1 },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onCheckClick(params.row);
            }}
          >
            <img src={checkMark} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const PBillcolumns = [
    { field: "number", headerName: "StockRecive No", width: 170 },
    {
      field: "stockdate",
      headerName: "StockRecive Date",
      renderCell: (params) => (
        <span>{DateFormater(params.row.stockReceiveDate)}</span>
      ),
      flex: 1,
    },
    { field: "supplierName", headerName: "Supplier Name", width: 170 },
    {
      field: "referenceTransactionNo",
      headerName: "Purchase Bill No",
      flex: 1,
    },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onCheckClick(params.row);
            }}
          >
            <img src={checkMark} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
  const [isLoading, SetisLoading] = React.useState(false);
  const [EmployeeList, setEmployeeList] = useState([]);
  const [SelectedEmployee, setSelectedEmployee] = useState();
  const GetPurchaseBillPage = async (props) => {
    setpageState((old) => ({ ...old, isLoading: true }));

    SetisLoading(true);
    await axios
      .get(
        "" +
          process.env.REACT_APP_SECRET +
          "/api/StockIssue/getAllOrderDetails?startRecordNo=" +
          props.page +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          "1=1" +
          "&sortColumn=" +
          "number" +
          "&sortOrder=" +
          "desc" +
          ""
      )
      .then((response) => {
        setpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: pageState.page,
        }));
        var list = [];

        setBillrows(response.data.orderDetailsViewForIssues);
        SetisLoading(false);
      })
      .catch((error) => {
        console.log(error);
        SetisLoading(false);
      });
  };
  const GetPBPage = async (props) => {
    setpageState((old) => ({ ...old, isLoading: true }));

    SetisLoading(true);
    await axios
      .get(
        "" +
          process.env.REACT_APP_SECRET +
          "/api/StockIssue/getAllPBDetails?startRecordNo=" +
          props.page +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          "1=1" +
          "&sortColumn=" +
          "number" +
          "&sortOrder=" +
          "desc" +
          ""
      )
      .then((response) => {
        setpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: pageState.page,
        }));
        var list = [];
        response.data._stockRecive.map((dt, i) => {
          dt.id = i + 1;
          list.push(dt);
        });
        setBillrows(list);
        SetisLoading(false);
      })
      .catch((error) => {
        console.log(error);
        SetisLoading(false);
      });
  };
  const getAllEmployee = async () => {
    var list = [];
    var res = await GetEmployeeAPi();

    res.map((d, i) => {
      var obj = { id: d.employeeId, title: d.firstName + " " + d.middleName };
      list.push(obj);
    });
    setEmployeeList(list);
  };

  useEffect(() => {
    setIssuetypeSelect({ id: 1 });
    GetPurchaseBillPage({
      page: 0,
      pageSize: 10,
    });
    GetPurchaseOrderNoAPi({
      page: 0,
      pageSize: 10,
    });
    getAllEmployee();
  }, []);
  const apiAddress = process.env.REACT_APP_SECRET;
  const getReceiveDetails = async (props) => {
    await axios
      .get(
        apiAddress +
          "/api/StockReceive/getStockReceiveDetailsByNumber?number=" +
          props
      )
      .then((res) => {
        var list = [];
        res.data.details.map((dt, i) => {
          var obj = {
            id: dt.id,
            stockReceiveNumber: dt.stockReceiveNumber,
            itemId: dt.itemId,
            requestedQty: dt.qty,
            itemName: dt.productName,
            currentStock: dt.currentStock,
          };
          list.push(obj);
        });

        setBillItemDetailsrows(list);
      });
  };

  const onCheckClick = async (data) => {
    if (IssuetypeSelect.id == 1) {
      setBillNo(data.id);
      var res = await getAPIForIssue(data.id);

      setBillItemDetailsrows(res.salesOrderdetailsList);
    } else {
      setBillNo(data.referenceTransactionNo);
      getReceiveDetails(data.number);
    }
  };

  const Itemscolumns = [
    { field: "itemId", headerName: "ItemId", flex: 1 },
    { field: "itemName", headerName: "Item Name", flex: 1 },
    { field: "requestedQty", headerName: "Quantity", flex: 1 },
  ];
  const selectedItemscolumns = [
    { field: "itemId", headerName: "ItemId", flex: 1 },
    { field: "itemName", headerName: "Item Name", flex: 1 },
    { field: "currentStock", headerName: "Current Stock", flex: 1 },
    { field: "requestedQty", headerName: "Quantity", flex: 1 },
    {
      field: "issuedQty",
      headerName: "Issued Qty",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <TextAreaComponents
            id={2}
            value={(e) => (params.row.issuedQty = parseInt(e))}
            label={"Issued Qty"}
            defaultValue={params.row.issuedQty}
          />
        </div>
      ),
    },
  ];
  const getSelectedItems = (props) => {
    var lists = [];

    props.map((dt, i) => {
      var obj = billItemDetailsrows.find((x) => x.id == dt);
      if (obj != undefined) {
        obj.issuedQty = obj.requestedQty;
        lists.push(obj);
      }
    });
    setSelectedBillItemDetailsrows(lists);
  };
  const handleRequestedBy = (e) => {
    setSelectedEmployee(e);
  };

  const onSave = () => {
    setAlertOpen(false);
    var res = sessionStorage.getItem("imsToken");
    var decodedData = jwtDecode(res);
    var valid = true;
    if (!!billNo == false) {
      valid = false;
      errorToast("Bill No Not found");
    }
    if (!!SelectedEmployee == false) {
      valid = false;
      errorToast("Please select employee");
    }
    if (!!DateTime == false) {
      valid = false;
      errorToast("Please select issued date");
    }
    if (selectedBillItemDetailsrows.length <= 0) {
      valid = false;
      errorToast("Please select item");
    } else {
      // selectedBillItemDetailsrows.map((dt, i) => {
      //   if (dt.issuedQty <= 0) {
      //     valid = false;
      //     errorToast(
      //       "Please insert issued Qty more then zero for " + dt.productName
      //     );
      //   }
      //   if (dt.issuedQty > dt.currentStock) {
      //     valid = false;
      //     errorToast(
      //       "Issued qty can not more then current stock qty for " +
      //         dt.productName
      //     );
      //   }
      // });
    }
    if (valid) {
      const data = {
        issueView: {
          number: "0",
          referenceNumber: billNo,
          issueDate: DateTime,
          issueByEmployeeId: SelectedEmployee.id,
          creationDate: dayjs(),
          creatorId: decodedData.UserId,
          remarks: Remarks,
          modificationDate: dayjs(),
          modifierId: decodedData.UserId,
          employeeName: "",
          customerName: "",
          customerId: 1,
        },
        issueDetails: [],
      };
      selectedBillItemDetailsrows.map((dt, i) => {
        var obj = {
          id: 0,
          issueNumber: "0",
          itemId: dt.itemId,
          qty: dt.issuedQty,
          remarks:"String",
          itemName: "",
          CurrentStock: 0,
        };
        data.issueDetails.push(obj);
      });
      const apiAddress = process.env.REACT_APP_SECRET;
      axios
        .post(apiAddress + "/api/StockIssue", data)
        .then((res) => {
          successToast("Successfully saved");
          setDateTime(null);
          // setclearAutoComplete(true);
          setBillItemDetailsrows([]);
          setSelectedBillItemDetailsrows([]);

          if (IssuetypeSelect.id == 2) {
            GetPBPage({
              page: 0,
              pageSize: 10,
            });
          } else {
            GetPurchaseBillPage({
              page: 0,
              pageSize: 10,
            });
          }
          GetPurchaseOrderNoAPi({
            page: 0,
            pageSize: 10,
          });
        })
        .catch((error) => {
          alert(error);
        });
    }
  };
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [akgrows, setAkgrows] = React.useState([]);
  const [IssueNumber, setIssueNumber] = useState();
  const [AlertOpen, setAlertOpen] = useState(false);
  const Acknowledgementcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "number", headerName: "Number", flex: 1 },
    { field: "referenceNumber", headerName: "Reference Number", flex: 1 },
    {
      field: "issueDate",
      headerName: "Issue Date",
      renderCell: (params) => <span>{DateFormater(params.row.issueDate)}</span>,
      flex: 1,
    },
    { field: "employeeName", headerName: "Issued by Emp. Name", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              window.open(
                "/issue-report/" + params.row.number,
                "popup",
                "width=850,height=550,left=280,top=80"
              );
            }}
          >
            <img src={print} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const GetPurchaseOrderNoAPi = async (props) => {
    setakgpageState((old) => ({ ...old, isLoading: true }));

    SetakgIsLoading(true);
    await axios
      .get(
        "" +
          process.env.REACT_APP_SECRET +
          "/api/StockIssue/GetAllIssuePaged?startRecordNo=" +
          props.page +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          "1=1" +
          "&sortColumn=" +
          "number" +
          "&sortOrder=" +
          "desc" +
          ""
      )
      .then((response) => {
        setakgpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: akgpageState.page,
        }));
        var list = [];

        response.data.stockIssues.map((d, i) => {
          var obj = {
            id: i,
            sn: i + 1,
            number: d.number,
            issueDate: d.issueDate,
            referenceNumber: d.referenceNumber,
            remarks: d.remarks,
            employeeName: d.employeeName,
            referenceNumber: d.referenceNumber,
            // action: d.number,
          };
          list.push(obj);
        });
        var entryNo = EntryNumberGenarator(
          response.data.stockIssues[0].number,
          "INV1-SI-"
        );
        setIssueNumber(entryNo);
        setAkgrows(list);
        SetakgIsLoading(false);
        setSelectedEmployee(null);
      })
      .catch((error) => {
        console.log(error);
        SetakgIsLoading(false);
      });
  };

  return (
    <div>
      <ToastContainer />
      <SweetAlertModal
        open={AlertOpen}
        headerText={"Are you sure?"}
        bodyText={"You want to save?"}
        close={() => setAlertOpen(false)}
        onyes={onSave}
      />
      <h5 className="">Stock Issue</h5>
      <div className="card card-shadow">
        <div className="row p-3">
          <div className="col-12">
            <AutoCompleteMultiSelect
              datas={initialValue}
              id={1}
              value={(e) => {
                setIssuetypeSelect(e);
                if (e.id == 2) {
                  GetPBPage({
                    page: 0,
                    pageSize: 10,
                  });
                } else {
                  GetPurchaseBillPage({
                    page: 0,
                    pageSize: 10,
                  });
                }
              }}
              label={"Issue Type"}
              placeholder={"--Issue Type--"}
              getOptionLabel={(option) => option.title}
              defaultValue={initialValue[0]}
              clear={clearAutoComplete}
            />
          </div>
        </div>
      </div>
      {IssuetypeSelect.id == 1 ? (
        <div className="card p-3 card-shadow acknowledgementCard mt-3">
          <div>
            <h6>Sales Bill List</h6>
          </div>
          {billrows.length > 0 ? (
            <DataTable
              pageState={pageState}
              GetPurchaseOrderNoAPi={GetPurchaseBillPage}
              isLoading={isLoading}
              rows={billrows}
              columns={Billcolumns}
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="card p-3 card-shadow acknowledgementCard mt-3">
          <div>
            <h6>Purchase Bill List</h6>
          </div>
          {billrows.length > 0 ? (
            <DataTable
              pageState={pageState}
              GetPurchaseOrderNoAPi={GetPBPage}
              isLoading={isLoading}
              rows={billrows}
              columns={PBillcolumns}
            />
          ) : (
            ""
          )}
        </div>
      )}

      <div className="card card-shadow mt-3">
        <div className="row p-3">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 PurchaseOrderCard mt-3">
            <TextAreaComponents
              id={6}
              value={(e) => {}}
              val={IssueNumber}
              defaultValue={IssueNumber}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 PurchaseOrderCard mt-3">
            <TextAreaComponents
              id={5}
              value={(e) => {}}
              val={billNo}
              label={"Selected Order"}
              Placeholder={"Selected Order"}
              isactive={true}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 PurchaseOrderCard mt-3">
            <CustomDateTimePicker
              value={(e) => setDateTime(e)}
              label={"Issue date"}
              val={DateTime}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 PurchaseOrderCard mt-3">
            <AutoCompleteMultiSelect
              datas={EmployeeList}
              value={handleRequestedBy}
              id={1}
              label={"Issued by"}
              getOptionLabel={(option) => option.title}
              placeholder={"Issued by"}
              clear={clearAutoComplete}
              val={SelectedEmployee}
            />
          </div>
         
              <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 PurchaseOrderCard mt-3">
            <TextAreaComponents
              id={5}
              value={(e)=>{setRemarks(e)}}
              label={"Remarks"}
              // defaultValue={Remarks}
            />
          </div>

          </div>
      </div>
      <div className="card card-shadow p-3 mt-3">
        <div>
          <h6>Ordered Items</h6>
        </div>
        {billItemDetailsrows.length > 0 ? (
          <ClientSiteDataTable
            dataRows={billItemDetailsrows}
            columns={Itemscolumns}
            isActive={true}
            getselectedData={getSelectedItems}
          />
        ) : (
          ""
        )}
      </div>
      <div className="card card-shadow p-3 mt-3">
        <div>
          <h6>Selected Ordered Items</h6>
        </div>
        {selectedBillItemDetailsrows.length > 0 ? (
          <ClientSiteDataTable
            dataRows={selectedBillItemDetailsrows}
            columns={selectedItemscolumns}
            isActive={false}
            getselectedData={getSelectedItems}
          />
        ) : (
          ""
        )}
      </div>
      <div className="row mt-3">
        <div className="col-4">
          <div className="d-flex gap-4 ">
            <button
              className="btn-yes btn-common"
              onClick={() => {
                setAlertOpen(true);
              }}
            >
              Save
            </button>
            <button className="btn-no btn-common">Clear</button>
          </div>
        </div>
      </div>
      <div className="card card-shadow p-3 mt-3">
        <div>
          <h6>Stock Issue List</h6>
        </div>
        <div className="mt-2">
          <TextAreaComponents
            id={6}
            value={(e) => setSearch(e)}
            label={"Search"}
          />
        </div>
        <DataTable
          pageState={akgpageState}
          GetPurchaseOrderNoAPi={GetPurchaseOrderNoAPi}
          isLoading={akgisLoading}
          rows={akgrows}
          columns={Acknowledgementcolumns}
        />
      </div>
    </div>
  );
}

export default StockIssue;
