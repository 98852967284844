import React, { useEffect } from 'react';
import CountUp from 'react-countup';
import Map from './Map'
import AOS from "aos";

const Part = () => {
    useEffect(() => {
        AOS.init({
          easing: "ease-in-sine",
        });
      }, []);

    return (
        <div className='flex flex-col lg:flex-row gap-3 lg:gap-0 items-center my-16 px-8 lg:px-16'>
            <div className='flex-1'>
                <h2 className='text-4xl font-bold'>Be a Part Of Our Community</h2>
                <div className='mt-12 grid grid-cols-2 md:grid-cols-3 gap-4' 
                data-aos="fade-down"
                >
                    <div className=''>
                        <div className='text-purple-700 mb-2 text-4xl font-bold'>
                            <CountUp
                                end={270}
                                duration={5}
                            />
                            <span>+</span>
                        </div>
                        <p>Corporate member <br /> companies</p>
                    </div>
                    <div className=''>
                        <div className='text-purple-700 mb-2 text-4xl font-bold'>
                            <CountUp
                                end={775}
                                duration={5}
                            />
                            <span>+</span>
                        </div>
                        <p>Leading global companies</p>
                    </div>
                    <div className=''>
                        <div className='text-purple-700 mb-2 text-4xl font-bold'>
                            <CountUp
                                end={35000}
                                duration={5}
                            />
                            <span>+</span>
                        </div>
                        <p>Senior leaders</p>
                    </div>
                    <div className=''>
                        <div className='text-purple-700 mb-2 text-4xl font-bold'>
                            <CountUp
                                end={6}
                                duration={5}
                            />
                            <span>+</span>
                        </div>
                        <p>Global locations</p>
                    </div>
                    <div className=''>
                        <div className='text-purple-700 mb-2 text-4xl font-bold'>
                            <CountUp
                                end={120}
                                duration={5}
                            />
                            <span>+</span>
                        </div>
                        <p>Procurement solution providers</p>
                    </div>
                    <div className=''>
                        <div className='text-purple-700 mb-2 text-4xl font-bold'>
                            <CountUp
                                end={11000}
                                duration={5}
                            />
                            <span>+</span>
                        </div>
                        <p>Event attendees</p>
                    </div>
                </div>
            </div>
            <div className='flex-1' 
            data-aos="fade-up"
            >
                <Map />
            </div>
        </div >
    );
};

export default Part;