import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";

function PurchaseOrderReport(props) {
  const apiAddress = process.env.REACT_APP_SECRET;
  const componentRef = useRef();
  let { number } = useParams();

  const [receiveDetails, setReceiveDetails] = useState({});
  const [receiveItemDetails, setreceiveItemDetails] = useState([]);
  console.log(number);
  useEffect(() => {
    axios
      .get(apiAddress + "/api/PurchaseOrder/GetPurchaseOrderByNumber/" + number)
      .then((res) => {
        console.log(res.data);
        setReceiveDetails(res.data.purchaseOrder);
        setreceiveItemDetails(res.data.purchaseOrderdetailsList);
      });
  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  function calculateSum(data, key) {
    return data.reduce((sum, item) => sum + parseFloat(item[key] || 0), 0);
  }

  return (
    <div className="p-3">
      <button className="btn btn-success mt-2 " onClick={handlePrint}>
        Print
      </button>
      <hr />
      <div ref={componentRef}>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ flexDirection: "column" }}
        >
          <h2 style={{ fontWeight: "bold", fontSize: "30px" }}>
            A & H Solutions
          </h2>
          <h5 style={{ textDecoration: "underline", fontWeight: "bold" }}>
            Purchase Order Report
          </h5>
        </div>
        <div className="d-flex justify-content-between">
          {receiveDetails != null ? (
            <>
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Order Number: {receiveDetails.number}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Order Date: {DateFormater(receiveDetails.orderDate)}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Requested By: {receiveDetails.employeeName}
                </p>
              </div>
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Supplier Name: {receiveDetails.supplierName}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Remarks: {receiveDetails.remarks}
                </p>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div>
          <table className="table table-bordered mt-3">
            <thead>
              <tr>
                <th className="text-center">S/N</th>
                <th className="text-center">Item Name</th>
                <th className="text-center">Received Qty</th>
                <th className="text-center">Vat</th>
                <th className="text-center">SD</th>
                <th className="text-center">Remarks</th>
              </tr>
            </thead>
            <tbody>
              {receiveItemDetails.length > 0 ? (
                receiveItemDetails.map((dt, i) => {
                  return (
                    <tr key={i}>
                      <td className="text-center">{i + 1}</td>
                      <td className="text-center">{dt.combination}</td>
                      <td className="text-center">{dt.requestedQty}</td>
                      <td className="text-center">{dt.vatPercent}</td>
                      <td className="text-center">{dt.sdPercent}</td>
                      <td className="text-center">{dt.remarks}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={6}>No data available</td>
                </tr>
              )}
              <tr>
                <td colSpan={2} className="text-end">
                  Total:
                </td>
                <td className="text-center">
                  {calculateSum(receiveItemDetails, "requestedQty")}
                </td>
                <td className="text-center">
                  {calculateSum(receiveItemDetails, "vatPercent")}
                </td>
                <td className="text-center">
                  {calculateSum(receiveItemDetails, "sdPercent")}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PurchaseOrderReport;
