import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";

function CustomerPaymentReport(props) {
  const apiAddress = process.env.REACT_APP_SECRET;
  const componentRef = useRef();
  let { number } = useParams();

  const [receiveDetails, setReceiveDetails] = useState({});
  const [receiveItemDetails, setreceiveItemDetails] = useState([]);

  useEffect(() => {
    axios
      .get(
        apiAddress +
          "/api/PaymentRcvCustomer/GetPaymentFromCustomerByNumber/" +
          number
      )
      .then((res) => {
        console.log(res.data);

        setReceiveDetails(res.data);
        // setreceiveItemDetails(res.data.customerPaymentAdjustmentDetailsList);
      });
  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="p-3">
      <button className="btn btn-success mt-2 " onClick={handlePrint}>
        Print
      </button>
      <hr />
      <div ref={componentRef}>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ flexDirection: "column" }}
        >
          <h2 style={{ fontWeight: "bold", fontSize: "30px" }}>
            A & H Solutions
          </h2>
          <h5 style={{ textDecoration: "underline", fontWeight: "bold" }}>
            Customer Payment Report
          </h5>
        </div>
        <div className="d-flex justify-content-between">
          {receiveDetails != null ? (
            <>
              {/* <div className="row" style={{width:'100%'}}> */}
              <div className="d-flex flex-column">
                <div className="d-flex" style={{ flexDirection: "column" }}>
                  <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                    Payment Number: {receiveDetails.number}
                  </p>

                  <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                    Payment Date: {DateFormater(receiveDetails.creationDate)}
                  </p>
                  <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                    Issued By: {receiveDetails.employeeName}
                  </p>

                  <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                    Remarks: {receiveDetails.remarks}
                  </p>
                </div>
              </div>
              <div className="d-flex flex-column">
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Customer Name: {receiveDetails.customerName}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Bill Number: {receiveDetails.billNumber}
                </p>

                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Bill Amount: {receiveDetails.billAmount}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Paid Amount: {receiveDetails.paidAmount}
                </p>
              </div>
              {/* </div> */}
            </>
          ) : (
            ""
          )}
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default CustomerPaymentReport;
