import React, { useEffect } from 'react';
import vision from '../../../assests/images/vission.jpg'
import AOS from "aos";

const Vision = () => {

    useEffect(() => {
        AOS.init({
          easing: "ease-in-sine",
        });
      }, []);

    return (
        <div id='vision' className='my-16 bg-purple-300 py-16'>
            <div className='flex flex-col lg:flex-row gap-3 lg:gap-0 items-center justify-center'>
                <div className='flex-1 pl-5 lg:pl-32' 
                data-aos="fade-down"
                >
                    <h2 className='font-bold text-4xl mb-4'>Vision Statement</h2>
                    <hr className='mb-10 w-1/2 text-purple-700 border-[3px]' />
                    <p className='max-w-xl'>
                        At A&H Solutions, we envision a future where every business in Bangladesh experiences optimal efficiency in procurement. We aspire to be the foremost procurement service provider, setting industry standards for integrity, innovation, and excellence. Our vision is to catalyze economic growth by fostering a procurement ecosystem that benefits companies and contributes to the overall development of our nation.
                    </p>
                </div>
                <div className='flex-1 flex justify-center' 
                data-aos="fade-up"
                >
                    <div className=' border-[4px] rounded-lg'>
                        <img src={vision} className="max-w-xs lg:max-w-md p-2 rounded-xl relative left-6 top-6" alt="" />

                    </div>
                </div>
            </div>
        </div>
    );
};

export default Vision;