import React, { useEffect } from 'react';
import { FaFileSignature, FaGlobe, FaGlobeAsia } from "react-icons/fa";
import AOS from "aos";

const Community = () => {

    useEffect(() => {
        AOS.init({
          easing: "ease-in-sine",
        });
      }, []);

    return (
        <div id='community' className='bg-gray-100 py-10 mb-20'>
            {/* first portion */}
            <div className='flex flex-col lg:flex-row justify-around items-center gap-3 lg:gap-4 px-10 lg:px-10 lg:ml-8 my-16'>
                <div className='flex-1'>
                    <p className='text-4xl' 
                    data-aos="fade-down"
                    >Sustainable sourcing values since 2004</p>
                </div>
                <div className='flex-1'>
                    <p className='text-xl' 
                    data-aos="fade-up"
                    >With an eye on sustainability, we deliver sustainable value to organisations in the procurement of goods and services.</p>
                </div>
            </div>

            {/* second portion */}
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 lg:mx-16 mx-6' 
            data-aos="fade-up"
            >
                <div className="card w-96 bg-gray-100 border-none">
                    <figure className="px-10 pt-10">
                        <FaGlobeAsia size={30} />
                    </figure>
                    <div className="card-body items-center text-center">
                        <h2 className="card-title">Global Sourcing</h2>
                        <p className='mb-6'>- Global -</p>
                        <p>As a procurement service provider, Dragon Sourcing is committed to offering its customers sustainable value by developing international marketplaces. While providing a full sourcing service to businesses around the world seeking to successfully source from low-cost nations, we recognise the difficulties involved and collaborate with clients to manage the risks and increase profits.</p>
                        {/* <div className="card-actions">
                            <button className="btn btn-primary">Buy Now</button>
                        </div> */}
                    </div>
                </div>
                <div className="card w-96 bg-gray-100 border-none">
                    <figure className="px-10 pt-10">
                        <FaFileSignature  size={30} />
                    </figure>
                    <div className="card-body items-center text-center">
                        <h2 className="card-title">Sourcing Opportunities</h2>
                        <p className='mb-2'>- Insights -</p>
                        <p>Being the largest economy in Southeast Asia and possessing several unique qualities that make the country ideal for freshly advanced economic development, Indonesia is a nation with significant economic potential. The central government has been actively promoting the upliftment of the industrial sector in recent years.</p>
                        {/* <div className="card-actions">
                            <button className="btn btn-primary">Buy Now</button>
                        </div> */}
                    </div>
                </div>
                <div className="card w-96 bg-gray-100 border-none">
                    <figure className="px-10 pt-10">
                        <FaGlobe  size={30} />
                    </figure>
                    <div className="card-body items-center text-center">
                        <h2 className="card-title">The BRIC Countries</h2>
                        <p className='mb-6'>- Insights -</p>
                        <p>The BRIC nations—Brazil, Russia, India and China—were identified as having the highest prospects for economic growth in 2003. Given how quickly these nations are still developing, it is not wrong to consider the “Next 10 or N10” nations to be Bangladesh, Egypt, Indonesia, Mexico, Nigeria, Pakistan, the Philippines, Turkey, South Korea, and Vietnam.</p>
                        {/* <div className="card-actions">
                            <button className="btn btn-primary">Buy Now</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Community;