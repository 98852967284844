// import React from "react";
import React, { useEffect, useState } from 'react';
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import { ToastContainer, toast } from "react-toastify";
//radio button imports start
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
//import "./Purpose.css";
//radio button imports end
import edit from "./../../../assests/images/edit.png";
import print from "./../../../assests/images/printing.png";
import DataTable from "../../sharedComponent/dataTable/DataTable";
import axios from "axios";
import { PostPurposeAPi } from '../../../api/adminApi/purposeApi/purposeApi';
import { json } from 'react-router-dom';
import ItemGroupSetup from '../itemGroup/ItemGroupSetup';
import { GetAllItemGroupApi } from '../../../api/adminApi/itemGroupApi/ItemGroupApi';
import { GetAllVatApi } from "../../../api/adminApi/VatApi";





function ItemSubGroupSetup() {



const apiAddress = process.env.REACT_APP_SECRET;

const [ItemGroupList, setItemGroupList] = useState([]);
const [SelectedItemGroup, setSelectedItemGroup] = useState();
const[Search, setSearch]=useState()
const[ItemSubGroupDes, setItemSubGroupDes]=useState()
const[Error, setError]=useState({})
//const [vatList, setVatList] = useState([]);


const GetAllItemGroup = async () => {
    var list = [];
    var res = await GetAllItemGroupApi();
    res.map((d, i) => {
        var obj = { id: d.id , title: d.groupName };
        list.push(obj);
      });
    setItemGroupList(list);
    console.log(res);
  };
//   const GetAllVat = async () => {
//     var res = await GetAllVatApi();
//     setVatList(res);
//   };

const handleItemGroupSelect = (e) => {
setSelectedItemGroup(e);
console.log(e);
};

useEffect(() => {
 GetAllItemGroup();
 //onReset();
 //GetAllVat();
  }, []);
const[ItemSubGroupSetup, setItemSubGroupSetup]=useState({

    Id: 0,
    ItemGroupId:0,
    SubGroupName: "",
    IsActive: true,
    CreatorId: 0,
    CreationDate: "2023-10-26T06:12:17.734Z",
    UpdatorId: 0,
    UpdateDate: "2023-10-26T06:12:17.734Z"
  
});

  //item subgroup table declaration start
  const [IsgisLoading, SetIsgIsLoading] = React.useState(false);
  const [IsgRows, SetIsgRows] = React.useState([]);
  const [IsgpageState, SetIsgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const Isgcolumns = [
    { field: "sn", headerName: "IS/N", width: 170 },
    { field: "subGroupName", headerName: "Item Sub Group", width: 170 },
    { field: "groupName", headerName: "Group Name", width: 170 },
    {
      field: "status",
      headerName: "Status",
      width: 170,
    },

    {
      field: "action",
      headerName: "Action",
      width: 170,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div

          // onClick={() => {
          //   onEditClick(params.row.id);
          // }}
          >
            <img src={edit} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
   //item subgroup table declaration end

   //item subgroup table function start

  const GetItemSubGroupNoAPi = async (props) => {

      await ItemSubgroupListPaged(props, "Main");
  };

  const ItemSubgroupListPaged = async(props,from) =>{
    if (from != "Search") {
      SetIsgpageState((old) => ({ ...old, isLoading: true }));

     SetIsgIsLoading(true);

      await axios
        .get(
          "" +
          process.env.REACT_APP_SECRET +
          "/api/ItemSubGroup/GetItemSubGroupPaged?startRecordNo=" +
          props.page +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          "1=1" +
          "&sortColumn=" +
          "id" +
          "&sortOrder=" +
          "desc" +
          ""
        )
        .then((response) => {
          console.log(response.data);
          SetIsgpageState((old) => ({
            ...old,
            isLoading: false,
  
            total: response.data._totalCount,
            page: IsgpageState.page,
          }));
          var list = [];
          response.data._itemSubGroupPagedViews.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              subGroupName: d.subGroupName,
              groupName : d.groupName,
              status: d.isActive,
  
              // action: d.number,
            };
            list.push(obj);
          });
          SetIsgRows(list);
          SetIsgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetIsgIsLoading(false);
        });
      }
      else{

      var where=""
      if(!!props.whereClause== false || props.whereClause==undefined){
      where="1=1"
      setSearch("");
      }
      else{
        where=props.whereClause
      }
      console.log(where)


      // const newString = "'" + Search + "'";
      // const condition = "subgroupname=" + newString;
      SetIsgpageState((old) => ({ ...old, isLoading: true }));

      SetIsgIsLoading(true);

      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/ItemSubGroup/GetItemSubGroupPaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            where +
            "&sortColumn=" +
            "id" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          console.log(response.data);
          SetIsgpageState((old) => ({
            ...old,
            isLoading: false,
  
            total: response.data._totalCount,
            page: IsgpageState.page,
          }));
          var list = [];
          response.data._itemSubGroupPagedViews.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              subGroupName: d.subGroupName,
              groupName : d.groupName,
              status: d.isActive,
  
              // action: d.number,
            };
            list.push(obj);
          });
          SetIsgRows(list);
          SetIsgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetIsgIsLoading(false);
        });
      } 
    };


    const onSearch = async () => {
      var prop= Search
      if(Search == "" || Search == undefined) {
        toast.error("Search is Empty", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
        else
        {
          setSearch(prop);
          var upperText = prop.toUpperCase();
          const newString = "'%" + upperText + "%'";
          const condition = "upper(subgroupname) like " + newString;
          prop = condition;
        }
    
        var obj = {
          page: 0,
          pageSize: 10,
          whereClause: prop,
        };

      await ItemSubgroupListPaged(obj, "Search");
    };


    const onClear = async () => {
      var props = {
        page: 0,
        pageSize: 10,
      };
      setSearch("");
      await ItemSubgroupListPaged(props, "Main");
    };

    //item subgroup table function end

    const onReset= async() =>{
        setItemSubGroupDes("");
        setItemSubGroupSetup("");
        console.log("emptied")
    };
  const onSave = async () => {
    //let isValid =validateField()
    
    if (!!ItemSubGroupDes == false) {
      toast.error("Sub Group Name is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!SelectedItemGroup == false) {
      toast.error("Select an Item Group", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else{

      ItemSubGroupSetup.SubGroupName= ItemSubGroupDes;
      ItemSubGroupSetup.ItemGroupId= SelectedItemGroup.id;
      var url = apiAddress + "/api/ItemSubGroup";
      await axios.post(url,ItemSubGroupSetup)
      .then((response) => {
        toast.success("Insert Successfull", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
      onReset();
    console.log("Inserted")
    var props = {
      page: 0,
      pageSize: 10,
    };
    await ItemSubgroupListPaged(props, "Main");
    console.log("Api hit")
      console.log("not hit")
    }
  };
  //Acknowledgement table end



  return (
    <>
      <div className="card card-shadow p-3">
        <div className="row">
          <div className="col-12 col-md-5 col-lg-5 gap-3">
            <ToastContainer />
            <div>
              <h5>Item Sub Group</h5>
            </div>
            <TextAreaComponents
              id={6}
              value={(e) => setItemSubGroupDes(e)}
              label={"Item Sub Group"}    
              val={ItemSubGroupDes}
              //error={true}          
              //   val={PurchaseOrderNumber}
            />
            <div className='non-valid mt-2'><span>{Error.SubGroupName}</span></div>
            <div>
            <AutoCompleteMultiSelect
            datas={ItemGroupList}
            value={handleItemGroupSelect}
            id={1}
            label={"Item Group"}
            getOptionLabel={(option) => option.title}
            val={SelectedItemGroup}
            /></div>
            <div className='non-valid mt-2'><span>{Error.ItemGroupId}</span></div>
            <div className="mt-2">
              <FormControl className="d-flex flex-row gap-3">
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  className="mt-2"
                >
                  Status
                </FormLabel>
                <RadioGroup
                  row
                  defaultValue={"active"}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  // onChange={purchaseOrderTypeRadio}
                >
                  <FormControlLabel
                    value="active"
                    control={<Radio />}
                    label="Active"
                  />
                  <FormControlLabel
                    value="inactive"
                    control={<Radio />}
                    label="Inactive"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="row mt-3">
              <div className="col-6"></div>
              <div className="col-6">
                <div className="d-flex gap-4 ">
                  <button onClick={()=>{onSave()}} className="btn-yes btn-common">Save</button>
                  <button onClick={()=>{onReset()}} className="btn-no btn-common">Reset</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7 col-lg-7">
            <div>
              <h5>Item SubGroup List</h5>
            </div>
            <div className='row'>
            <div className='mb-3 col-md-6'>
                <TextAreaComponents id={6} value={(e)=>setSearch(e)} label={"Search"} val={Search} />
            </div>
            <div className="col-md-6 d-flex gap-3 ">
                  <button className="btn-yes btn-common" onClick={onSearch}>Search</button>
                  <button className="btn-no btn-common"onClick={onClear}>Reset</button>
                </div>
            </div>
            <div>
              <DataTable
                pageState={IsgpageState}
                GetPurchaseOrderNoAPi={GetItemSubGroupNoAPi}
                isLoading={IsgisLoading}
                rows={IsgRows}
                columns={Isgcolumns}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ItemSubGroupSetup;
