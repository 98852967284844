import React, { useState, useEffect } from "react";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import {
  GetEmployeeAPi,
  GetSupplierAPi,
} from "../../../api/screenApi/purchaseOrder/purchaseOrderApi.js";
import DataTable from "../../sharedComponent/dataTable/DataTable.jsx";
import axios from "axios";
import Switch from "@mui/material/Switch";
import checkMark from "./../../../assests/images/check-mark.png";
import print from "./../../../assests/images/printing.png";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater.jsx";
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents.jsx";
import ClientSiteDataTable from "../../sharedComponent/dataTable/ClientSiteDataTable.jsx";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker.js";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlertModal from "../../sharedComponent/SweetAlertModal/SweetAlertModal.js";
import { EntryNumberGenarator } from "../../sharedComponent/CommonMethods.js";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

function SupplierPaymentAdjustment() {
  const apiAddress = process.env.REACT_APP_SECRET;
  const [AlertOpen, setAlertOpen] = useState(false);
  const [clearAutoComplete, setclearAutoComplete] = useState(false);
  const [Search, setSearch] = useState();
  const [PaymentSearch, setpaymentSearch] = useState();
  const [SupplierList, setSupplierList] = useState([]);
  const [SelectedSupplierId, setSelectedSupplierId] = useState();

  const [SelectedBillList, setSelectedBillList] = useState([]);
  const [Flag, setFlag] = useState(true);
  const [RowId, setRowId] = useState(0);
  const [Remarks, setRemarks] = useState();
  const [Adjusteddate, setAdjustedDate] = useState(null);
  const [AdjustedBy, setAdjustedBy] = useState();
  const [ReasonId, setReasonId] = useState();
  const [ReasonList, setReasonList] = useState([]);
  const [EmployeeList, setEmployeeList] = useState([]);

  const [supplierPaymentAdjustmentView, setSupplierPaymentAdjustmentView] =
    useState({
      supplierPaymentAdjustment: {
        number: "string",
        adjustedDate: null,
        adjustedBy: 0,
        reasonId: 0,
        remarks: "",
        counterId: 1,
        creatorId: 1,
        creationDate: dayjs(),
        updatorId: 1,
        modificationDate: dayjs(),
      },
      supplierPaymentAdjustmentDetailsList: [],
    });

  //validation toaster start
  const errorToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  //validation toaster end
  const GetEmployeeList = async () => {
    var list = [];
    var res = await GetEmployeeAPi();
    console.log(res);
    res.map((d, i) => {
      var obj = { id: d.employeeId, title: d.firstName + " " + d.middleName };
      list.push(obj);
    });
    setEmployeeList(list);
  };

  const GetReasonList = async () => {
    await axios.get(apiAddress + "/api/Reasons").then((response) => {
      setReasonList(response.data);
    });
  };
  const getSupplier = async () => {
    var res = await GetSupplierAPi();
    console.log(res);
    setSupplierList(res);
  };
  useEffect(() => {
    getSupplier();
    GetEmployeeList();
    GetReasonList();
  }, []);
  console.log(ReasonList);
  const handleSupplierSelection = (id) => {
    console.log(id);
    if (!!id == true) {
      setSearch(id);
      BillListPaged("props", id);
    } else {
      var props = {
        page: 0,
        pageSize: 10,
      };
      BillListPaged(props);
    }
  };

  //bill table start
  const Billcolumns = [
    { field: "sn", headerName: "IS/N", width: 170 },
    { field: "billNo", headerName: "Bill No", width: 170 },
    {
      field: "billDate",
      headerName: "Bill Date",
      width: 170,
      renderCell: (params) => <span>{DateFormater(params.row.billDate)}</span>,
    },
    { field: "supplier", headerName: "Supplier Name", width: 170 },
    { field: "remarks", headerName: "Remarks", width: 170 },
    {
      field: "action",
      headerName: "Action",
      width: 170,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onCheckClick(params.row);
            }}
          >
            <img src={checkMark} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
  const [billrows, setBillrows] = React.useState([]);

  const [isLoading, SetisLoading] = React.useState(false);

  const [pageState, setpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const BillListPaged = async (props, id) => {
    if (!!id == false) {
      setpageState((old) => ({ ...old, isLoading: true }));
      console.log(props);
      SetisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseBill/GetPurchaseBillPagedForAdjustment?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          console.log(response.data);
          setpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: pageState.page,
          }));
          var list = [];
          response.data._purchaseBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billNo: d.number,
              billDate: d.billDate,
              supplier: d.supplierName,
              remarks: d.remarks,
              fulllRow: d,
              supplierId: d.supplierId,
            };
            list.push(obj);
          });
          setBillrows(list);
          SetisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetisLoading(false);
        });
    } else {
      const condition = "SupplierId=" + id;
      setpageState((old) => ({ ...old, isLoading: true }));
      console.log(props);
      SetisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseBill/GetPurchaseBillPagedForAdjustment?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            5 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          console.log(response.data);
          setpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: pageState.page,
          }));
          var list = [];
          response.data._purchaseBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billNo: d.number,
              billDate: d.billDate,
              supplier: d.supplierName,
              remarks: d.remarks,
              fulllRow: d,
              supplierId: d.supplierId,
            };
            list.push(obj);
          });
          setBillrows(list);
          SetisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetisLoading(false);
        });
    }
  };

  const GetPurchaseBillPage = async (props) => {
    await BillListPaged(props);
  };

  //bill table end

  //Sales bill table start
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [akgrows, setAkgrows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const SalesBillcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "adjustmentNo", headerName: "AdjustmentNumber", flex: 1 },
    { field: "adjustedDate", headerName: "Date", flex: 1 },

    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              window.open(
                "/supplierPayment-adjustment-report/" + params.row.adjustmentNo,
                "popup",
                "width=850,height=550,left=280,top=80"
              );
            }}
          >
            <img src={print} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const billListPage = async (props, from) => {
    if (from != "Search") {
      setakgpageState((old) => ({ ...old, isLoading: true }));
      console.log(props);
      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SupplierPaymentAdjustment/GetSupplierPaymentPaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          // var entryNo = EntryNumberGenarator(
          //   response.data._payment[0].number,
          //   "INV1-PS-"
          // );
          // setPaymentNo(entryNo);
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data._suppierpaymentList.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              adjustmentNo: d.number,
              adjustedDate: d.adjustedDate,
              // paidAmount: d.paidAmount,

              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    } else {
      const newString = "'" + PaymentSearch + "'";
      const condition = "Number=" + newString;
      setakgpageState((old) => ({ ...old, isLoading: true }));
      console.log(props);
      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SupplierPaymentAdjustment/GetSupplierPaymentPaged?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data._suppierpaymentList.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              adjustmentNo: d.number,
              adjustedDate: d.adjustedDate,
              // paidAmount: d.paidAmount,

              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    }
  };

  const onPaymentSearch = async () => {
    await billListPage("poprs", "Search");
  };
  const onpaymentClear = async () => {
    setpaymentSearch("");
    var props = {
      page: 0,
      pageSize: 10,
    };
    await billListPage(props, "Main");
  };
  const GetSalesAcknoledgementPage = async (props) => {
    await billListPage(props, "Main");
  };
  //sales bill table end

  const onCheckClick = (data) => {
    var list = [...SelectedBillList];

    var obj = {
      id: RowId + 1,
      adjustmentNumber: "string",
      pbNumber: data.billNo,
      isIncreased: false,
      adjustedAmount: 0,
    };
    list.push(obj);
    setSelectedBillList(list);
    setRowId(RowId + 1);
  };

  //selected bill table start

  const SelectedBillcolumns = [
    // { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "pbNumber", headerName: "BillNumber", flex: 1 },

    {
      field: "adjustedAmount",
      headerName: "AdjustedValue",
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <TextAreaComponents
            id={2}
            value={(e) => (params.row.adjustedAmount = parseInt(e))}
          />
        </div>
      ),
      flex: 1,
    },

    {
      field: "isIncreased",
      headerName: "IsIncreased?",
      flex: 1,
      renderCell: (params) => (
        <Switch
          onChange={() => {
            {
              params.row.isIncreased = !params.row.isIncreased;
              setFlag(!Flag);
            }
          }}
        />
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  //selected bill table end

  const onSave = () => {
    setAlertOpen(false);
    var valid = true;
    supplierPaymentAdjustmentView.supplierPaymentAdjustment.remarks = Remarks;
    supplierPaymentAdjustmentView.supplierPaymentAdjustmentDetailsList =
      SelectedBillList;
    if (SelectedBillList.length == 0) {
      valid = false;
      errorToast("Item not selected");
    }
    if (SelectedBillList.length > 0) {
      var res = SelectedBillList.find((x) => x.adjustedAmount == 0);
      if (!!res == true) {
        valid = false;
        errorToast("Adjusted value not given");
      }
    }
    if (
      supplierPaymentAdjustmentView.supplierPaymentAdjustment.adjustedBy == 0
    ) {
      valid = false;
      errorToast("Employee not selected");
    }
    if (supplierPaymentAdjustmentView.supplierPaymentAdjustment.reasonId == 0) {
      valid = false;
      errorToast("Reason not selected");
    }
    if (
      supplierPaymentAdjustmentView.supplierPaymentAdjustment.adjustedDate ==
      null
    ) {
      valid = false;
      errorToast("Date not selected");
    }

    if (valid) {
      var url = apiAddress + "/api/SupplierPaymentAdjustment";

      axios.post(url, supplierPaymentAdjustmentView).then((response) => {
        successToast("Successfully saved");
        setclearAutoComplete(true);
        setSelectedBillList([]);
        setRemarks("");
        supplierPaymentAdjustmentView.supplierPaymentAdjustment.adjustedDate =
          null;
        supplierPaymentAdjustmentView.supplierPaymentAdjustment.remarks = "";
        var props = {
          page: 0,
          pageSize: 10,
        };
        BillListPaged(props);

        billListPage(props, "Main");
      });
    }
  };

  return (
    <div>
      <ToastContainer />
      <SweetAlertModal
        open={AlertOpen}
        headerText={"Are you sure?"}
        bodyText={"You want to save?"}
        close={() => setAlertOpen(false)}
        onyes={onSave}
      />
      <h5 className="">Supplier payment Adjustment</h5>
      <div className="card card-shadow">
        <div className="row p-3">
          <div className="col-12 mt-2">
            <AutoCompleteMultiSelect
              datas={SupplierList}
              id={1}
              getOptionLabel={(option) => option.supplierName}
              value={(e) => handleSupplierSelection(e?.id)}
              label={"Received Type"}
              placeholder={"--Received Type--"}
              //   defaultValue={initialValue[0]}
              clear={clearAutoComplete}
            />
          </div>
        </div>
      </div>

      <div className="card p-3 card-shadow acknowledgementCard mt-3">
        <div>
          <h6>Purchase Bill List</h6>
        </div>
        <DataTable
          pageState={pageState}
          GetPurchaseOrderNoAPi={GetPurchaseBillPage}
          isLoading={isLoading}
          rows={billrows}
          columns={Billcolumns}
        />
      </div>

      <div className="card card-shadow mt-3 p-3">
        <div className="row">
          <div className="col-12">
            <h6>Adjustment Entry</h6>
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <div>
              <TextAreaComponents
                id={5}
                // value={(e) => setStockReceiveNumber(e)}
                label={"Adjustment Number"}
                // val={OrderNo}
                isactive={true}
              />
            </div>
            <div>
              <CustomDateTimePicker
                value={(props) =>
                  (supplierPaymentAdjustmentView.supplierPaymentAdjustment.adjustedDate =
                    props)
                }
                label={"Adjustment date"}
                val={
                  supplierPaymentAdjustmentView.supplierPaymentAdjustment
                    .adjustedDate
                }
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 PurchaseOrderCard">
            <AutoCompleteMultiSelect
              datas={EmployeeList}
              getOptionLabel={(option) => option.title}
              id={1}
              value={(e) =>
                (supplierPaymentAdjustmentView.supplierPaymentAdjustment.adjustedBy =
                  e?.id)
              }
              label={"Adjusted By"}
              placeholder={"--Adjusted By--"}
              clear={clearAutoComplete}
            />
            <AutoCompleteMultiSelect
              datas={ReasonList}
              getOptionLabel={(option) => option.reasonName}
              id={1}
              value={(e) =>
                (supplierPaymentAdjustmentView.supplierPaymentAdjustment.reasonId =
                  e?.id)
              }
              label={"Reason"}
              placeholder={"--Reason--"}
              clear={clearAutoComplete}
            />
          </div>
          <div className="col-12 mt-2">
            <TextAreaComponents
              id={5}
              value={(props) => setRemarks(props)}
              label={"Remarks"}
              val={Remarks}
            />
          </div>
        </div>
      </div>

      {SelectedBillList.length > 0 ? (
        <ClientSiteDataTable
          dataRows={SelectedBillList}
          columns={SelectedBillcolumns}
          isActive={true}
          // getselectedData={getSelectedItems}
        />
      ) : (
        ""
      )}

      <div className="row mt-3">
        <div className="col-4">
          <div className="d-flex gap-4 ">
            <button
              className="btn-yes btn-common"
              onClick={() => setAlertOpen(true)}
            >
              Save
            </button>
            <button className="btn-no btn-common">Clear</button>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
          <TextAreaComponents
            id={6}
            value={(props) => setpaymentSearch(props)}
            label={"Search"}
            val={PaymentSearch}
          />
        </div>
        <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
          <button className="btn-search btn-common" onClick={onPaymentSearch}>
            Search
          </button>
          <button className="btn-search btn-common" onClick={onpaymentClear}>
            Clear
          </button>
        </div>
      </div>
      <div className="mt-2">
        <DataTable
          pageState={akgpageState}
          GetPurchaseOrderNoAPi={GetSalesAcknoledgementPage}
          isLoading={akgisLoading}
          rows={akgrows}
          columns={SalesBillcolumns}
        />
      </div>
    </div>
  );
}

export default SupplierPaymentAdjustment;
