import React, { useState, useEffect } from "react";
import axios from "axios";
import checkMark from "./../../../assests/images/check-mark.png";
import remove from "./../../../assests/images/remove.png";
import DataTable from "../../sharedComponent/dataTable/DataTable";
import {
  GetSalesOrderByNumberAPi,
  GetSalesOrderByNumberForBillAPi,
} from "../../../api/salesAPi/salesOrder/SalesOrderApi";
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import Switch from "@mui/material/Switch";
import SweetAlertModal from "../../sharedComponent/SweetAlertModal/SweetAlertModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwtDecode from "jwt-decode";
import dayjs from "dayjs";
import edit from "./../../../assests/images/edit.png";
import print from "./../../../assests/images/printing.png";
import { EntryNumberGenarator } from "../../sharedComponent/CommonMethods";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import ClientSiteDataTable from "../../sharedComponent/dataTable/ClientSiteDataTable";

function SalesBill() {
  const apiAddress = process.env.REACT_APP_SECRET;
  const [AlertOpen, setAlertOpen] = useState(false);
  const [UserId, setUserId] = useState();

  const [FinalAmountWithAitAD, setFinalAmountWithAitAD] = useState(0);
  const [isAitApplicable, setAitApplicable] = useState(false);
  const [Ait, setAit] = useState(0);
  const [SaleBillNo, setSaleBillNo] = useState();
  const [BillDate, setBillDate] = useState();
  const [Remarks, setRemarks] = useState();
  const [AdditionalDiscount, setAdditionalDiscount] = useState(0);
  const [OrderedItemList, setOrderedItemList] = useState([]);
  const [SelectedsalesOrder, setSelectedSalesOrder] = useState();
  const [OrderNo, setOrderNo] = useState();
  const [SelectedItemList, setSelectedItemList] = useState([]);
  const [flag, setFlag] = useState(true);
  const [SalesBillView, setSalesBillView] = useState({
    salesBill: {
      number: "string",
      billDate: "2023-10-28T03:58:18.221Z",
      salesOrderNo: "string",
      remarks: "string",
      addtionalDiscount: 0,
      ait: 0,
      finalAmount: 0,
      counterId: 1,
      creatorId: 0,
      creationDate: "2023-10-28T03:58:18.221Z",
      updatorId: 0,
      updateDate: dayjs(),
    },
  });
  const [Search, setSearch] = useState();
  const [AckSearch, setAckSearch] = useState();
  //validation toaster start
  const errorToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  //validation toaster end

  //user info start
  const token = sessionStorage.getItem("imsToken");
  var Userdata = jwtDecode(token);
  if (!!UserId == false) {
    setUserId(Number(Userdata.UserId));
  }
  //user info end

  // Purchase Order Table start
  const columns = [
    { field: "id", headerName: "S/N", flex: 1 },
    { field: "number", headerName: "Reference No", flex: 1 },
    {
      field: "orderDate",
      headerName: "Issue Date",
      renderCell: (params) => <span>{DateFormater(params.row.orderDate)}</span>,
      flex: 1,
    },
    { field: "orderId", headerName: "Order Id", flex: 1 },
    { field: "remarks", headerName: "Remarks ", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onCheckClick(params.row);
            }}
          >
            <img src={checkMark} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
  const [orderrows, setorderrows] = React.useState([]);

  const [isorderLoading, SetorderisLoading] = React.useState(false);

  const [orderpageState, setorderpageState] = React.useState({
    isLoading: false,
    // data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const AcknowledgeListpage = async (props, from) => {
    if (from != "Search") {
      setorderpageState((old) => ({ ...old, isLoading: true }));

      SetorderisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesAcknowledgement/GetSalesAcknowledgementPagedNotInBill?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setorderpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: orderpageState.page,
          }));
          var list = [];
          response.data._salesAcknowledgements.map((d, i) => {
            var obj = {
              id: i + 1,
              number: d.number,
              orderDate: d.acknowledgmentDate,
              orderId: d.salesOrderNumber,
              remarks: d.remarks,
            };
            list.push(obj);
          });
          setorderrows(list);
          SetorderisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetorderisLoading(false);
        });
    } else {
      const newString = "'" + AckSearch + "'";
      const condition = "Number=" + newString;
      setorderpageState((old) => ({ ...old, isLoading: true }));

      SetorderisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesAcknowledgement/GetSalesAcknowledgementPagedNotInBill?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setorderpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: orderpageState.page,
          }));
          var list = [];
          response.data._salesAcknowledgements.map((d, i) => {
            var obj = {
              id: i + 1,
              number: d.number,
              orderDate: d.acknowledgmentDate,
              orderId: d.salesOrderNumber,
              remarks: d.remarks,
            };
            list.push(obj);
          });
          setorderrows(list);
          SetorderisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetorderisLoading(false);
        });
    }
  };
  const onAckSearch = async () => {
    await AcknowledgeListpage("props", "Search");
  };
  const onAckClear = async () => {
    setAckSearch("");
    var props = {
      page: 0,
      pageSize: 10,
    };
    await AcknowledgeListpage(props, "Main");
  };

  const GetPurchaseOrderNoAPi = async (props) => {
    await AcknowledgeListpage(props, "Main");
  };

  const onCheckClick = async (data) => {
    var res = await GetSalesOrderByNumberForBillAPi(data.number);
    setSelectedSalesOrder(data);
    setOrderNo(data.orderId);
    var list = [];
    res.salesOrderdetailsList.map((d, i) => {
      var obj = {
        id: i + 1,
        salesBillNo: "string",
        itemId: d.itemId,
        itemName: d.itemName,
        qty: d.requestedQty,
        unitPrice: 0,
        amount: 0,
        discount: 0,
        afterDiscount: 0,
        sd: 0,
        vat: 0,
      };
      list.push(obj);
    });
    setOrderedItemList(list);
  };

  // Purchase Order Table End

  //Sales bill table start
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [akgrows, setAkgrows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const SalesBillcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "billno", headerName: "SalesBill No", flex: 1 },
    { field: "salesOrderNo", headerName: "SalesOrderNo No", flex: 1 },
    {
      field: "billdate",
      headerName: "Bill Date",
      renderCell: (params) => <span>{DateFormater(params.row.billdate)}</span>,
      flex: 1,
    },

    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              window.open(
                "/sales-bill-report/" + params.row.billno,
                "popup",
                "width=850,height=550,left=280,top=80"
              );
            }}
          >
            <img src={print} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const billListPage = async (props, from) => {
    if (from != "Search") {
      setakgpageState((old) => ({ ...old, isLoading: true }));

      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesBill/GetsalesBillPaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          var entryNo = EntryNumberGenarator(
            response.data.salesBill[0].number,
            "INV1-SB-"
          );
          setSaleBillNo(entryNo);
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data.salesBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billno: d.number,
              salesOrderNo: d.salesOrderNo,
              billdate: d.billDate,

              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    } else {
      const newString = "'" + Search + "'";
      const condition = "Number=" + newString;
      setakgpageState((old) => ({ ...old, isLoading: true }));

      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesBill/GetsalesBillPaged?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data.salesBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billno: d.number,
              salesOrderNo: d.salesOrderNo,
              billdate: d.billDate,

              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    }
  };

  const onSearch = async () => {
    await billListPage("poprs", "Search");
  };
  const onClear = async () => {
    setSearch("");
    var props = {
      page: 0,
      pageSize: 10,
    };
    await billListPage(props, "Main");
  };
  const GetSalesAcknoledgementPage = async (props) => {
    await billListPage(props, "Main");
  };
  //sales bill table end

  const onSelectItem = (data) => {
    var res = SelectedItemList.find((x) => x.itemId == data.itemId);
    if (res == undefined) {
      var list = [...SelectedItemList];
      list.push(data);
      setSelectedItemList(list);
    }
  };

  const removeSelectedItem = (index) => {
    var list = [...SelectedItemList];

    list.splice(index, 1);
    setSelectedItemList(list);
  };

  const inputChange = (e, index, field) => {
    if (!!e == false) {
      e = 0;
    }
    var updatedList = [...SelectedItemList];
    updatedList[index][field] = parseFloat(e);
    const { qty, unitPrice, discount, sd, vat } = updatedList[index];
    var afterDiscount = qty * unitPrice - discount;
    updatedList[index].afterDiscount = afterDiscount;
    var individualRowTotal = qty * unitPrice - discount + sd + vat;
    updatedList[index].amount = individualRowTotal;

    setSelectedItemList(updatedList);
  };

  const individualTotal = (itemList, field) => {
    var sum = 0;
    itemList.map((d, i) => {
      sum += d[field];
    });
    if (field == "amount") {
      SalesBillView.salesBill.finalAmount = sum + Ait - AdditionalDiscount;
    }
    return sum;
  };
  const handleAitSwitch = () => {
    setAitApplicable(!isAitApplicable);
  };

  const addAIT = (e) => {
    if (!!e == false) {
      setAit(0);
    } else {
      setAit(parseInt(e));
    }
    setFlag(!flag);
  };
  const addAdditioanlDiscount = (e) => {
    if (!!e == false) {
      setAdditionalDiscount(0);
    } else {
      setAdditionalDiscount(parseInt(e));
    }
    setFlag(!flag);
  };

  const netTotal = () => {
    const total =
      SalesBillView.salesBill.finalAmount - AdditionalDiscount + Ait;
    // setFinalAmount(total);
    SalesBillView.salesBill.finalAmount = total;
    setFinalAmountWithAitAD(total);
  };
  useEffect(() => {
    netTotal();
  }, [flag]);

  //selected order column start
  const SelectedOrderColumns = [
    { field: "id", headerName: "Serial", flex: 1 },

    {
      field: "itemName",
      headerName: "ProductName",

      flex: 1,
    },

    {
      field: "qty",
      headerName: "Requested Quantity",
      flex: 1,
    },
  ];
  //selected order column end
  const getSelectedItems = (data) => {
    var list = [];
    data.map((d, i) => {
      var res = OrderedItemList.find((x) => x.id == d);

      if (!!res == true) {
        // res.id = i
        list.push(res);
      }
    });
    setSelectedItemList(list);
  };

  //New calculation start
  const NewinputChange = (e, i, field) => {
    var obj = SelectedItemList.find((x) => x.id == i);
    var index = SelectedItemList.indexOf(obj);
    if (!!e == false) {
      e = 0;
    }
    var updatedList = [...SelectedItemList];
    updatedList[index][field] = parseFloat(e);
    const { qty, unitPrice, discount, sd, vat } = updatedList[index];
    var afterDiscount = qty * unitPrice - discount;
    updatedList[index].afterDiscount = afterDiscount;
    var individualRowTotal = qty * unitPrice - discount + sd + vat;
    updatedList[index].amount = individualRowTotal;

    setSelectedItemList(updatedList);
  };

  //New calculation end

  //selected item column start
  const SelectedItemColumns = [
    // { field: "id", headerName: "Serial", flex: 1 },

    {
      field: "itemName",
      headerName: "ProductName",

      flex: 2.5,
    },

    {
      field: "qty",
      headerName: "Quantity",
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <TextAreaComponents
            id={2}
            value={(e) => NewinputChange(e, params.id, params.field)}
            val={params.row.qty}
          />
        </div>
      ),
      flex: 1,
    },
    {
      field: "unitPrice",
      headerName: "BasePrice",
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <TextAreaComponents
            id={2}
            value={(e) => NewinputChange(e, params.id, params.field)}
            val={params.row.unitPrice}
          />
        </div>
      ),
      flex: 1,
    },
    {
      field: "discount",
      headerName: "Discount",
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <TextAreaComponents
            id={2}
            value={(e) => NewinputChange(e, params.id, params.field)}
            val={params.row.discount}
          />
        </div>
      ),
      flex: 1,
    },
    {
      field: "afterDiscount",
      headerName: "AfterDiscount",
      flex: 1,
    },
    {
      field: "sd",
      headerName: "SD",
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <TextAreaComponents
            id={2}
            value={(e) => NewinputChange(e, params.id, params.field)}
            val={params.row.sd}
          />
        </div>
      ),
      flex: 1,
    },
    {
      field: "vat",
      headerName: "Vat",
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <TextAreaComponents
            id={2}
            value={(e) => NewinputChange(e, params.id, params.field)}
            val={params.row.vat}
          />
        </div>
      ),
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Total",
      flex: 1,
    },
  ];
  //selected item column end

  const onSave = () => {
    setAlertOpen(false);
    var valid = true;
    if (!!Remarks == false) {
      valid = false;
      errorToast("Remarks is required");
    }
    if (!!SelectedsalesOrder == false) {
      valid = false;
      errorToast("Please select a sales order");
    }
    if (!!BillDate == false) {
      valid = false;
      errorToast("Date not selected");
    }
    if (SelectedItemList.length == 0) {
      valid = false;
      errorToast("No item selected");
    }
    if (SelectedItemList.length > 0) {
      var res = SelectedItemList.find((x) => x.unitPrice == 0);
      if (!!res == true) {
        valid = false;
        errorToast("Item base price can't be 0");
      }
    }

    if (valid) {
      SalesBillView.salesBill.salesOrderNo = SelectedsalesOrder.orderId;
      SalesBillView.salesBill.IssueNo = SelectedsalesOrder.number;

      SalesBillView.salesBill.ait = Ait;
      SalesBillView.salesBill.addtionalDiscount = AdditionalDiscount;
      SalesBillView.salesBill.billDate = BillDate;
      SalesBillView.salesBill.remarks = Remarks;
      SalesBillView.salesBill.creatorId = UserId;
      SalesBillView.salesBill.updatorId = UserId;
      SalesBillView.salesBillDeatilsList = SelectedItemList;

      console.log(SalesBillView);

      var url = apiAddress + "/api/SalesBill";
      axios.post(url, SalesBillView).then((response) => {
        successToast("Successfully saved");
        setAdditionalDiscount(0);
        setAit(0);
        setRemarks("");
        setBillDate(null);
        setSelectedItemList([]);
        setOrderedItemList([]);
        setOrderNo("");
        var props = {
          page: 0,
          pageSize: 10,
        };
        billListPage(props, "Main");
        AcknowledgeListpage(props, "Main");
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <SweetAlertModal
        open={AlertOpen}
        headerText={"Are you sure?"}
        bodyText={"You want to save?"}
        close={() => setAlertOpen(false)}
        onyes={onSave}
      />
      <div className="card card-shadow p-3 mt-3">
        <div>
          <h6>Sales Order List</h6>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setAckSearch(props)}
              label={"Search"}
              val={AckSearch}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onAckSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onAckClear}>
              Clear
            </button>
          </div>
        </div>
        <div className="mt-2">
          <DataTable
            pageState={orderpageState}
            GetPurchaseOrderNoAPi={GetPurchaseOrderNoAPi}
            isLoading={isorderLoading}
            rows={orderrows}
            columns={columns}
          />
        </div>
      </div>

      <div className="card card-shadow mt-3 p-3">
        <div className="row">
          <div className="col-12">
            <h6>Sales Bill Entry</h6>
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <div>
              <TextAreaComponents
                id={5}
                label={"Sales Bill No"}
                val={SaleBillNo}
                isactive={true}
              />
            </div>
            <div>
              <TextAreaComponents
                id={5}
                label={"Sales Order No"}
                val={OrderNo}
                isactive={true}
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 PurchaseOrderCard">
            <CustomDateTimePicker
              // value={(props) => setDateTime(props)}
              isDisabled={true}
              val={dayjs(SelectedsalesOrder?.orderDate)}
              label={"Sales Order Date"}
            />
            <CustomDateTimePicker
              value={(props) => setBillDate(props)}
              label={"Sales bill Date"}
              val={BillDate}
            />
          </div>
          <div className="mt-2">
            <TextAreaComponents
              id={6}
              value={(props) => setRemarks(props)}
              label={"Remarks"}
              val={Remarks}
            />
          </div>
        </div>
      </div>

      {/* <div className="card card-shadow p-3 mt-3">
        <div>
          <h6>Items Under Sales Order</h6>
        </div>
        <table class="table table-striped mt-2 ">
          <thead style={{ backgroundColor: "#2d358f", color: "white" }}>
            <tr>
              <th>S/N</th>
              <th>Item Id</th>
              <th>Requested Quantity</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {OrderedItemList.map((d, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{d.itemName}</td>

                  <td className="col-1">
                    <TextAreaComponents
                      id={2}
                      // value={(e) => (d.qty = e)}
                      // value={(e) => inputChange(e, i, "qty")}
                      // label={d.requestedQty}
                      placeholder={"Requested Qty"}
                      val={d.qty}
                      isactive={true}
                    />
                  </td>
                  <td>
                    <div onClick={() => onSelectItem(d)}>
                      <img src={checkMark} alt="" style={{ width: "1rem" }} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="row d-flex justify-content-end"></div>
      </div> */}

      {OrderedItemList.length > 0 ? (
        <>
          <div className="card card-shadow p-3 mt-3">
            <div>
              <h6>Items Under Sales Order</h6>
            </div>
            <ClientSiteDataTable
              dataRows={OrderedItemList}
              columns={SelectedOrderColumns}
              isActive={true}
              getselectedData={getSelectedItems}
            />
          </div>
        </>
      ) : (
        ""
      )}

      {/* <div className="card card-shadow p-3 mt-3">
        <div>
          <h6>Selected Items</h6>
        </div>
        <table class="table table-striped mt-2 ">
          <thead style={{ backgroundColor: "#2d358f", color: "white" }}>
            <tr>
              <th>S/N</th>
              <th>Item Id</th>

              <th>PO Quantity</th>
              <th>Base Price</th>
              <th>Discount</th>
              <th>After Discount</th>
              <th>SD</th>
              <th>VAT</th>
              <th>Total</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {SelectedItemList.map((d, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{d.itemId}</td>

                  <td className="col-1">
                    <TextAreaComponents
                      id={2}
                    
                      value={(e) => inputChange(e, i, "qty")}
                      label={"Requested quantity"}
                      placeholder={"Requested Qty"}
                      val={d.qty}
                    />
                  </td>
                  <td className="col-1">
                    <TextAreaComponents
                      id={2}
                    
                      value={(e) => inputChange(e, i, "unitPrice")}
                      label={"0"}
                      placeholder={"Base Price"}
                    />
                  </td>
                  <td className="col-1">
                    <TextAreaComponents
                      id={2}
                      
                      value={(e) => inputChange(e, i, "discount")}
                      label={"0"}
                      placeholder={"Discount"}
                    />
                  </td>
                  
                  <td>{d.afterDiscount}</td>
                  <td className="col-1">
                    <TextAreaComponents
                      id={2}
                     
                      value={(e) => inputChange(e, i, "sd")}
                      label={d.sdPercent}
                      placeholder={"S.D."}
                      val={d.sd}
                    />
                  </td>
                  <td className="col-1">
                    <TextAreaComponents
                      id={2}
                   
                      value={(e) => inputChange(e, i, "vat")}
                      label={d.vatPercent}
                      placeholder={"VAT"}
                      val={d.vat}
                    />
                  </td>

                  <td>{d.amount}</td>
                  <td>
                    <div onClick={()=>removeSelectedItem(i)}>
                      <img src={remove} alt="" style={{ width: "1.5rem" }} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div className="row d-flex justify-content-end"></div>
      </div> */}

      {SelectedItemList.length > 0 ? (
        <>
          <div className="card card-shadow p-3 mt-3">
            <div>
              <h6>Selected Items</h6>
            </div>
            <ClientSiteDataTable
              dataRows={SelectedItemList}
              columns={SelectedItemColumns}
              // isActive={true}
              // getselectedData={getSelectedItems}
            />
          </div>
        </>
      ) : (
        ""
      )}
      {/* total chart start */}
      <div className="card card-shadow p-3 mt-3">
        <div className="row p-3 justify-content-end">
          <div className="col-12 col-md-12 col-lg-5 d-flex flex-column  gap-2 ">
            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Total Base Amount</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    // value={(e) => setTotalBaseAmount(e)}
                    label={"Total Base Amount"}
                    val={individualTotal(SelectedItemList, "unitPrice")}
                    isactive={true}
                    placeholder={"Total Base Amount"}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Discount Total</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    // value={(e) => setDiscountTotal(e)}
                    label={"Discount Total "}
                    val={individualTotal(SelectedItemList, "discount")}
                    isactive={true}
                  />
                </div>
              </div>
            </div>
            <hr className="hr" />

            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Total After Discount</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    // value={(e) => setAfterDiscount(e)}
                    label={"Total After Discount"}
                    val={individualTotal(SelectedItemList, "afterDiscount")}
                    isactive={true}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Total SD</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    // value={(e) => setTotalSd(e)}
                    label={"Total SD "}
                    val={individualTotal(SelectedItemList, "sd")}
                    isactive={true}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Total Vat</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    // value={(e) => setTotalVat(e)}
                    label={"Total VAT"}
                    val={individualTotal(SelectedItemList, "vat")}
                    isactive={true}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Total Amount with Vat</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    // value={(e) => setTotalAmountWithVat(e)}
                    label={"Total Amount With VAT"}
                    val={individualTotal(SelectedItemList, "amount")}
                    isactive={true}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Is AIT applicable? </span>
                </div>
                <div className="col-7">
                  <Switch onChange={handleAitSwitch} />
                </div>
              </div>
            </div>
            {isAitApplicable && (
              <div>
                <div className="row  d-flex">
                  <div className="col-5">
                    <span>AIT</span>
                  </div>
                  <div className="col-7">
                    <TextAreaComponents
                      id={2}
                      value={(e) => addAIT(e)}
                      label={"AIT"}
                      val={Ait}
                    />
                  </div>
                </div>
              </div>
            )}
            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Additional Discount</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    value={(e) => addAdditioanlDiscount(e)}
                    label={"Additional Discount"}
                    val={AdditionalDiscount}
                  />
                </div>
              </div>
            </div>
            <hr className="hr" />

            <div>
              <div className="row  d-flex">
                <div className="col-5">
                  <span>Final Total Amount</span>
                </div>
                <div className="col-7">
                  <TextAreaComponents
                    id={2}
                    label={"Total Amount With VAT and AIT"}
                    val={SalesBillView.salesBill.finalAmount}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* total chart end */}

      <div className="row mt-3">
        <div className="col-4">
          <div className="d-flex gap-4 ">
            <button
              className="btn-yes btn-common"
              onClick={() => setAlertOpen(true)}
            >
              Save
            </button>
            <button className="btn-no btn-common">Clear</button>
            {/* <button className="btn-Review btn-common">Review</button> */}
          </div>
        </div>
      </div>

      <div className="card p-3 card-shadow acknowledgementCard mt-3">
        <div>
          <h6>Sales Bill List</h6>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setSearch(props)}
              label={"Search"}
              val={Search}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onClear}>
              Clear
            </button>
          </div>
        </div>
        <DataTable
          pageState={akgpageState}
          GetPurchaseOrderNoAPi={GetSalesAcknoledgementPage}
          isLoading={akgisLoading}
          rows={akgrows}
          columns={SalesBillcolumns}
        />
      </div>
    </>
  );
}

export default SalesBill;
