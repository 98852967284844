import React, { useEffect, useState } from "react";

import Switch from "@mui/material/Switch";
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import axios from "axios";
import checkMark from "./../../../assests/images/check-mark.png";
import remove from "./../../../assests/images/remove.png";
import DataTable from "../../sharedComponent/dataTable/DataTable";
import dayjs from "dayjs";
import { GetSalesOrderByNumberAPi } from "../../../api/salesAPi/salesOrder/SalesOrderApi";
import edit from "./../../../assests/images/edit.png";
import print from "./../../../assests/images/printing.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwtDecode from "jwt-decode";
import SweetAlertModal from "../../sharedComponent/SweetAlertModal/SweetAlertModal";
import { GetSalesBillByNumberAPi } from "../../../api/salesAPi/salesBillApi/SalesBillApi";
import { EntryNumberGenarator } from "../../sharedComponent/CommonMethods";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";
import ClientSiteDataTable from "../../sharedComponent/dataTable/ClientSiteDataTable.jsx";

function SalesAcknowledgement() {
  const apiAddress = process.env.REACT_APP_SECRET;
  const [Type, setType] = useState("Sales Order");
  const [UserId, setUserId] = useState();
  const [AckowledgeNo, setAcknowledgeNo] = useState();
  const [AckDate, setAckDate] = useState();
  const [Remarks, setRemarks] = useState();
  const [AlertOpen, setAlertOpen] = useState(false);
  const [Search, setSearch] = useState();
  const [OrderSearch, setOrderSearch] = useState();
  const [BillSearch, setBillSearch] = useState();
  const [SelectedSalesOrderItemList, setSelectedSalesOrderItemList] = useState(
    []
  );
  const [SalesAcknowledgementView, setSalesAcknowledgementView] = useState({
    salesAcknowledgement: {
      number: "string",
      acknowledgmentDate: AckDate,
      salesOrderNumber: "",
      remarks: Remarks,
      counterId: 0,
      creatorId: 0,
      creationDate: dayjs(),
      updatorId: 0,
      updateDate: dayjs(),
    },
  });

  //user info start
  const token = sessionStorage.getItem("imsToken");
  var Userdata = jwtDecode(token);
  if (!!UserId == false) {
    setUserId(Number(Userdata.UserId));
  }
  //user info end

  // Sales Order Table start
  const columns = [
    { field: "id", headerName: "S/N", flex: 1 },
    { field: "number", headerName: "Order No", flex: 1 },
    {
      field: "orderDate",
      headerName: "Order Date",
      renderCell: (params) => <span>{DateFormater(params.row.orderDate)}</span>,
      flex: 1,
    },
    { field: "customerName", headerName: "Customer Name", flex: 1 },
    { field: "salesType", headerName: "Sales Type", flex: 1 },
    { field: "remarks", headerName: "Remarks ", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onCheckClick(params.row);
            }}
          >
            <img src={checkMark} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
  const [orderrows, setorderrows] = React.useState([]);

  const [isorderLoading, SetorderisLoading] = React.useState(false);

  const [orderpageState, setorderpageState] = React.useState({
    isLoading: false,
    // data: [],
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const OrderListpage = async (props, from) => {
    if (from != "Search") {
      setorderpageState((old) => ({ ...old, isLoading: true }));

      SetorderisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesOrder/GetSalesOrderPagedNotInAcknowledgement?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setorderpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: orderpageState.page,
          }));
          var list = [];
          response.data._salesOrders.map((d, i) => {
            var obj = {
              id: i + 1,
              number: d.number,
              orderDate: d.orderDate,
              customerName: d.customerName,
              salesType: d.salesTypeName,
              remarks: d.remarks,
            };
            list.push(obj);
          });
          setorderrows(list);
          SetorderisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetorderisLoading(false);
        });
    } else {
      const newString = "'" + OrderSearch + "'";
      const condition = "Number=" + newString;
      setorderpageState((old) => ({ ...old, isLoading: true }));

      SetorderisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesOrder/GetSalesOrderPagedNotInAcknowledgement?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setorderpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: orderpageState.page,
          }));
          var list = [];
          response.data._salesOrders.map((d, i) => {
            var obj = {
              id: i + 1,
              number: d.number,
              orderDate: d.orderDate,
              customerName: d.customerName,
              salesType: d.salesTypeName,
              remarks: d.remarks,
            };
            list.push(obj);
          });
          setorderrows(list);
          SetorderisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetorderisLoading(false);
        });
    }
  };
  const onOrderSearch = async () => {
    await OrderListpage("props", "Search");
  };
  const onOrderClear = async () => {
    setOrderSearch("");
    var props = {
      page: 0,
      pageSize: 10,
    };
    await OrderListpage(props, "Main");
  };
  const GetSalesOrderAPi = async (props) => {
    await OrderListpage(props, "Main");
  };

  const onCheckClick = async (data) => {
    var res = await GetSalesOrderByNumberAPi(data.number);
    SalesAcknowledgementView.salesAcknowledgement.salesOrderNumber =
      res.salesOrder.number;
    SalesAcknowledgementView.salesAcknowledgement.creatorId = UserId;
    SalesAcknowledgementView.salesAcknowledgement.counterId =
      res.salesOrder.counterId;
    SalesAcknowledgementView.salesAcknowledgement.updatorId = UserId;

    //sales acknowledgement item list start
    var list = [];
    res.salesOrderdetailsList.map((d, i) => {
      var item = {
        id: i,
        acknowledgmentNumber: "string",
        itemCode: d.itemId,
        itemName: d.itemName,
        qty: d.requestedQty,
        remarks: d.remarks,
      };
      list.push(item);
    });
    setSelectedSalesOrderItemList(list);
    //sales acknowledgement item list end
  };

  // Sales Order Table End

  //items under order column start
  const ItemsUnderOrderColumns = [
    // { field: "id", headerName: "Serial", flex: 1 },
    {
      field: "itemName",
      headerName: "ItemName",

      flex: 1,
    },

    {
      field: "qty",
      headerName: "PO.Quantity",
      flex: 1,
    },
  ];
  //items under order column  end

  //Acknowledgement table start
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [akgrows, setAkgrows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const Acknowledgementcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "akgno", headerName: "Acknowledgment No", flex: 1 },
    { field: "salesOrderNo", headerName: "Reference No", flex: 1 },
    {
      field: "akgdate",
      headerName: "Acknowledgment Date",
      renderCell: (params) => <span>{DateFormater(params.row.akgdate)}</span>,
      flex: 1,
    },

    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",

      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              window.open(
                "/sales-acknowledge-report/" + params.row.akgno,
                "popup",
                "width=850,height=550,left=280,top=80"
              );
            }}
          >
            <img src={print} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const AcknowledgementListPage = async (props, from) => {
    if (from != "Search") {
      setakgpageState((old) => ({ ...old, isLoading: true }));

      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesAcknowledgement/GetSalesAcknowledgementPaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          var entryNo = EntryNumberGenarator(
            response.data._salesAcknowledgements[0].number,
            "INV1-SA-"
          );
          setAcknowledgeNo(entryNo);
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data._salesAcknowledgements.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              akgno: d.number,
              salesOrderNo: d.salesOrderNumber,
              akgdate: d.acknowledgmentDate,

              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    } else {
      const newString = "'" + Search + "'";
      const condition = "Number=" + newString;
      setakgpageState((old) => ({ ...old, isLoading: true }));

      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesAcknowledgement/GetSalesAcknowledgementPaged?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data._salesAcknowledgements.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              akgno: d.number,
              salesOrderNo: d.salesOrderNumber,
              akgdate: d.acknowledgmentDate,

              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    }
  };
  const onSearch = async () => {
    await AcknowledgementListPage("props", "Search");
  };
  const onClear = async () => {
    setSearch("");
    var props = {
      page: 0,
      pageSize: 10,
    };
    await AcknowledgementListPage(props, "Main");
  };
  const GetSalesAcknoledgementPage = async (props) => {
    await AcknowledgementListPage(props, "Main");
  };
  //Acknowledgement table end

  //Sales bill table start
  const [billisLoading, SetbillIsLoading] = React.useState(false);
  const [billrows, setbillrows] = React.useState([]);

  const [billpageState, setbillpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const SalesBillcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "billno", headerName: "SalesBill No", flex: 1 },
    { field: "salesOrderNo", headerName: "SalesOrderNo No", flex: 1 },
    {
      field: "billdate",
      headerName: "Bill Date",
      renderCell: (params) => <span>{DateFormater(params.row.billdate)}</span>,
      flex: 1,
    },

    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onBillCheckClick(params.row);
            }}
          >
            <img src={checkMark} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const BillListPaged = async (props, from) => {
    if (from != "Search") {
      setbillpageState((old) => ({ ...old, isLoading: true }));

      SetbillIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesBill/GetsalesBillPagedNotInAcknowledgement?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setbillpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: billpageState.page,
          }));
          var list = [];
          response.data.salesBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billno: d.number,
              salesOrderNo: d.salesOrderNo,
              billdate: d.billDate,

              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setbillrows(list);
          SetbillIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetbillIsLoading(false);
        });
    } else {
      const newString = "'" + BillSearch + "'";
      const condition = "Number=" + newString;
      setbillpageState((old) => ({ ...old, isLoading: true }));

      SetbillIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesBill/GetsalesBillPagedNotInAcknowledgement?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setbillpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: billpageState.page,
          }));
          var list = [];
          response.data.salesBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billno: d.number,
              salesOrderNo: d.salesOrderNo,
              billdate: d.billDate,

              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setbillrows(list);
          SetbillIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetbillIsLoading(false);
        });
    }
  };

  const onBillSearch = async () => {
    await BillListPaged("props", "Search");
  };
  const onBillClear = async () => {
    setBillSearch("");
    var props = {
      page: 0,
      pageSize: 10,
    };
    await BillListPaged(props, "Main");
  };
  const GetSalesBillPageNotInAcknowledgement = async (props) => {
    await BillListPaged(props, "Main");
  };

  const onBillCheckClick = async (data) => {
    var res = await GetSalesBillByNumberAPi(data.billno);

    SalesAcknowledgementView.salesAcknowledgement.salesOrderNumber =
      res.salesBill.number;
    SalesAcknowledgementView.salesAcknowledgement.creatorId = UserId;
    SalesAcknowledgementView.salesAcknowledgement.counterId =
      res.salesBill.counterId;
    SalesAcknowledgementView.salesAcknowledgement.updatorId = UserId;

    //sales acknowledgement item list start
    var list = [];
    res.salesBillDeatilsList.map((d, i) => {
      var item = {
        id: d.itemId,
        acknowledgmentNumber: "string",
        itemCode: d.itemId,
        itemName: d.itemName,
        qty: d.qty,
        remarks: d.remarks,
      };
      list.push(item);
    });
    setSelectedSalesOrderItemList(list);
    // sales acknowledgement item list end
  };
  //sales bill table end

  //dropdown start
  const handleDrpdown = (e) => {
    setType(e.target.textContent);
  };
  //dropdown end

  const onSave = () => {
    setAlertOpen(false);
    var valid = true;
    SalesAcknowledgementView.salesAcknowledgement.acknowledgmentDate = AckDate;
    SalesAcknowledgementView.salesAcknowledgement.remarks = Remarks;

    SalesAcknowledgementView.salesAccknowledgementDetailsList =
      SelectedSalesOrderItemList;
    if (!!SalesAcknowledgementView.salesAcknowledgement.remarks == false) {
      valid = false;
      toast.error("Remarks is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (
      !!SalesAcknowledgementView.salesAcknowledgement.acknowledgmentDate ==
      false
    ) {
      valid = false;
      toast.error("Date is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    if (valid == true) {
      var url = apiAddress + "/api/SalesAcknowledgement";
      axios.post(url, SalesAcknowledgementView).then((response) => {
        setSelectedSalesOrderItemList([]);

        toast.success("Saved successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        //clearing states start
        SalesAcknowledgementView.salesAcknowledgement.salesOrderNumber = "";
        setRemarks("");
        setAckDate(null);
        //clearing states end
        var props = {
          page: 0,
          pageSize: 10,
        };
        AcknowledgementListPage(props, "Main");
        BillListPaged(props, "Main");
        OrderListpage(props, "Main");
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <SweetAlertModal
        open={AlertOpen}
        headerText={"Are you sure?"}
        bodyText={"You want to save?"}
        close={() => setAlertOpen(false)}
        onyes={onSave}
      />

      <div class="dropdown">
        <button
          class="btn btn-sm  dropdown-toggle acknowledgeType"
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Type
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
          <li onClick={(e) => handleDrpdown(e)}>
            <a class="dropdown-item" href="#">
              Sales Order
            </a>
          </li>
          <li onClick={(e) => handleDrpdown(e)}>
            <a class="dropdown-item" href="#">
              Sales Bill
            </a>
          </li>
        </ul>
      </div>

      {Type == "Sales Order" && (
        <div className="card card-shadow p-3 mt-3">
          <div>
            <h6>Sales Order List</h6>
          </div>
          <div className="mt-2">
            <div className="row mb-2">
              <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
                <TextAreaComponents
                  id={6}
                  value={(props) => setOrderSearch(props)}
                  label={"Search"}
                  val={OrderSearch}
                />
              </div>
              <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
                <button
                  className="btn-search btn-common"
                  onClick={onOrderSearch}
                >
                  Search
                </button>
                <button
                  className="btn-search btn-common"
                  onClick={onOrderClear}
                >
                  Clear
                </button>
              </div>
            </div>
            <DataTable
              pageState={orderpageState}
              GetPurchaseOrderNoAPi={GetSalesOrderAPi}
              isLoading={isorderLoading}
              rows={orderrows}
              columns={columns}
            />
          </div>
        </div>
      )}

      {Type == "Sales Bill" && (
        <div className="card p-3 card-shadow acknowledgementCard mt-3">
          <div>
            <h6>Sales Bill List</h6>
          </div>
          <div className="row mb-2">
            <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
              <TextAreaComponents
                id={6}
                value={(props) => setBillSearch(props)}
                label={"Search"}
                val={BillSearch}
              />
            </div>
            <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
              <button className="btn-search btn-common" onClick={onBillSearch}>
                Search
              </button>
              <button className="btn-search btn-common" onClick={onBillClear}>
                Clear
              </button>
            </div>
          </div>
          <DataTable
            pageState={billpageState}
            GetPurchaseOrderNoAPi={GetSalesBillPageNotInAcknowledgement}
            isLoading={billisLoading}
            rows={billrows}
            columns={SalesBillcolumns}
          />
        </div>
      )}
      <div className="card card-shadow mt-3 p-3">
        <div className="row">
          <div className="col-12">
            <h6>Sales Acknowledgement Entry</h6>
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <div>
              <TextAreaComponents
                id={5}
                // value={(e) => setStockReceiveNumber(e)}
                label={"Sales Acknowledgement No"}
                val={AckowledgeNo}
                isactive={true}
              />
            </div>
            <div>
              <TextAreaComponents
                id={5}
                // value={(e) => setStockReceiveNumber(e)}
                label={"Sales Order No"}
                val={
                  SalesAcknowledgementView.salesAcknowledgement.salesOrderNumber
                }
                isactive={true}
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 PurchaseOrderCard">
            <CustomDateTimePicker
              value={(props) => setAckDate(props)}
              //   isDisabled={true}
              val={AckDate}
              label={"Sales Acknowledgement Date"}
            />

            <div>
              <TextAreaComponents
                id={6}
                value={(props) => setRemarks(props)}
                label={"Remarks"}
                val={Remarks}
              />
            </div>
          </div>
        </div>
      </div>

      {/* <div className="card card-shadow p-3 mt-3"> */}

      {/* <table class="table table-striped mt-2 ">
          <thead style={{ backgroundColor: "#2d358f", color: "white" }}>
            <tr>
              <th>S/N</th>
              <th>Item Id</th>

              <th>PO Quantity</th>
              <th>Remarks</th>
            </tr>
          </thead>
          <tbody>
            {SelectedSalesOrderItemList.map((d, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{d.itemCode}</td>
                  <td>{d.qty}</td>
                  <td className="col-1">{d.remarks}</td>
                </tr>
              );
            })}
          </tbody>
        </table> */}

      {SelectedSalesOrderItemList.length > 0 ? (
        <>
          <div className="card card-shadow p-3 mt-3">
            <div>
              <h6>Selected Items</h6>
            </div>
            <ClientSiteDataTable
              dataRows={SelectedSalesOrderItemList}
              columns={ItemsUnderOrderColumns}
              // isActive={true}
              // getselectedData={getSelectedItems}
            />
          </div>
        </>
      ) : (
        ""
      )}

      {/* </div> */}

      <div className="row mt-3 ">
        <div className="col-4">
          <div className="d-flex gap-4 ">
            <button
              className="btn-yes btn-common"
              onClick={() => setAlertOpen(true)}
            >
              Save
            </button>
            <button className="btn-no btn-common">Clear</button>
            <button className="btn-Review btn-common">Review</button>
          </div>
        </div>
      </div>

      <div className="card p-3 card-shadow acknowledgementCard mt-3">
        <div>
          <h6>Sales Acknowledgement List</h6>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setSearch(props)}
              label={"Search"}
              val={Search}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onClear}>
              Clear
            </button>
          </div>
        </div>
        <DataTable
          pageState={akgpageState}
          GetPurchaseOrderNoAPi={GetSalesAcknoledgementPage}
          isLoading={akgisLoading}
          rows={akgrows}
          columns={Acknowledgementcolumns}
        />
      </div>
    </>
  );
}

export default SalesAcknowledgement;
