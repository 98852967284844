import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../components/login/Login";
import Auth from "../hook/auth/Auth";
import PageNotFound from "../components/pagenotfound/PageNotFound";
import SideBar from "../components/layout/sideBar/SideBar";
import Home from "../components/eCommerce/Home";
import ReceiveReport from "../components/transaction/stockReceive/ReceiveReport/ReceiveReport";
import IssueReport from "../components/transaction/stockIssue/IssueReport/IssueReport";
import ItemPricingDetailsReport from "../components/admin/itemSetup/ItemPricingSetup/ItemPricingDetailsReport/ItemPricingDetailsReport";
import PurchaseOrderReport from "../components/purchase/purchaseOrder/PurchaseOrederReport";
import PurchaseBillReport from "../components/purchase/purchaseBill/PurchaseBillReport";
import PurchaseAcknowledgementReport from "../components/purchase/purchaseAcknowledgement/PurchaseAcknowledgementReport";
import PurchaseBillReturnReport from "../components/purchase/purchaseBillReturn/PurchaseBillReturnReport";
import SalesOrderReport from "../components/sales/salesOrder/SalesOrderReport";
import SalesBillReport from "../components/sales/salesBill/SalesBillReport";
import SalesAcknowledgementReport from "../components/sales/salesAcknowledgement/SalesAcknowledgementReport";
import SalesReturnReport from "../components/sales/salesReturn/SalesReturnReport";
import CustomerPaymentAdjustmentReport from "../components/payment/customerPaymentAdjustment/CustomerPaymentAdjustmentReport";
import SuuplierPaymentAdjustmentReport from "../components/payment/supplierPaymentAdjustment/SupplierPaymentAdjustmentReport";
import PaymentToSupplierReport from "../components/payment/paymentToSupplier/PaymentToSupplierReport";
import CustomerPaymentReport from "../components/payment/paymentRcvCustomer/PaymentRcvCustomerReport";
// import HomePage from "../components/homepage/homepage";
import HomePage from "../components/homepage/HomePage";

function Pages(props) {
  return (
    <Routes>
      <Route path={"/login"} element={<Auth child={<Login />} />} />
      <Route path={"/admin"} element={<Auth child={<Login />} />} />
      <Route path={"/dashboard"} element={<Auth child={<SideBar />} />} />
      <Route path={"/home"} element={<Auth child={<Home />} />} />
      {/* new home page added */}
      <Route path={"/"} element={<HomePage />} />
      <Route path="/receive-report/:number" element={<ReceiveReport />} />
      <Route path="/issue-report/:number" element={<IssueReport />} />
      <Route
        path="/item-pricing-details-report/:id"
        element={<ItemPricingDetailsReport />}
      />
      <Route
        path="/purchase-order-report/:number"
        element={<PurchaseOrderReport />}
      />
      <Route
        path="/purchase-bill-report/:number"
        element={<PurchaseBillReport />}
      />
      <Route
        path="/purchase-acknowledge-report/:number"
        element={<PurchaseAcknowledgementReport />}
      />
      <Route
        path="/purchase-billReturn-report/:number"
        element={<PurchaseBillReturnReport />}
      />
      <Route
        path="/sales-order-report/:number"
        element={<SalesOrderReport />}
      />
      <Route path="/sales-bill-report/:number" element={<SalesBillReport />} />
      <Route
        path="/sales-acknowledge-report/:number"
        element={<SalesAcknowledgementReport />}
      />
      <Route
        path="/sales-return-report/:number"
        element={<SalesReturnReport />}
      />
      <Route
        path="/customerPayment-adjustment-report/:number"
        element={<CustomerPaymentAdjustmentReport />}
      />
      <Route
        path="/supplierPayment-adjustment-report/:number"
        element={<SuuplierPaymentAdjustmentReport />}
      />
      <Route
        path="/paymentTo-supplier-report/:number"
        element={<PaymentToSupplierReport />}
      />
      <Route
        path="/customer-payment-report/:number"
        element={<CustomerPaymentReport />}
      />
      <Route path={"*"} element={<PageNotFound />} />
    </Routes>
  );
}

export default Pages;
