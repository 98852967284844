/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import logo from "../../../assests/images/LoginPageLogo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const scrollTo = (id) => {
    if (id === "home") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  };

  const navlinks = (
    <>
      <li>
        <a href="#">Home</a>
      </li>
      <li>
        <a href="#community" onClick={() => scrollTo("community")}>
          Community
        </a>
      </li>
      <li>
        <a href="#service" onClick={() => scrollTo("service")}>
          Service
        </a>
      </li>
      {/* <li><a href='#procurement' onClick={() => scrollTo("procurement")}>Procurement</a></li> */}
      <li>
        <a href="#mission" onClick={() => scrollTo("mission")}>
          Mission
        </a>
      </li>
      <li>
        <a href="#vision" onClick={() => scrollTo("vision")}>
          Vision
        </a>
      </li>
      <li>
        <a href="#goals" onClick={() => scrollTo("goals")}>
          Goals
        </a>
      </li>
      <li>
        <a href="#offerings" onClick={() => scrollTo("offerings")}>
          Offerings
        </a>
      </li>
      <li>
        <Link to={"/home"}>Order</Link>
      </li>
    </>
  );

  return (
    <div className="navbar absolute z-20 bg-transparent text-white">
      <div className="navbar-start">
        <div className="dropdown">
          <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h8m-8 6h16"
              />
            </svg>
          </div>
          <ul
            tabIndex={0}
            className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-gray-100 rounded-box w-52"
          >
            {navlinks}
          </ul>
        </div>
        <div className="btn btn-ghost text-xl">
          <img
            src={logo}
            alt=""
            className="w-[30px] h-[30px] rounded-md mr-2 z-10"
          />
          <p classname="font-mono z-10">A & H Solutions</p>
        </div>
        {/* <a className="btn btn-ghost text-xl">{logo} A & H Solutions</a> */}
      </div>
      <div className="navbar-center hidden lg:flex"></div>
      <div className="navbar-end  hidden lg:flex">
        <ul className="menu menu-horizontal px-1 uppercase font-semibold">
          {navlinks}
        </ul>
        {/* <button className="btn bg-purple-700 text-white uppercase hover:bg-purple-500 border-none">
          Button
        </button> */}
      </div>
    </div>
  );
};

export default Navbar;
