import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";

import ShoppingCartIcon from "./../../../assests/images/online-shopping.png";
import AdminPanelSettingsIcon from "./../../../assests/images/manager.png";
import SecurityIcon from "./../../../assests/images/shield.png";
import SalesIcon from "./../../../assests/images/sales.png";

import "./Sidebar.css";
import Purchase from "../../purchase/Purchase";
import Admin from "../../admin/Admin";
import { getAPI } from "../../../api/screenApi/ScreenApi";
import { Link } from "react-router-dom";
import Sales from "../../sales/Sales";
import Transaction from "../../transaction/Transaction";
import transactionImg from "./../../../assests/images/transactions.png";
import paymentImg from "./../../../assests/images/payment.png";
import Payment from "../../payment/Payment";

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function SideBar(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [screenDetails, setScreenDetails] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [menuSelection, setMenuSelection] = React.useState(0);
  const changeMenu = (props) => {
    setMenuSelection(props);
  };
  React.useEffect(() => {
    console.log(screenDetails);
  }, [screenDetails]);

  React.useEffect(() => {
    setIsLoading(true);
    fetchData();
    setIsLoading(false);
  }, []);
  const fetchData = async () => {
    var data = [];
    await getAPI(1).then((res) => {
      console.log(res);
      data = res;
    });
    setScreenDetails(data);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          background: "linear-gradient(to right, #2c3e50, #4ca1af);",
          color: "white",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <div className="d-flex justify-content-between w-100">
            <Typography variant="h6" noWrap component="div">
              IMS Pro
            </Typography>
            {/* <Typography variant="h6" noWrap component="div">
              Log out
            </Typography> */}
            <Link to={"/admin"} className="logOutLink">
              {" "}
              Log out
            </Link>
          </div>
        </Toolbar>
      </AppBar>

      {!isLoading && screenDetails.length > 0 ? (
        <>
          <Drawer variant="permanent" open={open}>
            <DrawerHeader>
              <Typography>IMS Pro Admin Panel</Typography>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "rtl" ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
              {/* {screenDetails.find(x => x.screenId == 1).length} */}
              {screenDetails.find((x) => x.screenId == 1) != undefined && (
                <>
                  <ListItem
                    key={"Admin"}
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => {
                      changeMenu(0);
                    }}
                    className={`${menuSelection == 0 && "selectedMenu"}`}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={AdminPanelSettingsIcon}
                          alt=""
                          style={{ width: "24px" }}
                        />
                        {/* <AdminPanelSettingsIcon /> */}
                      </ListItemIcon>
                      <ListItemText
                        primary={"Admin"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </>
              )}
              {screenDetails.find((x) => x.screenId == 2) != undefined && (
                <>
                  <ListItem
                    key={"Security"}
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => {
                      changeMenu(1);
                    }}
                    className={`${menuSelection == 1 && "selectedMenu"}`}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={SecurityIcon}
                          alt=""
                          style={{ width: "24px" }}
                        />
                        {/* <SecurityIcon /> */}
                      </ListItemIcon>
                      <ListItemText
                        primary={"Security"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </>
              )}
              {screenDetails.find((x) => x.screenId == 1) != undefined && (
                <>
                  <ListItem
                    key={"Purchase"}
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => {
                      changeMenu(2);
                    }}
                    className={`${menuSelection == 2 && "selectedMenu"}`}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={ShoppingCartIcon}
                          alt=""
                          style={{ width: "24px" }}
                        />
                        {/* <ShoppingCartIcon /> */}
                      </ListItemIcon>
                      <ListItemText
                        primary={"Purchase"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </>
              )}

              {screenDetails.find((x) => x.screenId == 1) != undefined && (
                <>
                  <ListItem
                    key={"Sales"}
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => {
                      changeMenu(3);
                    }}
                    className={`${menuSelection == 3 && "selectedMenu"}`}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <img src={SalesIcon} alt="" style={{ width: "24px" }} />
                        {/* <ShoppingCartIcon /> */}
                      </ListItemIcon>
                      <ListItemText
                        primary={"Sales"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </>
              )}
              {screenDetails.find((x) => x.screenId == 1) != undefined && (
                <>
                  <ListItem
                    key={"Transactions"}
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => {
                      changeMenu(4);
                    }}
                    className={`${menuSelection == 4 && "selectedMenu"}`}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={transactionImg}
                          alt=""
                          style={{ width: "24px" }}
                        />
                        {/* <ShoppingCartIcon /> */}
                      </ListItemIcon>
                      <ListItemText
                        primary={"Transactions"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </>
              )}
              {screenDetails.find((x) => x.screenId == 1) != undefined && (
                <>
                  <ListItem
                    key={"Payment"}
                    disablePadding
                    sx={{ display: "block" }}
                    onClick={() => {
                      changeMenu(5);
                    }}
                    className={`${menuSelection == 5 && "selectedMenu"}`}
                  >
                    <ListItemButton
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? "initial" : "center",
                        px: 2.5,
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 3 : "auto",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src={paymentImg}
                          alt=""
                          style={{ width: "24px" }}
                        />
                        {/* <ShoppingCartIcon /> */}
                      </ListItemIcon>
                      <ListItemText
                        primary={"Payment"}
                        sx={{ opacity: open ? 1 : 0 }}
                      />
                    </ListItemButton>
                  </ListItem>
                </>
              )}
            </List>
          </Drawer>
          <Box component="main" sx={{ flexGrow: 1 }}>
            <DrawerHeader />
            {menuSelection == 2 && <Purchase />}
            {menuSelection == 0 && <Admin />}
            {menuSelection == 3 && <Sales />}
            {menuSelection == 4 && <Transaction />}
            {menuSelection == 5 && <Payment />}
          </Box>
        </>
      ) : (
        <p>Loading</p>
      )}
    </Box>
  );
}
export default SideBar;
