import React, { useEffect, useState } from "react";
import SideBar from "../layout/sideBar/SideBar";
import "./Login.css";
import TextAreaComponents from "../sharedComponent/TextAreaComponents/TextAreaComponents";
import logo from "./../../assests/images/LoginPageLogo.png";
import { loginApi } from "../../api/loginApi/LoginApi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Ripple } from "react-preloaders";
import { Lines } from "react-preloaders";

function Login(props) {
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const [loginAs, setLoginAs] = useState();
  const [rotationAngle, setRotationAngle] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    sessionStorage.clear();
    const url = window.location.href;
    if (url.includes("admin")) {
      setLoginAs(true);
    } else {
      setLoginAs(false);
    }
  }, []);

  const onSubmit = async () => {
    var isvalid = true;
    setIsLoading(true);
    if (userName == "" || userName == undefined) {
      toast.error("User name is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      isvalid = false;
    }
    if (password == "" || password == undefined) {
      toast.error("Password is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      isvalid = false;
    }
    if (isvalid) {
      var res = await loginApi(userName, password, loginAs ? 1 : 2);
      if (res == "User Not Found") {
        toast.error(res, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
      } else {
        toast.success("Login Successfull", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setIsLoading(false);
        sessionStorage.setItem("imsToken", res);
        if (loginAs) {
          window.location.href = "/dashboard";
        } else {
          window.location.href = "/home";
        }
      }
    }
  };
  return (
    <>
      <div className="backGroundLogin"></div>
      {/* <div>
        <SideBar />
      </div> */}
      <ToastContainer />

      <div className="loginCard text-align-center p-4">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <img
              src={logo}
              alt=""
              style={{ width: "5rem", borderRadius: "50%" }}
            />
          </div>
          <div className="col-12 mt-3">
            <h3
              style={{
                color: "white",
                textAlign: "center",
                fontSize: "1.2rem",
              }}
            >
              Welcome to A&H Solutions{" "}
            </h3>
          </div>
          {/* <div
            className="col-12 d-flex justify-content-center align-items-center "
            style={{ gap: "7px" }}
            onClick={() => {
              setLoginAs(!loginAs);
              setRotationAngle(rotationAngle + 180);
            }}
          >
            <h6
              className="text-center"
              style={{ color: "white", cursor: "pointer", margin: 0 }}
            >
              {loginAs ? "Login As Customer" : "Login As Admin"}
            </h6>
            <div>
              <img
                src={require("./../../assests/images/refresh.png")}
                alt=""
                style={{
                  width: "1rem",
                  transform: `rotate(${rotationAngle}deg)`,
                  transition: "transform 0.3s ease",
                }}
              />
            </div>
          </div> */}
          <div className="col-12 mt-3">
            <TextAreaComponents
              id={6}
              label={"User Name"}
              placeholder={"User Name"}
              value={(props) => {
                setUserName(props);
              }}
            />
          </div>
          <div className="col-12 mt-3">
            <TextAreaComponents
              id={7}
              label={"Password"}
              placeholder={"Password"}
              value={(props) => {
                setPassword(props);
              }}
            />
          </div>
          <div className="col-12 mt-3">
            {isLoading ? (
              <button className="btn btn-login w-100">
                <img
                  src={require("./../../assests/images/loader.gif")}
                  style={{ width: "2rem", pointerEvents: "none" }}
                />
              </button>
            ) : (
              <button
                className="btn btn-login w-100"
                onClick={() => {
                  onSubmit();
                }}
              >
                Login
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
