import axios from "axios";

const apiAddress = process.env.REACT_APP_SECRET;

export const GetPurchaseBillByNumber = async (props) => {
  var res = await axios
    .get(apiAddress + "/api/PurchaseBill/GetPurchaseBillByNumber/" + props)
    .then((response) => {
      return response.data;
    });

  return res;
};

export const GetPurchaseOrderNoAPi = async (props) => {
  var res = await axios
    .get(
      `${
        process.env.REACT_APP_SECRET
      }/api/PurchaseBill/GetPurchaseBillPaged?startRecordNo=${1}&&rowPerPage=${5}&whereClause=${"1=1"}&&sortColumn=${"number"}&&sortOrder=${"desc"}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return res;
};

export const GetPurchaseOrderByNumber = async (props) => {
  var res = await axios
    .get(apiAddress + "/api/PurchaseOrder/GetPurchaseOrderByNumber/" + props)
    .then((response) => {
      return response.data;
    });

  return res;
};

export const PostPurchaseBillAPi = async (props) => {
  var url = apiAddress + "/api/PurchaseBill";
  var res = await axios.post(url, props).then((response) => {});
  return res;
};
