// import React from "react";
import React, { useEffect, useState } from 'react';
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import { ToastContainer, toast } from "react-toastify";
//radio button imports start
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
//import "./Purpose.css";
//radio button imports end
import edit from "./../../../assests/images/edit.png";
import print from "./../../../assests/images/printing.png";
import DataTable from "../../sharedComponent/dataTable/DataTable";
import axios from "axios";
import { PostPurposeAPi } from '../../../api/adminApi/purposeApi/purposeApi';
import { json } from 'react-router-dom';



function ItemGroupSetup() {

const apiAddress = process.env.REACT_APP_SECRET;
const[Search, setSearch]=useState()
const[ItemGroupDes, setItemGroupDes]=useState('')
const[Error, setError]=useState({})
const[ItemGroupSetup, setItemGroupSetup]=useState({

    Id: 0,
    GroupName: "",
    IsActive: true,
    CreatorId: 0,
    CreationDate: "2023-10-26T06:12:17.734Z",
    UpdatorId: 0,
    UpdateDate: "2023-10-26T06:12:17.734Z"
  
});

// item group table declaration starts

  const [igisLoading, SetigIsLoading] = React.useState(false);
  const [igRows, setigRows] = React.useState([]);
  const [igpageState, setigpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const ItemGroupcolumns = [
    { field: "sn", headerName: "IS/N", width: 170 },
    { field: "groupName", headerName: "Group Name", width: 170 },
    {
      field: "status",
      headerName: "Status",
      width: 170,
    },

    {
      field: "action",
      headerName: "Action",
      width: 170,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div

          // onClick={() => {
          //   onEditClick(params.row.id);
          // }}
          >
            <img src={edit} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
// item group table declaration ends
// item group table start

  const GetItemGroupNoAPi = async (props) => {
    // setakgpageState((old) => ({ ...old, isLoading: true }));
    // console.log(props);
    // SetakgIsLoading(true);
    // await axios
    //   .get(
    //     "" +
    //       process.env.REACT_APP_SECRET +
    //       "/api/ItemGroup/GetItemGroupPaged?startRecordNo=" +
    //       props.page +
    //       "&rowPerPage=" +
    //       props.pageSize +
    //       "&whereClause=" +
    //       "1=1" +
    //       "&sortColumn=" +
    //       "id" +
    //       "&sortOrder=" +
    //       "asc" +
    //       ""
    //   )
    //   .then((response) => {
    //     console.log(response.data);
    //     setakgpageState((old) => ({
    //       ...old,
    //       isLoading: false,

    //       total: response.data._totalCount,
    //       page: akgpageState.page,
    //     }));
    //     var list = [];
    //     response.data._itemGroupViews.map((d, i) => {
    //       var obj = {
    //         id: i,
    //         sn: i + 1,
    //         groupName : d.groupName,
    //         status: d.isActive,

    //         // action: d.number,
    //       };
    //       list.push(obj);
    //     });
    //     setmeasuremntUnitRows(list);
    //     SetakgIsLoading(false);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     SetakgIsLoading(false);
    //   });
    await ItemgroupListPaged(props, "Main");
  };

  const ItemgroupListPaged = async(props,from) =>{
    if (from != "Search") {
      setigpageState((old) => ({ ...old, isLoading: true }));

     SetigIsLoading(true);

      await axios
        .get(
          "" +
          process.env.REACT_APP_SECRET +
          "/api/ItemGroup/GetItemGroupPaged?startRecordNo=" +
          props.page +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          "1=1" +
          "&sortColumn=" +
          "id" +
          "&sortOrder=" +
          "asc" +
          ""
        )
        .then((response) => {
          console.log(response.data);
          setigpageState((old) => ({
            ...old,
            isLoading: false,
  
            total: response.data._totalCount,
            page: igpageState.page,
          }));
          var list = [];
          response.data._itemGroupViews.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              groupName : d.groupName,
              status: d.isActive,
  
              // action: d.number,
            };
            list.push(obj);
          });
          setigRows(list);
          SetigIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetigIsLoading(false);
        });
      }
      else{

        var where=""
        if(!!props.whereClause== false || props.whereClause==undefined){
          where="1=1"
          setSearch("");
        }
        else{
          where=props.whereClause
        }
        console.log(where)
  
      // const newString = "'" + Search + "'";
      // const condition = "groupname=" + newString;
      setigpageState((old) => ({ ...old, isLoading: true }));

      SetigIsLoading(true);

      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/ItemGroup/GetItemGroupPaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            where +
            "&sortColumn=" +
            "id" +
            "&sortOrder=" +
            "asc" +
            ""
        )
        .then((response) => {
          console.log(response.data);
          setigpageState((old) => ({
            ...old,
            isLoading: false,
  
            total: response.data._totalCount,
            page: igpageState.page,
          }));
          var list = [];
          response.data._itemGroupViews.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              groupName : d.groupName,
              status: d.isActive,
  
              // action: d.number,
            };
            list.push(obj);
          });
          setigRows(list);
          SetigIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetigIsLoading(false);
        });
      } 
    };


const onSearch = async () => {
      var prop= Search
      if(Search == "" || Search == undefined) {
        toast.error("Search is Empty", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
        else
        {
          setSearch(prop);
          var upperText = prop.toUpperCase();
          const newString = "'%" + upperText + "%'";
          const condition = "upper(groupname) like " + newString;
          prop = condition;
        }
    
        var obj = {
          page: 0,
          pageSize: 10,
          whereClause: prop,
        };

      await ItemgroupListPaged(obj, "Search");
    };

    const onClear = async () => {
      var props = {
        page: 0,
        pageSize: 10,
      };
      setSearch("");
      await ItemgroupListPaged(props, "Main");
    };

// Item group table end
// save button starts
const onReset= async() =>{

  setItemGroupDes("");
  console.log("emptied")
};
  const onSave = async () => {
    
    if (!!ItemGroupDes == false || ItemGroupDes == undefined) {
      toast.error("Group Name is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else{
      
      ItemGroupSetup.GroupName= ItemGroupDes;
      var url = apiAddress + "/api/ItemGroup";
      await axios.post(url,ItemGroupSetup)
      .then((response) => {
        toast.success("Insert Successfull", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });
      })
      onReset();
    console.log("Inserted")
    var props = {
      page: 0,
      pageSize: 10,
    };
    await ItemgroupListPaged(props, "Main");
    console.log("Api hit")

      console.log("not hit")
    }
  };
// save button ends

  return (
    <>
      <div className="card card-shadow p-3">
        <div className="row ">
          <div className="col-12 col-md-5 col-lg-5">
          <ToastContainer />
            <div>
              <h5>Item Group</h5>
            </div>
            <TextAreaComponents
              id={6}
              value={(e) => setItemGroupDes(e)}
              label={"Group Description"}    
              val={ItemGroupDes}
              //error={true}          
              //   val={PurchaseOrderNumber}
            />
            <div className='non-valid mt-2'><span>{Error.GroupName}</span></div>
            <div className="mt-2">
              <FormControl className="d-flex flex-row gap-3">
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  className="mt-2"
                >
                  Status
                </FormLabel>
                <RadioGroup
                  row
                  defaultValue={"active"}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  // onChange={purchaseOrderTypeRadio}
                >
                  <FormControlLabel
                    value="active"
                    control={<Radio />}
                    label="Active"
                  />
                  <FormControlLabel
                    value="inactive"
                    control={<Radio />}
                    label="Inactive"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="row mt-3">
              <div className="col-6"></div>
              <div className="col-6">
                <div className="d-flex gap-4 ">
                  <button onClick={()=>{onSave()}} className="btn-yes btn-common">Save</button>
                  <button onClick={()=>{onReset()}} className="btn-no btn-common">Reset</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7 col-lg-7">
            <div>
              <h5>Item Group List</h5>
            </div>
            <div className='row'>
            <div className='mb-3 col-md-6'>
                <TextAreaComponents id={6} value={(e)=>setSearch(e)} label={"Search"} val={Search}/>
            </div>
            <div className="col-md-6 d-flex gap-3 ">
                  <button className="btn-yes btn-common"onClick={onSearch}>Search</button>
                  <button className="btn-no btn-common"onClick={onClear}>Reset</button>
                </div>
            </div>
            <div>
              <DataTable
                pageState={igpageState}
                GetPurchaseOrderNoAPi={GetItemGroupNoAPi}
                isLoading={igisLoading}
                rows={igRows}
                columns={ItemGroupcolumns}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ItemGroupSetup;
