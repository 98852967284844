import React, { useEffect, useState } from "react";
//import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { GetAllItemApi } from "../../../api/adminApi/itemSetpAPi/ItemSetupApi";
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import { GetAllModelApi } from "../../../api/adminApi/ModelApi";
import { ToastContainer, toast } from "react-toastify";
import { GetAllDesignationApi } from "../../../api/adminApi/designationApi/DesingnationAPI";
import { GetAllCustomerApi } from "../../../api/adminApi/customer/CustomerApi";

//radio button imports start
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import DataTable from "../../sharedComponent/dataTable/DataTable";
//radio button imports end
import axios from "axios";
import edit from "./../../../assests/images/edit.png";
import { GetAllVatApi } from "../../../api/adminApi/VatApi";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
//import { GetAllCustomerApi } from "../../../api/adminApi/customer/CustomerApi";
//import "./EmployeeSetup.css"


function CustomerUserSetup() {

  const apiAddress = process.env.REACT_APP_SECRET;
  //AutoComplete  states start
  const [CustomerList, setCustomerList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [vatList, setVatList] = useState([]);
  const[Error, setError]=useState({})
  const[Search, setSearch]=useState()
  
  const [UserName, setUserName] = useState([]);
  const [UserRole, setUserRole] = useState([]);
  const [UserPassword, setUserPassword] = useState([]);
  const [UserConPassword, setUserConPassword] = useState([]);
  const [SelectedCustomer, setSelectedCustomer] = useState([]);
  const [value, setValue] = React.useState('one');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
  };
  //Supplier Field Declaration End

  const handleCustomerSelect = (e) => {
    setSelectedCustomer(e);
    console.log(e);
    };

    const GetAllCustomer = async (e) => {
        var list = [];
        var res = await GetAllCustomerApi();
        res.map((d, i) => {
            var obj = { id: d.id , title: d.customerName };
            list.push(obj);
          });
        setCustomerList(list);
        console.log(res);
        console.log(list);
      };

const[CustomersLoginSetup, setCustomersLoginSetup]=useState({
      UserId: 0,
      CustomerId: 0,
      RoleId: 4,
      Username: "",
      Password: "",
      IsActive: true,
      CreatorId: 0,
      CreateDate: "2023-10-30T07:09:42.998Z",
      UpdatorId: 0,
      UpdateDate: "2023-10-30T07:09:42.998Z"
});



  //Api calling section start
  //Api calling section end
  useEffect(() => {
    //GetAllDesignation();
    GetAllCustomer();
    onReset();
    //setToggleState(1);
  }, []);

  //Acknowledgement table start
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [measuremntUnitRows, setmeasuremntUnitRows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const MeasurmentUnitcolumns = [
    { field: "sn", headerName: "IS/N", width: 60 },
    {
      field: "username",
      headerName: "User Name",
      width: 120,
    },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 120,
    },
    {
        field: "isActive",
        headerName: "is Active",
        width: 150,
      },
      {
        field: "createDate",
        headerName: "Create Date",
        width: 120,
      },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <div className=" gap-3" style={{ gap: "10px" }}>
          <div

          // onClick={() => {
          //   onEditClick(params.row.id);
          // }}
          >
            <img src={edit} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      
    },
  ];

  const GetPurchaseOrderNoAPi = async (props) => {
    setakgpageState((old) => ({ ...old, isLoading: true }));
    console.log(props);
    SetakgIsLoading(true);
    var where=""
    if(!!props.whereClause== false || props.whereClause==undefined){
      where="1=1"
      setSearch("");
    }
    else{
      where=props.whereClause
    }
    console.log(where)
    await axios
      .get(
        "" +
          process.env.REACT_APP_SECRET +
          "/api/Customer/GetCustomerLoginPaged?startRecordNo=" +
          props.page +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          where +
          "&sortColumn=" +
          "userid" +
          "&sortOrder=" +
          "desc" +
          ""
      )
      .then((response) => {
        console.log(response.data);
        setakgpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: akgpageState.page,
        }));
        var list = [];
        response.data._customerLoginsPagedViews.map((d, i) => {
            var obj = {
                id: i,
                sn: i + 1,
                username: d.username,
                customerName: d.customerName,
                isActive: d.isActive,
                createDate: d.createDate
              };
          list.push(obj);
        });
        setmeasuremntUnitRows(list);
        SetakgIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        SetakgIsLoading(false);
      });
  };
  //Acknowledgement table end

  //search starts

  const handleSearch = (prop) => {
    var search = "";
    setSearch(prop);
  };

  const onsearchClick = () => {
    var prop = Search;
    if (prop == null || prop == undefined || prop == "") {
      toast.error("Search is Empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      //prop = "1=1";
      setSearch(prop);
    } else {
      //setTempchipText(prop);
      //setsearchTexttemp(prop);
      setSearch(prop);
      var upperText = prop.toUpperCase();
      const newString = "'%" + upperText + "%'";
      const condition = "upper(username) like " + newString;
      prop = condition;
    }
    
    var obj = {
      page: 0,
      pageSize: 10,
      whereClause: prop,
    };
    GetPurchaseOrderNoAPi(obj);
  };
  //search function start

  const onClear = async () => {
  if(!!Search == false){
  console.log("already empty")
  }
  else{
    setSearch("");
    console.log("emptied")
  }
    //var prop="";

    var obj = {
      page: 0,
      pageSize: 10,
      whereClause: "1=1",
    };
    GetPurchaseOrderNoAPi(obj);

    console.log("api called")

  }
  //search end
  const onReset = async(e) =>{
    setSelectedCustomer("");
    setUserName("");
    setUserConPassword("");
    setUserPassword("");
    console.log("emptied");

  }
  const onSave = async () => {
        console.log("save");
      if (!!SelectedCustomer == false ) {
        toast.error("Select Customer", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("hit")
      }
      else if (!!UserName == false ) {
        toast.error("User Name Is Required", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("hit")
      }
      else if (!!UserPassword == false) {
        toast.error("User Password Is Required", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("hit")
      }
      else if (!!UserConPassword == false) {
        toast.error("User Confirm Password Is Required", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("hit")
      }
      else if (UserConPassword != UserPassword) {
        toast.error("Passwords Do Not Match", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("hit")
      }
    else{

      CustomersLoginSetup.Username=UserName;
      CustomersLoginSetup.Password=UserPassword;
      CustomersLoginSetup.CustomerId= SelectedCustomer.id;

      var url = apiAddress + "/api/Customer/UserCheck";
      await axios.post(url,CustomersLoginSetup)
      .then((response) => {
        toast.success("New User Created", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });})
      .catch((response)=>{
        toast.error("User Exists", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      console.log(response)});
      onReset();
      console.log("Inserted")
      var obj = {
        page: 0,
        pageSize: 10,
        whereClause: "1=1",
      };
      GetPurchaseOrderNoAPi(obj);

    console.log("api called")

      console.log("not hit")
      console.log(CustomersLoginSetup)
    }
  };





  return (
    <div>  
    <div>
    <ToastContainer />
    <div className="card card-shadow p-3">
    <div className="d-flex">
        <h5 className="">Customer User Entry</h5>
      </div>
      
      <div>
      
      <div><div className="row mt-2 ">
        <div className="col-12 col-md-6 col-lg-4 d-flex flex-column gap-3">
        <div><AutoCompleteMultiSelect
            datas={CustomerList}
            value={handleCustomerSelect}
            id={1}
            label={"Select Customer"}
            getOptionLabel={(option) => option.title}
            //val={SelectedCustomer}
    
            /></div>
          <TextAreaComponents
            id={6}
            value={(e) => setUserName(e)}
            label={"User Name"}
            val={UserName}
        
          />
            <TextAreaComponents
            id={6}
            value={(e) => setUserPassword(e)}
            label={"Password"}
            val={UserPassword}
            
          />
            <TextAreaComponents
            id={6}
            value={(e) => setUserConPassword(e)}
            label={"Confirm Password"}
            val={UserConPassword}
            
          />
          <div className="">
          <FormControl className="d-flex flex-row justify-content gap-3">
          <FormLabel id="demo-row-radio-buttons-group-label" className="mt-2">
            Status
          </FormLabel>
          <RadioGroup
            row
            defaultValue={"active"}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="active"
              control={<Radio />}
              label="Active"
            />
            <FormControlLabel
              value="inactive"
              control={<Radio />}
              label="Inactive"
            />
          </RadioGroup>
          </FormControl>
          </div>
        </div>
      </div>

      </div>

      <div className="row mt-3 justify-content-start">
        <div className="col-12"></div>
        <div className="col-6">
          <div className="d-flex gap-4 ">
            <button onClick={()=>{onSave()}} className="btn-yes btn-common">Save</button>
            <button className="btn-no btn-common" onClick={(e)=> {onReset(e) }}>Reset</button>
          </div>
        </div>
      </div></div>




      </div>
      
        </div>

        <div>
        <div></div>
    </div>
      <div className="card card-shadow p-3 mt-3">
      <div className="col-12  ">
        <div className="col-md-6">
          <h5>User List</h5>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => handleSearch(props)}
              label={"Search"}
              val={Search}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
          <button className="btn-search btn-common" onClick={ ()=> {onsearchClick()}} >
              Search
            </button>
            <button className="btn-search btn-common" onClick={ ()=> {onClear()}}>
              Clear
            </button>
          </div>
        </div>
        <div className="mt-2">
          <DataTable
            pageState={akgpageState}
            GetPurchaseOrderNoAPi={GetPurchaseOrderNoAPi}
            isLoading={akgisLoading}
            rows={measuremntUnitRows}
            columns={MeasurmentUnitcolumns}
          />
        </div>
      </div>
      </div>
    </div>
  );
}

export default CustomerUserSetup;
