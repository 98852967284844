import React, { useEffect, useState } from "react";
import checkMark from "./../../../assests/images/check-mark.png";
import edit from "./../../../assests/images/edit.png";
import print from "./../../../assests/images/printing.png";
import DataTable from "../../sharedComponent/dataTable/DataTable";
import axios from "axios";
import dayjs from "dayjs";
import TextAreaComponents from "./../../sharedComponent/TextAreaComponents/TextAreaComponents";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import "./PurchaseAcknowledgement.css";
import { PostPurchaseAcknowledgementApi } from "../../../api/purchaseAcknowledgement/PurchaseAcknowledgementApi";
import { GetSupplierAPi } from "../../../api/screenApi/purchaseOrder/purchaseOrderApi";
import { GetPurchaseBillByNumber } from "../../../api/screenApi/purchaseBill/purchaseBillApi";
import SweetAlertModal from "../../sharedComponent/SweetAlertModal/SweetAlertModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import finalPropsSelectorFactory from "react-redux/es/connect/selectorFactory";
import { EntryNumberGenarator } from "../../sharedComponent/CommonMethods";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";

function PurchaseAcknowledegement() {
  const apiAddress = process.env.REACT_APP_SECRET;
  const [BillSearch, setBillSearch] = useState();
  const [Search, setSearch] = useState();
  const [AlertOpen, setAlertOpen] = useState(false);
  const [DateTime, setDateTime] = useState(dayjs());
  const [billDate, setBillDate] = useState();
  const [selectedBilNo, setSelectedBillNo] = useState();
  const [PurchaseAcknowledgeNo, setPurchaseAcknowledgeNo] = useState();
  const [selectedBillRow, setSelectedBillRow] = useState();
  const [supplierList, setSupplierList] = useState([]);
  const [selectedSupplier, setSelectedSuppllier] = useState();
  const [Remarks, setRemarks] = useState();
  const [PurchaseBillDetailsList, setPurchaseBillDetailsList] = useState([]);
  const [AutocompleteClear, setAutocompleteClear] = useState(false);

  //validation toaster start
  const errorToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  //validation toaster end

  //Acknowledgement table start
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [akgrows, setAkgrows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const Acknowledgementcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "akgno", headerName: "Acknowledgment No", flex: 1 },
    {
      field: "akgdate",
      headerName: "Acknowledgment Date",
      renderCell: (params) => <span>{DateFormater(params.row.akgdate)}</span>,
      flex: 1,
    },
    { field: "supplier", headerName: "Supplier Name", flex: 1 },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              window.open(
                "/purchase-acknowledge-report/" + params.row.akgno,
                "popup",
                "width=850,height=550,left=280,top=80"
              );
            }}
          >
            <img src={print} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const OrderListPaged = async (props, from) => {
    if (from != "Search") {
      setakgpageState((old) => ({ ...old, isLoading: true }));

      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseAcknowledgement/GetPurchaseAcknowledgementPaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          //purchase acknowledgement number generator start

          var entryNo = EntryNumberGenarator(
            response.data._purchaseAcknowledgement[0].number,
            "INV1-PA-"
          );
          setPurchaseAcknowledgeNo(entryNo);

          //purchase acknowledgement number genarator end
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data._purchaseAcknowledgement.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              akgno: d.number,
              akgdate: d.acknowledgmentDate,
              supplier: d.supplierName,
              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    } else {
      const newString = "'" + Search + "'";
      const condition = "Number=" + newString;
      setakgpageState((old) => ({ ...old, isLoading: true }));

      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseAcknowledgement/GetPurchaseAcknowledgementPaged?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data._purchaseAcknowledgement.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              akgno: d.number,
              akgdate: d.acknowledgmentDate,
              supplier: d.supplierName,
              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    }
  };
  const onSearch = async () => {
    await OrderListPaged("props", "Search");
  };
  const onClear = async () => {
    var props = {
      page: 0,
      pageSize: 10,
    };
    setSearch("");
    await OrderListPaged(props, "Main");
  };

  const GetPurchaseOrderNoAPi = async (props) => {
    await OrderListPaged(props, "Main");
  };
  //Acknowledgement table end

  //bill table start
  const Billcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    {
      field: "billNo",
      headerName: "Bill No",

      flex: 1,
    },
    {
      field: "billDate",
      headerName: "Bill Date",
      renderCell: (params) => <span>{DateFormater(params.row.billDate)}</span>,
      flex: 1,
    },
    { field: "supplier", headerName: "Supplier Name", flex: 1 },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onCheckClick(params.row);
            }}
          >
            <img src={checkMark} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
  const [billrows, setBillrows] = React.useState([]);

  const [isLoading, SetisLoading] = React.useState(false);

  const [pageState, setpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const onBillListPage = async (props, from) => {
    if (from != "Search") {
      setpageState((old) => ({ ...old, isLoading: true }));

      SetisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseBill/GetPurchaseBillPagedForAcknowledgement?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: pageState.page,
          }));
          var list = [];
          response.data._purchaseBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billNo: d.number,
              billDate: d.billDate,
              supplier: d.supplierName,
              remarks: d.remarks,
              fulllRow: d,
            };
            list.push(obj);
          });
          setBillrows(list);
          SetisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetisLoading(false);
        });
    } else {
      const newString = "'" + BillSearch + "'";
      const condition = "Number=" + newString;
      setpageState((old) => ({ ...old, isLoading: true }));

      SetisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseBill/GetPurchaseBillPagedForAcknowledgement?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: pageState.page,
          }));
          var list = [];
          response.data._purchaseBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billNo: d.number,
              billDate: d.billDate,
              supplier: d.supplierName,
              remarks: d.remarks,
              fulllRow: d,
            };
            list.push(obj);
          });
          setBillrows(list);
          SetisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetisLoading(false);
        });
    }
  };
  const onBillSearch = async () => {
    await onBillListPage("props", "Search");
  };
  const onBillSearchClear = async () => {
    setBillSearch("");
    var props = {
      page: 0,
      pageSize: 10,
    };
    await onBillListPage(props, "Main");
  };

  const GetPurchaseBillPage = async (props) => {
    await onBillListPage(props, "Main");
  };

  //bill table end

  //Api calling start
  const GetSupplier = async () => {
    var list = [];
    var res = await GetSupplierAPi();

    setSupplierList(res);
  };
  //Api calling end
  useEffect(() => {
    GetSupplier();
  }, []);
  const onCheckClick = async (data) => {
    setSelectedBillRow(data);
    setBillDate(dayjs(data.billDate));
    setSelectedBillNo(data.billNo);
    var res = await GetPurchaseBillByNumber(data.billNo);
    setPurchaseBillDetailsList(res.purchaseBillDetailsList);
  };
  const [purchaseAcknowledgement, setpurchaseAcknowledgement] = useState({
    PurchaseAcknowledgement: {
      Number: "123",
      AcknowledgmentDate: dayjs(),
      PurchaseBillNumber: selectedBillRow?.billNo,
      ManualBillNo: selectedBillRow?.manualBillNo,
      ManualBillDate: selectedBillRow?.manualBillDate,
      SupplierId: selectedSupplier?.id,
      SupplierName: selectedSupplier?.supplierName,
      Remarks: Remarks,
      AIT: selectedBillRow?.ait,
      CounterId: selectedBillRow?.counterId,
      CreatorId: selectedBillRow?.creatorId,
      CreationDate: selectedBillRow?.creationDate,
      UpdatorId: selectedBillRow?.updatorId,
      UpdateDate: selectedBillRow?.updateDate,
    },
    PurchaseAcknowledgementDetailsList: [{}],
  });

  const onSave = () => {
    setAlertOpen(false);
    var valid = true;
    purchaseAcknowledgement.PurchaseAcknowledgement.Number = "123";
    purchaseAcknowledgement.PurchaseAcknowledgement.AcknowledgmentDate =
      dayjs();
    purchaseAcknowledgement.PurchaseAcknowledgement.PurchaseBillNumber =
      selectedBillRow?.fulllRow.number;
    purchaseAcknowledgement.PurchaseAcknowledgement.ManualBillNo =
      selectedBillRow?.fulllRow.manualBillNo;
    purchaseAcknowledgement.PurchaseAcknowledgement.ManualBillDate =
      selectedBillRow?.fulllRow.manualBillDate;
    purchaseAcknowledgement.PurchaseAcknowledgement.SupplierId =
      selectedSupplier?.id;
    purchaseAcknowledgement.PurchaseAcknowledgement.SupplierName =
      selectedSupplier?.supplierName;
    purchaseAcknowledgement.PurchaseAcknowledgement.Remarks = Remarks;
    purchaseAcknowledgement.PurchaseAcknowledgement.AIT =
      selectedBillRow?.fulllRow.ait;
    purchaseAcknowledgement.PurchaseAcknowledgement.CounterId =
      selectedBillRow?.fulllRow.counterId;
    purchaseAcknowledgement.PurchaseAcknowledgement.CreatorId =
      selectedBillRow?.fulllRow.creatorId;
    purchaseAcknowledgement.PurchaseAcknowledgement.CreationDate =
      selectedBillRow?.fulllRow.creationDate;
    purchaseAcknowledgement.PurchaseAcknowledgement.UpdatorId =
      selectedBillRow?.fulllRow.updatorId;
    purchaseAcknowledgement.PurchaseAcknowledgement.UpdateDate =
      selectedBillRow?.fulllRow.updateDate;

    var list = [];
    PurchaseBillDetailsList.map((d, i) => {
      var obj = {
        Id: "123",
        AcknoledgmentNumber: "123",
        ItemCode: d.itemId,
        Qty: d.qty,
        UnitPrice: d.unitPrice,
        Amount: d.amount,
        Discount: d.discount,
        AfterDiscount: d.afterDiscount,
        SD: d.sd,
        VAT: d.vat,
      };
      list.push(obj);
    });
    purchaseAcknowledgement.PurchaseAcknowledgementDetailsList = list;
    if (!!selectedSupplier == false) {
      valid = false;
      errorToast("Suplier not selected");
    }
    if (!!Remarks == false) {
      valid = false;
      errorToast("Remarks is required");
    }
    if (
      purchaseAcknowledgement.PurchaseAcknowledgementDetailsList.length == 0
    ) {
      valid = false;
      errorToast("No purchase bill selected");
    }
    if (valid) {
      setSelectedSuppllier(null);
      setSelectedBillRow({});
      setRemarks("");
      setAutocompleteClear(true);
      setBillDate(null);

      var url = apiAddress + "/api/PurchaseAcknowledgement";
      axios.post(url, purchaseAcknowledgement).then((response) => {
        successToast("Saved successfully");
        setSelectedSuppllier(null);
        setSelectedBillRow(null);
        setRemarks("");
        setAutocompleteClear(true);
        setBillDate(null);
        var props = {
          page: 0,
          pageSize: 10,
        };
        OrderListPaged(props, "Main");
        onBillListPage(props, "Main");
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <SweetAlertModal
        open={AlertOpen}
        headerText={"Are you sure?"}
        bodyText={"You want to save?"}
        onyes={onSave}
      />
      <div>
        <h5>Purchase Acknowledgement</h5>
      </div>
      <div className="card p-3 card-shadow acknowledgementCard">
        <div>
          <h6>Purchase Bill List</h6>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setBillSearch(props)}
              label={"Search"}
              val={BillSearch}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onBillSearch}>
              Search
            </button>
            <button
              className="btn-search btn-common"
              onClick={onBillSearchClear}
            >
              Clear
            </button>
          </div>
        </div>
        <DataTable
          pageState={pageState}
          GetPurchaseOrderNoAPi={GetPurchaseBillPage}
          isLoading={isLoading}
          rows={billrows}
          columns={Billcolumns}
        />
      </div>

      <div className="mt-3">
        <div className="card card-shadow PurchaseBillCancellationCard p-3">
          <div>
            <h6>Purchase Acknowledgement Entry</h6>
          </div>
          <div className="d-flex flex-column gap-2">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2">
                <TextAreaComponents
                  id={5}
                  value={(e) => setPurchaseAcknowledgeNo(e)}
                  label={"Acknowledgement no"}
                  val={PurchaseAcknowledgeNo}
                  isactive={true}
                />
                <TextAreaComponents
                  id={5}
                  //   value={(e) => setPurchaseOrderNumber(e)}
                  label={"Chalan no"}
                  val={selectedBillRow?.billNo}
                  isactive={true}
                />

                <AutoCompleteMultiSelect
                  id={1}
                  datas={supplierList}
                  getOptionLabel={(option) => option.supplierName}
                  value={(e) => setSelectedSuppllier(e)}
                  label={"Supplier"}
                  placeholder={"--Supplier"}
                  clear={AutocompleteClear}
                />
              </div>
              <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2">
                <CustomDateTimePicker
                  //  value={(e) => setDateTime(e)}
                  defaultValue={DateTime}
                  label={"Acknowledgement Date"}
                  isDisabled={true}
                />
                <CustomDateTimePicker
                  //  value={(e) => setDateTime(e)}
                  label={"Chalan Date"}
                  val={billDate}
                />
                <TextAreaComponents
                  id={5}
                  value={(e) => setRemarks(e)}
                  label={"Remarks"}
                  // defaultValue={billDate}
                  val={Remarks}
                />
              </div>
            </div>
            {/* <div className="col-12">
              <TextAreaComponents
                id={2}
                //   value={(e) => setPurchaseOrderNumber(e)}
                label={"Cancel Reason"}
              />
            </div> */}
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-8"></div>
        <div className="col-4 d-flex gap-2">
          <button
            className="btn-yes btn-common"
            onClick={() => setAlertOpen(true)}
          >
            Save
          </button>
          <button className="btn-no btn-common">Clear</button>
        </div>
      </div>

      <div className="card p-3 card-shadow acknowledgementCard mt-3">
        <div>
          <h6>Purchase Acknowledgement List</h6>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setSearch(props)}
              label={"Search"}
              val={Search}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onClear}>
              Clear
            </button>
          </div>
        </div>
        <DataTable
          pageState={akgpageState}
          GetPurchaseOrderNoAPi={GetPurchaseOrderNoAPi}
          isLoading={akgisLoading}
          rows={akgrows}
          columns={Acknowledgementcolumns}
        />
      </div>
    </>
  );
}

export default PurchaseAcknowledegement;
