import React, { useEffect } from 'react';
import AOS from "aos";

const Offerings = () => {
    useEffect(() => {
        AOS.init({
          easing: "ease-in-sine",
        });
      }, []);
    return (
        <div>
            <div id='offerings' className="hero min-h-screen" style={{ backgroundImage: 'url(https://www.docpath.com/wp-content/uploads/adopting-industry-specific-document-software-solutions.jpg)' }}>
                <div className="hero-overlay bg-opacity-60"></div>
                <div className="hero-content text-center text-neutral-content">
                    <div className="max-w-xl text-white" 
                    data-aos="fade-up"
                    >
                        <h2 className='font-bold text-4xl mb-3 text-center'>Our Offerings</h2>
                        <h2 className='font-semibold text-xl mb-10 text-center'>Empowering Your Procurement Process</h2>
                        <p className="mb-5">
                            At A&H Solutions, we go beyond traditional procurement services. We provide a comprehensive online platform dedicated exclusively to our valued clients. Our user-friendly software is designed to streamline and optimize your procurement processes, offering unparalleled control and efficiency.
                        </p>
                    </div>
                </div>
            </div>

            <div className='relative -top-32 lg:-top-44' 
            data-aos="fade-up"
            >
                <div className='grid grid-cols-1 md:grid-cols-2 lg:w-[1200px] mx-auto pl-5 lg:pl-28 mt-10 lg:mt-0'>
                    <div className="card w-96 bg-gray-100 shadow-xl h-[500px]">
                        <div className="card-body">
                            <h2 className="card-title">Personalized Accounts</h2>
                            <p>Each client receives a dedicated account with unique credentials tailored to their department's specific needs. This allows seamless collaboration between departments while maintaining data security and confidentiality.</p>
                            <p><span className='font-bold'>Purchase Department ID: </span>Place orders, track deliveries in real-time, and receive products effortlessly.</p>
                            <p><span className='font-bold'>Accounts Department ID: </span>Monitor payments, track invoices, and manage financial aspects with precision.</p>
                        </div>
                    </div>
                    <div className="card w-96 bg-gray-100 shadow-xl h-[500px] mt-8 lg:mt-0">
                        <div className="card-body">
                            <h2 className="card-title">Automated Reporting</h2>
                            <p>Say goodbye to manual data compilation and analysis. Our software generates detailed reports at regular intervals, providing insights into your procurement activities.</p>
                            <p><span className='font-bold'>Financial Overview: </span>Track dues, cleared payments, and overall financial health effortlessly.</p>
                            <p><span className='font-bold'>Monthly Expenditure: </span>Gain insights into your monthly procurement expenditure, aiding in budgeting and financial planning.</p>
                            <p><span className='font-bold'>Consumption Analysis: </span>Understand your monthly consumption patterns, identifying trends and optimizing future orders.</p>
                        </div>
                    </div>
                    <div className="card w-96 bg-gray-100 shadow-xl mt-8">
                        <div className="card-body">
                            <h2 className="card-title">Quotation Comparison for Strategic Decision-Making</h2>
                            <p>For substantial or high-value orders, such as industrial machinery, our platform provides a unique feature. We offer 2–3 quotations, enabling clients to compare different brands and price ranges. This empowers decision-makers with the information needed to make strategic choices that align with their goals and budget.</p>
                        </div>
                    </div>
                    <div className="card w-96 bg-gray-100 shadow-xl mt-8">
                        <div className="card-body">
                            <h2 className="card-title">Cost-Effective Solution</h2>
                            <p>By leveraging our platform, you not only enhance efficiency but also reduce the need for additional personnel in your procurement department. Our automated system is a cost-effective solution designed to simplify your workflow and maximize productivity.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Offerings;