import axios from "axios";
import React from "react";

const apiAddress = process.env.REACT_APP_SECRET;

export const GetAllItemApi = async (props) => {
  var res = await axios.get(apiAddress + "/api/Item").then((response) => {
    return response.data;
  });

  return res;
};

export const GetItemUnitPrice = async (itemId) => {
  var res = await axios
    .get(apiAddress + "/api/Item/GetItemUnitPriceById?itemId=" + itemId + "")
    .then((response) => {
      return response.data.unitPrice;
    });
  console.log(res);
  return res;
};

export const GetItemPricingDetailsById = async (id) => {
  var res = await axios
    .get(apiAddress + "/api/Item/getItemPricingDetailsById?id=" + id)
    .then((response) => {
      return response;
    });
  console.log(res);
  return res;
};

export const PostItemApi = async (props) => {
  var res = await axios
    .post(apiAddress + "/api/Item", props)
    .then((response) => {
      return response;
    });

  return res;
};

export const GetItemByIdApi = async (props) => {
  var res = await axios
    .get(apiAddress + "/api/Item/GetItemById/" + props)
    .then((response) => {
      return response.data;
    });

  return res;
};
