import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import checkMark from "./../../../assests/images/delete.png";
import { ToastContainer, toast } from "react-toastify";
import "./History.css";
import axios from "axios";
import jwtDecode from "jwt-decode";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Pagination,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import moment from "moment/moment";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function History(props) {
  const [open, setOpen] = React.useState(props.isOpen);
  const [expanded, setExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const apiAddress = process.env.REACT_APP_SECRET;

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const [itemGroups, setitemGroups] = React.useState([]);
  const GetHistoryAPi = async (props) => {
    setIsLoading(true);
    setakgpageState((old) => ({ ...old, isLoading: true }));
    var data = sessionStorage.getItem("imsToken");
    var userInfo = jwtDecode(data);
    props.whereClause = "creatorid=" + userInfo.UserId;
    await axios
      .get(
        "" +
          process.env.REACT_APP_SECRET +
          "/api/SalesOrder/GetHistorygetPaged?startRecordNo=" +
          props?.page +
          "&rowPerPage=10" +
          "&whereClause=" +
          props?.whereClause +
          "&sortColumn=" +
          "creationdate" +
          "&sortOrder=" +
          "desc" +
          ""
      )
      .then((response) => {
        setakgpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: akgpageState.page,
        }));
        setitemGroups(response.data.histories);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        // SetakgIsLoading(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };

  React.useEffect(() => {
    var data = sessionStorage.getItem("imsToken");
    var userInfo = jwtDecode(data);
    var obj = {
      page: 0,
      pageSize: 10,
      whereClause: "creatorid=" + userInfo.UserId,
    };
    GetHistoryAPi(obj);
  }, []);

  const formatDate = (props) => {
    const parsedDate = moment(props, "YYYY-MM-DDTHH:mm:ss");

    if (parsedDate.isValid()) {
      const formattedDate = parsedDate.format("MMMM Do, YYYY");
      return <>{formattedDate}</>;
    } else {
      return "Invalid Date";
    }
  };

  const getCount = (props) => {
    const sum = props.reduce((accumulator, object) => {
      return accumulator + object.suggestedPrice * object.requestedQty;
    }, 0);
    return <>{sum}</>;
  };

  const onDeleteClick = (number) => {
    axios
      .delete(apiAddress + "/api/SalesOrder/Delete?salesOrderNumber=" + number)
      .then((res) => {
        toast.success("Delete Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });

    var data = sessionStorage.getItem("imsToken");
    var userInfo = jwtDecode(data);
    var obj = {
      page: 0,
      pageSize: 10,
      whereClause: "creatorid=" + userInfo.UserId,
    };
    GetHistoryAPi(obj);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "gray" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Order Details
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center h-100">
              <img src={require("./../../../assests/images/loader.gif")} />
            </div>
          ) : itemGroups.length > 0 ? (
            <div>
              <h3 className="text-center mt-3">Your recent order details</h3>
              <div className="px-md-5 p-3">
                {itemGroups.map((dt, i) => {
                  return (
                    <>
                      <Accordion
                        expanded={expanded === dt.number}
                        onChange={handleChange(dt.number)}
                      >
                        <AccordionSummary
                          expandIcon={<GridExpandMoreIcon />}
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography sx={{ width: "100%" }}>
                            Order{" "}
                            <span style={{ color: "#3498DB" }}>
                              #{dt.number}
                            </span>{" "}
                            <br />
                            <span style={{ fontSize: ".8rem" }}>
                              Placed on{" "}
                              {DateFormater(dt.orderDate, "MM-DD-YYYY")}
                            </span>
                            <div
                              className={`${
                                dt.status === "Pending"
                                  ? "pendingStatus"
                                  : "confirmStatus"
                              } d-flex gap-4`}
                              style={{ width: "100%" }}
                            >
                              {dt.status === "Pending"
                                ? "Pending"
                                : "Confirmed"}
                              <div>
                                {dt.status === "Pending" && (
                                  <div
                                    className=""
                                    onClick={() => {
                                      onDeleteClick(dt.number);
                                    }}
                                  >
                                    <img
                                      src={checkMark}
                                      alt=""
                                      style={{ width: "1rem" }}
                                    />
                                  </div>
                                )}
                              </div>
                            </div>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          {dt.details.map((d, i) => {
                            return (
                              <div className="d-flex  flex-column w-100">
                                <div className="d-flex itemDetailsAndImage justify-content-between">
                                  <h5> {d.productName}</h5>
                                  <p className="price d-flex justify-content-end">
                                    ৳ {d.suggestedPrice * d.requestedQty}
                                  </p>
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex  price-Div">
                                    <div className="d-flex align-items-center quantity">
                                      Quantity: {d.requestedQty}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                          <hr />
                          <div className="d-flex justify-content-end">
                            Total: <strong> ৳ {getCount(dt.details)}</strong>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </>
                  );
                })}
                <Pagination
                  count={Math.ceil(akgpageState.total / 10)}
                  variant="outlined"
                  color="primary"
                  onChange={(event, page) => {
                    GetHistoryAPi({
                      page: page - 1,
                      pageSize: 10,
                    });
                  }}
                  className="mb-3 mt-3"
                  showFirstButton
                  showLastButton
                />
              </div>
            </div>
          ) : (
            "No ItemFounds"
          )}
        </div>
      </Dialog>
    </React.Fragment>
  );
}
