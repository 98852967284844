import React from 'react';
import Banner from './banner/Banner';
import Navbar from './navbar/Navbar';
import Mission from './mission/Mission';
import Vision from './vision/Vision';
import Goals from './goals/Goals';
import Community from './community/Community';
import Services from './services/Services';
import Leaders from './leaders/Leaders';
import Part from './part/Part';
import WhyChooseUs from './whyChooseUs/WhyChooseUs';
import Footer from './footer/Footer';
import Offerings from './offerings/Offerings';
import About from './about/About';

function HomePage() {
    return (
        <div className=''>
            <Navbar />
            <Banner />
            <Community />
            <Services />
            <Leaders />
            <Mission />
            <Vision />
            <Goals />
            <Offerings />
            <Part />
            <WhyChooseUs />
            <About />
            <Footer />
        </div>
    );
}

export default HomePage;