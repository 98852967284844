import axios from "axios";
import React from "react";

const apiAddress = process.env.REACT_APP_SECRET;

export const GetAllCustomerAPi = async (props) => {
  var res = await axios.get(apiAddress + "/api/Customer").then((response) => {
    return response.data;
  });

  return res;
};

export const GetSalesOrderByNumberAPi = async (props) => {
  var res = await axios
    .get(apiAddress + "/api/SalesOrder/GetSalesOrderByNumber/" + props)
    .then((response) => {
      return response.data;
    });

  return res;
};
export const GetPaymentSummaryByUserId = async (props) => {
  var res = await axios
    .get(
      apiAddress + "/api/SalesOrder/GetPaymentSummaryByUserId?userid=" + props
    )
    .then((response) => {
      return response.data;
    });
  return res;
};
export const GetSalesOrderByNumberForBillAPi = async (props) => {
  var res = await axios
    .get(apiAddress + "/api/SalesOrder/GetSalesOrderByNumberForBill/" + props)
    .then((response) => {
      return response.data;
    });

  return res;
};
