import axios from "axios";

export async function UploadSingleFiles(filesData) {
  const apiAddress = process.env.REACT_APP_SECRET;

  var data = "";

  const formData = new FormData();
  formData.append("file", filesData);
  await axios({
    method: "post",
    url: apiAddress + "/api/FileUploader",
    headers: {
      "Content-Type": "multipart/form-data", // <- HERE
    },
    data: formData,
  })
    .then((response) => {
      console.log(response);
      data = response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return data;
}
