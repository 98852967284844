import React from "react";
import stockissueimg from "./../../assests/images/stockissue.png";
import payToSupplier from "./../../assests/images/payment-method.png";
import customerPayment from "./../../assests/images/wallet.png";
import stimg from "./../../assests/images/stock.png";
import backimg from "./../../assests/images/back.png";
import adjsutment from "./../../assests/images/adjust.png";
import adjustment2 from "./../../assests/images/adjust (1).png";

import PaymentToSupplier from "./paymentToSupplier/PaymentToSupplier";
import PaymentRcvCustomer from "./paymentRcvCustomer/PaymentRcvCustomer";

import SupplierPaymentAdjustment from "./supplierPaymentAdjustment/SupplierPaymentAdjustment";
import CustomerPaymentAdjustment from "./customerPaymentAdjustment/CustomerPaymentAdjustment";

function Payment() {
  const [menuSelection, setMenuSelection] = React.useState(3);
  const [screenDetails, setScreenDetails] = React.useState(0);
  const [toggleSideBar, setToggleSideBar] = React.useState(false);
  const changeMenu = (props) => {
    setMenuSelection(props);
  };
  return (
    <div>
      <div className="d-flex mt-2" style={{ color: "black" }}>
        <div style={{ position: "relative" }}>
          <div
            className={` backImgDiv ${
              toggleSideBar ? "backImgDivOpen" : "backImgDivClose"
            } `}
            style={{ transition: "all .5s" }}
            onClick={() => {
              setToggleSideBar(!toggleSideBar);
            }}
          >
            <img src={backimg} alt="" />
          </div>
          <div
            className={`scrollable-div ${
              toggleSideBar ? "sideBarOff" : "sideBarOn"
            } `}
            style={{ transition: "width .5s" }}
          >
            <h6 className=" ms-3 mt-2" style={{ fontSize: "13px" }}>
              <b>Payment</b>
            </h6>
            <hr className="" style={{ margin: ".5rem" }} />

            <div
              className={`menuItem ${menuSelection == 3 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(3)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={payToSupplier}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  PaymentToSupplier{" "}
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 4 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(4)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={customerPayment}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Customer Payment{" "}
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 5 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(5)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={adjsutment}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  PurchaseBillAdjustmnet{" "}
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 6 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(6)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={adjustment2}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  CustomerPaymentAdjustmnet{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
        {menuSelection === 3 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <PaymentToSupplier />
          </div>
        ) : (
          ""
        )}
        {menuSelection === 4 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <PaymentRcvCustomer />
          </div>
        ) : (
          ""
        )}

        {menuSelection === 5 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <SupplierPaymentAdjustment />
          </div>
        ) : (
          ""
        )}
        {menuSelection === 6 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <CustomerPaymentAdjustment />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default Payment;
