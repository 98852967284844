import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import AOS from "aos";

import 'swiper/css';
import 'swiper/css/navigation';

import { Navigation } from 'swiper/modules';

const WhyChooseUs = () => {
    useEffect(() => {
        AOS.init({
          easing: "ease-in-sine",
        });
      }, []);

    return (
        <div className='my-16'>
            <h2 className='text-4xl font-bold text-center mb-6'>Why Choose A&H Solutions</h2>

            <Swiper navigation={true} modules={[Navigation]} className="mySwiper" 
            data-aos="fade-up"
            >
                <SwiperSlide className='text-center py-16'>
                    <h2 className='font-bold text-xl mb-6 text-purple-700'>Expertise and Experience</h2>
                    <p className='text-center'>
                        Benefit from our team's extensive experience in procurement, ensuring that <br /> your sourcing needs are met with precision and efficiency.
                    </p>
                </SwiperSlide>
                <SwiperSlide className='text-center py-16'>
                    <h2 className='font-bold text-xl mb-6 text-purple-700'>Quality Assurance</h2>
                    <p className='text-center'>
                        Our dedicated quality control team guarantees that every product or service sourced meets <br /> stringent quality standards, minimizing the risk of subpar purchases.
                    </p>
                </SwiperSlide>
                <SwiperSlide className='text-center py-16'>
                    <h2 className='font-bold text-xl mb-6 text-purple-700'>Cost Efficiency</h2>
                    <p className='text-center'>
                        With a focus on strategic sourcing and negotiation, we aim to optimize costs for our <br /> clients, providing them with the best value for their investment.
                    </p>
                </SwiperSlide>
                <SwiperSlide className='text-center py-16'>
                    <h2 className='font-bold text-xl mb-6 text-purple-700'>Transparency and Integrity</h2>
                    <p className='text-center'>
                        A&H Solutions operates with transparency and integrity at its core. Clients can trust us <br /> to handle their procurement processes with the utmost honesty and professionalism.
                    </p>
                </SwiperSlide>
                <SwiperSlide className='text-center py-16'>
                    <h2 className='font-bold text-xl mb-6 text-purple-700'>Tailored Solutions</h2>
                    <p className='text-center'>
                        We understand that every business is unique. Our flexible approach allows us to tailor our services to meet <br /> the specific procurement requirements of each client, ensuring personalized and effective solutions.
                    </p>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default WhyChooseUs;