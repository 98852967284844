import axios from "axios";
import React from "react";

const apiAddress = process.env.REACT_APP_SECRET;

export const GetAllItemGroupApi = async (props) => {
  var res = await axios.get(apiAddress + "/api/ItemGroup").then((response) => {
    return response.data;
  });

  return res;
};
