import React, { useEffect } from "react";
import "./Payment.css";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import checkMark from "./../../../assests/images/delete.png";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import jwtDecode from "jwt-decode";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Pagination,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import moment from "moment/moment";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";
import { GetPaymentSummaryByUserId } from "../../../api/salesAPi/salesOrder/SalesOrderApi";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Payment(props) {
  const [open, setOpen] = React.useState(props.isOpen);
  const [expanded, setExpanded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const apiAddress = process.env.REACT_APP_SECRET;
  const [itemGroups, setitemGroups] = React.useState([]);
  const [summary, setsummary] = React.useState({});
  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const GetHistoryAPi = async (props) => {
    setIsLoading(true);
    setakgpageState((old) => ({ ...old, isLoading: true }));
    var data = sessionStorage.getItem("imsToken");
    var userInfo = jwtDecode(data);
    props.whereClause = "creatorid=" + userInfo.UserId;
    await axios
      .get(
        "" +
          process.env.REACT_APP_SECRET +
          "/api/SalesOrder/GetHistoryWithPaymentgetPaged?startRecordNo=" +
          props?.page +
          "&rowPerPage=10" +
          "&whereClause=" +
          props?.whereClause +
          "&sortColumn=" +
          "creationdate" +
          "&sortOrder=" +
          "desc" +
          ""
      )
      .then((response) => {
        setakgpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: akgpageState.page,
        }));
        setitemGroups(response.data.histories);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        // SetakgIsLoading(false);
      });
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };

  const onDeleteClick = (number) => {
    axios
      .delete(apiAddress + "/api/SalesOrder/Delete?salesOrderNumber=" + number)
      .then((res) => {
        toast.success("Delete Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });

    var data = sessionStorage.getItem("imsToken");
    var userInfo = jwtDecode(data);
    var obj = {
      page: 0,
      pageSize: 10,
      whereClause: "creatorid=" + userInfo.UserId,
    };
    GetHistoryAPi(obj);
  };
  const getCount = (props) => {
    const sum = props.reduce((accumulator, object) => {
      return accumulator + object.suggestedPrice * object.requestedQty;
    }, 0);
    return <>{sum}</>;
  };
  const getAmount = (paymentDetails) => {
    const paidAmount = paymentDetails.reduce((accumulator, object) => {
      return accumulator + object.paidAmount;
    }, 0);
    var due = paymentDetails[0].billAmount - paidAmount;
    return <>{due}</>;
  };

  const GetPaymentSummary = async (props) => {
    var res = await GetPaymentSummaryByUserId(props);
    console.log(res);
    setsummary(res);
  };

  useEffect(() => {
    var data = sessionStorage.getItem("imsToken");
    var userInfo = jwtDecode(data);
    var obj = {
      page: 0,
      pageSize: 10,
      whereClause: "creatorid=" + userInfo.UserId,
    };
    GetHistoryAPi(obj);
    GetPaymentSummary(userInfo.UserId);
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar sx={{ position: "relative", backgroundColor: "gray" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Payment Details
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
          <h3 className="text-center mt-3">Payment Summary</h3>
          <div className="d-flex justify-content-center mt-3 mb-5">
            <div
              className="card "
              style={{
                background: "linear-gradient(to right, #2c3e50, #4ca1af)",
              }}
            >
              <div className="d-flex p-2 ps-3 pe-3" style={{ gap: "30px" }}>
                <div
                  className="card ps-3 pe-3 d-flex justify-content-center align-items-center summaryCard"
                  style={{ flexDirection: "column", fontSize: "1rem" }}
                >
                  <p className="m-0 p-0">{summary.pending}</p>
                  <p className="m-0 p-0">Pending </p>
                </div>
                <div
                  className="card ps-3 pe-3 d-flex justify-content-center align-items-center summaryCard"
                  style={{ flexDirection: "column", fontSize: "1rem" }}
                >
                  <p className="m-0 p-0">{summary.due}</p>
                  <p className="m-0 p-0">Due</p>
                </div>
                <div
                  className="card ps-3 pe-3 d-flex justify-content-center align-items-center summaryCard"
                  style={{ flexDirection: "column", fontSize: "1rem" }}
                >
                  <p className="m-0 p-0">{summary.paid}</p>
                  <p className="m-0 p-0"> Paid</p>
                </div>
                <div
                  className="card ps-3 pe-3 d-flex justify-content-center align-items-center summaryCard"
                  style={{ flexDirection: "column", fontSize: "1rem" }}
                >
                  <p className="m-0 p-0">{summary.total}</p>
                  <p className="m-0 p-0"> Total</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center h-100">
                <img src={require("./../../../assests/images/loader.gif")} />
              </div>
            ) : itemGroups.length > 0 ? (
              <div>
                <h3 className="text-center mt-3">Payment Details</h3>
                <div className="px-md-5 p-3">
                  {itemGroups.map((dt, i) => {
                    return (
                      <>
                        <Accordion
                          expanded={expanded === dt.number}
                          onChange={handleChange(dt.number)}
                        >
                          <AccordionSummary
                            expandIcon={<GridExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography sx={{ width: "100%" }}>
                              Order{" "}
                              <span style={{ color: "#3498DB" }}>
                                #{dt.number}
                              </span>{" "}
                              <br />
                              <span style={{ fontSize: ".8rem" }}>
                                Placed on{" "}
                                {DateFormater(dt.orderDate, "MM-DD-YYYY")}
                              </span>
                              <div className="d-flex" style={{ gap: "10px" }}>
                                <div
                                  className={`${
                                    dt.paymentStatus === "Due Payment"
                                      ? "duePaymentStatus"
                                      : dt.paymentStatus === "Pending Payment"
                                      ? "pendingPaymentStatus"
                                      : "confirmStatus"
                                  } text-center`}
                                  style={{ width: "100%" }}
                                >
                                  {dt.paymentStatus}
                                </div>
                              </div>
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            {dt.details.map((d, i) => {
                              return (
                                d.suggestedPrice != 0 && (
                                  <div className="d-flex  flex-column w-100">
                                    <div className="d-flex itemDetailsAndImage justify-content-between">
                                      <h5> {d.productName}</h5>
                                      <p className="price d-flex justify-content-end">
                                        ৳ {d.suggestedPrice * d.requestedQty}
                                      </p>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                      <div className="d-flex  price-Div">
                                        <div className="d-flex align-items-center quantity">
                                          Quantity: {d.requestedQty}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              );
                            })}
                            <hr />
                            <div className="d-flex justify-content-end">
                              Total: <strong> ৳ {getCount(dt.details)}</strong>
                            </div>

                            {dt.paymentDetails.length > 0 ? (
                              <>
                                <hr />
                                <div className="d-flex justify-content-end">
                                  After Adjustment:{" "}
                                  <strong>
                                    {" "}
                                    ৳ {dt.paymentDetails[0].billAmount}
                                  </strong>
                                </div>
                              </>
                            ) : (
                              ""
                            )}

                            {dt.paymentDetails.map((d, i) => {
                              return (
                                <div>
                                  <div className="d-flex justify-content-end">
                                    <hr className="hr-payment" />
                                  </div>
                                  <div
                                    className="d-flex justify-content-end"
                                    style={{ gap: "20px" }}
                                  >
                                    <div>
                                      <Typography sx={{ width: "100%" }}>
                                        Payment{" "}
                                        <span style={{ color: "#3498DB" }}>
                                          #{d.number}
                                        </span>{" "}
                                        <br />
                                        <span style={{ fontSize: ".8rem" }}>
                                          Paid on{" "}
                                          {DateFormater(
                                            d.creationDate,
                                            "MM-DD-YYYY"
                                          )}
                                        </span>
                                      </Typography>
                                    </div>
                                    <div>
                                      Paid Amount:{" "}
                                      <strong> ৳ {d.paidAmount}</strong>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                            {dt.paymentDetails.length > 0 ? (
                              <>
                                <hr />
                                <div className="d-flex justify-content-end">
                                  Due Amount:{" "}
                                  <strong>
                                    {" "}
                                    ৳ {getAmount(dt.paymentDetails)}
                                  </strong>
                                </div>
                              </>
                            ) : (
                              ""
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </>
                    );
                  })}
                  <Pagination
                    count={Math.ceil(akgpageState.total / 10)}
                    variant="outlined"
                    color="primary"
                    onChange={(event, page) => {
                      GetHistoryAPi({
                        page: page - 1,
                        pageSize: 10,
                      });
                    }}
                    className="mb-3 mt-3"
                    showFirstButton
                    showLastButton
                  />
                </div>
              </div>
            ) : (
              "No ItemFounds"
            )}
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}

export default Payment;
