import axios from "axios";
import React from "react";

const apiAddress = process.env.REACT_APP_SECRET;

export const GetAllItemSubGroupApi = async (props) => {
  var res = await axios.get(apiAddress + "/api/ItemSubGroup").then((response) => {
    return response.data;
  });

  return res;
};
