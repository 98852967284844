import React, { useEffect, useState } from 'react';
import TextAreaComponents from '../../sharedComponent/TextAreaComponents/TextAreaComponents';
import AutoCompleteMultiSelect from '../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx';
import CustomDateTimePicker from '../../sharedComponent/datetimePicker/CustomDateTimePicker';


//radio button imports start
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

//radio button imports end

import './stockAdjustment.css'
import { GetEmployeeAPi, GetItem, GetSupplierAPi } from '../../../api/screenApi/purchaseOrder/purchaseOrderApi';
import cancel from './../../../assests/images/close.png'
import info from './../../../assests/images/information-button.png'
import { DialogContent } from '@mui/material';
import { cleanDigitSectionValue } from '@mui/x-date-pickers/internals/hooks/useField/useField.utils';

function StockAdjustment(props) {

//Stock Adjustment entry states start
const [AdjustmentTypeSelect, setAdjustmentType]=useState()
const [StockAdjustmentNo, setStockAdjustmentNo]=useState()
const [DateTime, setDateTime]=useState()
const[Remarks, setRemarks]=useState()
const[TotalPrice, setTotalPrice]=useState()
const[TotalVat, setTotalVat]=useState()
const[TotalAmount, setTotalAmount]=useState()
const[Search, setSearch]=useState()
// //supplier states
// const [SupplierList, setSupplierList]=useState([])
// const [SelectedSupplier, setSelectedSupplier]=useState()
// //employee states
// const[EmployeeList, setEmployeeList]=useState([])
// const [SelectedEmployee, setSelectedEmployee]=useState()
// //item list
// const [ItemList, setItemList]=useState([])
// const [SelectedItem, setSelectedItem]=useState()

// const [tableLoop, setTableLoop]=useState(5)


// //Purchase order entry states end

// const GetEmployeeList=async()=>{
//     var list=[]
//     var res=await GetEmployeeAPi()
//     console.log(res)
//     res.map((d,i)=>{
//     var obj={id:d.employeeId, title:d.firstName+ " "+d.middleName}
//     list.push(obj)
//     })
//     setEmployeeList(list)  
// }

// const GetSupplier= async()=>{
//     var list=[]
// var res=await GetSupplierAPi()
// res.map((d,i)=>{
// var obj={id:d.id, title:d.supplierName}
// list.push(obj)
// })
// setSupplierList(list)
// }



// useEffect(()=>{
//    GetSupplier()

// },[])


// const AdjustTypeInput=(event)=>{
//     setAdjustmentType(event.target.value)
// }


    const initialValue = [
        {title: 'Purchase Bill' },
        {title: 'Stock Receive'}
    ];
    console.log(AdjustmentTypeSelect)
    return (
        <div>
             <h5 className=''>Stock Adjustment</h5>
             <div className='card card-shadow'>
                <div className='row p-3'>
                    <div className='col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 mt-2 PurchaseOrderCard'>
                    <TextAreaComponents id={2} value={(e) => setStockAdjustmentNo(e)} label={"Stock Adjustment No"} />
                    <TextAreaComponents id={6} value={(e) => setRemarks(e)} label={"Remarks"} />
                    
                    </div>
                    <div className='col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 PurchaseOrderCard'>
                    <CustomDateTimePicker value={(e) => setDateTime(e)} label={"Issue date"} />                
                    <AutoCompleteMultiSelect getOptionLabel={(option) => option.title} value={(e) => setAdjustmentType(e)} datas={initialValue} id={1} label={"Adjustment Type"} placeholder={"Adjustment Type"} defaultValue={initialValue[0]} />
                    </div>
             
                </div>

            </div>
            {AdjustmentTypeSelect=={"title":"Purchase Bill"}|| AdjustmentTypeSelect==''?( 
            <div className='card card-shadow p-3 mt-3'>
                <div>
                    <h6>Purchase Bill List</h6>
                </div>
                <div className='mt-2'>
                <TextAreaComponents id={6} value={(e) => setSearch(e)}  label={"Search"} />
                </div> 
                <table class="table table-striped mt-2 ">
                    <thead style={{backgroundColor:'#2d358f', color:'white'}}>
                    <tr>
                        <th>S/N</th>
                        <th>Bill No</th>
                        <th>Bill Date</th>
                        <th>Chalan No</th>
                        <th>Chalan Date</th>
                        <th>Supplier Name</th>
                        <th>Amount</th>
                        <th>Remarks</th>

                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            ):""}

            {AdjustmentTypeSelect=='Stock Receive'?( 
            <div className='card card-shadow p-3 mt-3'>
            <div>
                <h6>Stock Receive List</h6>
            </div>
            <div className='mt-2'>
            <TextAreaComponents id={6} label={"Search"} />
            </div> 
            <table class="table table-striped mt-2 ">
                <thead style={{backgroundColor:'#2d358f', color:'white'}}>
                <tr>
                    <th>S/N</th>
                    <th>Receive No</th>
                    <th>Receive Date</th>
                    <th>Supplier Name</th>
                    <th>Amount</th>
                    <th>Remarks</th>

                </tr>
                </thead>
                <tbody>
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
            ):""}



            <div className='card card-shadow p-3 mt-3'>
                <div>
                    <h6>Items</h6>
                </div>
                <table class="table table-striped mt-2 ">
                    <thead style={{backgroundColor:'#2d358f', color:'white'}}>
                    <tr align="center" >
                        <th>Select Item</th>
                        <th>Item Id</th>
                        <th>Item Name</th>
                        <th>Qty</th>
                        <th>Price</th>
                        <th>Discount</th>
                        <th>AD</th>
                        <th>VAT</th>
                        <th>Total</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            
                        </tr>
                    </tbody>
                </table>
 
            </div>
            <div className='card card-shadow p-3 mt-3'>
                <div>
                    <h6>Adjusted Items</h6>
                </div>
                <table class="table table-striped mt-2 ">
                    <thead style={{backgroundColor:'#2d358f', color:'white'}}>
                    <tr align="center" >
                        <th>Item Id</th>
                        <th>Item Name</th>
                        <th>Adj Qty</th>
                        <th>Price</th>
                        <th>Discount</th>
                        <th>AD</th>
                        <th>SD</th>
                        <th>VAT</th>
                        <th>Total</th>
                        <th>Remakes</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            
                        </tr>
                    </tbody>
                </table>
            </div>

            <div className='card card-shadow p-3 mt-3'>
                {/* <h6 className='mt-2'>Stock Received Entry</h6> */}
                <div className='row p-3 justify-content-end'>
                    <div className='col-12 col-md-12 col-lg-4 d-flex flex-column  gap-2 mt-2 PurchaseOrderCard'>
                    <TextAreaComponents id={6} value={(e) => setTotalPrice(e)} label={"Total Price"} />
                    <TextAreaComponents id={6} value={(e) => setTotalVat(e)} label={"Total Vat"} />
                    <hr className="hr" />
                    <TextAreaComponents id={6} value={(e) => setTotalAmount(e)} label={"Total Amount"} />

                    </div>
                </div>             
            </div>
            <div className='row mt-3'>
            <div className='col-4'>
                <div className='d-flex gap-4 '>
                    <button className='btn-yes btn-common'>
                        Save
                    </button> 
                    <button className='btn-no btn-common'>
                        Clear
                    </button> 
                </div>
            </div>
            </div>
            <div className='card card-shadow p-3 mt-3'>
                <div>
                    <h6>Stock Adjustment List</h6>                   
                </div>
                <div className='mt-2'>
                <TextAreaComponents id={6} value={(e) => setSearch(e)} label={"Search"} />
                </div> 
                <table class="table table-striped mt-2 ">
                    <thead style={{backgroundColor:'#2d358f', color:'white'}}>
                    <tr align="center">
                        <th>S/N</th>
                        <th>Adjustment No</th>
                        <th>Adjustment Date</th>
                        <th>PB No</th>
                        <th>Receive No</th>
                        <th>Supplier Name</th>
                        <th>Quentity</th>
                        <th>Remarks</th>
                        <th>Actions</th>

                    </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>

                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    );
}

export default StockAdjustment;