import React, { useEffect, useState } from "react";
import { GetAllItemApi } from "../../../api/adminApi/itemSetpAPi/ItemSetupApi";
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import { GetAllModelApi } from "../../../api/adminApi/ModelApi";
import { ToastContainer, toast } from "react-toastify";
//radio button imports start
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import DataTable from "../../sharedComponent/dataTable/DataTable";
//radio button imports end
import axios from "axios";
import edit from "./../../../assests/images/edit.png";
import { GetAllVatApi } from "../../../api/adminApi/VatApi";
import { Email } from "@mui/icons-material";

function CustomerSetup() {

  const apiAddress = process.env.REACT_APP_SECRET;
  //AutoComplete  states start
  const [modelList, setModelList] = useState([]);
  const [vatList, setVatList] = useState([]);
  const[Error, setError]=useState({})
  const[Search, setSearch]=useState()
  const [searchTexttemp, setsearchTexttemp] = React.useState("1=1");
  //AutoComplete sates end
  //Supplier Field Declaration Start
  const [CustomerName, setCustomerName] = useState();
  const [CustomerAddress, setCustomerAddress] = useState();
  const [CustomerMobile, setCustomerMobile] = useState();
  const [CustomerEmail, setCustomerEmail] = useState();
  const [CustomerBIN, setBIN] = useState();
  const [CustomerTIN, setTIN] = useState();
  const [CustomerVatRegNo, setVatRegNo] = useState();
  const [CustomerContactPerson, setCustomerContactPerson] = useState();
  const [CustomerOpeningBalance, setCustomerOpeningBalance] = useState();
  const [CustomerOpeningBalanceDate, setCustomerOpeningBalanceDate] = useState();

  //Supplier Field Declaration End

  const[CustomerSetup, setCustomerSetup]=useState({

      Id: 0,
      CustomerName: "",
      CustomerAddress: "",
      Mobile: "",
      Email: "",
      BIN: "",
      TIN: "",
      VATRegNo: "",
      ContactPerson: "",
      IsActive: true,
      OpeningBalance: "",
      OpeningBalanceDate: "2023-10-25T07:10:20.620Z",
      UpdatorId: 0,
      UpdateDate: "2023-10-25T07:10:20.620Z",
      // CreatorName: "admin",
      // UpdatorName: "admin"
    
  
});

  //Api calling section start
  //Api calling section end

  const handleModelSelect = (e) => {
    console.log(e);
  };
  const handleVatSelect = (e) => {
    console.log(e);
  };
  useEffect(() => {
  }, []);

  //// customer table declaration start

  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [measuremntUnitRows, setmeasuremntUnitRows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const MeasurmentUnitcolumns = [
    { field: "sn", headerName: "IS/N", width: 60 },
    {
      field: "customerName",
      headerName: "Customer Name",
      width: 120,
    },
    {
        field: "customerAddress",
        headerName: "Address",
        width: 150,
      },
      {
        field: "mobile",
        headerName: "Mobile",
        width: 120,
      },
      {
        field: "email",
        headerName: "Email",
        width: 150,
      },
      {
        field: "bin",
        headerName: "BIN",
        width: 100,
      },
      {
        field: "tin",
        headerName: "TIN",
        width: 100,
      },
      {
        field: "vatregno",
        headerName: "VATRegNo",
        width: 100,
      },
    {
      field: "contactperson",
      headerName: "Contact Person",
      width: 120,
    },
    {
        field: "isActive",
        headerName: "IsActive",
        width: 100,
      },
      {
        field: "openingBalance",
        headerName: "Opening Balance",
        width: 120,
      },

      {
        field: "openingBalanceDate",
        headerName: "Opening Balance Date",
        width: 120,
      },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <div className=" gap-3" style={{ gap: "10px" }}>
          <div

          // onClick={() => {
          //   onEditClick(params.row.id);
          // }}
          >
            <img src={edit} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      
    },
  ];
  // customer table declaration end 


 // customer table fucntion end 

  const GetPurchaseOrderNoAPi = async (props) => {
    setakgpageState((old) => ({ ...old, isLoading: true }));
    console.log(props);
    SetakgIsLoading(true);
    var where=""
    if(!!props.whereClause== false || props.whereClause==undefined){
      where="1=1"
      setSearch("");
    }
    else{
      where=props.whereClause
    }
    console.log(where)
    await axios
      .get(
        "" +
          process.env.REACT_APP_SECRET +
          "/api/Customer/GetCustomerPaged?startRecordNo=" +
          props?.page +
          "&rowPerPage=" +
          props?.pageSize +
          "&whereClause=" +
          where +
          "&sortColumn=" +
          "id" +
          "&sortOrder=" +
          "asc" +
          ""
      )
      .then((response) => {
        console.log(response.data);
        setakgpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: akgpageState.page,
        }));
        var list = [];
        response.data._customerViews.map((d, i) => {
          var obj = {
            id: i,
            sn: i + 1,
            customerName: d.customerName,
            customerAddress: d.customerAddress,
            mobile: d.mobile,
            email: d.email,
            bin: d.bin,
            tin: d.tin,
            vatregno: d.vatRegNo,
            contactperson: d.contactPerson,
            isActive: d.isActive,
            openingBalance: d.openingBalance,
            openingBalanceDate: d.openingBalanceDate,
          };
          list.push(obj);
        });
        setmeasuremntUnitRows(list);
        SetakgIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        SetakgIsLoading(false);
      });
  };
  //customer table fucntion end 
  const handleSearch = (prop) => {
    var search = "";
    setSearch(prop);
  };

  const onsearchClick = () => {
    var prop = Search;
    if (prop == null || prop == undefined || prop == "") {
      toast.error("Search is Empty", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      //prop = "1=1";
      setSearch(prop);
    } else {
      //setTempchipText(prop);
      //setsearchTexttemp(prop);
      setSearch(prop);
      var upperText = prop.toUpperCase();
      const newString = "'%" + upperText + "%'";
      const condition = "upper(CustomerName) like " + newString;
      prop = condition;
    }
    
    var obj = {
      page: 0,
      pageSize: 10,
      whereClause: prop,
    };
    GetPurchaseOrderNoAPi(obj);
  };
  //search function start

  const onClear = async () => {
  if(!!Search == false){
  console.log("already empty")
  }
  else{
    setSearch("");
    console.log("emptied")
  }
    //var prop="";

    var obj = {
      page: 0,
      pageSize: 10,
      whereClause: "1=1",
    };
    GetPurchaseOrderNoAPi(obj);

    console.log("api called")

  }


  //search function end

  const onReset= async()=>{
    setCustomerName("");
    setCustomerAddress("");
    setBIN("");
    setCustomerContactPerson("");
    setCustomerEmail("") ;
    setCustomerMobile("");
    setCustomerOpeningBalance("");
    setCustomerOpeningBalanceDate("");
    setTIN("");
    setVatRegNo("");
  }
  const onSave = async () => {
    //let isValid =validateField()
    
    if (!!CustomerName == false || CustomerName == undefined) {
      toast.error("Customer Name is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!CustomerAddress == false || CustomerAddress == undefined) {
      toast.error("Customer Address is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!CustomerMobile == false || CustomerMobile == undefined) {
      toast.error("Customer Mobile Number is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!CustomerEmail == false || CustomerEmail == undefined) {
      toast.error("Customer Email is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!CustomerBIN == false || CustomerBIN == undefined) {
      toast.error("BIN is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!CustomerTIN == false || CustomerTIN == undefined) {
      toast.error("TIN is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!CustomerVatRegNo == false || CustomerVatRegNo == undefined) {
      toast.error("VAT Reg No. required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!CustomerContactPerson == false || CustomerContactPerson == undefined) {
      toast.error("Contact Person is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else if (!!CustomerOpeningBalance == false  || CustomerOpeningBalance == undefined) {
        toast.error("Opening Balance is required", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("hit")
      }
      else if (!!CustomerOpeningBalanceDate == false || CustomerOpeningBalanceDate == undefined) {
        toast.error("Customer Opening Balance Date is required", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("hit")
      }
    else{
        CustomerSetup.CustomerName= CustomerName;
        CustomerSetup.CustomerAddress= CustomerAddress;
        CustomerSetup.Mobile= CustomerMobile;
        CustomerSetup.Email= CustomerEmail;
        CustomerSetup.BIN= CustomerBIN;
        CustomerSetup.TIN= CustomerTIN;
        CustomerSetup.VATRegNo= CustomerVatRegNo;
        CustomerSetup.ContactPerson= CustomerContactPerson;
        CustomerSetup.OpeningBalance= CustomerOpeningBalance;
        //CustomerSetup.OpeningBalanceDate= CustomerOpeningBalanceDate;

      var url = apiAddress + "/api/Customer/CustomerPost";
      await axios.post(url,CustomerSetup)
      .then((response) => {
        toast.success("New Customer Added", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        });

      });
      onReset();
      console.log("Inserted")
      var obj = {
        page: 0,
        pageSize: 10,
        whereClause: "1=1",
      };
      GetPurchaseOrderNoAPi(obj);

    console.log("api called")

      console.log("not hit")
      console.log(CustomerSetup);
    }
  };

  return (
    <div className="card card-shadow p-3">
      <ToastContainer />
      <div>
        <h5>Customer Entry</h5>
      </div>
      <div className="row mt-2 ">
        <div className="col-6 col-md-3 col-lg-3 d-flex flex-column gap-3">
          <TextAreaComponents
            id={6}
            value={(e) => setCustomerName(e)}
            label={"Customer Name"}
            val={CustomerName}
          />
          <TextAreaComponents
            id={6}
            value={(e) => setBIN(e)}
            label={"BIN"}
            val={CustomerBIN}
          />
            <TextAreaComponents
            id={6}
            value={(e) => setCustomerOpeningBalance(e)}
            label={"Opening Balance"}
            val={CustomerOpeningBalance}
          />
        </div>
        <div className="col-12 col-md-3 col-lg-3 d-flex flex-column gap-3 ">
        <TextAreaComponents
            id={6}
            value={(e) => setCustomerAddress(e)}
            label={"Customer Address"}
            val={CustomerAddress}
          />
          <TextAreaComponents
            id={6}
            value={(e) => setTIN(e)}
            label={"TIN"}
            val={CustomerTIN}
          />
        <div className="datetime">
        <CustomDateTimePicker
        value={(props) => setCustomerOpeningBalanceDate(props)}
        //isDisabled={true}
        val={CustomerOpeningBalanceDate}
        label={"Opening Balance Date"}
        /></div>
          
        </div>
        <div className="col-12 col-md-3 col-lg-3 d-flex flex-column gap-3 ">
        <TextAreaComponents
            id={6}
            value={(e) => setCustomerMobile(e)}
            label={"Customer Mobile No."}
            val={CustomerMobile}
          />
          <TextAreaComponents
            id={6}
            value={(e) => setVatRegNo(e)}
            label={"VAT Registration No"}
            val={CustomerVatRegNo}
          />
        </div>
        <div className="col-12 col-md-3 col-lg-3 d-flex flex-column gap-3 ">
        <TextAreaComponents
            id={6}
            value={(e) => setCustomerEmail(e)}
            label={"Customer Email Address"}
            val={CustomerEmail}
          />
          <TextAreaComponents
            id={6}
            value={(e) => setCustomerContactPerson(e)}
            label={"Contact Person"}
            val={CustomerContactPerson}
          />
        </div>
      </div>
      <div className="mt-2">
        <FormControl className="d-flex flex-row gap-3">
          <FormLabel id="demo-row-radio-buttons-group-label" className="mt-2">
            Status
          </FormLabel>
          <RadioGroup
            row
            defaultValue={"active"}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            // onChange={purchaseOrderTypeRadio}
          >
            <FormControlLabel
              value="active"
              control={<Radio />}
              label="Active"
            />
            <FormControlLabel
              value="inactive"
              control={<Radio />}
              label="Inactive"
            />
          </RadioGroup>
        </FormControl>
      </div>

      <div className="row mt-3 justify-content-start">
        <div className="col-12"></div>
        <div className="col-6">
          <div className="d-flex gap-4 ">
            <button onClick={()=>{onSave()}} className="btn-yes btn-common">Save</button>
            <button onClick={()=>{onReset()}}className="btn-no btn-common">Reset</button>
          </div>
        </div>
      </div>
      <div className="col-12 mt-3 ">
        <div>
          <h5>Customer List</h5>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(e)=>{handleSearch(e)}}
              label={"Search"}
              val={Search}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={ ()=> {onsearchClick()}} >
              Search
            </button>
            <button className="btn-search btn-common" onClick={ ()=> {onClear()}}>
              Clear
            </button>
          </div>
        </div>
        <div className="mt-2">
          <DataTable
            pageState={akgpageState}
            GetPurchaseOrderNoAPi={GetPurchaseOrderNoAPi}
            isLoading={akgisLoading}
            rows={measuremntUnitRows}
            columns={MeasurmentUnitcolumns}
          />
        </div>
      </div>
    </div>
  );
}

export default CustomerSetup;
