/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import logo from '../../../assests/images/LoginPageLogo.png'

const Footer = () => {
    return (
        <div>
            <footer className="footer p-10 bg-black text-white">
                <aside>
                    <img src={logo} width={'70px'} height={'70px'} className='rounded-sm mb-3' alt="" />
                    <p><span className='text-purple-700 font-bold'>A&H Solutions</span><br />Providing reliable document solutions since 2023</p>
                </aside>
                <nav>
                    <h6 className="footer-title">Services</h6>
                    <a className="link link-hover">Branding</a>
                    <a className="link link-hover">Design</a>
                    <a className="link link-hover">Marketing</a>
                    <a className="link link-hover">Advertisement</a>
                </nav>
                <nav>
                    <h6 className="footer-title">Company</h6>
                    <a className="link link-hover">About us</a>
                    <a className="link link-hover">Contact</a>
                    <a className="link link-hover">Jobs</a>
                    <a className="link link-hover">Press kit</a>
                </nav>
                <nav>
                    <h6 className="footer-title">Legal</h6>
                    <a className="link link-hover">Terms of use</a>
                    <a className="link link-hover">Privacy policy</a>
                    <a className="link link-hover">Cookie policy</a>
                </nav>
            </footer>
            <footer className="footer footer-center p-4 bg-gray-600 text-white">
                <aside>
                    <p>Copyright © 2024 - All right reserved by A&H Solutions</p>
                </aside>
            </footer>
        </div >
    );
};

export default Footer;