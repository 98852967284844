// import React from "react";
import React, { useEffect, useState } from 'react';
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import { ToastContainer, toast } from "react-toastify";
//radio button imports start
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import "./Purpose.css";
//radio button imports end
import edit from "./../../../assests/images/edit.png";
import print from "./../../../assests/images/printing.png";
import DataTable from "../../sharedComponent/dataTable/DataTable";
import axios from "axios";
import { PostPurposeAPi } from '../../../api/adminApi/purposeApi/purposeApi';
import { json } from 'react-router-dom';



function PurposeSetup() {

const apiAddress = process.env.REACT_APP_SECRET;
const[Search, setSearch]=useState()
const[PurposeDes, setPurposeDes]=useState()
const[Error, setError]=useState({})
const[Purpose, setPurpose]=useState({

    Id: 0,
    Purpose: "",
    IsActive: true,
    CreatorId: 0,
    CreateDate: "2023-10-25T05:33:21.663Z",
    UpdatorId: 0,
    UpdateDate: "2023-10-25T05:33:21.663Z"
  
});

  //Purpose table declaration start
  const [purposeisLoading, SetpurposeisLoading] = React.useState(false);
  const [purposeRows, setpurposeRows] = React.useState([]);
  const [purposepageState, setpurposepageState] = React.useState({

    isLoading: false,
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const Purposecolumns = [
    { field: "sn", headerName: "IS/N", width: 170 },
    { field: "purpose", headerName: "Purpose", width: 170 },
    {
      field: "status",
      headerName: "Status",
      width: 170,
    },

    {
      field: "action",
      headerName: "Action",
      width: 170,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div

          // onClick={() => {
          //   onEditClick(params.row.id);
          // }}
          >
            <img src={edit} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
   //Purpose table declaration end

//Purpose table function start

  const GetPurposeNoAPi = async (props) => {
      await PurposeListPaged(props, "Main");
  };
  

  const PurposeListPaged = async(props,from) =>{
    if (from != "Search") {
      setpurposepageState((old) => ({ ...old, isLoading: true }));

     SetpurposeisLoading(true);

      await axios
        .get(
          "" +
          process.env.REACT_APP_SECRET +
          "/api/Purpose/GetPurposePaged?startRecordNo=" +
          props.page +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          "1=1" +
          "&sortColumn=" +
          "id" +
          "&sortOrder=" +
          "asc" +
          ""
        )
        .then((response) => {
          setpurposepageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: purposepageState.page,
          }));
          var list = [];
          response.data._purposeViews.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              purpose: d.purpose,
              status: d.isActive,
            };
            list.push(obj);
          });
          setpurposeRows(list);
          SetpurposeisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetpurposeisLoading(false);
        });
      }
      else{

      var where=""
      if(!!props.whereClause== false || props.whereClause==undefined){
        where="1=1"
        setSearch("");
      }
      else{
        where=props.whereClause
      }
      console.log(where)

      // const newString = "'" + Search + "'";
      // const condition = "purpose=" + newString;
      setpurposepageState((old) => ({ ...old, isLoading: true }));

      SetpurposeisLoading(true);

      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/Purpose/GetPurposePaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            where +
            "&sortColumn=" +
            "id" +
            "&sortOrder=" +
            "asc" +
            ""
        )
        .then((response) => {
          setpurposepageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: purposepageState.page,
          }));
          var list = [];
          response.data._purposeViews.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              purpose: d.purpose,
              status: d.isActive,

              // action: d.number,
            };
            list.push(obj);
          });
          setpurposeRows(list);
          SetpurposeisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetpurposeisLoading(false);
        });
      } 
    };

    const onSearch = async () => {
      var prop= Search
      if(Search == "" || Search == undefined) {
        toast.error("Search is Empty", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
        else
        {
          setSearch(prop);
          var upperText = prop.toUpperCase();
          const newString = "'%" + upperText + "%'";
          const condition = "upper(Purpose) like " + newString;
          prop = condition;
        }
    
        var obj = {
          page: 0,
          pageSize: 10,
          whereClause: prop,
        };

      await PurposeListPaged(obj, "Search");
    };

    const onClear = async () => {
      var props = {
        page: 0,
        pageSize: 10,
      };
      setSearch("");
      await PurposeListPaged(props, "Main");
    };



//Purpose table function end
const onReset= async() =>{
  if(!!PurposeDes== false || PurposeDes == undefined ){
    console.log("empty")
  }
  else{
    setPurposeDes("");
    console.log("emptied")
  }
};

  const onSave = async () => {
    
    if (PurposeDes == "" || PurposeDes == undefined) {
      toast.error("Purpose is required", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      console.log("hit")
    }
    else{

      Purpose.Purpose= PurposeDes;
      var url = apiAddress + "/api/Purpose";
      await axios.post(url,Purpose).then((response) => {});
      toast.success("Purpose Insert Successfull", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      onReset();
      console.log("Inserted")
      var props = {
        page: 0,
        pageSize: 10,
      };
    await PurposeListPaged(props, "Main");
    console.log("Api hit")
    console.log("not hit")
    }
  };


  return (
    <>
      <div className="card card-shadow p-3">
        <div className="row ">
          <div className="col-12 col-md-5 col-lg-5">
            <div>
            <ToastContainer />
              <h5>Purpose</h5>
            </div>
            <TextAreaComponents
              id={5}
              value={(e) => setPurposeDes(e)}
              label={"Purpose"}  
              val={PurposeDes}            
            />
            <div className="mt-2">
              <FormControl className="d-flex flex-row gap-3">
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  className="mt-2"
                >
                  Status
                </FormLabel>
                <RadioGroup
                  row
                  defaultValue={"active"}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="active"
                    control={<Radio />}
                    label="Active"
                  />
                  <FormControlLabel
                    value="inactive"
                    control={<Radio />}
                    label="Inactive"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            <div className="row mt-3">
              <div className="col-6"></div>
              <div className="col-6">
                <div className="d-flex gap-4 ">
                  <button onClick={()=>{onSave()}} className="btn-yes btn-common">Save</button>
                  <button onClick={()=>{onReset()}} className="btn-no btn-common">Reset</button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-7 col-lg-7">
            <div>
              <h5>Purpose List</h5>
            </div>
            <div className='row'>
            <div className='mb-3 col-md-6'>
                <TextAreaComponents id={6} value={(e)=>setSearch(e)} label={"Search"} val={Search}/>
            </div>
            <div className="col-md-6 d-flex gap-3 ">
                  <button className="btn-yes btn-common" onClick={onSearch}>Search</button>
                  <button className="btn-no btn-common" onClick={onClear}>Reset</button>
                </div>
            </div>
            <div>
              <DataTable
                pageState={purposepageState}
                GetPurchaseOrderNoAPi={GetPurposeNoAPi}
                isLoading={purposeisLoading}
                rows={purposeRows}
                columns={Purposecolumns}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PurposeSetup;
