import axios from "axios";
import React from "react";

const apiAddress = process.env.REACT_APP_SECRET;

export const GetAllDesignationApi = async (e) => {
  var res = await axios
    .get(apiAddress + "/api/Designation")
    .then((response) => {
      return response.data;
    });

  return res;
};

export const GetAllRolesApi = async (e) => {
  var res = await axios
    .get(apiAddress + "/api/Employee/GetAllEmployeeRoleList")
    .then((response) => {
      return response.data;
    });

  return res;
};
