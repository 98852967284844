import React, { useEffect } from 'react';
import AOS from "aos";

const About = () => {

    useEffect(() => {
        AOS.init({
          easing: "ease-in-sine",
        });
      }, []);

    return (
        <div className="hero min-h-fit" style={{ backgroundImage: 'url(https://www.kyoceradocumentsolutions.com/en/home/images/mv-2-sp.webp)' }}>
            <div className="hero-overlay bg-opacity-60"></div>
            <div className=" text-center text-black">
            <h2 className='font-bold text-4xl my-10 text-center text-white' 
            data-aos="fade-up"
            >About Us</h2>
                <div className="max-w-full grid grid-cols-1 md:grid-cols-2 gap-4 pb-10">
                    <div className="card w-96 bg-gray-100 shadow-xl" 
                    data-aos="fade-down"
                    >
                        <div className="card-body">
                            {/* <h2 className="card-title">Card title!</h2> */}
                            <p>At A&H Solutions, we are more than just a procurement service provider; we are your dedicated partner in enhancing efficiency and driving success. With a relentless commitment to excellence, we navigate the complexities of the procurement landscape to offer tailored solutions for our corporate clients in Bangladesh.</p>
                        </div>
                    </div>
                    <div className="card w-96 bg-gray-100 shadow-xl" 
                    data-aos="fade-up"
                    >
                        <div className="card-body">
                            {/* <h2 className="card-title">Card title!</h2> */}
                            <p>Our seasoned team, backed by a robust quality control process, ensures that every product or service we source meets the highest standards. Whether it's fulfilling your immediate procurement needs or strategizing for long-term cost savings, A&H Solutions is your go-to partner.</p>
                        </div>
                    </div>
                    <div className="card w-96 bg-gray-100 shadow-xl" 
                    data-aos="fade-down"
                    >
                        <div className="card-body">
                            {/* <h2 className="card-title">Card title!</h2> */}
                            <p>As a socially responsible company, we aspire to make a positive impact on both the business world and our community. By creating job opportunities and adhering to ethical business practices, we aim to contribute to the economic development of Bangladesh.</p>
                        </div>
                    </div>
                    <div className="card w-96 bg-gray-100 shadow-xl" 
                    data-aos="fade-up"
                    >
                        <div className="card-body">
                            {/* <h2 className="card-title">Card title!</h2> */}
                            <p>Join us on this journey toward optimized procurement, where innovation meets integrity and success is a shared destination. Choose A&H Solutions—your pathway to efficient, reliable, and cost-effective procurement.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;