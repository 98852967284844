import React, { useState, useEffect } from "react";

import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import edit from "./../../../assests/images/edit.png";
import checkMark from "./../../../assests/images/check-mark.png";
import remove from "./../../../assests/images/remove.png";
import print from "./../../../assests/images/printing.png";
import DataTable from "../../sharedComponent/dataTable/DataTable";
import axios from "axios";
import { GetPurchaseBillByNumber } from "../../../api/screenApi/purchaseBill/purchaseBillApi";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlertModal from "../../sharedComponent/SweetAlertModal/SweetAlertModal";
import { GetSalesBillByNumberAPi } from "../../../api/salesAPi/salesBillApi/SalesBillApi";
import jwtDecode from "jwt-decode";
import { EntryNumberGenarator } from "../../sharedComponent/CommonMethods";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";
import ClientSiteDataTable from "../../sharedComponent/dataTable/ClientSiteDataTable.jsx";

function SalesReturn() {
  const apiAddress = process.env.REACT_APP_SECRET;
  const [AlertOpen, setAlertOpen] = useState(false);
  const [Search, setSearch] = useState();
  const [ReturnSearch, setReturnSearch] = useState();
  const [ReturnNo, setReturnNo] = useState();
  const [Flag, setFlag] = useState(true);
  const [UserId, setUserId] = useState();
  const [Reason, setReason] = useState();
  const [Remarks, setRemarks] = useState();
  const [SelectedSalesBill, setSelectedSalesBill] = useState();
  const [SelectedBillDetails, setSelectedBillDetails] = useState([]);
  const [SelectedItemList, setSelectedItemList] = useState([]);
  const [SalesRernView, setSalesReturnView] = useState({
    salesReturn: {
      number: "string",
      billReturnDate: null,
      saleBillNumber: "string",
      reason: "",
      remarks: "",
      counterId: 1,
      creatorId: 0,
      creationDate: "2023-11-01T10:14:06.480Z",
      updatorId: 0,
    },
  });
  //validation toaster start
  const errorToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  //validation toaster end
  //user info start
  const token = sessionStorage.getItem("imsToken");
  var Userdata = jwtDecode(token);
  if (!!UserId == false) {
    setUserId(Number(Userdata.UserId));
  }
  //user info end

  //Sales bill table start
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [akgrows, setAkgrows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const SalesBillcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "billno", headerName: "SalesBill No", flex: 1 },
    { field: "salesOrderNo", headerName: "SalesOrderNo No", flex: 1 },
    {
      field: "billdate",
      headerName: "Bill Date",
      renderCell: (params) => <span>{DateFormater(params.row.billdate)}</span>,
      flex: 1,
    },

    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onCheckClick(params.row);
            }}
          >
            <img src={checkMark} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const billListPage = async (props, from) => {
    if (from != "Search") {
      setakgpageState((old) => ({ ...old, isLoading: true }));
      
      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesBill/GetsalesBillPagedNotInReturn?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data.salesBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billno: d.number,
              salesOrderNo: d.salesOrderNo,
              billdate: d.billDate,

              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    } else {
      const newString = "'" + Search + "'";
      const condition = "Number=" + newString;
      setakgpageState((old) => ({ ...old, isLoading: true }));
     
      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesBill/GetsalesBillPagedNotInReturn?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data.salesBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billno: d.number,
              salesOrderNo: d.salesOrderNo,
              billdate: d.billDate,

              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    }
  };

  const onSearch = async () => {
    await billListPage("poprs", "Search");
  };
  const onClear = async () => {
    setSearch("");
    var props = {
      page: 0,
      pageSize: 10,
    };
    await billListPage(props, "Main");
  };
  const GetSalesAcknoledgementPage = async (props) => {
    await billListPage(props, "Main");
  };
  //sales bill table end

  //Bill return table start
  const [returnisLoading, SetreturnIsLoading] = React.useState(false);
  const [returnows, setReturnrows] = React.useState([]);

  const [returnpageState, setreturnpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const Returncolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "billReturnNo", headerName: "PurchaseBill Return No", flex: 1 },
    {
      field: "date",
      headerName: "Return Date",
      renderCell: (params) => <span>{DateFormater(params.row.date)}</span>,
      flex: 1,
    },
    { field: "reason", headerName: "Reason", flex: 1 },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              window.open(
                "/sales-return-report/" + params.row.billReturnNo,
                "popup",
                "width=850,height=550,left=280,top=80"
              );
            }}
          >
            <img src={print} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const BillReturnListPaged = async (props, from) => {
    if (from != "Search") {
      setreturnpageState((old) => ({ ...old, isLoading: true }));
      
      SetreturnIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesReturn/GetSalesReturnPaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          var entryNo = EntryNumberGenarator(
            response.data._salesReturnList[0].number,
            "INV1-SR-"
          );
          setReturnNo(entryNo);
          setreturnpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: returnpageState.page,
          }));
          var list = [];
          response.data._salesReturnList.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billReturnNo: d.number,
              date: d.billReturnDate,
              reason: d.reason,
              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setReturnrows(list);
          SetreturnIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetreturnIsLoading(false);
        });
    } else {
      const newString = "'" + ReturnSearch + "'";
      const condition = "Number=" + newString;
      setreturnpageState((old) => ({ ...old, isLoading: true }));

      SetreturnIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesReturn/GetSalesReturnPaged?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setreturnpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: returnpageState.page,
          }));
          var list = [];
          response.data._salesReturnList.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billReturnNo: d.number,
              date: d.billReturnDate,
              reason: d.reason,
              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setReturnrows(list);
          SetreturnIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetreturnIsLoading(false);
        });
    }
  };
  const onReturnSearch = async () => {
    await BillReturnListPaged("props", "Search");
  };
  const onReturnClear = async () => {
    setReturnSearch("");
    var props = {
      page: 0,
      pageSize: 10,
    };

    await BillReturnListPaged(props, "Main");
  };

  const GetSalesRetunListAPi = async (props) => {
    await BillReturnListPaged(props, "Main");
  };
  //Bill return table end

  const onCheckClick = async (data) => {
    var res = await GetSalesBillByNumberAPi(data.billno);
    setSelectedSalesBill(res.salesBill);
    res.salesBillDeatilsList.map((d,i)=>{
      d.id=i
    })
    setSelectedBillDetails(res.salesBillDeatilsList);
  };


//items under bill table start
const ItemsUnderBillColumns = [
  
  // { field: "id", headerName: "Serial", flex: 1 },
  {
    field: "itemName",
    headerName: "ItemName",
   
    flex: 1,
  },


  {
    field: "qty",
    headerName: "PO.Quantity",
   
    flex: 1,
 
  
  },
  {
    field: "unitPrice",
    headerName: "UnitPrice",
    flex: 1,

  },

  
];
//items under bill table end

//Selected Item column start
const SelecteedItemColumns = [
  
  // { field: "id", headerName: "Serial", flex: 1 },
  {
    field: "itemName",
    headerName: "ItemName",
   
    flex: 1,
  },


  {
    field: "qty",
    headerName: "PO.Quantity",
    flex: 1,
 
  
  },
  {
    field: "unitPrice",
    headerName: "UnitPrice",
    flex: 1,

  },
  {
    field: "sd",
    headerName: "SD",
    flex: 1,

  },
  {
    field: "vat",
    headerName: "Vat",
    flex: 1,

  },

  
];
//Selected Item column  end

  const handleSelectRow = (data) => {
  
    var list = [...SelectedItemList];
    list.push(data);
    setSelectedItemList(list);
  };
  const removeRow = (index) => {
    var list = [...SelectedItemList];
    list.splice(index, 1);
    setSelectedItemList(list);
  };
  const onSave = () => {
    setAlertOpen(false);
    var valid = true;
    if (SelectedBillDetails.length == 0) {
      valid = false;
      errorToast("No bill selected");
    }
    if (!!Reason == false) {
      valid = false;
      errorToast("Reason field is empty");
    }
    if (!!SalesRernView.salesReturn.billReturnDate == false) {
      valid = false;
      errorToast("Date not selected");
    }
    if (SelectedItemList.length == 0) {
      valid = false;
      errorToast("No item selected");
    }
    if (valid) {
      SalesRernView.salesReturn.creatorId = UserId;
      SalesRernView.salesReturn.updatorId = UserId;
      SalesRernView.salesReturn.reason = Reason;
      SalesRernView.salesReturn.remarks = Remarks;
      SalesRernView.salesReturn.saleBillNumber = SelectedSalesBill.number;
      var list = [];
      SelectedItemList.map((d, i) => {
        var obj = {
          id: 0,
          billReturnNumber: "string",
          itemCode: d.itemId,
          qty: d.qty,
          unitPrice: d.unitPrice,
          sd: d.sd,
          vat: d.vat,
        };
        list.push(obj);
      });
      SalesRernView.salesRerturnDetailsList = list;
      var url = apiAddress + "/api/SalesReturn";
      axios.post(url, SalesRernView).then((response) => {
        successToast("Saved successfully");

        //states clearing start
        setReason("");
        setRemarks("");
        SalesRernView.salesReturn.billReturnDate = null;
        SelectedSalesBill.billDate = null;
        SelectedSalesBill.number = "";
        setSelectedItemList([]);
        setSelectedBillDetails([]);
        setFlag(!Flag);
        //states clearing end
        var props = {
          page: 0,
          pageSize: 10,
        };

        BillReturnListPaged(props, "Main");
        billListPage(props, "Main");
      });
    }
  };

  const getSelectedItems=(data)=>{
   var list=[]
    data.map((d,i)=>{
    var res=  SelectedBillDetails.find(x=>x.id==d)
    if(!!res==true){
      list.push(res)
    }
    })
    setSelectedItemList(list)
  }
  return (
    <div>
      <ToastContainer />
      <SweetAlertModal
        open={AlertOpen}
        headerText={"Are you sure?"}
        bodyText={"You want to save?"}
        close={() => setAlertOpen(false)}
        onyes={onSave}
      />
      <div className="card p-3 card-shadow acknowledgementCard mt-3">
        <div>
          <h6>Sales Bill List</h6>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setSearch(props)}
              label={"Search"}
              val={Search}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onClear}>
              Clear
            </button>
          </div>
        </div>
        <DataTable
          pageState={akgpageState}
          GetPurchaseOrderNoAPi={GetSalesAcknoledgementPage}
          isLoading={akgisLoading}
          rows={akgrows}
          columns={SalesBillcolumns}
        />
      </div>

      <h5>Sales Return Entry</h5>
      <div className="card d-flex  gap-2 card-shadow PurchaseBillCancellationCard p-3">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2">
            <TextAreaComponents
              id={5}
              //   value={(e) => setPurchaseOrderNumber(e)}
              label={"Sales Return No"}
              val={ReturnNo}
              isactive={true}
            />

            <TextAreaComponents
              id={5}
              //   value={(e) => setPurchaseOrderNumber(e)}
              label={"Sales Bill No"}
              val={SelectedSalesBill?.number}
              isactive={true}
            />
            <CustomDateTimePicker
              //  value={(e) => setDateTime(e)}
              label={"Sales Bill Date"}
              val={dayjs(SelectedSalesBill?.billDate)}
              isDisabled={true}
            />
          </div>

          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2">
            <CustomDateTimePicker
              value={(e) => {
                SalesRernView.salesReturn.billReturnDate = e;
              }}
              val={SalesRernView.salesReturn.billReturnDate}
            />
            <TextAreaComponents
              id={5}
              value={(e) => {
                // SalesRernView.salesReturn.reason = e;
                setReason(e);
              }}
              label={"Reason"}
              val={Reason}
            />
            <TextAreaComponents
              id={5}
              value={(e) => {
                // SalesRernView.salesReturn.remarks = e;
                setRemarks(e);
              }}
              label={"Remarks"}
              val={Remarks}
            />
          </div>
        </div>
      </div>

      {/* Items under sales bill div start */}
      {/* <div className="card card-shadow p-3 mt-3"> */}
        
        {/* <table class="table table-striped mt-2 ">
          <thead style={{ backgroundColor: "#2d358f", color: "white" }}>
            <tr>
              <th>S/N</th>
              <th>Item Id</th>

              <th>Quantity</th>
              <th>UnitPrice</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {SelectedBillDetails.map((d, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{d.itemId}</td>

                  <td>
                    <p>{d.qty}</p>
                  </td>

                  <td>
                    <p>{d.unitPrice}</p>
                  </td>

                  <td>
                    <div onClick={() => handleSelectRow(d)}>
                      <img src={checkMark} alt="" style={{ width: "1rem" }} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table> */}

        {SelectedBillDetails.length > 0 ? (
          <>
          <div className="card card-shadow p-3 mt-3">
              <div>
                <h6>Items Under Sales Bill</h6>
              </div>
                  <ClientSiteDataTable
                    dataRows={SelectedBillDetails}
                    columns={ItemsUnderBillColumns}
                    isActive={true}
                    getselectedData={getSelectedItems}
                  />
              </div>
            </>
          ) : (
            ""
      )}
       
        
      {/* </div> */}
      {/* Items under sales bill div end*/}

      {/* Selected item div start */}
      {/* <div className="card card-shadow p-3 mt-3"> */}
       
        {/* <table class="table table-striped mt-2 ">
          <thead style={{ backgroundColor: "#2d358f", color: "white" }}>
            <tr>
              <th>S/N</th>
              <th>Item Id</th>

              <th>Quantity</th>
              <th>UnitPrice</th>
              <th>SD</th>
              <th>VAT</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {SelectedItemList.map((d, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{d.itemId}</td>

                  <td>
                    <p>{d.qty}</p>
                  </td>

                  <td>
                    <p>{d.unitPrice}</p>
                  </td>
                  <td>
                    <p>{d.sd}</p>
                  </td>
                  <td>
                    <p>{d.vat}</p>
                  </td>

                  <td>
                    <div onClick={() => removeRow(i)}>
                      <img src={remove} alt="" style={{ width: "1.5rem" }} />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table> */}

        {SelectedItemList.length > 0 ? (
          <>
          <div className="card card-shadow p-3 mt-3">
              <div>
                  <h6>Selected Item</h6>
                </div>
                  <ClientSiteDataTable
                    dataRows={SelectedItemList}
                    columns={SelecteedItemColumns}
                    // isActive={true}
                    // getselectedData={getSelectedItems}
                  />
              </div>
            </>
          ) : (
            ""
      )}
      {/* </div> */}
      {/* selected item div end */}
      <div className="row mt-3">
        <div className="col-4">
          <div className="d-flex gap-4 ">
            <button
              className="btn-yes btn-common"
              onClick={() => setAlertOpen(true)}
              // onClick={onSave}
            >
              Save
            </button>
            <button className="btn-no btn-common">Clear</button>
            <button className="btn-Review btn-common">Review</button>
          </div>
        </div>
      </div>

      <div className="card p-3 card-shadow acknowledgementCard mt-3">
        <div>
          <h6>Sales Return List</h6>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setReturnSearch(props)}
              label={"Search"}
              val={ReturnSearch}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onReturnSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onReturnClear}>
              Clear
            </button>
          </div>
        </div>
        <DataTable
          pageState={returnpageState}
          GetPurchaseOrderNoAPi={GetSalesRetunListAPi}
          isLoading={returnisLoading}
          rows={returnows}
          columns={Returncolumns}
        />
      </div>
    </div>
  );
}

export default SalesReturn;
