import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";

function CustomerPaymentAdjustmentReport(props) {
  const apiAddress = process.env.REACT_APP_SECRET;
  const componentRef = useRef();
  let { number } = useParams();

  const [receiveDetails, setReceiveDetails] = useState({});
  const [receiveItemDetails, setreceiveItemDetails] = useState([]);

  useEffect(() => {
    axios
      .get(
        apiAddress +
          "/api/CustomerPaymentAdjustment/GetCustomerPaymentAdjustmentByNumber/" +
          number
      )
      .then((res) => {
        setReceiveDetails(res.data.customerPaymentAdjustment);
        setreceiveItemDetails(res.data.customerPaymentAdjustmentDetailsList);
      });
  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  function calculateSum(data, key) {
    return data.reduce((sum, item) => sum + parseFloat(item[key] || 0), 0);
  }
  return (
    <div className="p-3">
      <button className="btn btn-success mt-2 " onClick={handlePrint}>
        Print
      </button>
      <hr />
      <div ref={componentRef}>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ flexDirection: "column" }}
        >
          <h2 style={{ fontWeight: "bold", fontSize: "30px" }}>
            A & H Solutions
          </h2>
          <h5 style={{ textDecoration: "underline", fontWeight: "bold" }}>
            Customer Payment Adjustment Report
          </h5>
        </div>
        <div className="d-flex justify-content-between">
          {receiveDetails != null ? (
            <>
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Adjustment Number: {receiveDetails.number}
                </p>

                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Adjusted Date: {DateFormater(receiveDetails.adjustedDate)}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Adjusted By: {receiveDetails.employeeName}
                </p>

                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Reason: {receiveDetails.reason}
                </p>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div>
          <table className="table table-bordered mt-3">
            <thead>
              <th className="text-center">S/N</th>

              <th className="text-center">Bill Number</th>
              <th className="text-center">Is Increased?</th>
              <th className="text-center">Adjusted Amount</th>
            </thead>
            <tbody>
              {receiveItemDetails.length > 0
                ? receiveItemDetails.map((dt, i) => {
                    return (
                      <tr>
                        <td className="text-center">{i + 1}</td>

                        <td className="text-center">{dt.pbNumber}</td>
                        <td className="text-center">
                          {dt.isIncreased ? "True" : "False"}
                        </td>
                        <td className="text-center">{dt.adjustedAmount}</td>
                      </tr>
                    );
                  })
                : ""}
              <td colSpan={3} className="text-end">
                Total
              </td>
              <td className="text-center">
                {calculateSum(receiveItemDetails, "adjustedAmount")}
              </td>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default CustomerPaymentAdjustmentReport;
