import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";

function SalesBillReport(props) {
  const apiAddress = process.env.REACT_APP_SECRET;
  const componentRef = useRef();
  let { number } = useParams();

  const [receiveDetails, setReceiveDetails] = useState({});
  const [receiveItemDetails, setreceiveItemDetails] = useState([]);

  useEffect(() => {
    axios
      .get(apiAddress + "/api/SalesBill/GetSalesBillByNumber/" + number)
      // .get(apiAddress + "/api/SalesBill/GetSalesBillByNumber/" + number)
      .then((res) => {
        setReceiveDetails(res.data.salesBill);
        setreceiveItemDetails(res.data.salesBillDeatilsList);
      });
  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div className="p-3">
      <button className="btn btn-success mt-2 " onClick={handlePrint}>
        Print
      </button>
      <hr />
      <div ref={componentRef}>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ flexDirection: "column" }}
        >
          <img
            src={require("./../../../assests/images/LoginPageLogo.png")}
            alt=""
            style={{
              width: "8rem",
              borderRadius: "10px",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          />

<div style={{ textAlign: "center", marginBottom: "10px" }}>
  <p style={{ margin: 0, fontSize: "25px", fontWeight: "bold" }}>A And H Solutions</p>
  <p style={{ margin: 0 }}>
    <strong>Phone No:</strong> +8801600360735 / +8801326796817
  </p>
  <p style={{ margin: 0 }}>
    <strong>Email:</strong> ahmedandhasangroup@gmail.com
  </p>
  <p style={{ margin: 0 }}>
    <strong>Address:</strong> Bangla Bazar, Dhaka 1100
  </p>
</div>
          <hr
            className="mb-0"
            style={{ width: "100%", backgroundColor: "#ecefef" }}
          />
          <div
            className="h-100 p-2"
            style={{ width: "100%", backgroundColor: "#f6f6f6" }}
          >
            <h5
              className="text-center"
              style={{ color: "#5F6368", fontWeight: "bold" }}
            >
              Sales Bill Report
            </h5>
          </div>

          <hr
            className="mt-0"
            style={{ width: "100%", backgroundColor: "#ecefef" }}
          />
        </div>
        <div className="d-flex justify-content-between">
          {receiveDetails != null ? (
            <>
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <p
                  className="p-0 m-0 text-head 25"
                  style={{ lineHeight: "20px" }}
                >
                  <span>Bill Number:</span> {receiveDetails.number}
                </p>

                <p className="p-0 m-0 text-head" style={{ lineHeight: "20px" }}>
                  <span> Bill Date: </span>{" "}
                  {DateFormater(receiveDetails.billDate)}
                </p>
                <p className="p-0 m-0 text-head" style={{ lineHeight: "20px" }}>
                  <span> Remarks: </span>
                  {receiveDetails.remarks}
                </p>
              </div>
              <div className="d-flex flex-column">
                <p className="p-0 m-0 text-head" style={{ lineHeight: "20px" }}>
                  <span> Order By: </span>
                  {receiveDetails.customerName}
                </p>
                <p className="p-0 m-0 text-head" style={{ lineHeight: "20px" }}>
                  <span> Order Number: </span>
                  {receiveDetails.salesOrderNo}
                </p>
                <p className="p-0 m-0 text-head" style={{ lineHeight: "20px" }}>
                  <span> Issue Number: </span>
                  {receiveDetails.issueNo}
                </p>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div>
          <table className="table table-striped mt-3">
            <thead>
              <th className="text-center headText">S/N</th>

              <th className="text-center headText">Item Name</th>
              <th className="text-center headText">Qty</th>
              <th className="text-center  headText">Base Price</th>
              <th className="text-center headText">Discount</th>
              <th className="text-center headText">Vat</th>
              <th className="text-center headText">SD</th>
              <th className="text-center headText">Total</th>
            </thead>
            <tbody>
              {receiveItemDetails.length > 0
                ? receiveItemDetails.map((dt, i) => {
                    return (
                      <tr>
                        <td className="text-center">{i + 1}</td>

                        <td className="text-center">{dt.itemName}</td>
                        <td className="text-center">{dt.qty}</td>
                        <td className="text-center">৳ {dt.unitPrice}</td>
                        <td className="text-center">৳ {dt.discount}</td>
                        <td className="text-center">৳ {dt.vat}</td>
                        <td className="text-center">৳ {dt.sd}</td>
                        <td className="text-center">৳ {dt.amount}</td>
                      </tr>
                    );
                  })
                : ""}
              <tr>
                <td className="text-end" colSpan={7}>
                  Additional Discount:
                </td>
                <td className="text-center">
                  ৳ {receiveDetails.addtionalDiscount}
                </td>
              </tr>
              <tr>
                <td className="text-end" colSpan={7}>
                  AIT:
                </td>
                <td className="text-center">৳ {receiveDetails.ait}</td>
              </tr>
              <tr>
                <td className="text-end" colSpan={7}>
                  Total:
                </td>
                <td className="text-center">৳ {receiveDetails.finalAmount}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SalesBillReport;
