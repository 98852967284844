import React, { useEffect, useState } from "react";
import TextAreaComponents from "./../../sharedComponent/TextAreaComponents/TextAreaComponents";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";

import edit from "./../../../assests/images/edit.png";
import print from "./../../../assests/images/printing.png";
import DataTable from "../../sharedComponent/dataTable/DataTable";
import axios from "axios";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import "./PurchaseBillCancellation.css";
import checkMark from "./../../../assests/images/check-mark.png";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlertModal from "../../sharedComponent/SweetAlertModal/SweetAlertModal";
import { EntryNumberGenarator } from "../../sharedComponent/CommonMethods";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";

function PurchaseBillCancelation() {
  const apiAddress = process.env.REACT_APP_SECRET;
  const [AlertOpen, setAlertOpen] = useState(false);
  const [SelectedBill, setSelectedBill] = useState();
  const [SelectedBillNo, setSelectedBillNo] = useState();
  const [CancelDate, setCancelDate] = useState();
  const [Remarks, setRemarks] = useState();
  const [Reason, setReason] = useState();
  const [BillCancelNO, setBillcancelNo] = useState();
  const [Search, setSearch] = useState();
  const [BillSearch, setBillSearch] = useState();

  //validation toaster start
  const errorToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  //validation toaster end
  //Bill cancel  table start
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [akgrows, setAkgrows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const Cancelationtcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "cancelno", headerName: "BillCancelation No", flex: 1 },

    { field: "reason", headerName: "Reason", flex: 1 },
    {
      field: "canceldate",
      headerName: "Cancel Date",
      renderCell: (params) => (
        <span>{DateFormater(params.row.canceldate)}</span>
      ),
      flex: 1,
    },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div

          // onClick={() => {
          //   onEditClick(params.row.id);
          // }}
          >
            <img src={edit} alt="" style={{ width: "1rem" }} />
          </div>
          <div>
            <img src={print} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const BillCancelListPaged = async (props, from) => {
    if (from != "Search") {
      setakgpageState((old) => ({ ...old, isLoading: true }));

      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseCancelation/GetPurchaseBillCancelationPaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          //Bill entry no genarate strat
          var entryNo = EntryNumberGenarator(
            response.data.purchaseBillCancelation[0].number,
            "INV1-PC-"
          );
          setBillcancelNo(entryNo);
          //Bill entry no genarate end
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data.purchaseBillCancelation.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              cancelno: d.number,
              reason: d.reason,
              canceldate: d.billCancelDate,
              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    } else {
      const newString = "'" + Search + "'";
      const condition = "Number=" + newString;
      setakgpageState((old) => ({ ...old, isLoading: true }));

      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseCancelation/GetPurchaseBillCancelationPaged?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          //Bill entry no genarate strat
          var entryNo = EntryNumberGenarator(
            response.data.purchaseBillCancelation[0].number,
            "INV1-PC-"
          );
          setBillcancelNo(entryNo);
          //Bill entry no genarate end
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data.purchaseBillCancelation.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              cancelno: d.number,
              reason: d.reason,
              canceldate: d.billCancelDate,
              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    }
  };
  const onSearch = async () => {
    await BillCancelListPaged("props", "Search");
  };

  const onClear = async () => {
    var props = {
      page: 0,
      pageSize: 10,
    };
    setSearch("");
    await BillCancelListPaged(props, "Main");
  };

  const GetPurchaseOrderNoAPi = async (props) => {
    await BillCancelListPaged(props, "Main");
  };
  //Bill cancel table end

  //bill table start
  const Billcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "billNo", headerName: "Bill No", flex: 1 },
    {
      field: "billDate",
      headerName: "Bill Date",
      renderCell: (params) => <span>{DateFormater(params.row.billDate)}</span>,
      flex: 1,
    },
    { field: "supplier", headerName: "Supplier Name", flex: 1 },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onCheckClick(params.row);
            }}
          >
            <img src={checkMark} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
  const [billrows, setBillrows] = React.useState([]);

  const [isLoading, SetisLoading] = React.useState(false);

  const [pageState, setpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const BillListPaged = async (props, from) => {
    if (from != "Search") {
      setpageState((old) => ({ ...old, isLoading: true }));

      SetisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseBill/GetPurchaseBillPagedForAcknowledgement?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: pageState.page,
          }));
          var list = [];
          response.data._purchaseBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billNo: d.number,
              billDate: d.billDate,
              supplier: d.supplierName,
              remarks: d.remarks,
              fulllRow: d,
            };
            list.push(obj);
          });
          setBillrows(list);
          SetisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetisLoading(false);
        });
    } else {
      const newString = "'" + BillSearch + "'";
      const condition = "Number=" + newString;
      setpageState((old) => ({ ...old, isLoading: true }));

      SetisLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseBill/GetPurchaseBillPagedForAcknowledgement?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            5 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: pageState.page,
          }));
          var list = [];
          response.data._purchaseBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billNo: d.number,
              billDate: d.billDate,
              supplier: d.supplierName,
              remarks: d.remarks,
              fulllRow: d,
            };
            list.push(obj);
          });
          setBillrows(list);
          SetisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetisLoading(false);
        });
    }
  };
  const onBillSearch = async () => {
    await BillListPaged("props", "Search");
  };
  const onBillClear = async () => {
    setBillSearch("");
    var props = {
      page: 0,
      pageSize: 10,
    };
    await BillListPaged(props, "Main");
  };

  const GetPurchaseBillPage = async (props) => {
    await BillListPaged(props, "Main");
  };

  //bill table end

  const onCheckClick = (data) => {
    setSelectedBill(data.fulllRow);
    setSelectedBillNo(data.fulllRow.number);
  };

  const onSave = () => {
    setAlertOpen(false);
    var valid = true;
    var PurchaseCancelation = {
      number: "string",
      billCancelDate: CancelDate,
      purchaseBillNo: SelectedBill?.number,
      reason: Reason,
      remarks: Remarks,
      counterId: SelectedBill?.counterId,
      creatorId: SelectedBill?.creatorId,
      creationDate: dayjs(),
      updatorId: SelectedBill?.creatorId,
    };
    if (!!CancelDate == false) {
      valid = false;
      errorToast("Date not selected");
    }
    if (!!SelectedBill == false) {
      valid = false;
      errorToast("No bill selected");
    }
    if (!!Reason == false) {
      valid = false;
      errorToast("Reason required");
    }
    if (!!Remarks == false) {
      valid = false;
      errorToast("Remarks required");
    }
    if (valid) {
      var url = apiAddress + "/api/PurchaseCancelation";
      axios.post(url, PurchaseCancelation).then((response) => {
        successToast("Saved successfully");
        setSelectedBillNo("");
        setCancelDate(null);
        setRemarks("");
        setReason("");

        var props = {
          page: 0,
          pageSize: 10,
        };

        BillCancelListPaged(props, "Main");
        BillListPaged(props, "Main");
      });
    }
  };
  return (
    <div>
      <ToastContainer />
      <SweetAlertModal
        open={AlertOpen}
        headerText={"Are you sure?"}
        bodyText={"You want to save?"}
        close={() => setAlertOpen(false)}
        onyes={onSave}
      />
      <div className="card p-3 card-shadow acknowledgementCard">
        <div>
          <h6>Purchase Bill List</h6>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setBillSearch(props)}
              label={"Search"}
              val={BillSearch}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onBillSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onBillClear}>
              Clear
            </button>
          </div>
        </div>
        <DataTable
          pageState={pageState}
          GetPurchaseOrderNoAPi={GetPurchaseBillPage}
          isLoading={isLoading}
          rows={billrows}
          columns={Billcolumns}
        />
      </div>

      <h5>Purchase Bill Cancellation</h5>
      <div className="card card-shadow PurchaseBillCancellationCard p-3">
        <div className="d-flex flex-column gap-2">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2">
              <TextAreaComponents
                id={5}
                //   value={(e) => setPurchaseOrderNumber(e)}
                label={"Purchase Cancel No"}
                val={BillCancelNO}
                isactive={true}
              />

              <TextAreaComponents
                id={5}
                //   value={(e) => setPurchaseOrderNumber(e)}
                label={"PurchaseBill No"}
                val={SelectedBillNo}
                isactive={true}
              />
            </div>
            <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2">
              <CustomDateTimePicker
                value={(e) => setCancelDate(e)}
                label={"Cancel Date"}
                val={CancelDate}
              />
              <TextAreaComponents
                id={5}
                value={(e) => setRemarks(e)}
                label={"Remarks"}
                val={Remarks}
              />
            </div>
          </div>
          <div className="col-12">
            <TextAreaComponents
              id={5}
              value={(e) => setReason(e)}
              label={"Cancel Reason"}
              val={Reason}
            />
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-4">
          <div className="d-flex gap-4 ">
            <button
              className="btn-yes btn-common"
              onClick={() => setAlertOpen(true)}
            >
              Save
            </button>
            <button className="btn-no btn-common">Clear</button>
            <button className="btn-Review btn-common">Review</button>
          </div>
        </div>
      </div>

      <div className="card p-3 card-shadow acknowledgementCard mt-3">
        <div>
          <h6>Purchase Bill Cancel List</h6>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              // value={(props) => setSearch(props)}
              value={(e) => setSearch(e)}
              label={"Search"}
              val={Search}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onClear}>
              Clear
            </button>
          </div>
        </div>
        <DataTable
          pageState={akgpageState}
          GetPurchaseOrderNoAPi={GetPurchaseOrderNoAPi}
          isLoading={akgisLoading}
          rows={akgrows}
          columns={Cancelationtcolumns}
        />
      </div>
    </div>
  );
}

export default PurchaseBillCancelation;
