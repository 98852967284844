import React, { useEffect, useState } from "react";
import TextAreaComponents from "./../../sharedComponent/TextAreaComponents/TextAreaComponents";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";

import edit from "./../../../assests/images/edit.png";
import print from "./../../../assests/images/printing.png";
import DataTable from "../../sharedComponent/dataTable/DataTable";
import axios from "axios";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";

import checkMark from "./../../../assests/images/check-mark.png";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlertModal from "../../sharedComponent/SweetAlertModal/SweetAlertModal";
import jwtDecode from "jwt-decode";
import { EntryNumberGenarator } from "../../sharedComponent/CommonMethods";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";

function SalesBillCancelation() {
  const apiAddress = process.env.REACT_APP_SECRET;
  const [UserId, setUserId] = useState();
  const [AlertOpen, setAlertOpen] = useState(false);
  const [SelectedBill, setSelectedBill] = useState();
  const [CancelDate, setCancelDate] = useState();
  const [Remarks, setRemarks] = useState();
  const [Reason, setReason] = useState();
  const [Search, setSearch] = useState();
  const [BillSearch, setBillSearch] = useState();
  const [CancelNo, setCancelNo] = useState();

  //user info start
  const token = sessionStorage.getItem("imsToken");
  var Userdata = jwtDecode(token);
  if (!!UserId == false) {
    setUserId(Number(Userdata.UserId));
  }
  //user info end
  //validation toaster start
  const errorToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  //validation toaster end
  //Cancelation table start
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [akgrows, setAkgrows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const Cancelationtcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "cancelno", headerName: "BillCancelation No", flex: 1 },

    { field: "reason", headerName: "Reason", flex: 1 },
    {
      field: "canceldate",
      headerName: "Cancel Date",
      renderCell: (params) => (
        <span>{DateFormater(params.row.canceldate)}</span>
      ),
      flex: 1,
    },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div

          // onClick={() => {
          //   onEditClick(params.row.id);
          // }}
          >
            <img src={edit} alt="" style={{ width: "1rem" }} />
          </div>
          <div>
            <img src={print} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const CancelationListPage = async (props, from) => {
    if (from != "Search") {
      setakgpageState((old) => ({ ...old, isLoading: true }));
      console.log(props);
      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/Salescancelation/GetSalesBillCancelationPaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          var entryNo = EntryNumberGenarator(
            response.data._salesBillCancelationList[0].number,
            "INV1-SC-"
          );
          setCancelNo(entryNo);
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data._salesBillCancelationList.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              cancelno: d.number,
              reason: d.reason,
              canceldate: d.billCancelDate,
              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    } else {
      const newString = "'" + Search + "'";
      const condition = "Number=" + newString;
      setakgpageState((old) => ({ ...old, isLoading: true }));
      console.log(props);
      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/Salescancelation/GetSalesBillCancelationPaged?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data._salesBillCancelationList.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              cancelno: d.number,
              reason: d.reason,
              canceldate: d.billCancelDate,
              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    }
  };

  const onSearch = async () => {
    await CancelationListPage("props", "Search");
  };
  const onClear = async () => {
    setSearch("");
    var props = {
      page: 0,
      pageSize: 10,
    };
    await CancelationListPage(props, "Main");
  };
  const GetPurchaseOrderNoAPi = async (props) => {
    await CancelationListPage(props, "Main");
  };
  //Cancelation table end

  //Sales bill table start
  const [billisLoading, SetbillIsLoading] = React.useState(false);
  const [billrows, setbillrows] = React.useState([]);

  const [billpageState, setbillpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const SalesBillcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "billno", headerName: "SalesBill No", flex: 1 },
    { field: "salesOrderNo", headerName: "SalesOrderNo No", flex: 1 },
    {
      field: "billdate",
      headerName: "Bill Date",
      renderCell: (params) => <span>{DateFormater(params.row.billdate)}</span>,
      flex: 1,
    },

    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onBillCheckClick(params.row);
            }}
          >
            <img src={checkMark} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const BillListPaged = async (props, from) => {
    if (from != "Search") {
      setbillpageState((old) => ({ ...old, isLoading: true }));
      console.log(props);
      SetbillIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesBill/GetsalesBillPagedNotInAcknowledgementAndCancel?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          console.log(response.data);
          setbillpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: billpageState.page,
          }));
          var list = [];
          response.data.salesBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billno: d.number,
              salesOrderNo: d.salesOrderNo,
              billdate: d.billDate,

              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setbillrows(list);
          SetbillIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetbillIsLoading(false);
        });
    } else {
      const newString = "'" + BillSearch + "'";
      const condition = "Number=" + newString;
      setbillpageState((old) => ({ ...old, isLoading: true }));
      console.log(props);
      SetbillIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesBill/GetsalesBillPagedNotInAcknowledgementAndCancel?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          console.log(response.data);
          setbillpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: billpageState.page,
          }));
          var list = [];
          response.data.salesBill.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              billno: d.number,
              salesOrderNo: d.salesOrderNo,
              billdate: d.billDate,

              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setbillrows(list);
          SetbillIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetbillIsLoading(false);
        });
    }
  };
  const onBillSearch = async () => {
    await BillListPaged("props", "Search");
  };
  const onBillClear = async () => {
    setBillSearch("");
    var props = {
      page: 0,
      pageSize: 10,
    };
    await BillListPaged(props, "Main");
  };
  const GetSalesBillPageNotInAcknowledgement = async (props) => {
    await BillListPaged(props, "Main");
  };

  const onBillCheckClick = async (data) => {
    console.log(data);
    setSelectedBill(data);
  };
  //sales bill table end

  const onCheckClick = (data) => {
    console.log(data);
    setSelectedBill(data.fulllRow);
  };

  const onSave = () => {
    setAlertOpen(false);
    var valid = true;
    var SalesCancelation = {
      number: "string",
      billCancelDate: CancelDate,
      salesBillNo: SelectedBill?.billno,
      reason: Reason,
      remarks: Remarks,
      counterId: 1,
      creatorId: UserId,
      creationDate: dayjs(),
      updatorId: UserId,
    };
    if (!!CancelDate == false) {
      valid = false;
      errorToast("Date not selected");
    }
    if (!!SelectedBill == false) {
      valid = false;
      errorToast("No bill selected");
    }
    if (!!Reason == false) {
      valid = false;
      errorToast("Reason required");
    }
    if (!!Remarks == false) {
      valid = false;
      errorToast("Remarks required");
    }
    if (valid) {
      var url = apiAddress + "/api/Salescancelation";
      axios.post(url, SalesCancelation).then((response) => {
        successToast("Saved successfully");
        //states clearing start
        SelectedBill.billno = "";
        setReason("");
        setRemarks("");
        setCancelDate(null);
        //states clearing end
        var props = {
          page: 0,
          pageSize: 10,
        };
        CancelationListPage(props, "Main");
        BillListPaged(props, "Main");
      });
    }
  };
  return (
    <div>
      <ToastContainer />
      <SweetAlertModal
        open={AlertOpen}
        headerText={"Are you sure?"}
        bodyText={"You want to save?"}
        close={() => setAlertOpen(false)}
        onyes={onSave}
      />
      <div className="card p-3 card-shadow acknowledgementCard mt-3">
        <div>
          <h6>Sales Bill List</h6>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setBillSearch(props)}
              label={"Search"}
              val={BillSearch}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onBillSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onBillClear}>
              Clear
            </button>
          </div>
        </div>
        <DataTable
          pageState={billpageState}
          GetPurchaseOrderNoAPi={GetSalesBillPageNotInAcknowledgement}
          isLoading={billisLoading}
          rows={billrows}
          columns={SalesBillcolumns}
        />
      </div>

      <h5>Sales Bill Cancellation</h5>
      <div className="card card-shadow PurchaseBillCancellationCard p-3">
        <div className="d-flex flex-column gap-2">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2">
              <TextAreaComponents
                id={5}
                //   value={(e) => setPurchaseOrderNumber(e)}
                label={"Sales Cancel No"}
                val={CancelNo}
                isactive={true}
              />
              <TextAreaComponents
                id={5}
                //   value={(e) => setPurchaseOrderNumber(e)}
                label={"SalesBill No"}
                val={SelectedBill?.billno}
                isactive={true}
              />
            </div>
            <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2">
              <CustomDateTimePicker
                value={(e) => setCancelDate(e)}
                label={"Cancel Date"}
                val={CancelDate}
              />
              <TextAreaComponents
                id={5}
                value={(e) => setRemarks(e)}
                label={"Remarks"}
                val={Remarks}
              />
            </div>
          </div>
          <div className="col-12">
            <TextAreaComponents
              id={5}
              value={(e) => setReason(e)}
              label={"Cancel Reason"}
              val={Reason}
            />
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-4">
          <div className="d-flex gap-4 ">
            <button
              className="btn-yes btn-common"
              onClick={() => setAlertOpen(true)}
            >
              Save
            </button>
            <button className="btn-no btn-common">Clear</button>
            <button className="btn-Review btn-common">Review</button>
          </div>
        </div>
      </div>

      <div className="card p-3 card-shadow acknowledgementCard mt-3">
        <div>
          <h6>SalesBill Cancel List</h6>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setSearch(props)}
              label={"Search"}
              val={Search}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onClear}>
              Clear
            </button>
          </div>
        </div>
        <DataTable
          pageState={akgpageState}
          GetPurchaseOrderNoAPi={GetPurchaseOrderNoAPi}
          isLoading={akgisLoading}
          rows={akgrows}
          columns={Cancelationtcolumns}
        />
      </div>
    </div>
  );
}

export default SalesBillCancelation;
