import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import logo from "./../../assests/images/LoginPageLogo.png";
import TextAreaComponents from "./../sharedComponent/TextAreaComponents/TextAreaComponents";
import "./Home.css";
import axios from "axios";
import Cart from "./Cart/Cart";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Pagination,
  TablePagination,
} from "@mui/material";
import { GetAllItemGroupApi } from "../../api/adminApi/itemGroupApi/ItemGroupApi";
import { CheckBox } from "@mui/icons-material";

import Aos from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom";
import History from "./History/History";
import Payment from "./Payment/Payment";

const pages = ["Products"];
const settings = ["History", "Logout"];

function Home(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [itemList, setItemList] = React.useState([]);
  const [tab1Page, setTab1Page] = React.useState(1);
  const [isFirstLoad, setisFirstLoad] = React.useState(true);
  const [isHistoryOpen, setisHistoryOpen] = React.useState(false);
  const [isPaymentOpen, setisPaymentOpen] = React.useState(false);
  const [tab1HasMore, setTab1HasMore] = React.useState(true);
  const [searchText, setsearchText] = React.useState("1=1");
  const [searchTexttemp, setsearchTexttemp] = React.useState("1=1");
  const [chipText, setchipText] = React.useState("");
  const [tempchipText, setTempchipText] = React.useState("");
  const [itemGroups, setitemGroups] = React.useState([]);
  const [cartItems, setCartItems] = React.useState([]);
  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,
    total: 0,
    page: 1,
    pageSize: 10,
  });
  const myRef = React.useRef(null);
  const navigate = useNavigate();

  const GetPurchaseOrderNoAPi = async (props) => {
    setakgpageState((old) => ({ ...old, isLoading: true }));
    console.log(props);

    await axios
      .get(
        "" +
          process.env.REACT_APP_SECRET +
          "/api/Item/GetItemPaged?startRecordNo=" +
          props?.page +
          "&rowPerPage=" +
          props?.pageSize +
          "&whereClause=" +
          props?.whereClause +
          "&sortColumn=" +
          "i.id" +
          "&sortOrder=" +
          "desc" +
          ""
      )
      .then((response) => {
        console.log(response.data);
        setakgpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: akgpageState.page,
        }));
        var list = [];
        response.data._itemViews.map((d, i) => {
          var obj = {
            id: d.id,
            name: d.productName,
            suggestedPrice: d.suggestedPrice,
            isAddedToCart: false,
            image: d.image,
          };
          var res = sessionStorage.getItem("cartItems");
          if (res != undefined) {
            var lists = JSON.parse(res);
            if (lists.length > 0) {
              var obj2 = lists.find((x) => x.id == d.id);
              console.log(obj2);
              if (obj2 != undefined) {
                obj.isAddedToCart = true;
              }
            }
          }

          list.push(obj);
        });
        if (response.data._itemViews.length == response.data._totalCount) {
          setTab1HasMore(false);
        }
        // setItemList((prevlist) => [...prevlist, ...list]);

        setItemList(list);
        // SetakgIsLoading(false);
        setTab1Page(props?.page + 1);
        if (!isFirstLoad) {
          myRef.current.scrollIntoView();
        } else {
          setisFirstLoad(false);
        }
      })
      .catch((error) => {
        console.log(error);
        // SetakgIsLoading(false);
      });
  };

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    navigate("/login");
  };

  React.useEffect(() => {
    // GetAllItem();
    var data = sessionStorage.getItem("imsToken");
    if (data == undefined) {
      navigate("/login");
    }
    Aos.init({
      easing: "ease-in-sine",
    });
    setisFirstLoad(true);
    GetAllItemGroup();
    var obj = {
      page: 1,
      pageSize: 12,
      whereClause: searchText,
    };
    GetPurchaseOrderNoAPi(obj);
  }, []);

  const GetAllItemGroup = async () => {
    var res = await GetAllItemGroupApi();
    setitemGroups(res);
  };

  const handleSearch = (prop) => {
    var search = "";
    if (prop == null || prop == undefined || prop == "") {
      search = "1=1 ";
      setsearchText(search);
      setTempchipText(prop);
    } else {
      setTempchipText(prop);
      setsearchTexttemp(prop);
      var upperText = prop.toUpperCase();
      search = "upper(ProductName) like '" + "%" + upperText + "%" + "'";
    }
  };

  const onsearchClick = () => {
    var res = searchText.includes("and i.ItemGroupId");
    var search = "";
    if (res) {
      search =
        searchText +
        "and upper(ProductName) like '" +
        "%" +
        searchTexttemp.toUpperCase() +
        "%" +
        "'";
    } else {
      search =
        "1=1 " +
        "and upper(ProductName) like '" +
        "%" +
        searchTexttemp.toUpperCase() +
        "%" +
        "'";
    }
    setsearchText(search);
    var obj = {
      page: 1,
      pageSize: 12,
      whereClause: search,
    };
    setchipText(tempchipText);
    console.log(obj);
    GetPurchaseOrderNoAPi(obj);
  };

  const handleGroupCheck = (props) => {
    var temp = "";
    var res = searchText.includes("and i.ItemGroupId=" + props);
    if (res) {
      temp = searchText.replace("and i.ItemGroupId=" + props, "");
    } else {
      temp = searchText + "and i.ItemGroupId=" + props;
    }
    var obj = {
      page: 1,
      pageSize: 12,
      whereClause: temp,
    };
    setchipText(tempchipText);
    setsearchText(temp);
    console.log(obj);
    GetPurchaseOrderNoAPi(obj);
  };

  const handleChipDelete = () => {
    var res = searchText.includes("i.ItemGroupId=");
    var temp = "";
    if (res) {
      temp = searchText.replace(
        "and upper(ProductName) like '" +
          "%" +
          searchTexttemp.toUpperCase() +
          "%" +
          "'",
        ""
      );
    } else {
      temp = "1=1 ";
    }
    setsearchText(temp);
    GetPurchaseOrderNoAPi({
      page: 1,
      pageSize: 12,
      whereClause: temp,
    });
    setTempchipText("");
    setchipText("");
  };

  const handleAddToCart = (props) => {
    var list = sessionStorage.getItem("cartItems");
    var listData = [];
    props.quantity = 1;
    if (list != undefined) {
      listData = JSON.parse(list);

      listData.push(props);
    } else {
      listData.push(props);
    }
    sessionStorage.setItem("cartItems", JSON.stringify(listData));
    const broadcastChannel = new BroadcastChannel("storage_change_channel");
    broadcastChannel.postMessage("storage_changed");

    window.dispatchEvent(new Event("storage"));
  };

  React.useEffect(() => {
    const broadcastChannel = new BroadcastChannel("storage_change_channel");
    broadcastChannel.addEventListener("message", (event) => {
      if (event.data === "storage_changed") {
        var res = sessionStorage.getItem("cartItems");
        var lists = JSON.parse(res);
        setCartItems(lists);
        var items = itemList;

        items.map((dt, i) => {
          if (lists.length > 0) {
            var ob = lists.find((x) => x.id == dt.id);
            if (ob != undefined) {
              dt.isAddedToCart = true;
            } else {
              dt.isAddedToCart = false;
            }
          } else {
            dt.isAddedToCart = false;
          }
        });
        setItemList(items);
      }
    });
  });

  const getImageData = (img) => {
    console.log(img);
    return process.env.REACT_APP_SECRET + "/Resources/Images/" + img;
  };

  return (
    <div style={{ overflowX: "hidden" }}>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            {/* <AdbIcon sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} /> */}
            <IconButton sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}>
              <img
                src={logo}
                alt=""
                className="mr-1 "
                style={{
                  width: "2rem",
                  borderRadius: "50%",
                  marginRight: "5px",
                }}
              />
            </IconButton>

            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "monospace",
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              A & H Solutions
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem
                  onClick={() => {
                    setAnchorElNav(null);
                    myRef.current.scrollIntoView();
                  }}
                >
                  <Typography textAlign="center">Products</Typography>
                </MenuItem>
              </Menu>
            </Box>
            {/* <AdbIcon sx={{ display: { xs: "flex", md: "none" }, mr: 1 }} /> */}
            <IconButton sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
              <img
                src={logo}
                alt=""
                className="mr-1 d-md-none"
                style={{
                  width: "2rem",
                  borderRadius: "50%",
                  marginRight: "5px",
                }}
              />
            </IconButton>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                color: "inherit",
                textDecoration: "none",
              }}
            >
              A & H
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <MenuItem
                onClick={() => {
                  setAnchorElNav(null);
                  myRef.current.scrollIntoView();
                }}
              >
                <Typography textAlign="center">Product</Typography>
              </MenuItem>
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Tooltip title="Open settings">
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                  <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <MenuItem
                  onClick={() => {
                    setisHistoryOpen(true);
                  }}
                >
                  <Typography textAlign="center">History</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setisPaymentOpen(true);
                  }}
                >
                  <Typography textAlign="center">Payment</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <div className="jumbo-bg-container">
        <div className="bg-jumbo"></div>
        <div className="jumbotron jumbotron-fluid ">
          <div className="container text-container">
            <h1 className="display-4" 
            data-aos="fade-down"
            >
              Welcome to A & H Solutions
            </h1>
            <p className="lead" 
            data-aos="fade-up"
            >
              Your Road to Quality Vehicle Products
            </p>
          </div>
        </div>
      </div>

      <div className="ourproductContainer">
        <div className="container-fluid mt-5 ps-5 pe-5">
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <h2
                className="display-6"
                style={{ fontWeight: "500" }}
                ref={myRef}
                data-aos="fade-up"
              >
                Our Products
              </h2>
            </div>
          </div>
          {chipText != "" ? (
            <div className="row">
              <div
                className="col-12 d-flex justify-content-center"
                data-aos="fade-up"
              >
                <Chip
                  label={chipText}
                  onDelete={() => {
                    handleChipDelete();
                  }}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <div className="row">
            <div className="col-0 col-md-2 " style={{ position: "relative" }}>
              <div>
                <div
                  className="d-flex gap-2 align-items-center"
                  style={{
                    cursor: "pointer",
                    zIndex: 99999,
                  }}
                >
                  <div 
                  data-aos="fade-up"
                  >
                    <img
                      src={require("./../../assests/images/filter.png")}
                      alt=""
                      srcset=""
                      style={{ width: "1rem" }}
                    />
                  </div>
                  <div>
                    <p style={{ marginBottom: "0" }} 
                    data-aos="fade-up"
                    >
                      Filters
                    </p>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-12" 
                  data-aos="fade-up"
                  >
                    <TextAreaComponents
                      id={6}
                      label={"Search By Item Name"}
                      value={handleSearch}
                      val={tempchipText}
                    />
                  </div>
                  <div className="col-12 mt-3" 
                  data-aos="fade-up"
                  >
                    <Button
                      variant="contained"
                      color="success"
                      sx={{ width: "100%" }}
                      onClick={() => {
                        onsearchClick();
                      }}
                    >
                      Search
                    </Button>
                  </div>
                  <div className="col-12 mt-3">
                    {itemGroups.length > 0 ? (
                      <div
                        className="accordion"
                        id="accordionExample"
                        data-aos="fade-up"
                      >
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="headingOne">
                            <button
                              className="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#collapseOne"
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              Item Group
                            </button>
                          </h2>
                          <div
                            id="collapseOne"
                            className="accordion-collapse collapse show"
                            aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              {itemGroups.map((dt, i) => {
                                return (
                                  <FormGroup>
                                    <FormControlLabel
                                      control={<Checkbox />}
                                      label={dt.groupName}
                                      onChange={() => {
                                        handleGroupCheck(dt.id);
                                      }}
                                    />
                                  </FormGroup>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-10">
              <div className="row d-flex justify-content-center mt-1 mb-5">
                {itemList.map((dt, i) => {
                  return (
                    <div className="col-md-3 mt-3" key={i}>
                      <div
                        className="card homeCard"
                        style={{
                          width: "18rem;",
                          border: "none",
                          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                          borderRadius: "20px",
                        }}
                      >
                        <img
                          className="card-img-top"
                          src={getImageData(dt.image)}
                          alt={dt.image}
                          style={{
                            height: "10rem",
                            objectFit: "contain",
                            width: "100%",
                          }}
                        />
                        <div className="card-body">
                          <p className="card-text mb-0">
                            <strong>{dt.name}</strong>
                          </p>
                          <p style={{ fontWeight: "bold", color: "#e2296d" }}>
                            ৳ {dt.suggestedPrice}
                          </p>
                        </div>
                        {dt.isAddedToCart ? (
                          <div className="card-footer deactive">
                            <h6
                              className="text-center"
                              style={{ color: "white", cursor: "pointer" }}
                            >
                              Already added
                            </h6>
                          </div>
                        ) : (
                          <div
                            className="card-footer isactive"
                            onClick={() => {
                              handleAddToCart(dt);
                            }}
                          >
                            <h6
                              className="text-center"
                              style={{ color: "white", cursor: "pointer" }}
                            >
                              Add to Cart
                            </h6>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <Pagination
                count={Math.ceil(akgpageState.total / 12)}
                variant="outlined"
                color="primary"
                onChange={(event, page) => {
                  GetPurchaseOrderNoAPi({
                    page: page,
                    pageSize: 12,
                    whereClause: searchText,
                  });
                }}
                className="mb-3"
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row d-flex justify-content-center">
        <div
          className="col-12 d-flex justify-content-center"
          style={{ background: "#0b0028", color: "white" }}
        >
          <p style={{ padding: "5px" }}>
            Copyright © 2023 A & H Solutions. All Right Reserved
          </p>
        </div>
      </div>
      <Cart />
      {isHistoryOpen && (
        <History
          isOpen={isHistoryOpen}
          handleClose={() => setisHistoryOpen(false)}
        />
      )}
      {isPaymentOpen && (
        <Payment
          isOpen={isPaymentOpen}
          handleClose={() => setisPaymentOpen(false)}
        />
      )}
    </div>
  );
}

export default Home;
