import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "../../sharedComponent/dataTable/DataTable";
import dayjs from "dayjs";
import { EntryNumberGenarator } from "../../sharedComponent/CommonMethods";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import checkMark from "./../../../assests/images/check-mark.png";
import jwtDecode from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlertModal from "../../sharedComponent/SweetAlertModal/SweetAlertModal";
import edit from "./../../../assests/images/edit.png";
import print from "./../../../assests/images/printing.png";
import { GetEmployeeAPi } from "../../../api/screenApi/purchaseOrder/purchaseOrderApi";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";

function PaymentToSupplier() {
  const apiAddress = process.env.REACT_APP_SECRET;
  const [AlertOpen, setAlertOpen] = useState(false);
  const [clearAutoComplete, setclearAutoComplete] = useState(false);
  const [AmountToPaid, setAmountToPaid] = useState();

  const [paymentNo, setPaymentNo] = useState();
  const [Search, setSearch] = useState();
  const [PaymentSearch, setpaymentSearch] = useState();
  const [EmployeeList, setEmployeeList] = useState([]);
  const [UserId, setUserId] = useState();
  const [PaymentView, setPaymentView] = useState({
    number: "",
    billNumber: "",
    billAmount: 0,
    paidAmount: 0,
    supplierId: 0,
    paymentIssuedBy: 0,
    remarks: "",
    counterId: 0,
    creatorId: 0,
    creationDate: null,
    updatorId: 0,
  });

  //user info start
  const token = sessionStorage.getItem("imsToken");
  var Userdata = jwtDecode(token);
  if (!!UserId == false) {
    setUserId(Number(Userdata.UserId));
  }
  //user info end

  //validation toaster start
  const errorToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  //validation toaster end

  const GetEmployeeList = async () => {
    var list = [];
    var res = await GetEmployeeAPi();

    res.map((d, i) => {
      var obj = { id: d.employeeId, title: d.firstName + " " + d.middleName };
      list.push(obj);
    });
    setEmployeeList(list);
  };

  useEffect(() => {
    GetEmployeeList();
  }, []);
  // Purchase Bill Table starts

  const billcolumns = [
    { field: "id", headerName: "S/N", flex: 1 },
    { field: "billnumber", headerName: "Bill No", flex: 1.5 },
    {
      field: "billDate",
      headerName: "Bill Date",
      renderCell: (params) => <span>{DateFormater(params.row.billDate)}</span>,
      flex: 1,
    },
    { field: "adjustedAmount", headerName: "AdjustedAmount", flex: 1 },
    { field: "totalAmount", headerName: "Total", flex: 1 },
    { field: "paidAmount", headerName: "Paid", flex: 1 },
    { field: "supplierName", headerName: "Supplier Name", flex: 1 },
    { field: "remarks", headerName: "Remarks ", flex: 1 },
    {
      field: "action",
      headerName: "Action ",
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onCheckClick(params.row);
            }}
          >
            <img src={checkMark} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      flex: 1,
    },
  ];

  const [billrows, setBillrows] = React.useState([]);

  const [isBillLoading, SetBillisLoading] = React.useState(false);

  const [billpageState, setBillpageState] = React.useState({
    isLoading: false,
    total: 0,
    page: 1,
    pageSize: 10,
  });

  const BillListPaged = async (props, from) => {
    if (from != "Search") {
      setBillpageState((old) => ({ ...old, isLoading: true }));

      SetBillisLoading(true);

      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseBill/GetPurchaseBillPagedForPayment?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setBillpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: billpageState.page,
          }));
          var list = [];
          response.data._purchaseBill.map((d, i) => {
            var obj = {
              id: i + 1,
              billnumber: d.number,
              billDate: d.billDate,
              adjustedAmount: d.adjustedAmount,
              totalAmount: d.adjustedPrice,
              paidAmount: d.paidAmount,
              supplierName: d.supplierName,
              supplierId: d.supplierId,
              remarks: d.remarks,
            };
            list.push(obj);
          });
          setBillrows(list);
          SetBillisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetBillisLoading(false);
        });
    } else {
      const newString = "'" + Search + "'";
      const condition = "Number=" + newString;
      setBillpageState((old) => ({ ...old, isLoading: true }));

      SetBillisLoading(true);

      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/PurchaseBill/GetPurchaseBillPagedForPayment?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setBillpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: billpageState.page,
          }));
          var list = [];
          response.data._purchaseBill.map((d, i) => {
            var obj = {
              id: i + 1,
              billnumber: d.number,
              billDate: d.billDate,
              totalAmount: d.adjustedPrice,
              paidAmount: d.paidAmount,
              supplierName: d.supplierName,
              supplierId: d.supplierId,
              remarks: d.remarks,
            };
            list.push(obj);
          });
          setBillrows(list);
          SetBillisLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetBillisLoading(false);
        });
    }
  };
  const onSearch = async () => {
    await BillListPaged("props", "Search");
  };
  const onClear = async () => {
    var props = {
      page: 0,
      pageSize: 10,
    };
    setSearch("");
    await BillListPaged(props, "Main");
  };

  const GetPurchaseBillNoAPi = async (props) => {
    await BillListPaged(props, "Main");
  };

  // Purchase Bill Table End

  //Sales bill table start
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [akgrows, setAkgrows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const SalesBillcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "paymentNo", headerName: "Payment No", flex: 1 },
    { field: "billAmount", headerName: "Bill Amount", flex: 1 },
    {
      field: "paidAmount",
      headerName: "Paid",

      flex: 1,
    },

    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              window.open(
                "/paymentTo-supplier-report/" + params.row.paymentNo,
                "popup",
                "width=850,height=550,left=280,top=80"
              );
            }}
          >
            <img src={print} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const billListPage = async (props, from) => {
    if (from != "Search") {
      setakgpageState((old) => ({ ...old, isLoading: true }));

      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/paymentToSupplier/GetPaymentPaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          var entryNo = EntryNumberGenarator(
            response.data._payment[0].number,
            "INV1-PS-"
          );
          setPaymentNo(entryNo);
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data._payment.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              paymentNo: d.number,
              billAmount: d.billAmount,
              paidAmount: d.paidAmount,

              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    } else {
      const newString = "'" + PaymentSearch + "'";
      const condition = "Number=" + newString;
      setakgpageState((old) => ({ ...old, isLoading: true }));

      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/paymentToSupplier/GetPaymentPaged?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data._payment.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              paymentNo: d.number,
              billAmount: d.billAmount,
              paidAmount: d.paidAmount,

              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    }
  };

  const onPaymentSearch = async () => {
    await billListPage("poprs", "Search");
  };
  const onpaymentClear = async () => {
    setpaymentSearch("");
    var props = {
      page: 0,
      pageSize: 10,
    };
    await billListPage(props, "Main");
  };
  const GetSalesAcknoledgementPage = async (props) => {
    await billListPage(props, "Main");
  };
  //sales bill table end

  const onCheckClick = (data) => {
    const updatedPaymentView = {
      ...PaymentView,
      billAmount: data.totalAmount,
      // paidAmount: data.totalAmount,
      billNumber: data.billnumber,
      creatorId: UserId,
      updatorId: UserId,
      // paymentIssuedBy: UserId,
      counterId: 1,
      supplierId: data.supplierId,
    };

    if (data.paidAmount == 0) {
      updatedPaymentView.paidAmount = data.totalAmount;
      setAmountToPaid(data.totalAmount);
    } else {
      updatedPaymentView.paidAmount = data.totalAmount - data.paidAmount;
      setAmountToPaid(updatedPaymentView.paidAmount);
    }
    setPaymentView(updatedPaymentView);
  };
  const onSave = async () => {
    setAlertOpen(false);
    var valid = true;
    if (PaymentView.billNumber == "") {
      valid = false;
      errorToast("Bill not selected");
    }
    if (PaymentView.creationDate == null) {
      valid = false;
      errorToast("Date not selected");
    }
    if (PaymentView.paidAmount == 0) {
      valid = false;
      errorToast("Payment not inserted");
    }
    if (PaymentView.paidAmount > AmountToPaid) {
      valid = false;
      errorToast("Amount is greater than amount to be paid");
    }
    if (PaymentView.paymentIssuedBy == 0) {
      valid = false;
      errorToast("Employee not selected");
    }
    if (valid) {
      var url = apiAddress + "/api/paymentToSupplier";
      axios.post(url, PaymentView).then((response) => {
        successToast("Successfully saved");
        setclearAutoComplete(true);

        setPaymentView({
          number: "",
          billNumber: "",
          billAmount: 0,
          paidAmount: 0,
          supplierId: 0,
          paymentIssuedBy: 0,
          remarks: "",
          counterId: 0,
          creatorId: 0,
          creationDate: null,
          updatorId: 0,
        });

        var props = {
          page: 0,
          pageSize: 10,
        };
        billListPage(props, "Main");
        BillListPaged(props, "Main");
      });
    }
  };
  return (
    <>
      <ToastContainer />
      <SweetAlertModal
        open={AlertOpen}
        headerText={"Are you sure?"}
        bodyText={"You want to save?"}
        close={() => setAlertOpen(false)}
        onyes={onSave}
      />
      <div className="card card-shadow p-3 mt-3">
        <div>
          <h6>Purchase Bill List</h6>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setSearch(props)}
              label={"Search"}
              val={Search}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onClear}>
              Clear
            </button>
          </div>
        </div>

        <div className="mt-2">
          <DataTable
            pageState={billpageState}
            GetPurchaseOrderNoAPi={GetPurchaseBillNoAPi}
            isLoading={isBillLoading}
            rows={billrows}
            columns={billcolumns}
          />
        </div>
      </div>

      <div className="card card-shadow mt-3 p-3">
        <div className="row">
          <div className="col-12">
            <h6>Payment Entry</h6>
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <div className="mt-2">
              <TextAreaComponents
                id={5}
                // value={(e) => setStockReceiveNumber(e)}
                label={"Payment Number"}
                val={paymentNo}
                isactive={true}
              />
            </div>
            <div>
              <TextAreaComponents
                id={5}
                // value={(e) => setStockReceiveNumber(e)}
                label={"Purchase Bill Number"}
                val={PaymentView.billNumber}
                isactive={true}
              />
            </div>
            <div>
              <TextAreaComponents
                id={2}
                label={"Bill amount"}
                place
                val={PaymentView.billAmount}
                isactive={true}
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column mt-2 gap-2 PurchaseOrderCard">
            <CustomDateTimePicker
              value={(props) =>
                setPaymentView({
                  ...PaymentView,
                  creationDate: props,
                })
              }
              label={"Payment Date"}
              val={PaymentView.creationDate}
            />
            <div>
              <TextAreaComponents
                id={2}
                value={(props) =>
                  setPaymentView({
                    ...PaymentView,
                    paidAmount: Number(props),
                  })
                }
                label={"Pay"}
                val={PaymentView.paidAmount}
              />
            </div>
            <AutoCompleteMultiSelect
              datas={EmployeeList}
              getOptionLabel={(option) => option.title}
              id={1}
              value={(e) => (PaymentView.paymentIssuedBy = e?.id)}
              label={"Adjusted By"}
              placeholder={"--Adjusted By--"}
              clear={clearAutoComplete}
            />
          </div>
          <div className="mt-2">
            <TextAreaComponents
              id={5}
              value={(props) =>
                setPaymentView({
                  ...PaymentView,
                  remarks: props,
                })
              }
              label={"Remarks"}
              val={PaymentView.remarks}
            />
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-4">
          <div className="d-flex gap-4 ">
            <button
              className="btn-yes btn-common"
              onClick={() => setAlertOpen(true)}
            >
              Save
            </button>
            <button className="btn-no btn-common">Clear</button>
            <button className="btn-Review btn-common">Review</button>
          </div>
        </div>
      </div>

      <div className="card p-3 card-shadow acknowledgementCard mt-3">
        <div>
          <h6>Payment List</h6>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setpaymentSearch(props)}
              label={"Search"}
              val={PaymentSearch}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onPaymentSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onpaymentClear}>
              Clear
            </button>
          </div>
        </div>
        <DataTable
          pageState={akgpageState}
          GetPurchaseOrderNoAPi={GetSalesAcknoledgementPage}
          isLoading={akgisLoading}
          rows={akgrows}
          columns={SalesBillcolumns}
        />
      </div>
    </>
  );
}

export default PaymentToSupplier;
