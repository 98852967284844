import React, { useEffect, useState } from "react";
import {
  GetAllItemApi,
  GetItemByIdApi,
  PostItemApi,
} from "../../../api/adminApi/itemSetpAPi/ItemSetupApi";
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import { GetAllModelApi } from "../../../api/adminApi/ModelApi";
//radio button imports start
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import DataTable from "../../sharedComponent/dataTable/DataTable";
//radio button imports end
import axios from "axios";
import edit from "./../../../assests/images/edit.png";
import { GetAllVatApi } from "../../../api/adminApi/VatApi";
import { GetAllItemGroupApi } from "../../../api/adminApi/itemGroupApi/ItemGroupApi.js";
import { GetAllItemSubGroupApi } from "../../../api/ItemSubGroupApi/ItemSubGroupApi.js";
import { GetAllMeasurementApi } from "../../../api/adminApi/measurementUnitApi/MeasurementUnitApi.js";
import { Button, Grid } from "@mui/material";
import ImageUploader from "../../sharedComponent/ImageUploader/ImageUploader.jsx";
import { UploadSingleFiles } from "../../../api/fileUploader/FileUploader.jsx";
import jwtDecode from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";

function ItemSetup() {
  //AutoComplete  states start
  const [modelList, setModelList] = useState([]);
  const [clear, setClear] = useState(false);
  const [clear1, setClear1] = useState(false);
  const [clear2, setClear2] = useState(false);
  const [clear3, setClear3] = useState(false);
  const [clear4, setClear4] = useState(false);
  const [itemId, setItemId] = useState(0);
  const [itemCode, setItemCode] = useState("N/A");
  const [productName, setProductName] = useState("");
  const [modelId, setModelId] = useState("");
  const [itemGroupId, setItemGroupId] = useState("");
  const [itemSubGroupId, setItemSubGroupId] = useState("");
  const [measurementUnitId, setMeasurementUnitId] = useState("");
  const [vatId, setVatId] = useState("");
  const [rol, setRol] = useState("");
  const [suggestedPrice, setSuggestedPrice] = useState("");
  const [isActive, setIsActive] = useState(true);
  const [radioGroupDefaultValue, setradioGroupDefaultValue] =
    useState("active");
  const [vatList, setVatList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [subgroupList, setsubGroupList] = useState([]);
  const [measurementList, setMeasurementList] = useState([]);
  const [logoImg, setlogoImg] = useState([]);
  const [BackgroundImagestate, setBackgroundImagestate] = useState([]);
  const [selectedModel, setSelectedModel] = useState(null);
  const [selectedItemGroup, setselectedItemGroup] = useState(null);
  const [selectedSubItemGroup, setselectedSubItemGroup] = useState(null);
  const [selectedMeasurementUnit, setselectedMeasurementUnit] = useState(null);
  const [selectedVat, setselectedVat] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  //AutoComplete sates end

  //Api calling section start
  const GetAllItem = async () => {
    var res = await GetAllItemApi();
  };
  const GetAllModel = async () => {
    var res = await GetAllModelApi();
    setModelList(res);
  };
  const GetAllVat = async () => {
    var res = await GetAllVatApi();
    setVatList(res);
  };
  const GetAllItemGroup = async () => {
    var res = await GetAllItemGroupApi();
    setGroupList(res);
  };
  const GetAllItemSubGroup = async () => {
    try {
      var res = await GetAllItemSubGroupApi();
      setsubGroupList(res);
      console.log(res);
    } catch (error) {
      console.error("Failed to fetch item subgroups:", error);
    }
  };
  const GetAllMeasurement = async () => {
    var res = await GetAllMeasurementApi();
    setMeasurementList(res);
  };
  //Api calling section end

  useEffect(() => {
    GetAllItem();
    GetAllModel();
    GetAllVat();
    GetAllItemGroup();
    GetAllItemSubGroup();
    GetAllMeasurement();
  }, []);

  useEffect(() => {
    if (modelId != "") {
      modalSelection(modelId);
    } else {
      setSelectedModel(null);
    }
  }, [modelId]);
  useEffect(() => {
    if (itemGroupId != "") {
      itemGroupSelection(itemGroupId);
    } else {
      setselectedItemGroup(null);
    }
  }, [itemGroupId]);
  useEffect(() => {
    if (itemSubGroupId != "") {
      itemSubGroupSelection(itemSubGroupId);
    } else {
      setselectedSubItemGroup(null);
    }
  }, [itemSubGroupId]);
  useEffect(() => {
    if (measurementUnitId != "") {
      MeasurementUnitSelection(measurementUnitId);
    } else {
      setselectedMeasurementUnit(null);
    }
  }, [measurementUnitId]);
  useEffect(() => {
    if (vatId != "") {
      vatSelection(vatId);
    } else {
      setselectedVat(null);
    }
  }, [vatId]);

  //Acknowledgement table start
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [measuremntUnitRows, setmeasuremntUnitRows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const MeasurmentUnitcolumns = [
    // { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "itemcode", headerName: "ItemCode", flex: 1 },
    {
      field: "name",
      headerName: "ProductName",
      flex: 1,
    },
    {
      field: "price",
      headerName: "SuggesteedPrice",
      flex: 1,
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div

          // onClick={() => {
          //   onEditClick(params.row.id);
          // }}
          >
            <img
              src={
                process.env.REACT_APP_SECRET +
                "/Resources/Images/" +
                params.row.image
              }
              alt={
                process.env.REACT_APP_SECRET +
                "/Resources/Images/" +
                params.row.image
              }
              style={{ objectFit: "cover", width: "4rem" }}
              className="img-fluid"
            />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },

    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onEditClick(params.row);
            }}
          >
            <img src={edit} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
  const onEditClick = async (params) => {
    var obj = await GetItemByIdApi(params.id);
    console.log(obj);
    setModelId(obj.modelId);
    modalSelection(obj.modelId);
    setItemGroupId(obj.itemGroupId);
    itemGroupSelection(obj.itemGroupId);
    setItemSubGroupId(obj.itemSubGroupId);
    itemSubGroupSelection(obj.itemSubGroupId);
    setMeasurementUnitId(obj.measureUnitId);
    MeasurementUnitSelection(obj.measureUnitId);
    setVatId(obj.purchaseVatId);
    vatSelection(obj.purchaseVatId);
    setItemCode(obj.itemCode);
    setProductName(obj.productName);
    setRol(obj.rol);
    setSuggestedPrice(obj.suggestedPrice);
    setradioGroupDefaultValue(obj.isActive ? "active" : "inactive");
    setIsActive(obj.isActive);
    setItemId(obj.id);
    setImageUrl(obj.image);
  };
  const GetPurchaseOrderNoAPi = async (props) => {
    setakgpageState((old) => ({ ...old, isLoading: true }));
    SetakgIsLoading(true);
    var startPageNo = props.page + 1;
    await axios
      .get(
        "" +
          process.env.REACT_APP_SECRET +
          "/api/Item/GetItemPaged?startRecordNo=" +
          startPageNo +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          "1=1" +
          "&sortColumn=" +
          "i.id" +
          "&sortOrder=" +
          "desc" +
          ""
      )
      .then((response) => {
        setakgpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: akgpageState.page,
        }));
        var list = [];
        response.data._itemViews.map((d, i) => {
          var obj = {
            id: d.id,
            sn: i + 1,
            itemcode: d.itemCode,
            name: d.productName,
            price: d.suggestedPrice,
            image: d.image,
          };
          list.push(obj);
        });
        setmeasuremntUnitRows(list);
        SetakgIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        SetakgIsLoading(false);
      });
  };

  const Submit = async () => {
    var isValid = true;
    if (
      // !!itemCode == false ||
      !!productName == false ||
      !!modelId == false ||
      !!itemGroupId == false ||
      !!itemSubGroupId == false ||
      !!measurementUnitId == false ||
      !!vatId == false ||
      !!rol == false ||
      !!suggestedPrice == false ||
      !!isActive == false
    ) {
      isValid = false;
      toast.error("Some Fields Are Empty");
    }
    if (isValid) {
      var res = "";
      var imageValid = true;
      if (!!logoImg[0] == true) {
        res = await UploadSingleFiles(logoImg[0]);
      } 
      // else if (imageUrl == "") {
      //   imageValid = false;
      //   toast.error("Image Field required");
      // } else if (imageUrl != "") {
      //   res = imageUrl;
      // } else if (!!logoImg[0] == false) {
      //   toast.error("Please Upload Image");
      //   imageValid = false;
      // } else {
      //   toast.error("Please Upload Image");
      //   imageValid = false;
      // }
      if (imageValid) {
        var token = sessionStorage.getItem("imsToken");
        var tokenObj = jwtDecode(token);

        var obj = {
          id: itemId,
          itemCode: itemCode,
          productName: productName,
          modelId: modelId,
          itemGroupId: itemGroupId,
          itemSubGroupId: itemSubGroupId,
          measureUnitId: measurementUnitId,
          purchaseVatId: vatId,
          supplimentaryDutyId: 0,
          hasExpiry: true,
          rol: rol,
          suggestedPrice: suggestedPrice,
          isActive: isActive,
          creatorId: tokenObj.UserId,
          creationDate: new Date(),
          updatorId: tokenObj.UserId,
          updateDate: new Date(),
          barcode: "N/A",
          image: "N/A",
        };
        console.log(itemSubGroupId);
        var resFromPost = await PostItemApi(obj);
        if (resFromPost.status === 200) {
          toast.success("Successfully Added");
          handleClear();
        } else {
          toast.error("Something went wrong");
        }
      }
    }
    // setClear(false);
  };
  const handleClear = () => {
    GetPurchaseOrderNoAPi({
      pageSize: 10,
      page: 0,
    });
    // Toggle the state variables to trigger re-renders
    setClear((prev) => !prev);
    setClear1((prev) => !prev);
    setClear2((prev) => !prev);
    setClear3((prev) => !prev);
    setClear4((prev) => !prev);

    setItemId(0);
    setItemCode("");
    setProductName("");
    setRol("");
    setSuggestedPrice("");
    setlogoImg("");
    setBackgroundImagestate([]);
    setSelectedModel(null);
    setselectedItemGroup(null);
    setselectedSubItemGroup(null);
    setselectedMeasurementUnit(null);
    setselectedVat(null);

    setradioGroupDefaultValue("active");
    setIsActive(true);
    setImageUrl("");
  };

  const modalSelection = (id) => {
    var obj = modelList.find((x) => x.id == id);
    setSelectedModel({ id: id, name: obj.name });
  };
  const itemGroupSelection = (id) => {
    var obj = groupList.find((x) => x.id == id);
    setselectedItemGroup({ id: id, groupName: obj.groupName });
  };
  const itemSubGroupSelection = (id) => {
    var obj = subgroupList.find((x) => x.id == id);
    setselectedSubItemGroup({ id: id, subGroupName: obj.subGroupName });
  };
  const MeasurementUnitSelection = (id) => {
    var obj = measurementList.find((x) => x.id == id);
    setselectedMeasurementUnit({
      id: id,
      unitDescription: obj.unitDescription,
    });
  };
  const vatSelection = (id) => {
    var obj = vatList.find((x) => x.id == id);
    setselectedVat({
      id: id,
      vatPercent: obj.vatPercent,
    });
  };
  //Acknowledgement table end

  return (
    <div className="card card-shadow p-3">
      <ToastContainer />
      <div>
        <h5>Item Setup</h5>
      </div>
      <div className="row mt-2 ">
        <div className="col-12 col-md-4 col-lg-4 d-flex flex-column gap-3">
          <TextAreaComponents
            id={5}
            value={(e) => {
              setItemCode(e);
            }}
            label={"Item code"}
            val={itemCode}
          />
          <TextAreaComponents
            id={5}
            value={(e) => setProductName(e)}
            label={"Product Name"}
            val={productName}
          />
          <AutoCompleteMultiSelect
            datas={modelList}
            value={(e) => {
              if (e != null) {
                setModelId(e.id);
              } else {
                setClear(true);
                setModelId("");
              }
            }}
            id={1}
            label={"Model Id"}
            getOptionLabel={(option) => option.name}
            placeholder={"Model Id"}
            key={clear ? "clear" : "no-clear"}
            val={selectedModel}
          />

          <AutoCompleteMultiSelect
            datas={vatList}
            value={(e) => {
              if (e != null) {
                setVatId(e.id);
              } else {
                setClear3(true);
                setVatId("");
              }
            }}
            id={1}
            label={"Purchase Vat"}
            getOptionLabel={(option) => option.vatPercent}
            placeholder={"Purchase Vat"}
            key={clear3 ? "clear3" : "no-clear3"}
            val={selectedVat}
          />
        </div>
        <div className="col-12 col-md-4 col-lg-4 d-flex flex-column gap-3 ">
          <AutoCompleteMultiSelect
            datas={groupList}
            value={(e) => {
              if (e != null) {
                setItemGroupId(e.id);
              } else {
                setClear1(true);
                setItemGroupId("");
              }
            }}
            id={1}
            label={"Item Group"}
            getOptionLabel={(option) => option.groupName}
            placeholder={"Item Group"}
            key={clear1 ? "clear1" : "no-clear1"}
            val={selectedItemGroup}
          />

          <AutoCompleteMultiSelect
            datas={subgroupList}
            value={(e) => {
              if (e != null) {
                setItemSubGroupId(e.id);
              } else {
                setClear1(true);
                setItemSubGroupId("");
              }
            }}
            id={1}
            label={"Item SubGroup"}
            getOptionLabel={(option) => option.subGroupName}
            placeholder={"Item SubGroup"}
            key={clear4 ? "clear4" : "no-clear4"}
            val={selectedSubItemGroup}
          />

          <AutoCompleteMultiSelect
            datas={measurementList}
            value={(e) => {
              if (e != null) {
                setMeasurementUnitId(e.id);
              } else {
                setClear2(true);
                setMeasurementUnitId("");
              }
            }}
            id={1}
            label={"Measurement Unit"}
            getOptionLabel={(option) => option.unitDescription}
            placeholder={"Measurement Unit"}
            key={clear2 ? "clear2" : "no-clear2"}
            val={selectedMeasurementUnit}
          />

          {/* <AutoCompleteMultiSelect
            datas={groupList}
            value={(e) => {
              setItemGroupId(e.id);
              setClear1(false);
            }}
            id={1}
            label={"Model Id"}
            getOptionLabel={(option) => option.groupName}
            placeholder={"Model Id"}
            key={clear}
          />
          <AutoCompleteMultiSelect
            datas={measurementList}
            value={(e) => {
              setMeasurementUnitId(e.id);
              setClear2(false);
            }}
            id={1}
            label={"Measurement Unit "}
            getOptionLabel={(option) => option.unitDescription}
            placeholder={"Measurement Unit"}
            key={clear2}
          />
          <AutoCompleteMultiSelect
            datas={vatList}
            value={(e) => {
              setVatId(e.id);
              setClear3(false);
            }}
            id={1}
            label={"Purchase Vat"}
            getOptionLabel={(option) => option.vatPercent}
            placeholder={"Purchase Vat"}
            key={clear3}
          /> */}
        </div>

        <div className="col-12 col-md-4 col-lg-4 d-flex flex-column gap-3 ">
          <TextAreaComponents
            id={2}
            value={(e) => setRol(e)}
            label={"Rol"}
            val={rol}
          />
          <TextAreaComponents
            id={2}
            value={(e) => setSuggestedPrice(e)}
            label={"Suggested Price"}
            val={suggestedPrice}
          />
          <FormControl className="d-flex flex-row gap-3">
            <FormLabel id="demo-row-radio-buttons-group-label" className="mt-2">
              Status
            </FormLabel>
            <RadioGroup
              row
              defaultValue={radioGroupDefaultValue}
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              onChange={(event) => {
                setIsActive(event.target.value == "active" ? true : false);
              }}
              value={isActive ? "active" : "inactive"}
            >
              <FormControlLabel
                value="active"
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value="inactive"
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="mt-2">
        <ImageUploader
          imgData={(img) => {
            setlogoImg(img);
          }}
          previewImageData={(previewImageData) => {
            setBackgroundImagestate(previewImageData);
          }}
        />
        {BackgroundImagestate?.length > 0
          ? BackgroundImagestate?.map((image, index) => (
              <>
                <div
                  style={{
                    position: "relative",
                    paddingTop: "20px",
                  }}
                >
                  <img
                    src={image}
                    alt={`Preview ${index + 1}`}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      objectFit: "cover",
                      width: "6rem",
                    }}
                  />
                </div>
              </>
            ))
          : imageUrl != "" && (
              <img
                src={
                  process.env.REACT_APP_SECRET + "/Resources/Images/" + imageUrl
                }
                alt="Image"
                style={{
                  maxWidth: "100%",
                  maxHeight: "100%",
                  objectFit: "cover",
                  width: "6rem",
                  paddingTop: "20px",
                }}
              />
            )}
      </div>

      <div className="row mt-3">
        <div className="col-6"></div>
        <div className="col-6">
          <div className="d-flex gap-4 ">
            <button
              className="btn-yes btn-common"
              onClick={() => {
                Submit();
              }}
            >
              Save
            </button>
            <button
              className="btn-no btn-common"
              onClick={() => {
                handleClear();
              }}
            >
              Reset
            </button>
          </div>
        </div>
      </div>
      <div className="col-12 ">
        <div>
          <h5>Item List</h5>
        </div>
        <div>
          <DataTable
            pageState={akgpageState}
            GetPurchaseOrderNoAPi={GetPurchaseOrderNoAPi}
            isLoading={akgisLoading}
            rows={measuremntUnitRows}
            columns={MeasurmentUnitcolumns}
          />
        </div>
      </div>
    </div>
  );
}

export default ItemSetup;
