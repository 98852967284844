import React, { useState, useEffect } from "react";
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import { GetItem } from "../../../api/screenApi/purchaseOrder/purchaseOrderApi";
import { GetAllCustomerAPi } from "../../../api/salesAPi/salesOrder/SalesOrderApi";
import dayjs from "dayjs";
import cancel from "./../../../assests/images/remove.png";
import print from "./../../../assests/images/printing.png";
import axios from "axios";
import DataTable from "../../sharedComponent/dataTable/DataTable";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlertModal from "../../sharedComponent/SweetAlertModal/SweetAlertModal";
import { EntryNumberGenarator } from "../../sharedComponent/CommonMethods";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";
import ClientSiteDataTable from "../../sharedComponent/dataTable/ClientSiteDataTable.jsx";

function SalesOrder() {
  const apiAddress = process.env.REACT_APP_SECRET;
  const [OrderNo, setOrderNo] = useState();
  const [Flag, setFlag] = useState(false);
  const [customerClear, setCustomerClear] = useState(false);
  const [itemClear, setItemClear] = useState(false);
  const [ItemList, setItemList] = useState([]);
  const [CustomerList, setCustomerList] = useState([]);
  const [SelectedItem, setSelectedItem] = useState();
  const [AlertOpen, setAlertOpen] = useState(false);
  const [OrderDate, setOrderdate] = useState();
  const [CustomerId, setCustomerId] = useState();
  const [CustomerReferenceNo, setCustomerReferenceNo] = useState();
  const [Remarks, setRemarks] = useState();

  const [SelectedItemList, setSelectedItemList] = useState([]);
  const [SalesOrder, setSalesOrder] = useState({
    salesOrder: {},
  });
  const [Search, setSearch] = useState();
  //validation toaster start
  const errorToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  //validation toaster end

  //Sales order table start
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [akgrows, setAkgrows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const Cancelationtcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "cancelno", headerName: "SalesOrder No", flex: 1 },

    { field: "reason", headerName: "CustomerId", flex: 1 },
    {
      field: "canceldate",
      headerName: "Date",
      renderCell: (params) => (
        <span>{DateFormater(params.row.canceldate)}</span>
      ),
      flex: 1,
    },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              window.open(
                "/sales-order-report/" + params.row.cancelno,
                "popup",
                "width=850,height=550,left=280,top=80"
              );
            }}
          >
            <img src={print} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const salesOrderListPage = async (props, from) => {
    if (from != "Search") {
      setakgpageState((old) => ({ ...old, isLoading: true }));

      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesOrder/GetSalesOrderPaged?startRecordNo=" +
            props.page +
            "&rowPerPage=" +
            props.pageSize +
            "&whereClause=" +
            "1=1" +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          //order no genarate start
          var entryNo = EntryNumberGenarator(
            response.data._salesOrders[0].number,
            "INV1-SO-"
          );
          setOrderNo(entryNo);
          //order no genarate end
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data._salesOrders.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              cancelno: d.number,
              reason: d.orderFromCustomerId,
              canceldate: d.orderDate,
              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    } else {
      const newString = "'" + Search + "'";
      const condition = "Number=" + newString;
      setakgpageState((old) => ({ ...old, isLoading: true }));

      SetakgIsLoading(true);
      await axios
        .get(
          "" +
            process.env.REACT_APP_SECRET +
            "/api/SalesOrder/GetSalesOrderPaged?startRecordNo=" +
            0 +
            "&rowPerPage=" +
            1 +
            "&whereClause=" +
            condition +
            "&sortColumn=" +
            "number" +
            "&sortOrder=" +
            "desc" +
            ""
        )
        .then((response) => {
          setakgpageState((old) => ({
            ...old,
            isLoading: false,

            total: response.data._totalCount,
            page: akgpageState.page,
          }));
          var list = [];
          response.data._salesOrders.map((d, i) => {
            var obj = {
              id: i,
              sn: i + 1,
              cancelno: d.number,
              reason: d.orderFromCustomerId,
              canceldate: d.orderDate,
              remarks: d.remarks,
              // action: d.number,
            };
            list.push(obj);
          });
          setAkgrows(list);
          SetakgIsLoading(false);
        })
        .catch((error) => {
          console.log(error);
          SetakgIsLoading(false);
        });
    }
  };

  const onSearch = async () => {
    await salesOrderListPage("props", "Search");
  };
  const onClear = async () => {
    var props = {
      page: 0,
      pageSize: 10,
    };
    setSearch("");
    await salesOrderListPage(props, "Main");
  };

  const GetPurchaseOrderNoAPi = async (props) => {
    await salesOrderListPage(props, "Main");
  };
  //sales order table end

  const GetItemList = async () => {
    var res = await GetItem();

    setItemList(res);
  };

  const handleSelectedItem = (data) => {
    setSelectedItem(data);
  };
  const GetAllCustomer = async () => {
    var res = await GetAllCustomerAPi();
    setCustomerList(res);
  };

  useEffect(() => {
    GetItemList();
    GetAllCustomer();
  }, []);

  const removeItem = (index) => {
    var list = [...SelectedItemList];
    list.splice(index, 1);
    setSelectedItemList(list);
  };

  const onAdd = () => {
    var valid = true;
    if (!!CustomerId == false) {
      valid = false;
      errorToast("Customer not selected");
    }
    if (!!CustomerReferenceNo == false) {
      valid = false;
      errorToast("Customer reference is required");
    }
    if (!!Remarks == false) {
      valid = false;
      errorToast("Remarks is required");
    }
    if (!!SelectedItem == false) {
      valid = false;
      errorToast("Item not selected");
    }
    if (!!SelectedItem == true) {
      if (SelectedItemList.find((x) => x.itemId == SelectedItem.id)) {
        valid = false;
        errorToast("Item already selected");
      }
    }
    if (valid) {
      var salesobj = {
        number: "string",
        orderDate: OrderDate,
        counterId: 1,
        orderFromCustomerId: CustomerId,
        customerReferenceNo: CustomerReferenceNo,
        remarks: Remarks,
        isVoid: true,
        creatorId: 1,
        creationDate: dayjs(),
        updatorId: 1,
        updateDate: dayjs(),
        salesType: 1,
      };
      // setSalesOrder(salesobj);
      SalesOrder.salesOrder = salesobj;
      var list = [...SelectedItemList];
      var itemdetails = {
        id: list.length,
        salesOrderNumber: "string",
        itemId: SelectedItem.id,
        isVoid: true,
        requestedQty: 0,
        remarks: "string",
      };
      list.push(itemdetails);
      setSelectedItemList(list);
      setItemClear(true);
      setSelectedItem(null);
      setFlag(!Flag);
    }
  };

  const OrderListcolumns = [
    // { field: "serial", headerName: "Serial", flex: 1 },

    {
      field: "itemId",
      headerName: "Item Id",

      flex: 1,
    },

    {
      field: "requestedQty",
      headerName: "PO.Quantity",
      flex: 1,
      renderCell: (params) => (
        <div>
          <TextAreaComponents
            id={2}
            value={(e) => {
              params.row.requestedQty = parseInt(e);
            }}
            label={"Requested Qty"}
            placeholder={"Requested Qty"}
          />
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "remarks",
      headerName: "Remarks",
      flex: 1,
      renderCell: (params) => (
        <TextAreaComponents
          id={6}
          value={(e) => (params.row.remarks = e)}
          label={"Remarks"}
          placeholder={"Remarks"}
        />
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div
          className="d-flex icon gap-2 mt-2"
          onClick={() => removeRow(params.row)}
        >
          <img src={cancel} alt="img" />
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const removeRow = (data) => {
    var list = [...SelectedItemList];
    var obj = SelectedItemList.find((x) => x.id == data.id);
    var res = SelectedItemList.indexOf(obj);
    list.splice(res, 1);
    setSelectedItemList(list);
  };

  const onSave = () => {
    setAlertOpen(false);
    var valid = true;

    SalesOrder.salesOrderdetailsList = SelectedItemList;
    if (!!SelectedItemList.length == 0) {
      valid = false;
      errorToast("No item selected");
    }

    if (SelectedItemList.length > 0) {
      var res = SelectedItemList.find((x) => x.requestedQty == 0);
      if (!!res == true) {
        valid = false;
        errorToast("Item Qunatity can't be empty");
      }
    }
    if (!!OrderDate == false) {
      valid = false;
      errorToast("Date not selected");
    }
    if (valid) {
      var url = apiAddress + "/api/SalesOrder";
      axios.post(url, SalesOrder).then((response) => {
        successToast("Saved successfully");

        setSelectedItemList([]);
        setOrderdate(null);
        setRemarks("");
        setCustomerReferenceNo("");
        setSelectedItem({});
        setCustomerClear(true);
        setFlag(!Flag);
        var props = {
          page: 0,
          pageSize: 10,
        };

        salesOrderListPage(props, "Main");
      });
    }
  };

  const handleClear = () => {
    setSelectedItemList([]);
    setOrderdate(null);
    setRemarks("");
    setCustomerReferenceNo("");
    setSelectedItem({});
    setCustomerClear(true);
    setItemClear(true);
    setFlag(!Flag);
  };

  // useEffect(() => {
  //   setItemClear(false);
  // }, [Flag]);
  return (
    <>
      <ToastContainer />
      <SweetAlertModal
        open={AlertOpen}
        headerText={"Are you sure?"}
        bodyText={"You want to save?"}
        close={() => setAlertOpen(false)}
        onyes={onSave}
      />
      <div className="card card-shadow mt-3 p-3">
        <div className="row">
          <div className="col-12">
            <h6>Sales Order Entry</h6>
          </div>
        </div>
        <div className="row ">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <div>
              <TextAreaComponents
                id={5}
                // value={(e) => setStockReceiveNumber(e)}
                label={"Sales Order No"}
                val={OrderNo}
                isactive={true}
              />
            </div>
            <div>
              <CustomDateTimePicker
                value={(props) => setOrderdate(props)}
                label={"Sales Order date"}
                val={OrderDate}
              />
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 PurchaseOrderCard">
            <AutoCompleteMultiSelect
              datas={CustomerList}
              getOptionLabel={(option) => option.customerName}
              id={1}
              value={(e) => setCustomerId(e?.id)}
              label={"Customer Name"}
              placeholder={"--Customer Name--"}
              clear={customerClear}
            />

            <TextAreaComponents
              id={5}
              value={(e) => setCustomerReferenceNo(e)}
              label={"Customer Reference No"}
              // val={PurchaseBillNumber}
              val={CustomerReferenceNo}
            />
          </div>
          <div className="col-12">
            <div className="mt-2">
              <TextAreaComponents
                id={5}
                value={(props) => setRemarks(props)}
                label={"Remarks"}
                val={Remarks}
              />
            </div>
          </div>
          <div className="mt-2 d-flex gap-2">
            <div className="col-10">
              <AutoCompleteMultiSelect
                datas={ItemList}
                getOptionLabel={(option) => option.combination}
                id={1}
                value={handleSelectedItem}
                label={"Choose item no"}
                placeholder={"--Choose no--"}
                clear={itemClear}
              />
            </div>
            <div className="col-2">
              <button className="btn-yes btn-common" onClick={() => onAdd()}>
                Add
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="card card-shadow p-3 mt-3">
        <div>
          <h6>Selected Item</h6>
        </div>
        {/* <table class="table table-striped ">
          <thead
            style={{
              backgroundColor: "#2d358f",
              color: "white",
              
            }}
          >
            <tr>
              <th>Serial No</th>
              <th>Item Id</th>
             
              <th>PO. quentity</th>
              <th>Remarks</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {SelectedItemList.map((d, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>{d.itemId}</td>
                  
                  <td>
                    <TextAreaComponents
                      id={2}
                      value={(e) => (d.requestedQty = e)}
                      label={"Requested Qty"}
                      placeholder={"Requested Qty"}
                    />
                  </td>
                  <td>
                    <TextAreaComponents
                      id={6}
                      value={(e) => (d.remarks = e)}
                      label={"Remarks"}
                      placeholder={"Remarks"}
                    />
                  </td>
                  <td>
                    <div
                      className="d-flex icon gap-2 mt-2"
                      onClick={() => removeItem(i)}
                    >
                      <img src={cancel} alt="img" />
                     
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table> */}

        {SelectedItemList.length > 0 ? (
          <div className="card card-shadow p-3 mt-3">
            <div>
              <h6>Selected Item</h6>
            </div>
            <ClientSiteDataTable
              dataRows={SelectedItemList}
              columns={OrderListcolumns}
              // isActive={true}
              // getselectedData={getSelectedItems}
            />
          </div>
        ) : (
          ""
        )}
        <div className="row mt-3">
          <div className="col-4">
            <div className="d-flex gap-4 ">
              <button
                className="btn-yes btn-common"
                onClick={() => setAlertOpen(true)}
              >
                Save
              </button>
              <button className="btn-no btn-common" onClick={handleClear}>
                Clear
              </button>
              {/* <button className="btn-Review btn-common">Review</button> */}
            </div>
          </div>
        </div>
      </div>

      <div className="card p-3 card-shadow acknowledgementCard mt-3">
        <div>
          <h6>Sales Order List</h6>
        </div>
        <div className="row mb-2">
          <div className="col-12 col-md-12 col-lg-6 d-flex flex-column gap-2 ">
            <TextAreaComponents
              id={6}
              value={(props) => setSearch(props)}
              label={"Search"}
              val={Search}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 d-flex gap-2 gap-md-2">
            <button className="btn-search btn-common" onClick={onSearch}>
              Search
            </button>
            <button className="btn-search btn-common" onClick={onClear}>
              Clear
            </button>
          </div>
        </div>
        <DataTable
          pageState={akgpageState}
          GetPurchaseOrderNoAPi={GetPurchaseOrderNoAPi}
          isLoading={akgisLoading}
          rows={akgrows}
          columns={Cancelationtcolumns}
        />
      </div>
    </>
  );
}

export default SalesOrder;
