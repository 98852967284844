import axios from "axios";
import React from "react";

const apiAddress = process.env.REACT_APP_SECRET;

export const GetSupplierAPi = async (props) => {
  var res = await axios.get(apiAddress + "/api/Supplier").then((response) => {
    return response.data;
  });

  return res;
};

export const GetEmployeeAPi = async (props) => {
  var res = await axios.get(apiAddress + "/api/Employee").then((response) => {
    return response.data;
  });

  return res;
};

export const GetItem = async (props) => {
  var res = await axios.get(apiAddress + "/api/Item").then((response) => {
    return response.data;
  });

  return res;
};

export const GetPurchaseOrderNoAPi = async (props) => {
  var res = await axios
    .get(
      `${
        process.env.REACT_APP_SECRET
      }/api/PurchaseOrder/GetPurchaseOrderPaged?startRecordNo=${1}&&rowPerPage=${5}&whereClause=${"1=1"}&&sortColumn=${"number"}&&sortOrder=${"desc"}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
    });

  return res;
};

export const PostPurchaseOrderAPi = async (props) => {
  var url = apiAddress + "/api/PurchaseOrder";
  var res = await axios.post(url, props).then((response) => {});
  return res;
};
