import React, { useEffect } from 'react';
import AOS from "aos";

const Leaders = () => {
    useEffect(() => {
        AOS.init({
          easing: "ease-in-sine",
        });
      }, []);
    return (
        <div id='procurement' className='p-12 lg:p-24 my-20 bg-gray-100'>
            <h2 className='text-lg uppercase font-semibold text-purple-700 mb-2'>how membership works</h2>
            <h2 className='text-4xl font-bold'>Procurement Leaders Approach</h2>

            {/* cards */}
            <div 
            data-aos="fade-up" 
            className='mt-8 lg:mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-2 lg:border-4 lg:border-purple-700 py-16 px-10 lg:px-20 rounded-full'>
                <div>
                    <p className='text-2xl font-semibold text-purple-700'>1</p>
                    <p className='uppercase text-lg my-1'>BENCHMARK</p>
                    <p className=''>Benchmark priorities with <br /> the benchmarking <br /> diagnostic</p>
                </div>
                <div>
                    <p className='text-2xl font-semibold text-purple-700'>2</p>
                    <p className='uppercase text-lg my-1'>MAP AND PRIORITISE</p>
                    <p className=''>Identify the right priorities <br /> to have the most impact</p>
                </div>
                <div>
                    <p className='text-2xl font-semibold text-purple-700'>3</p>
                    <p className='uppercase text-lg my-1'>BUILD VALUE PLAN</p>
                    <p className=''>A tailored roadmap to <br /> achieve business and <br /> functional success</p>
                </div>
                <div>
                    <p className='text-2xl font-semibold text-purple-700'>4</p>
                    <p className='uppercase text-lg my-1'>ADOPT EXCELLENCE</p>
                    <p className=''>Through strategy insights, <br /> applications and <br /> connections</p>
                </div>
                <div>
                    <p className='text-2xl font-semibold text-purple-700'>5</p>
                    <p className='uppercase text-lg my-1'>MEASURE EXCELLENCE</p>
                    <p className=''>Measure progress & ROI</p>
                </div>
            </div>
        </div>
    );
};

export default Leaders;