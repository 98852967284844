import React, { useEffect } from 'react';
import goal1 from '../../../assests/images/goals1.jpg'
import goal2 from '../../../assests/images/goals2.webp'
import goal3 from '../../../assests/images/goals3.jpg'
import goal4 from '../../../assests/images/goals4.webp'
import goal5 from '../../../assests/images/goals5.jpg'
import AOS from "aos";

const Goals = () => {
    useEffect(() => {
        AOS.init({
          easing: "ease-in-sine",
        });
      }, []);

    return (
        <div id='goals' className='my-24'>
            <h2 className='font-bold text-4xl mb-10 text-center'>Goals</h2>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5 w-[90%] mx-auto' 
            data-aos="fade-up"
            >
                <div className="card card-compact w-96 bg-gray-100 shadow-xl">
                    <figure><img src={goal1} alt="Client Satisfaction" className='h-56 w-full' /></figure>
                    <div className="card-body">
                        <h2 className="card-title">Client Satisfaction</h2>
                        <p>Achieve and maintain a high level of client satisfaction by consistently delivering quality products, efficient services, and cost-effective solutions.</p>
                        {/* <div className="card-actions justify-end">
                            <button className="btn btn-primary">Buy Now</button>
                        </div> */}
                    </div>
                </div>
                <div className="card card-compact w-96 bg-gray-100 shadow-xl">
                    <figure><img src={goal2} alt="Market Leadership"  className='h-56 w-full' /></figure>
                    <div className="card-body">
                        <h2 className="card-title">Market Leadership</h2>
                        <p>Establish A&H Solutions as the leading procurement service provider in Bangladesh, recognized for reliability, transparency, and innovation.</p>
                        {/* <div className="card-actions justify-end">
                            <button className="btn btn-primary">Buy Now</button>
                        </div> */}
                    </div>
                </div>
                <div className="card card-compact w-96 bg-gray-100 shadow-xl">
                    <figure><img src={goal3} alt="Global Reach"  className='h-56 w-full' /></figure>
                    <div className="card-body">
                        <h2 className="card-title">Global Reach</h2>
                        <p>Expand our procurement network by exploring international markets and forging strategic partnerships with suppliers worldwide.</p>
                        {/* <div className="card-actions justify-end">
                            <button className="btn btn-primary">Buy Now</button>
                        </div> */}
                    </div>
                </div>
            </div>
            <div className='flex flex-col lg:flex-row justify-center gap-5 mt-5 w-[90%] mx-auto'>
                <div className="card card-compact w-96 bg-gray-100 shadow-xl">
                    <figure><img src={goal4} alt="Employee Development"  className='h-56 w-full' /></figure>
                    <div className="card-body">
                        <h2 className="card-title">Employee Development</h2>
                        <p>Foster a culture of continuous learning and professional growth for our team, ensuring they are equipped with the skills to excel in the dynamic field of procurement.</p>
                        {/* <div className="card-actions justify-end">
                            <button className="btn btn-primary">Buy Now</button>
                        </div> */}
                    </div>
                </div>
                <div className="card card-compact w-96 bg-gray-100 shadow-xl">
                    <figure><img src={goal5} alt="Social Impact"  className='h-56 w-full' /></figure>
                    <div className="card-body">
                        <h2 className="card-title">Social Impact</h2>
                        <p>Contribute to poverty reduction by creating job opportunities, promoting ethical business practices, and engaging in community development initiatives.</p>
                        {/* <div className="card-actions justify-end">
                            <button className="btn btn-primary">Buy Now</button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Goals;