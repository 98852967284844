import React, { useEffect } from 'react';
import mission from '../../../assests/images/mission.jpg';
import AOS from "aos";

const Mission = () => {
    useEffect(() => {
        AOS.init({
          easing: "ease-in-sine",
        });
      }, []);

    return (
        <div id='mission' className='my-16'>
            <div className='flex flex-col-reverse lg:flex-row gap-3 lg:gap-0 items-center justify-center'>
                <div className='flex-1 flex justify-center' 
                data-aos="fade-down"
                >
                    <div className=' border-[4px] rounded-lg'>
                        <img src={mission} className=" max-w-xs lg:max-w-md p-2 rounded-xl relative left-6 top-6" alt="" />

                    </div>
                </div>
                <div className='flex-1  ml-5 lg:ml-0' 
                data-aos="fade-up"
                >
                    <h2 className='font-bold text-4xl mb-4'>Mission Statement</h2>
                    <hr className='mb-10 w-1/2 text-blue-400 border-[3px]' />
                    <p className='lg:max-w-xl max-w-md'>
                        A&H Solutions is dedicated to revolutionizing the procurement landscape in Bangladesh. We commit to providing unparalleled procurement services to our corporate clients, ensuring seamless sourcing, quality control, and cost efficiency. Our mission is to become the trusted partner for businesses, streamlining their procurement processes and contributing to their success.
                    </p>
                </div>
            </div>

        </div>
    );
};

export default Mission;