import React, { useEffect, useRef, useState } from "react";
import TextAreaComponents from "../../sharedComponent/TextAreaComponents/TextAreaComponents";
import AutoCompleteMultiSelect from "../../sharedComponent/AutoCompleteMultiselect/AutoCompleteMultiSelect.tsx";
import CustomDateTimePicker from "../../sharedComponent/datetimePicker/CustomDateTimePicker";
import print from "./../../../assests/images/printing.png";
import edit from "./../../../assests/images/edit.png";
import axios from "axios";
import checkMark from "./../../../assests/images/check-mark.png";

import "./stockReceive.css";
import {
  GetEmployeeAPi,
  GetItem,
  GetSupplierAPi,
} from "../../../api/screenApi/purchaseOrder/purchaseOrderApi";
import cancel from "./../../../assests/images/close.png";

import DataTable from "../../sharedComponent/dataTable/DataTable";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SweetAlertModal from "../../sharedComponent/SweetAlertModal/SweetAlertModal";
import { GetPurchaseBillByNumber } from "../../../api/screenApi/purchaseBill/purchaseBillApi";
import { DataGrid } from "@mui/x-data-grid";
import ClientSiteDataTable from "../../sharedComponent/dataTable/ClientSiteDataTable";
import DateFormater from "../../sharedComponent/DateFormater/DateFormater";
import { EntryNumberGenarator } from "../../sharedComponent/CommonMethods";
import ReceiveReport from "./ReceiveReport/ReceiveReport";
import { getAllIssueDetailsByNumberAPI } from "../../../api/stockIssueAPI/StockIssueAPI.js";

function StockReceive(props) {
  const apiAddress = process.env.REACT_APP_SECRET;
  const [AlertOpen, setAlertOpen] = useState(false);

  const initialValue = [
    { id: 1, title: "Purchase Bill" },
    { id: 2, title: "Sales Return" },
  ];
  //Stock Receive entry states start
  const [StockReceiveNumber, setStockReceiveNumber] = useState();
  const [clearAutoComplete, setclearAutoComplete] = useState(false);
  const [chalanNo, setChalanNo] = useState("");
  const [SelectedReceivedBy, setSelectedReceivedBy] = useState(
    initialValue[0].title
  );
  const [DateTime, setDateTime] = useState();
  const [chalanDatetime, setchalanDatetime] = useState();
  const [Remarks, setRemarks] = useState();
  const [Search, setSearch] = useState();
  const [stockReceiveView, setStockReceiveView] = useState({});
  const [selectedPb, setSellectedPb] = useState();
  const [PurchaseBillDetailsList, setPurchaseBillDetailsList] = useState([]);
  // //supplier states
  const [SupplierList, setSupplierList] = useState([]);
  const [SelectedSupplier, setSelectedSupplier] = useState();

  const [referenceId, SetreferenceId] = useState();

  //Acknowledgement table start
  const [akgisLoading, SetakgIsLoading] = React.useState(false);
  const [akgrows, setAkgrows] = React.useState([]);

  const [akgpageState, setakgpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  //validation toaster start
  const errorToast = (msg) => {
    toast.error(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const successToast = (msg) => {
    toast.success(msg, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  //validation toaster end

  const Acknowledgementcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "stockNo", headerName: "StockRecive No", flex: 1 },
    {
      field: "stockdate",
      headerName: "StockRecive Date",
      renderCell: (params) => <span>{DateFormater(params.row.stockdate)}</span>,
      flex: 1,
    },
    { field: "supplier", headerName: "Supplier or Customer Name", flex: 1 },
    {
      field: "referenceTransactionNo",
      headerName: "Reference Tran. No",
      flex: 1,
    },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div
          onClick={() => {
            window.open(
              "/receive-report/" + params.row.stockNo,
              "popup",
              "width=850,height=550,left=280,top=80"
            );
          }}
        >
          <img src={print} alt="" style={{ width: "1rem" }} />
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];

  const Itemscolumns = [
    { field: "itemId", headerName: "ItemId", flex: 1 },
    { field: "itemName", headerName: "Item Name", flex: 1 },
    { field: "qty", headerName: "Quantity", flex: 1 },
  ];

  const ReceivedItemscolumns = [
    { field: "itemId", headerName: "ItemId", flex: 1 },
    { field: "itemName", headerName: "Item Name", flex: 1 },
    { field: "qty", headerName: "Quantity", flex: 1 },
    {
      field: "receivedQty",
      headerName: "Received Qty",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <TextAreaComponents
            id={2}
            value={(e) => (params.row.receivedQty = parseInt(e))}
            label={"Received Qty"}
            defaultValue={params.row.receivedQty}
          />
        </div>
      ),
    },
  ];
  const [selectedItems, setSelectedItems] = useState([]);
  const getSelectedItems = (props) => {
    var lists = [];

    props.map((dt, i) => {
      var obj = billItemDetailsrows.find((x) => x.id == dt);
      if (obj != undefined) {
        obj.receivedQty = obj.qty;
        lists.push(obj);
      }
    });
    setSelectedItems(lists);
  };

  const GetPurchaseOrderNoAPi = async (props) => {
    setakgpageState((old) => ({ ...old, isLoading: true }));

    SetakgIsLoading(true);
    await axios
      .get(
        "" +
          process.env.REACT_APP_SECRET +
          "/api/StockReceive/GetPurchaseAcknowledgementPaged?startRecordNo=" +
          props.page +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          "1=1" +
          "&sortColumn=" +
          "number" +
          "&sortOrder=" +
          "desc" +
          ""
      )
      .then((response) => {
        setakgpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: akgpageState.page,
        }));
        var list = [];
        response.data._stockRecive.map((d, i) => {
          var obj = {
            id: i,
            sn: i + 1,
            stockNo: d.number,
            stockdate: d.stockReceiveDate,
            supplier: d.supplierName,
            remarks: d.remarks,
            referenceTransactionNo: d.referenceTransactionNo,
            // action: d.number,
          };
          list.push(obj);
        });
        var entryNo = EntryNumberGenarator(
          response.data._stockRecive[0].number,
          "INV1-SR-"
        );
        setStockReceiveNumber(entryNo);
        setAkgrows(list);
        SetakgIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        SetakgIsLoading(false);
      });
  };
  //Acknowledgement table end

  //bill table start
  const Billcolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "billNo", headerName: "Bill No", flex: 1 },
    {
      field: "billDate",
      headerName: "Bill Date",
      flex: 1,
      renderCell: (params) => <span>{DateFormater(params.row.billDate)}</span>,
    },
    { field: "supplier", headerName: "Supplier Name", flex: 1 },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onCheckClick(params.row);
            }}
          >
            <img src={checkMark} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
  const salesReturncolumns = [
    { field: "sn", headerName: "IS/N", flex: 1 },
    { field: "number", headerName: "Issue Number", flex: 1 },
    {
      field: "issueDate",
      headerName: "Issue Date",
      flex: 1,
      renderCell: (params) => <span>{DateFormater(params.row.issueDate)}</span>,
    },
    { field: "referenceNumber", headerName: "PB Number", flex: 1 },
    { field: "customerName", headerName: "Customer Name", flex: 1 },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <div className="d-flex gap-3" style={{ gap: "10px" }}>
          <div
            onClick={() => {
              onCheckClick(params.row);
            }}
          >
            <img src={checkMark} alt="" style={{ width: "1rem" }} />
          </div>
        </div>
      ),
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
  ];
  const [billrows, setBillrows] = React.useState([]);

  const [isLoading, SetisLoading] = React.useState(false);

  const [pageState, setpageState] = React.useState({
    isLoading: false,

    total: 0,
    page: 1,
    pageSize: 10,
  });

  const GetPurchaseBillPage = async (props) => {
    setpageState((old) => ({ ...old, isLoading: true }));

    SetisLoading(true);
    await axios
      .get(
        "" +
          process.env.REACT_APP_SECRET +
          "/api/PurchaseBill/GetPurchaseBillPagedInAcknowledgement?startRecordNo=" +
          props.page +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          "1=1" +
          "&sortColumn=" +
          "number" +
          "&sortOrder=" +
          "desc" +
          ""
      )
      .then((response) => {
        setpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: pageState.page,
        }));
        var list = [];
        response.data._purchaseBill.map((d, i) => {
          var obj = {
            id: i,
            sn: i + 1,
            billNo: d.number,
            billDate: d.billDate,
            supplier: d.supplierName,
            remarks: d.remarks,
            fulllRow: d,
            supplierId: d.supplierId,
          };
          list.push(obj);
        });
        setBillrows(list);
        SetisLoading(false);
      })
      .catch((error) => {
        console.log(error);
        SetisLoading(false);
      });
  };

  const GetStockIssuePage = async (props) => {
    setpageState((old) => ({ ...old, isLoading: true }));
    SetisLoading(true);
    await axios
      .get(
        "" +
          process.env.REACT_APP_SECRET +
          "/api/StockReceive/GetStockReturnGetPaged?startRecordNo=" +
          props.page +
          "&rowPerPage=" +
          props.pageSize +
          "&whereClause=" +
          "1=1" +
          "&sortColumn=" +
          "number" +
          "&sortOrder=" +
          "desc" +
          ""
      )
      .then((response) => {
        setpageState((old) => ({
          ...old,
          isLoading: false,

          total: response.data._totalCount,
          page: pageState.page,
        }));
        var list = [];
        response.data.stockIssues.map((d, i) => {
          d.id = i + 1;
          d.sn = i + 1;
          list.push(d);
        });
        setBillrows(list);
        SetisLoading(false);
      })
      .catch((error) => {
        console.log(error);
        SetisLoading(false);
      });
  };

  //bill table end
  const [billItemDetailsrows, setBillItemDetailsrows] = React.useState([]);
  const onCheckClick = async (data) => {
    if (SelectedReceivedBy.id == 1) {
      SetreferenceId(data.billNo);
      var res = await GetPurchaseBillByNumber(data.billNo);
      setSelectedSupplier(data.supplierId);
      setBillItemDetailsrows(res.purchaseBillDetailsList);
    } else {
      SetreferenceId(data.referenceNumber);
      setSelectedSupplier(data.customerId);
      var res = await getAllIssueDetailsByNumberAPI(data.number);
      setBillItemDetailsrows(res.issueDetails);
    }
  };
  const GetSupplier = async () => {
    var list = [];
    var res = await GetSupplierAPi();

    setSupplierList(res);
  };

  useEffect(() => {
    GetSupplier();
    setSelectedReceivedBy({ id: 1 });
    GetPurchaseBillPage({
      page: 0,
      pageSize: 10,
    });
  }, []);
  const onSave = async () => {
    setAlertOpen(false);
    var valid = true;
    if (!!referenceId == false) {
      valid = false;
      errorToast("No bill selected");
    }
    if (!!Remarks == false) {
      valid = false;
      errorToast("Please insert Remarks");
    }
    if (!!DateTime == false) {
      valid = false;
      errorToast("Recevied date invalid");
    }
    if (!!chalanDatetime == false) {
      valid = false;
      errorToast("Chalan Date invalid");
    }
    if (selectedItems.length == 0) {
      valid = false;
      errorToast("No item selected");
    }
    if (valid) {
      stockReceiveView.stockReceive = {
        number: "123",
        stockReceiveDate: DateTime,
        chalanNo: chalanNo,
        chalanDate: chalanDatetime,
        SupplierIDOrCustomerId: SelectedSupplier,
        referenceTransactionNo: referenceId,
        remarks: Remarks,
        supplierName: "n/a",
        employeeName: "N/A",
        isVoid: true,
        ReceiveType: SelectedReceivedBy.id,
        creatorID: 1,
        creationDate: dayjs(),
        updatorID: 1,
        updateDate: dayjs(),
      };
      var list = [];
      selectedItems.map((d, i) => {
        var obj = {
          id: 0,
          stockReceiveNumber: "string",
          itemId: d.itemId,
          qty: d.qty,
          productName: "N/A",
        };
        list.push(obj);
      });

      stockReceiveView.stockReceiveDetailsList = list;

      var url = apiAddress + "/api/StockReceive";

      axios.post(url, stockReceiveView).then((response) => {
        GetPurchaseOrderNoAPi({
          page: 0,
          pageSize: 10,
        });
        GetPurchaseBillPage({
          page: 0,
          pageSize: 10,
        });
        setSelectedItems([]);
        setBillItemDetailsrows([]);
        setChalanNo("");
        setDateTime(null);
        setRemarks("");
        SetreferenceId("");
        setclearAutoComplete(true);
        setchalanDatetime(null);
      });
    }
  };

  return (
    <div>
      <ToastContainer />
      <SweetAlertModal
        open={AlertOpen}
        headerText={"Are you sure?"}
        bodyText={"You want to save?"}
        close={() => setAlertOpen(false)}
        onyes={onSave}
      />
      <h5 className="">Stock Receive</h5>
      <div className="card card-shadow">
        <div className="row p-3">
          <div className="col-12 mt-2">
            <AutoCompleteMultiSelect
              datas={initialValue}
              id={1}
              getOptionLabel={(option) => option.title}
              value={(e) => {
                setSelectedReceivedBy(e);
                GetStockIssuePage({
                  page: 0,
                  pageSize: 10,
                });
              }}
              label={"Received Type"}
              placeholder={"--Received Type--"}
              defaultValue={initialValue[0]}
              clear={clearAutoComplete}
            />
          </div>
        </div>
      </div>

      {SelectedReceivedBy.id == 1 ? (
        <div className="card p-3 card-shadow acknowledgementCard mt-3">
          <div>
            <h6>Purchase Bill List</h6>
          </div>
          <DataTable
            pageState={pageState}
            GetPurchaseOrderNoAPi={GetPurchaseBillPage}
            isLoading={isLoading}
            rows={billrows}
            columns={Billcolumns}
          />
        </div>
      ) : (
        ""
      )}

      {SelectedReceivedBy.id == 2 ? (
        <div className="card card-shadow p-3 mt-3">
          <div>
            <h6>Purchase Return List</h6>
          </div>
          <DataTable
            pageState={pageState}
            GetPurchaseOrderNoAPi={GetStockIssuePage}
            isLoading={isLoading}
            rows={billrows}
            columns={salesReturncolumns}
          />
        </div>
      ) : (
        ""
      )}
      <div className="card card-shadow mt-3">
        <div className="row p-3">
          <div className="col-12 col-md-12 col-lg-6 mt-2">
            <TextAreaComponents
              id={6}
              // value={(e) => setStockReceiveNumber(e)}
              // label={"Stock Received No"}
              value={(ease) => {}}
              val={StockReceiveNumber}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 mt-2">
            <TextAreaComponents
              id={6}
              value={(e) => setChalanNo(e)}
              label={"Chalan Number"}
              val={chalanNo}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 mt-2">
            <CustomDateTimePicker
              value={(props) => setchalanDatetime(props)}
              label={"Chalan Date"}
              val={chalanDatetime}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6  mt-2">
            <CustomDateTimePicker
              value={(props) => setDateTime(props)}
              label={"Receive Date"}
              val={DateTime}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 mt-2">
            <TextAreaComponents
              id={6}
              placeholder={"Reference Id"}
              val={referenceId}
            />
          </div>
          <div className="col-12 col-md-12 col-lg-6 mt-2">
            <TextAreaComponents
              id={6}
              value={(props) => setRemarks(props)}
              label={"Remarks"}
              val={Remarks}
            />
          </div>
        </div>
      </div>
      <div className="card card-shadow p-3 mt-3">
        <div>
          {SelectedReceivedBy.id == 1 ? (
            <h6>Ordered Items</h6>
          ) : (
            <h6>Sold Items</h6>
          )}
        </div>
        {billItemDetailsrows.length > 0 ? (
          <ClientSiteDataTable
            dataRows={billItemDetailsrows}
            columns={Itemscolumns}
            isActive={true}
            getselectedData={getSelectedItems}
          />
        ) : (
          ""
        )}
      </div>

      <div className="card card-shadow p-3 mt-3">
        <div>
          <h6>Received Item</h6>
        </div>
        {selectedItems.length > 0 ? (
          <ClientSiteDataTable
            dataRows={selectedItems}
            columns={ReceivedItemscolumns}
            // getselectedData={getSelectedItems}
          />
        ) : (
          ""
        )}
      </div>

      <div className="row mt-3">
        <div className="col-4">
          <div className="d-flex gap-4 ">
            <button
              className="btn-yes btn-common"
              onClick={() => {
                setAlertOpen(true);
              }}
            >
              Save
            </button>
            <button className="btn-no btn-common">Clear</button>
          </div>
        </div>
      </div>
      <div className="card card-shadow p-3 mt-3">
        <div>
          <h6>Stock Receive List</h6>
        </div>
        <div className="mt-2">
          <TextAreaComponents
            id={6}
            value={(props) => setSearch(props)}
            label={"Search"}
          />
        </div>
        <DataTable
          pageState={akgpageState}
          GetPurchaseOrderNoAPi={GetPurchaseOrderNoAPi}
          isLoading={akgisLoading}
          rows={akgrows}
          columns={Acknowledgementcolumns}
        />
      </div>
    </div>
  );
}

export default StockReceive;
