import axios from "axios";
import React from "react";

const apiAddress = process.env.REACT_APP_SECRET;

export const GetAllCustomerApi = async () => {
  var res = await axios.get(apiAddress + "/api/Customer").then((response) => {
    return response.data;
  });

  return res;
};