import React, { useEffect } from "react";
// import Login from "../login/Login";
// import PurchaseOrder from "./purchaseOrder/PurchaseOrder";
// import PurchaseBillCancelation from "./purchaseBillcancelation/PurchaseBillCancelation";
// import PurchaseBillReturn from "./purchaseBillReturn/PurchaseBillReturn";
// import StockReceive from "./stockReceive/StockReceive";
import billImg from "./../../assests/images/bill.png";
import orderImg from "./../../assests/images/checklist.png";
import ackImg from "./../../assests/images/acknowledgement.png";
import stimg from "./../../assests/images/stock.png";
import suppimg from "./../../assests/images/supplier.png";
import purimg from "./../../assests/images/purpose.png";
import igimg from "./../../assests/images/ItemGroup.png";
import isgimg from "./../../assests/images/ItemSubGroup.png";
import empimg from "./../../assests/images/employee.png";
import cusimg from "./../../assests/images/customer.png";
import userimg from "./../../assests/images/user.png";
import priceimg from "./../../assests/images/pricing.png";
import { getAPI } from "../../api/screenApi/ScreenApi";
// import StockIssue from "./stockIssue/StockIssue";
import stockissueimg from "./../../assests/images/stockissue.png";
import backimg from "./../../assests/images/back.png";
// import StockAdjustment from "./stockAdjustment/StockAdjustment";
import stockadjimg from "./../../assests/images/stockAdjustment.png";
import MeasurementUnitSetup from "./measuremnetUnit/MeasurementUnitSetup";
import ItemSetup from "./itemSetup/ItemSetup";
import SupplierSetup from "./supplierSetup/SupplierSetup";
import PurposeSetup from "./purpose/PurposeSetup";
import ItemGroupSetup from "./itemGroup/ItemGroupSetup";
import ItemSubGroupSetup from "./itemSubGroup/ItemSubGroupSetup";
import EmployeeSetup from "./employee/EmployeeSetup";
import CustomerSetup from "./customer/CustomerSetup";
import CustomerUserSetup from "./customer/CustomerUserSetup";
import ItemPricingSetup from "./itemSetup/ItemPricingSetup/ItemPricingSetup";
// import PurchaseAcknowledegement from "./purchaseAcknowledgement/PurchaseAcknowledegement";

function Purchase(props) {
  const [menuSelection, setMenuSelection] = React.useState(0);
  const [screenDetails, setScreenDetails] = React.useState(0);
  const [toggleSideBar, setToggleSideBar] = React.useState(false);
  const changeMenu = (props) => {
    setMenuSelection(props);
  };
  useEffect(() => {
    var dt = getAPI(1);
    console.log(dt);
    setScreenDetails(dt);
  }, []);

  return (
    <div>
      <div className="d-flex mt-2">
        <div className="" style={{ position: "relative" }}>
          <div
            className={` backImgDiv ${
              toggleSideBar ? "backImgDivOpen" : "backImgDivClose"
            } `}
            style={{ transition: "all .5s" }}
            onClick={() => {
              setToggleSideBar(!toggleSideBar);
            }}
          >
            <img src={backimg} alt="" />
          </div>
          {/* <div className='searchBoxForProduct' style={{ padding: "10px" }}>
                        <input type='text' placeholder='Search' className='form-control searchBar' />

                    </div> */}
          <div
            className={`scrollable-div ${
              toggleSideBar ? "sideBarOff" : "sideBarOn"
            } `}
            style={{ transition: "width .5s" }}
          >
            <h6 className=" ms-3 mt-2" style={{ fontSize: "13px" }}>
              <b>Admin</b>
            </h6>
            <hr className="" style={{ margin: ".5rem" }} />

            <div
              className={`menuItem ${menuSelection == 0 ? "active" : ""}`}
              style={{
                width: "100%",
                height: "2.5rem",
                transition: "background-color 0.3s",
              }}
              onClick={() => changeMenu(0)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={orderImg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Item Setup{" "}
                </p>
              </div>
            </div>

            <div
              className={`menuItem ${menuSelection == 1 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(1)}
            >
              <div
                className="  d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={billImg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Measurement Unit
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 2 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(2)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={suppimg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Supplier
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 3 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(3)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={purimg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Purpose
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 4 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(4)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={igimg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Item Group
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 5 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(5)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={isgimg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Item Sub Group
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 6 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(6)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={empimg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Employee
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 7 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(7)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={cusimg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Customer
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 8 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(8)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={userimg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  User
                </p>
              </div>
            </div>
            <div
              className={`menuItem ${menuSelection == 9 ? "active" : ""}`}
              style={{ width: "100%", height: "2.5rem" }}
              onClick={() => changeMenu(9)}
            >
              <div
                className=" d-flex justify-content-start align-items-center ms-4"
                style={{ height: "100%", gap: "10px" }}
              >
                <img
                  src={priceimg}
                  alt="leo"
                  style={{ width: "1.2rem", height: "1.2rem" }}
                />
                <p
                  style={{
                    color: "black",
                    fontWeight: "500",
                    marginBottom: "0px",
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  Item Pricing
                </p>
              </div>
            </div>
          </div>
        </div>

        {menuSelection === 1 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <MeasurementUnitSetup />
          </div>
        ) : (
          ""
        )}
        {menuSelection === 0 ? (
          <div
            className="sidePanelView"
            style={{
              marginLeft: "10px",
              width: "300px",
            }}
          >
            <ItemSetup />
          </div>
        ) : (
          ""
        )}

        {menuSelection === 2 ? (
          <div className="" style={{ marginLeft: "10px", width: "100%" }}>
            <SupplierSetup />
          </div>
        ) : (
          ""
        )}
        {menuSelection === 3 ? (
          <div className="" style={{ marginLeft: "10px", width: "100%" }}>
            <PurposeSetup />
          </div>
        ) : (
          ""
        )}

        {menuSelection === 4 ? (
          <div className="" style={{ marginLeft: "10px", width: "100%" }}>
            <ItemGroupSetup />
          </div>
        ) : (
          ""
        )}
        {menuSelection === 5 ? (
          <div className="" style={{ marginLeft: "10px", width: "100%" }}>
            <ItemSubGroupSetup />
          </div>
        ) : (
          ""
        )}
        {menuSelection === 6 ? (
          <div className="" style={{ marginLeft: "10px", width: "100%" }}>
            <EmployeeSetup />
          </div>
        ) : (
          ""
        )}
        {menuSelection === 7 ? (
          <div className="" style={{ marginLeft: "10px", width: "100%" }}>
            <CustomerSetup />
          </div>
        ) : (
          ""
        )}
        {menuSelection === 8 ? (
          <div className="" style={{ marginLeft: "10px", width: "100%" }}>
            <CustomerUserSetup />
          </div>
        ) : (
          ""
        )}
        {menuSelection === 9 ? (
          <div className="" style={{ marginLeft: "10px", width: "100%" }}>
            <ItemPricingSetup />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
export default Purchase;
