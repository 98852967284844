import axios from "axios";
import React from "react";

const apiAddress = process.env.REACT_APP_SECRET;

export const GetAllMeasurementApi = async (props) => {
  var res = await axios
    .get(apiAddress + "/api/MeasurementUnit")
    .then((response) => {
      return response.data;
    });

  return res;
};
