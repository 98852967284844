import React from "react";

function PageNotFound(props) {
  return (
    <div className="d-flex justify-content-center align-items-center w-100">
      <div style={{ width: "40rem" }}>
        <img
          src={require("./../../assests/images/13315300_5203299.jpg")}
          style={{ objectFit: "cover" }}
          className="img-fluid"
        />
      </div>
    </div>
  );
}

export default PageNotFound;
