import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import DateFormater from "../../../../sharedComponent/DateFormater/DateFormater";

function ItemPricingDetailsReport(props) {
  const apiAddress = process.env.REACT_APP_SECRET;
  const componentRef = useRef();
  let { id } = useParams();

  const [itemPricingDetails, setitemPricingDetails] = useState({});
  const [itemPricingAddtionalDetails, setItemPricingAddtionalDetails] = useState([]);
  const [itemPricingCustomerDetails, setitemPricingCustomerDetails] = useState([]);
  console.log(id);
  useEffect(() => {
    axios
      .get(
        apiAddress +
          "/api/Item/getItemPricingDetailsById?id=" +
          id
      )
      .then((res) => {
        console.log(res.data);
        setitemPricingDetails(res.data.itemPricingSetupView);
        setItemPricingAddtionalDetails(res.data.itemPricingAdditionalPricesViews);
        setitemPricingCustomerDetails(res.data.itemPricingCustomerViews);
      });
  }, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <div>
      <button className="btn btn-success mt-2 " onClick={handlePrint}>
        Print
      </button>
      <hr />
      <div ref={componentRef}>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ flexDirection: "column" }}
        >
          <h2 style={{ fontWeight: "bold", fontSize: "30px" }}>
            A & H Solutions
          </h2>
          <h5 style={{ textDecoration: "underline", fontWeight: "bold" }}>
            Item Pricing Report
          </h5>
        </div>
        <div className="d-flex justify-content-between">
          {itemPricingDetails != null ? (
            <>
              <div className="d-flex" style={{ flexDirection: "column" }}>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Item Pricing Id: {itemPricingDetails.id}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Item Id: {itemPricingDetails.itemId}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Product Name: {itemPricingDetails.productName}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                    Base Unit Price: {itemPricingDetails.baseUnitPrice}
                </p>
              </div>
              <div className="d-flex" style={{ flexDirection: "column" }}>
              <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
              Profit Margin Type: {itemPricingDetails.profitMarginIsSpecific ? 'Specific' : 'Percentage'}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                Profit Margin Amount: {itemPricingDetails.profitMargin}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                Is Specific Customer: {itemPricingDetails.isSpecificCustomer? 'Yes' : 'No'}
                </p>
                <p className="p-0 m-0" style={{ lineHeight: "20px" }}>
                  Receive By: {itemPricingDetails.employeeName}
                </p>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div>
          <table className="table table-bordered  mt-3">
            <thead>
              <th className="text-center">S/N</th>
              <th className="text-center">Name</th>
              <th className="text-center">Value</th>
              <th className="text-center">Is specific</th>
              <th className="text-center">Operation</th>
            </thead>
            <tbody>
              {itemPricingAddtionalDetails !=null
                ? itemPricingAddtionalDetails.map((dt, i) => {
                    return (
                      <tr>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-center">{dt.name}</td>
                        <td className="text-center">{dt.value}</td>
                        <td className="text-center">{dt.isSpecific ?'Specific':'Percentage'}</td>
                        <td className="text-center">{dt.isSum ?'Add':'Minus'}</td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>

        <div>
          <table className="table table-bordered table-fixed mt-3">
            <thead>
              <th className="text-center">S/N</th>
              <th className="text-center">Customer Name</th>
              <th className="text-center">is Specific Date</th>
              <th className="text-center">From Date</th>
              <th className="text-center">To Date</th>
            </thead>
            <tbody>
              {itemPricingCustomerDetails !=null
                ? itemPricingCustomerDetails.map((dt, i) => {
                    return (
                      <tr>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-center">{dt.customerName}</td>
                        <td className="text-center">{dt.isSpecificDate? 'Yes' : 'No'}</td>
                        <td className="text-center">
                          {dt.fromDate == null ? (
                            <span className="minor">{null}</span>
                          ) : (
                            <span className="adult">{dt.fromDate.toString()}</span>
                          )}
                        </td>
                        <td className="text-center">
                          {dt.toDate == null ? (
                            <span className="minor">{null}</span>
                          ) : (
                            <span className="adult">{dt.toDate.toString()}</span>
                          )}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default ItemPricingDetailsReport;
