import axios from "axios";
import jwtDecode from "jwt-decode";
import React from "react";

const apiAddress = process.env.REACT_APP_SECRET;

export const getAPI = async (props) => {
  var res = await axios
    .get(apiAddress + "/api/SalesOrder/GetSalesOrderByNumber/" + props)
    .then((response) => {
      return response.data;
    });
  return res;
};
export const getAllIssueDetailsByNumberAPI = async (props) => {
  var res = await axios
    .get(
      apiAddress +
        "/api/StockIssue/GetAllIssueDetailsByIssueNumber?number=" +
        props
    )
    .then((response) => {
      return response.data;
    });
  return res;
};

export const getAPIForIssue = async (props) => {
  var res = await axios
    .get(apiAddress + "/api/SalesOrder/GetSalesOrderByNumberForIssue/" + props)
    .then((response) => {
      return response.data;
    });
  return res;
};
