import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

export default function SimpleMap() {
  const defaultProps = {
    center: {
      lat: 23.8103,
      lng: 90.4125
    },
    zoom: 11
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '60vh', width: '100%' }}>
      {/* <GoogleMapReact
        bootstrapURLKeys={{ key: "" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={59.955413}
          lng={30.337844}
          text="My Marker"
        />
      </GoogleMapReact> */}
      {/* <div style="text-decoration:none; overflow:hidden;max-width:100%;width:500px;height:500px;">
        <div id="g-mapdisplay" style="height:100%; width:100%;max-width:100%;">
          <iframe style="height:100%;width:100%;border:0;" frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=Dhaka,+Bangladesh&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe>
        </div>
        <a class="from-embedmap-code" rel="nofollow" href="https://www.bootstrapskins.com/themes" id="grab-map-info">
          premium bootstrap themes
        </a>
        <style>#g-mapdisplay img.text-marker{max - width:none!important;background:none!important;}img{max - width:none}</style>
      </div> */}
      {/* <div className='overflow-hidden max-w-full w-[700px] h-[500px]'> */}
        <div id='embed-map-canvas' className='h-full w-full max-w-full'>
          <iframe 
          title='Map of Dhaka'
          loading='lazy'
          className='w-full h-full border-0'
          allowFullScreen
          src="https://www.google.com/maps/embed/v1/place?q=Dhaka,+Bangladesh&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8" frameborder="0"></iframe>
        </div>
      {/* </div> */}
    </div>
  );
}